import * as React from 'react';
import { styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { NavItem } from './NavItem';
import { IC_ARROW_RIGHT_WHITE } from 'assets/constants/imageConst';
import RBAC from 'components/RBAC';

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<img src={IC_ARROW_RIGHT_WHITE} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  padding: 0,
  '& .MuiAccordionSummary-expandIconWrapper': {
    marginRight: '20px',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
    marginRight: '20px',
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
}));

export default function NavItems({ item, handleChange, expanded }) {
  if (!item) {
    return <></>;
  }
  const { title, items } = item;

  return (
    <Accordion sx={{
      '&:before': {
        display: 'none'
      }
    }} disableGutters elevation={0} expanded={expanded === title} onChange={handleChange(title)}>
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <NavItem item={item} />
      </AccordionSummary>
      <AccordionDetails>
        {items.map((itemValue) => (
          <RBAC
          key={`rbac- ${itemValue.title}`}
            action={itemValue.rbacAction}
            yes={
          <NavItem key={itemValue.title} item={itemValue} />
            }
            />
        ))}
      </AccordionDetails>
    </Accordion>
  );
}

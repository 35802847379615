import React from 'react';
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material';
import Chip from '../Chip/Chip';
import { Tooltip } from '../FormControls';

const GroupChip = ({ data, width = 200 }) => {
  let hiddenData = [];
  let displayedData = [];
  const emptyChipWidth = 16;
  const numberChipWidth = emptyChipWidth + data?.length > 1 ? hiddenData?.length.toString()?.length : 0;
  let finalWidth = width - numberChipWidth;
  let dynamicWidth = 0;

  data?.forEach((element, index) => {
    const textLen = element?.name?.length;
    if ((dynamicWidth += textLen * 8) < finalWidth) {
      displayedData.push(element?.name);
    } else {
      hiddenData.push(element?.name);
    }
  });

  return (
    <Box sx={{ display: 'flex', columnGap: '4px' }}>
      {displayedData?.map((value) => (
        <Chip label={value} key={value} />
      ))}
      {hiddenData?.length > 0 && (
        <Tooltip
          title={
            <List dense disablePadding>
              {hiddenData?.map((value, index) => (
                <ListItem key={index} disablePadding>
                  <ListItemText
                    sx={{ marginTop: 0, marginBottom: 0 }}
                    primary={<Typography variant="cabin">{value}</Typography>}
                  />
                </ListItem>
              ))}
            </List>
          }
          arrow
          placement="top"
        >
          <div>
            <Chip label={`+${hiddenData?.length}`} />
          </div>
        </Tooltip>
      )}
    </Box>
  );
};

export default GroupChip;

import api from './apis/api';

class UsersServices {
  getUser(uuid,params) {
    return api.user.getUser(uuid,params);
  }

  addUser(data) {
    return api.user.AddUser(data);
  }

  updateUser(uuid, data) {
    return api.user.updateUser(uuid, data);
  }

  deleteUser(uuid) {
    return api.user.deleteUser(uuid);
  }

  resetPassword(uuid) {
    return api.user.resetPassword(uuid);
  }
  assignRoles(data) {
    return api.user.assignRoles(data);
  }

}

export default new UsersServices();

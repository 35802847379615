import React from 'react';
import { ReactSVG } from 'react-svg';

// MUI
import { Typography, Divider, Box, IconButton } from '@mui/material';
//Internal
import { IC_EDIT } from 'assets/constants/imageConst';
import { Button, Tooltip } from 'components/common/FormControls';
import WrapperBox from 'components/common/Wrapper/WrapperBox';
import Switch from 'components/common/FormControls/Switch';
import { TARGET_PRIMARY } from 'assets/constants/imageConst';
import { Chip } from 'components/common';
import ImageWrapper from 'components/common/Wrapper/ImageWrapper';
import RBAC from 'components/RBAC';
import {
  RISK_AI_LIGHTENING_INTEGRATIONS_CONNECT,
  RISK_AI_LIGHTENING_INTEGRATIONS_DELETE,
  RISK_AI_LIGHTENING_INTEGRATIONS_EDIT,
} from 'utils/permissionUtils';

const GridView = ({ data, handleConnect, handleEdit }) => {
  const { icon, title, byName, aliasName, isDisabled, aggregate_impact_score, uuid, type,manual_impact_score } = data;

  return (
    <WrapperBox padding="20px 16px">
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', marginBottom: '20px', alignItems: 'center' }}>
          <ImageWrapper>
            <img src={icon} className="svg-wrapper" />
          </ImageWrapper>
          <Box ml={1}>
            <Box sx={{ display: 'flex' }}>
              <Typography variant="subtitle2">{title}</Typography>
            </Box>
            <Typography variant="body2" color="secondary" display="flex">
              {byName && 'By '}
              <Typography variant="body2" color="primary" ml={0.25}>
                {byName}
              </Typography>
            </Typography>
          </Box>
        </div>
        <Box>
          {aggregate_impact_score||manual_impact_score ? (
            <Chip
              icon={<ReactSVG src={TARGET_PRIMARY} />}
              label={`Impact: ${manual_impact_score?manual_impact_score:aggregate_impact_score}`}
              color="primary"
            />
          ) : (
            ''
          )}
        </Box>
      </Box>
      <Divider />
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', alignItems: 'center' }}>
        <Box>
          <Typography variant="caption" color="secondary" sx={{ display: 'block' }}>
            Tenant Connector Name:
          </Typography>
          <Typography variant="caption" color="secondary">
            {aliasName || 'empty'}
          </Typography>
        </Box>
        {uuid ? (
          <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            <RBAC
              action={RISK_AI_LIGHTENING_INTEGRATIONS_EDIT}
              yes={
                <IconButton onClick={() => handleEdit(uuid, type)}>
                  <ReactSVG src={IC_EDIT} />
                </IconButton>
              }
            />
            <RBAC
              action={RISK_AI_LIGHTENING_INTEGRATIONS_DELETE}
              yes={<Switch defaultChecked />}
              no={<Switch defaultChecked disabled />}
            />
          </Box>
        ) : (
          <RBAC
            action={RISK_AI_LIGHTENING_INTEGRATIONS_CONNECT}
            yes={
              isDisabled && data.tooltip ? (
                <Tooltip title={data?.tooltip} width="100px">
                  <div>
                    <Button
                      size="small"
                      color="secondary"
                      disabled={isDisabled}
                      variant="outlined"
                      onClick={() => handleConnect(type)}
                    >
                      Connect
                    </Button>
                  </div>
                </Tooltip>
              ) : (
                <Button
                  size="small"
                  color="secondary"
                  disabled={isDisabled}
                  variant="outlined"
                  onClick={() => handleConnect(type)}
                >
                  Connect
                </Button>
              )
            }
          />
        )}
      </div>
    </WrapperBox>
  );
};

{
  /* <Tooltip title={data?.tooltip} width="100px">
<div>
  <Button
    size="small"
    color="secondary"
    disabled={isDisabled}
    variant="outlined"
    onClick={() => handleConnect(type)}
  >
    Connect
  </Button>
</div>
</Tooltip> */
}
export default GridView;

import { Box } from '@mui/material';
import React from 'react';
import { ReactSVG } from 'react-svg';
import styles from './Icon.module.scss';

function Icon({ src, size = 'default', variant, ...rest }) {
  let style = styles.default;
  if (size === 'small') {
    style = styles.small;
  } else if (size === 'large') {
    style = styles.large;
  }
  let colorStyle;
  if (variant === 'secondary') {
    colorStyle = {
      svg: {
        path: {
          fill: '#4F4A558F',
        },
      },
    };
  } else if (variant === 'default') {
    colorStyle = {
      svg: {
        path: {
          fill: '#4F4A55',
          fillOpacity: 1,
        },
      },
    };
  }

  return (
    <Box sx={colorStyle} {...rest}>
      <ReactSVG src={src} className={style} />
    </Box>
  );
}

export default Icon;

import { Box, CircularProgress, Typography } from '@mui/material';
import React from 'react';

function CommonLoader({ height }) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: height,
      }}
    >
      <Box>
        <CircularProgress />
      </Box>
      <Box mt={3}>
        <Typography variant="body2" color="text.secondary">
          Please wait...
        </Typography>
      </Box>
    </Box>
  );
}

export default CommonLoader;

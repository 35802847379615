import { Box, Typography } from '@mui/material';
import React from 'react';

function Heading({ title, subtitle }) {
  return (
    <Box sx={{ my: 4 }}>
      <Typography variant="h5" mb={1}>
        {title}
      </Typography>
      <Typography variant="body2" color="secondary">
        {subtitle}
      </Typography>
    </Box>
  );
}

export default Heading;

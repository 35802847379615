import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Paper,
  styled,
  IconButton,
} from '@mui/material';

import FolderCopyIcon from '@mui/icons-material/FolderCopy';

import { Avatar } from 'components/common';
import { NO_RECORD } from 'assets/constants/const';
import CommonLoader from 'components/common/Loader/CommonLoader';
import { useSelector } from 'react-redux';
import { IC_EDIT, IC_LEFT } from 'assets/constants/imageConst';
import { ReactSVG } from 'react-svg';
import { shouldBeMasked } from 'utils/general';
import Icon from 'components/common/Icon/Icon';

const tableHeader = [
  { field: 'code', title: 'Control Code', minWidth: 150, isSorting: true },
  { field: 'name', title: 'Control Name', minWidth: 300, isSorting: true },
  { field: 'description', title: 'Description' },
  { field: 'weight', title: 'Weight' },
  { field: 'action', title: 'Action' },
];

const WrappedText = styled(Box)({ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', width: '300px' });

const ControlManagement = ({
  dataPerPage,
  handleAddModal,
  setIsAscending,
  isAscending,
  setSortedField,
  sortedField,
  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  const { loading, controlList } = useSelector((state) => state.controls);

  const tableData = controlList?.controls ?? [];
  const currentPage = controlList?.current_page ?? 1;
  const total = controlList?.total ?? 0;

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="a dense table">
          <TableHead>
            <TableRow>
              {tableHeader.map((th) => (
                <TableCell
                  align="left"
                  key={th.title}
                  sx={{ minWidth: th?.minWidth }}
                  sortDirection={sortedField == th.field ? (isAscending ? 'asc' : 'desc') : false}
                >
                  <Typography variant="subtitle2" color="secondary">
                    {th.title}
                    {th?.isSorting && (
                      <IconButton
                        color="inherit"
                        size="small"
                        onClick={() => {
                          if (th.field == sortedField) {
                            setIsAscending(!isAscending);
                          } else {
                            setIsAscending(false);
                          }
                          setSortedField(th.field);
                        }}
                      >
                        <Icon
                          size="small"
                          src={IC_LEFT}
                          className={`${
                            sortedField == th.field ? (isAscending ? 'left-arrow' : 'right-arrow') : 'left-arrow'
                          }`}
                        />
                      </IconButton>
                    )}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          {loading ? (
            <TableBody>
              <TableCell colSpan={6} rowSpan={10} sx={{ textAlign: 'center', py: 4 }}>
                <CommonLoader />
              </TableCell>
            </TableBody>
          ) : (
            <TableBody>
              {tableData?.map((row, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
                      {row.code || '-'}
                    </TableCell>

                    <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
                      {row.name || '-'}
                    </TableCell>

                    <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
                      <WrappedText title={row.description}>{row.description || '-'}</WrappedText>
                    </TableCell>

                    <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
                      {row.weight || '-'}
                    </TableCell>
                    <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
                      <Box sx={{ display: 'flex', columnGap: '16px', alignItems: 'center' }}>
                        <Avatar variant="rounded" size="small" onClick={() => handleAddModal(true, row)}>
                          <IconButton>
                            <ReactSVG src={IC_EDIT} />
                          </IconButton>
                        </Avatar>
                        {/* <IconButton>
                            <Avatar variant="rounded" size="small">
                              <FolderCopyIcon />
                            </Avatar>
                          </IconButton> */}
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
              {total < 1 && (
                <TableRow>
                  <TableCell colSpan={6} sx={{ textAlign: 'center', py: 4 }}>
                    {NO_RECORD}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={total}
          rowsPerPage={dataPerPage}
          page={currentPage - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Paper>
  );
};

export default ControlManagement;

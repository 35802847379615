import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

// Internal
import { setBreadcrumbs } from 'store/actions/breadcrumbs';
import { Box } from '@mui/material';

const path = {
  '/soc': [
    { path: 'risk-analysis', label: 'Compliance' },
    { path: '', label: 'SOC 2' },
  ],
  '/hitrust': [
    { path: 'risk-analysis', label: 'Compliance' },
    { path: '', label: 'HITRUST' },
  ],
  '/pci': [
    { path: 'risk-analysis', label: 'Compliance' },
    { path: '', label: 'PCI' },
  ],
  '/iso': [
    { path: 'risk-analysis', label: 'Compliance' },
    { path: '', label: 'ISO 27001' },
  ],
  '/third-parties': [{ path: '', label: 'Third Parties' }],
  '/risk-management': [{ path: '', label: 'Risk Management' }],
  '/technical-testing': [{ path: '', label: 'Technical Testing' }],
  '/it-assets': [
    { path: 'risk-analysis', label: 'Risk Analysis' },
    { path: '', label: 'It Assets' },
  ],
};

const ComingSoonPage = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(setBreadcrumbs(path[pathname]));
  });
  return (
    <Box>
      <h1>Coming Soon</h1>
    </Box>
  );
};
export default ComingSoonPage;

import React from 'react';
import { Box, styled } from '@mui/material';

const CustomBox = styled(Box)(({ theme }) => ({
  border: theme.palette.border.dashedBorder,
  borderRadius: theme.palette.borderRadius.main,
  textAlign: 'center',
  padding: '16px',
}));
function DashedBox({ children, ...props }) {
  return <CustomBox {...props}>{children}</CustomBox>;
}

export default DashedBox;

import { Box, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  ReferenceDot,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

const CustomTooltip = (props) => {
  const { active, payload } = props;
  if (active) {
    const currData = payload && payload.length ? payload[0].payload : null;

    return (
      <Box sx={{ background: '#fff', borderRadius: '4px', padding: '8px' }}>
        <Typography variant="body2">{currData?.average || ''}</Typography>
      </Box>
    );
  }

  return null;
};

const getTicks = (startDate, num) => {
  const ticks = [startDate];
  for (let i = 1; i < num; i++) {
    ticks.push(
      Number(
        moment(startDate)
          .add(7 * i, 'days')
          .format('x')
      )
    );
  }
  return ticks;
};

const dateFormatter = (date) => {
  return moment(date).format('MMM DD');
};

const renderReference = (props) => {
  const { cx, cy } = props;
  return (
    <g cx={cx} cy={cy}>
      <circle fill="#3F31FA" r="3" cx={cx} cy={cy} />
    </g>
  );
};

function DateWiseGraph({ height = 200, data }) {
  const startDate = data[0].date;
  const ticks = getTicks(startDate, 5);
  const lastValue = data[data.length - 1];
  const domain = [(dataMin) => dataMin, 'auto'];
  return (
    <ResponsiveContainer height={height}>
      <ComposedChart height={250} data={data} margin={{ left: 17, right: 15 }}>
        <CartesianGrid vertical={true} horizontal={false} stroke="#4F4A553B" strokeOpacity={0.3} />
        <XAxis
          dataKey="date"
          tickFormatter={dateFormatter}
          tick={{ fontSize: 12, color: '#4F4A5599', fill: '#4F4A5599' }}
          domain={domain}
          scale="time"
          tickMargin={5}
          type="number"
          ticks={ticks}
          allowDuplicatedCategory={false}
          tickLine={false}
          axisLine={false}
        />
        <YAxis axisLine={false} tick={false} domain={[0, 103]} tickLine={false} width={10} />
        <defs>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="100%" stopColor="#6F00E71A" stopOpacity={0.8} />
            <stop stopColor="#6F00E71A" stopOpacity={0} />
          </linearGradient>
        </defs>
        <Tooltip content={<CustomTooltip />} cursor={false} />

        {lastValue?.average > 0 && <ReferenceDot x={lastValue?.date} y={lastValue?.average} shape={renderReference} />}
        <Line dataKey="average" type="monotone" stroke="#3F31FA" dot={false} />
        <Area type="monotone" dataKey="average" fill="url(#colorPv)" dot={false} stroke="" />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

export default DateWiseGraph;

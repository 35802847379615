import { useEffect, useMemo } from 'react';
import { ReactSVG } from 'react-svg';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import _ from 'lodash';
import { toast } from 'react-toastify';

// MUI
import { Typography, Box, Grid, Snackbar, Skeleton, CircularProgress } from '@mui/material';

// Internal
import {
  CUSTOM_CONNECTOR,
  IC_CLOUD_OFFLINE,
  IC_DOWNLOAD,
  IC_USER_GROUP,
  IC_WARNING_ALT,
  TARGET_PRIMARY,
  USER,
} from 'assets/constants/imageConst';
import { Tabs, Chip } from 'components/common';
import { Button, Tooltip } from 'components/common/FormControls';
import ProgressCard from 'components/common/Card/ProgressCard';
import CommonLoader from 'components/common/Loader/CommonLoader';
import ConnectorData from './ConnectorData';
import { setBreadcrumbs } from 'store/actions/breadcrumbs';
import {
  breadcrumbsData,
  connectorData,
  connectorsName,
  impactWithRecords,
  impactWithSensitiveData,
  impactWithUsers,
} from 'assets/constants/const';
import {
  getConnectorNoteAndDcoument,
  getConnectorRuns,
  getConnectorRunsInProgress,
  getProgressScore,
  getScore,
  getVulnerabilityList,
  updateRiskScore,
} from 'store/actions/riskAnalysis';
import HeatGraph from 'components/common/Graph/HeatGraph';
import WrapperBox from 'components/common/Wrapper/WrapperBox';
import { BACKEND_SHORT_DATE, getDateFormat, startOfMonth } from 'utils/date';
import ImageWrapper from 'components/common/Wrapper/ImageWrapper';
import Icon from 'components/common/Icon/Icon';
import Alert from 'components/common/Alert/Alert';
import FlexBox from 'components/common/Wrapper/FlexBox';
import UpdatedDate from 'components/common/UpdatedDate/UpdatedDate';
import DateWiseGraph from 'components/common/Graph/DateWiseGraph';
import moment from 'moment';
import { getTrendValues, getLabelFromRisk, shouldBeMasked, objectToStringConvertor } from 'utils/general';
import ButtonSlider from 'components/common/ButtonSlider/ButtonSlider';
import RBAC from 'components/RBAC';
import {
  RISK_ANALYSIS_INFORMATION_SYSTEMS_CONNECTOR_RUN_RISK_ANALYSIS,
  RISK_ANALYSIS_INFORMATION_SYSTEMS_CONNECTOR_UPDATE_RISK_SCORE,
} from 'utils/permissionUtils';
import { generateConnectorPDF } from 'utils/PDFgenrator';
import {
  addControlsToVulnerability,
  getControlLibraryFramework,
  getControlLibraryList,
} from 'store/actions/controlLibrary';
import * as actionTypes from 'store/actions/actionTypes';

const GoogleSystem = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const {
    loading,
    tenant_connector_data,
    selectedControls,
    isComplete,
    totalVulnerabilities,
    isInProgress,
    preSelectedControls,
    riskAnalysisScoreData,
    controls_comparison_score,
    vulnerabilities,
    newlyAddedControls,
    risk_score
  } = useSelector((state) => state.riskAnalysis);
  const params = useParams();
  const workSpaceName = params?.id;

  const { tenantData } = useSelector((state) => state.common);
  const { id, aggregate_impact_score, smallIcon, userConfig } = state;

  const systemData =
    workSpaceName == connectorsName.CUSTOM
      ? {
          logo: state?.icon,
          title: state?.name || 'Custom Connector',
          userConfig: userConfig,
        }
      : workSpaceName == connectorsName.GDRIVE
      ? {
          ...connectorData[workSpaceName],
          userConfig: userConfig,
        }
      : connectorData[workSpaceName];

  const customBreadcums = [
    { path: '/risk-analysis', label: 'Risk Analysis' },
    { path: '', label: state?.name },
  ];

  const progressResponseRatioSinceLastWeek = getTrendValues(
    controls_comparison_score,
    'control_response_ratio_diff_since_seven_day'
  );

  let timeInterval;

  useEffect(() => {
    if (id && isInProgress) {
      timeInterval = setInterval(() => {
        // Call Integration Runs API
        dispatch(getConnectorRunsInProgress(id));
      }, 3 * 1000); // set for 3 sec
    }
    if (!isInProgress) {
      clearInterval(timeInterval);
    }
    return () => {
      clearInterval(timeInterval);
    };
  }, [isInProgress]);

  useEffect(() => {
    if (isComplete) {
      toast.success('Your changes have been saved successfully. An updated Risk Score is now available.');
    }
  }, [isComplete]);

  useEffect(() => {
    dispatch(
      setBreadcrumbs(
        workSpaceName == connectorsName.CUSTOM ? customBreadcums : breadcrumbsData.riskAnalysisConnectors[workSpaceName]
      )
    );
    if (id && tenantData?.uuid) {
      dispatch(getControlLibraryFramework(tenantData?.uuid));
      dispatch(getConnectorRuns(id));
      dispatch(getConnectorNoteAndDcoument(id));
      dispatch(
        getScore({
          // connector_name: workSpaceName,
          end_date: moment().format(BACKEND_SHORT_DATE),
          start_date: moment(startOfMonth()).format(BACKEND_SHORT_DATE),
          // tenant_uuid: tenantData?.uuid,
          tenant_connector_uuid: id,
        })
      );
    }
    if (tenantData?.uuid) {
      dispatch(getProgressScore(tenantData?.uuid));
    }
  }, [id, tenantData]);

  useEffect(() => {
    dispatch(getControlLibraryList(tenantData?.uuid));
  }, []);

  const automaticConnectorConfiguration = {
    title: 'User configuration policies',
    icon: IC_USER_GROUP,
    tooltip: '',
    chip: <Chip label={systemData?.userConfig} icon={<ReactSVG src={smallIcon || ''} />} svgColor={false} />,
    progress: '',
  };

  const CustomConnectorCard = (
    <Box
      sx={{ display: 'flex', justifyContent: 'space-between', rowGap: '5px', width: '100%', flexDirection: 'column' }}
    >
      <Box sx={{ display: 'flex' }}>
        <Tooltip title="records">
          <div>
            <Chip
              label={objectToStringConvertor(systemData?.userConfig?.number_of_health_records)}
              icon={<ReactSVG src={USER} />}
            />
          </div>
        </Tooltip>
        <Typography variant="subtitle2" sx={{ padding: '4px 8px' }}>
          {`Impact: ${
            impactWithRecords[objectToStringConvertor(systemData?.userConfig?.number_of_health_records)] || 'N/A'
          }`}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Tooltip title="users">
          <div>
            <Chip
              label={objectToStringConvertor(systemData?.userConfig?.number_of_user)}
              icon={<ReactSVG src={USER} />}
            />
          </div>
        </Tooltip>
        <Typography variant="subtitle2" sx={{ padding: '4px 8px' }}>
          {`Impact: ${impactWithUsers[objectToStringConvertor(systemData?.userConfig?.number_of_user)] || 'N/A'}`}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Tooltip title="Type of sensitive data">
          <div>
            <Chip label={systemData?.userConfig?.type_of_sensitive_data} icon={<ReactSVG src={USER} />} />
          </div>
        </Tooltip>
        <Typography variant="subtitle2" sx={{ padding: '4px 8px' }}>
          {`Impact: ${impactWithSensitiveData[systemData?.userConfig?.type_of_sensitive_data] || 'N/A'}`}
        </Typography>
      </Box>
    </Box>
  );

  const manualConnectorConfiguration = {
    title: ' Inherit Impact Score',
    icon: IC_USER_GROUP,
    tooltip: '',
    chip: CustomConnectorCard,
    progress: '',
  };

  let cardData = [
    {
      title: 'Current progress',
      icon: progressResponseRatioSinceLastWeek?.icon,
      tooltip: 'Complete the control response in the tabs below to update your progress.',
      chip: (
        <Chip
          label={`${progressResponseRatioSinceLastWeek?.label || 0}% since last week`}
          color={progressResponseRatioSinceLastWeek?.color}
        />
      ),
      progress: `${controls_comparison_score?.control_response_ratio_average || 0}%`,
    },
  ];

  if (workSpaceName == connectorsName.CUSTOM || workSpaceName == connectorsName.GDRIVE) {
    cardData = [...cardData, manualConnectorConfiguration];
  } else {
    cardData = [...cardData, automaticConnectorConfiguration];
  }

  useEffect(() => {
    dispatch(getVulnerabilityList());
  }, []);

  const tabs = Object.entries(vulnerabilities)
    .filter(([, value]) =>
      _.some(value, (te) =>
        _.some(te, (tv) => tv.some((v) => totalVulnerabilities.map((name) => name.name).includes(v)))
      )
    )
    .map((v) => v[0]);

  const tabInfo = tabs?.map((tabName, index) => ({
    id: index,
    label: tabName,
    component: (
      <ConnectorData
        tabName={tabName}
        rawJson={vulnerabilities}
        vulnerabilities={totalVulnerabilities}
        isInProgress={isInProgress}
        tenant_connector_uuid={id}
      />
    ),
  }));

  // const handleRunIntegration = () => {
  //   dispatch(addConnectorRuns(id)).then(() => {
  //     dispatch(getConnectorRuns(id));
  //   });
  // };

  const handleUpdateRiskScore = () => {
    const selectedCode = selectedControls.map((v) => v.code);
    const preManualSelected = preSelectedControls.filter(
      (value) => !selectedCode.includes(value.code) && value.control_response_type === 'manual'
    );
    const data = {
      control_json: {
        controls: _.uniqBy([...preManualSelected, ...selectedControls], 'code'),
      },
      tenant_connector_id: tenant_connector_data?.tenant_connector_id,
      tenant_id: tenantData?.id,
    };
    dispatch(updateRiskScore({ data })).then(() => {
      dispatch(getConnectorRuns(id)).then(() => {
        dispatch(
          getScore({
            // connector_name: workSpaceName,
            end_date: moment().format(BACKEND_SHORT_DATE),
            start_date: moment(startOfMonth()).format(BACKEND_SHORT_DATE),
            // tenant_uuid: tenantData?.uuid,
            tenant_connector_uuid: id,
          })
        );
      });
      dispatch(getConnectorNoteAndDcoument(id));
    });
  };

  const handleAddControls = () => {
    if (newlyAddedControls && newlyAddedControls.length > 0) {
      const data = {
        data: {
          controls: newlyAddedControls.map((nd) => ({ control_id: nd.id, vulnerability_name: nd.vulnerability_name })),
        },
      };
      //call api for add controls
      dispatch(addControlsToVulnerability(id, data)).then(() => handleUpdateRiskScore());
    } else {
      handleUpdateRiskScore();
    }
    dispatch({
      type: actionTypes.SET_NEW_CONTROLS_TO_VULNERABILITY,
      payload: { data: [] },
    });
  };

  // if last job is processing then => 10 sec
  const showSnackBar =
    (selectedControls && selectedControls.length > 0) ||
    (newlyAddedControls && newlyAddedControls.length > 0) ||
    isInProgress;

  const snackBarMsg =
    (selectedControls && selectedControls.length > 0) || (newlyAddedControls && newlyAddedControls.length > 0)
      ? 'You have unsaved changes. Please click on the Update Risk Score button to save the changes you made.'
      : isInProgress
      ? 'Please wait. Your Risk Score is being updated.'
      : '';

  const graphData = riskAnalysisScoreData.map((value) => ({
    ...value,
    date: Number(moment(value.date, BACKEND_SHORT_DATE).format('x')),
    average: value.average === 0 ? undefined : Number(value.average),
  }));

  return (
    <>
      <Snackbar open={showSnackBar} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <div>
          <Alert severity="warning" variant="warning" icon={<Icon size="small" src={IC_WARNING_ALT} />}>
            {snackBarMsg}
          </Alert>
        </div>
      </Snackbar>
      <Grid container mb={6}>
        <Grid item xs={12} sm={4}>
          <Box sx={{ display: 'flex', columnGap: '16px', alignItems: 'center' }}>
            <ImageWrapper background="#fff" size="large" padding="12px">
              <img src={systemData?.logo} width="100%" height="100%" />
            </ImageWrapper>
            <Box>
              {risk_score?.aggregate_impact_score ? (
                <Chip
                  label={`Impact: ${risk_score?.aggregate_impact_score || 0}`}
                  color="primary"
                  icon={<ReactSVG src={TARGET_PRIMARY} />}
                />
              ) : (
                ''
              )}
              <Typography variant="h5" mt={1}>
                {systemData?.title}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={8} sx={{ justifyItems: 'end', display: 'grid' }}>
          <FlexBox sx={{ columnGap: '12px' }}>
            {!loading && tenant_connector_data?.created_at && (
              <FlexBox sx={{ columnGap: '5px' }}>
                <Icon size="small" src={IC_CLOUD_OFFLINE} />
                <Typography variant="subtitle2" color="secondary">
                  Updated <UpdatedDate date={tenant_connector_data?.created_at} />
                </Typography>
              </FlexBox>
            )}
            {loading && (
              <FlexBox sx={{ columnGap: '5px' }}>
                <CircularProgress color="secondary" size={13} />{' '}
                <Typography variant="subtitle2" color="secondary">
                  Updating
                </Typography>
              </FlexBox>
            )}
            <RBAC
              action={RISK_ANALYSIS_INFORMATION_SYSTEMS_CONNECTOR_UPDATE_RISK_SCORE}
              yes={
                <Tooltip
                  title={
                    !(
                      (selectedControls && selectedControls.length > 0) ||
                      (newlyAddedControls && newlyAddedControls.length > 0)
                    )
                      ? 'There’s nothing to update!'
                      : ''
                  }
                >
                  <div>
                    <Button
                      disabled={
                        !(
                          (selectedControls && selectedControls.length > 0) ||
                          (newlyAddedControls && newlyAddedControls.length > 0)
                        )
                      }
                      variant="filled"
                      color="secondary"
                      onClick={handleAddControls}
                    >
                      Update risk score
                    </Button>
                  </div>
                </Tooltip>
              }
            />
            {!loading && (
              <Button
                variant="filled"
                startIcon={<ReactSVG src={IC_DOWNLOAD} className="download-small-icon" />}
                onClick={() =>
                  generateConnectorPDF(
                    vulnerabilities,
                    tabInfo,
                    totalVulnerabilities,
                    systemData?.title,
                    tenantData,
                    Math.round(Number(controls_comparison_score?.risk_score_average)),
                    risk_score?.aggregate_impact_score
                  )
                }
              >
                Download risk report
              </Button>
            )}
          </FlexBox>
        </Grid>
      </Grid>
      <Grid container spacing={2} mb={6}>
        <Grid item xs={12} sm={6}>
          <Box mb={2}>
            {!loading && riskAnalysisScoreData?.length > 0 ? (
              <WrapperBox>
                <Box display="flex" sx={{ justifyContent: 'space-between', mb: 1 }}>
                  <Box>
                    <Typography variant="subtitle2">Current risk score</Typography>
                    <Box display="flex" sx={{ columnGap: '8px', mt: 0.5 }}>
                      {controls_comparison_score?.risk_score_average && (
                        <Typography variant="h5">
                          {Math.round(Number(controls_comparison_score?.risk_score_average))}
                        </Typography>
                      )}
                      {controls_comparison_score?.risk_score_average && (
                        <Chip
                          {...getLabelFromRisk(Number(controls_comparison_score?.risk_score_average))}
                          icon={<ReactSVG src={IC_WARNING_ALT} />}
                        />
                      )}
                    </Box>
                  </Box>
                  {shouldBeMasked() && <ButtonSlider name="This month" />}
                </Box>
                <DateWiseGraph data={graphData} />
              </WrapperBox>
            ) : (
              <WrapperBox>
                <Box display="flex" sx={{ justifyContent: 'space-between', mb: 2 }}>
                  <Box>
                    <Typography variant="subtitle2">Current risk score</Typography>
                  </Box>
                  {shouldBeMasked() && <ButtonSlider name="This month" />}
                </Box>
                <Skeleton variant="rounded" width={'auto'} height={218} />
              </WrapperBox>
            )}
          </Box>

          <Grid container spacing={2}>
            {cardData.map((cardValue, index) => (
              <Grid item key={index} xs={12} sm={6} sx={{ display: 'flex' }}>
                <ProgressCard
                  title={cardValue.title}
                  icon={cardValue.icon}
                  chip={cardValue.chip}
                  progress={cardValue.progress}
                  tooltip={cardValue.tooltip}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <WrapperBox padding="16px 16px 16px 0">
            <Box ml={2}>
              <Typography variant="subtitle2">Risk Heatmap</Typography>
              <Typography variant="caption" color="secondary">
                As of {getDateFormat(tenant_connector_data?.created_at)}
              </Typography>
            </Box>
            <Box mt={1.5}>
              <div style={{ height: 357 }}>
                <HeatGraph scoreData={totalVulnerabilities} />
              </div>
            </Box>
          </WrapperBox>
        </Grid>
      </Grid>

      {loading ? <CommonLoader height={400} /> : <Tabs tabInfo={tabInfo} />}
    </>
  );
};
export default GoogleSystem;

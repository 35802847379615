import { shouldBeMasked } from 'utils/general';
import {
  ATHENAHEALTH_LOGO,
  AWS_LOGO,
  AZURE_LOGO,
  EPIC_LOGO,
  GOOGLE_LOGO,
  G_DRIVE_LOGO,
  MICROSOFT_AD_LOGO,
  ONEDRIVE_LOGO,
  SLACK_LOGO,
} from './imageConst';

export default []
  .concat(
    shouldBeMasked(['production', 'staging'])
      ? [
          {
            icon: ATHENAHEALTH_LOGO,
            title: 'Athenahealth EMR',
            byName: 'Athenahealth',
            accountId: '',
            clientAlias: '',
            path: '/lightening/athenaHealth',
            isDisabled: true,
            type: 'AthenaHealth',
          },
          {
            icon: AWS_LOGO,
            title: 'AWS (Amazon Web Services)',
            byName: 'Amazon',
            accountId: '',
            clientAlias: '',
            path: '/lightening/aws',
            isDisabled: true,
            type: 'AWS',
          },
        ]
      : []
  )
  .concat([
    {
      icon: AZURE_LOGO,
      title: 'Azure Active Directory',
      byName: 'Microsoft',
      accountId: '',
      clientAlias: '',
      path: '/lightening/Azure',
      isDisabled: false,
      type: 'Azure AD',
    },
  ])
  .concat(
    shouldBeMasked(['production', 'staging'])
      ? [
          {
            icon: EPIC_LOGO,
            title: 'Epic',
            byName: 'Epic Systems',
            accountId: '',
            clientAlias: '',
            path: '/lightening/epic',
            isDisabled: true,
            type: 'Epic',
          },
        ]
      : []
  )
  .concat([
    {
      icon: GOOGLE_LOGO,
      title: 'Google Workspace',
      byName: 'Google',
      path: '/lightening/google',
      type: 'GSuite',
    },
    {
      icon: G_DRIVE_LOGO,
      title: 'Google Drive',
      byName: 'Google',
      path: '/lightening/google-drive',
      type: 'GDrive',
      isDisabled: false,
      tooltip: 'GSuite must connected for GDrive',
    },
    {
      icon: MICROSOFT_AD_LOGO,
      title: 'Microsoft Active Directory',
      byName: 'Microsoft',
      path: '/lightening/microsoft',
      type: 'MS AD',
    },
  ])
  .concat(
    shouldBeMasked(['production', 'staging'])
      ? [
          {
            icon: ONEDRIVE_LOGO,
            title: 'Microsoft OneDrive',
            byName: 'Microsoft',
            path: '/lightening/microsoft-one-drive',
            type: 'MS_ONE_DRIVE',
            isDisabled: true,
          },
          {
            icon: SLACK_LOGO,
            title: 'Slack',
            byName: 'Slack',
            path: '/lightening/slack',
            type: 'SLACK',
            isDisabled: true,
          },
        ]
      : []
  );

// {
//   icon: CISCO_BOX_LOGO,
//   title: 'Cisco',
//   byName: 'Cisco Systems',
//   accountId: '',
//   clientAlias: '',
//   path: '/lightening/cisco',
//   isDisabled: true,
//   type: 'Cisco',
// },

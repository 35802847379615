class StoreClass {
    get(key) {
      const data = sessionStorage.getItem(key)
      return data ?JSON.parse(data) : undefined ;
    }
  
    set(key, value) {
      sessionStorage.setItem(key, value);
    }
  
    remove(key) {
      sessionStorage.removeItem(key);
    }
  
    clearAll() {
      sessionStorage.clear();
    }
  }
  
  export default new StoreClass();
  
import { Box, Typography, InputAdornment } from '@mui/material';
import { dummyOptions, numberOfRecords, sensitiveData } from 'assets/constants/options';
import Input from 'components/common/FormField/Input/Input';
import Dropdown from 'components/common/FormField/Select/Dropdown';
import React from 'react';
import { UP_ARROW } from 'assets/constants/imageConst';
import { ReactSVG } from 'react-svg';

function GsuitForm({ formik }) {
  return (
    <>
      <Box mb={2}>
        <Typography variant="subtitle2">Polling Interval</Typography>
        <Typography variant="body2" mb={1} color="text.secondary">
          The interval at which this integration will run
        </Typography>
        <Dropdown
          options={dummyOptions}
          id="id"
          // label="Polling Interval"
          name="pollingInterval"
          formik={formik}
        />
      </Box>
      <Box mb={3}>
        <Input
          id="adminEmail"
          className="mb-3"
          fullWidth
          placeholder="ex. john@mycompany.com"
          label="Admin email"
          name="adminEmail"
          value={formik.values.adminEmail}
          onChange={formik.handleChange}
          error={formik.touched.adminEmail && Boolean(formik.errors.adminEmail)}
          helperText={formik.touched.adminEmail && formik.errors.adminEmail}
        />
      </Box>
      <Box mb={3}>
        <Input
          id="impactScore"
          className="mb-3"
          fullWidth
          label="Impact Score"
          name="impactScore"
          placeholder="N/A"
          info="We automatically calculate your application impact scores depending on the application below. You can also override this field by entering any value between 1 and 10."
          value={formik?.values?.impactScore}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}
              >
                <ReactSVG
                  src={UP_ARROW}
                  className="down-arrow"
                  onClick={() => {
                    formik.setFieldValue('impactScore', typeof formik?.values.impactScore == 'number' ? formik?.values.impactScore + 1 : 1);
                  }}
                />
                <ReactSVG
                  src={UP_ARROW}
                  onClick={() => {
                    formik.setFieldValue(
                      'impactScore',
                      formik?.values.impactScore && formik?.values.impactScore > 1 ? formik?.values.impactScore - 1 : 1
                    );
                  }}
                />
              </InputAdornment>
            ),
          }}
          // onChange={formik?.handleChange}
          error={formik.touched.impactScore && Boolean(formik.errors.impactScore)}
          helperText={formik.touched.impactScore && formik.errors.impactScore}
        />
      </Box>

      <Box mb={3}>
        <Typography variant="subtitle2" mb={1}>Number of records</Typography>
        <Dropdown options={numberOfRecords} id="id" name="numberOfHealthRecords" formik={formik} placeholder="Select an option" />
      </Box>

      <Box mb={3}>
        <Typography variant="subtitle2" mb={1}>Type of sensitive data</Typography>

        <Dropdown options={sensitiveData} id="id" name="sensitiveData"
          placeholder="Select an option"
          formik={formik} />
      </Box>
    </>
  );
}

export default GsuitForm;

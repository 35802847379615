import * as actionTypes from '../actions/actionTypes';
import _, { get } from 'lodash';

export const defaultState = {
  loading: false,
  error: null,
  data:null,
  changePasswordLoading:false,
  assignRolesLoading:false,
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {

    case actionTypes.GET_USER_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case actionTypes.GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: get(action['payload','data','payload'],null),
      };

    case actionTypes.GET_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actionTypes.ADD_USER_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case actionTypes.ADD_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case actionTypes.ADD_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actionTypes.DELETE_USER_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case actionTypes.DELETE_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case actionTypes.DELETE_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actionTypes.UPDATE_USER_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case actionTypes.UPDATE_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case actionTypes.UPDATE_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actionTypes.RESET_PASSWORD_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case actionTypes.RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case actionTypes.RESET_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

      case actionTypes.CHANGE_PASSWORD_START:
      return {
        ...state,
        changePasswordLoading: true,
        error: null,
      };

    case actionTypes.CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        changePasswordLoading: false,
      };
    }

    case actionTypes.CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        changePasswordLoading: false,
        error: action.payload,
      };

      case actionTypes.ASSIGN_ROLES_START:
      return {
        ...state,
        assignRolesLoading: true,
        error: null,
      };

    case actionTypes.ASSIGN_ROLES_SUCCESS: {
      return {
        ...state,
        assignRolesLoading: false,
      };
    }

    case actionTypes.ASSIGN_ROLES_ERROR:
      return {
        ...state,
        assignRolesLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, List } from '@mui/material';
import MultiMenu from './NavItems';
import NavItems from './NavItems';
import { NavItem } from './NavItem';
import RBAC from 'components/RBAC';

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  const [expanded, setExpanded] = useState('');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) =>

          <RBAC
            action={item.rbacAction}
            key={`rbac- ${item.title}`}
            yes={
              item?.isMulti ? (
                <NavItems handleChange={handleChange} expanded={expanded} item={item} key={item.title} />)
                :
                (<NavItem key={item.title} item={item} />
                )
            }
          />
        )}
        <MultiMenu />
      </List>
    </Box>
  );
}

import jsPDF from 'jspdf';
import _ from 'lodash';
import 'jspdf-autotable';
import { BACKEND_DATE_FORMAT, getDateFormat,FE_DATE_FORMAT } from 'utils/date';
import { RISKAI_PDF_LOGO, PRIMARY_FULL_RISK_AI_PNG_LOGO, RISK_CATEGORY, RISK_REGISTER, ANALYSIS_ELEMENTS, AUTOMATED_RISK_TREATMENT } from 'assets/constants/imageConst';
import moment from 'moment/moment';
import tenantServices from 'services/tenantServices';


var momentTZ = require('moment-timezone');

const textStyleOptions =(pdf)=> {
  return {
  maxWidth: pdf.internal.pageSize.width - 20,
  lineHeightFactor: 1.5
}
}

export const addFooter = (pdf, pageNumber) => {
  pdf.setPage(pageNumber);
  pdf.setFontSize(9.5);
  let y = pdf.internal.pageSize.height - 12;
  pdf.setDrawColor('#6F00E7');
  pdf.text(`.`, 20, y, {
    margin: { top: 10 }
  });
  pdf.line(10, y, pdf.internal.pageSize.width - 10, y);
  y += 5;
  pdf.setTextColor('#6F00E7');
  pdf.text(`www.riskai.com`, 12, pdf.internal.pageSize.height - 4, {
    // margin: { top: 10 }
  });
  pdf.text(`RISKAI | \u00A9 ${moment().format('YYYY')}`, pdf.internal.pageSize.width - 40, pdf.internal.pageSize.height - 4, {
    // margin: { top: 10 }
  });
};


export const genrateVulnTable = (data) => {
  const header = [['Control ID', 'Control', 'Control response',
    'Likelihood score', 'Impact score', 'Risk score', 'Last updated']];
  let tableData = [];

  _.map(data, (row) => {
    tableData.push([
      row.code,
      row.name,
      row.response ? row.response.toUpperCase() : '-',
      row.likelyhood_score ? row.likelyhood_score.toString() : '-',
      row.impact_score ? row.impact_score.toString() : '-',
      row.risk_score ? row.risk_score.toString() : '-',
      row.modified_at ?  moment(momentTZ.tz(row.modified_at, BACKEND_DATE_FORMAT, 'utc').tz(momentTZ.tz.guess())).format(FE_DATE_FORMAT) : '-'])
  });
  return { header, tableData }

}

export const renderData = (rawJsonFormat, vulnerabilities, tabName) => {
  let result = {};

  const json = rawJsonFormat[tabName];
  _.map(json, (value, key) => {
    let sources = {};
    _.map(value, (val, key2) => {
      const categories = {};
      _.map(val, (v) => {
        const findVulnerability = _.find(vulnerabilities, (vuln) => {
          return vuln.name == v;
        });
        if (findVulnerability) {
          categories[v] = findVulnerability;
        }
      });
      if (!_.isEmpty(categories)) {
        sources[key2] = categories;
      }
    });
    if (!_.isEmpty(sources)) {
      result[key] = sources;
    }
  });
  return result;
};

export const generateConnectorPDF = async (rawJsonFormat, arr, vulnerabilities, ConnectorName = 'report',tenantData,score,impactScore) => {
  const pdf = new jsPDF();
  const imapactScoreArr =[{'name':ConnectorName,impact_score:impactScore}];
  await StaticPages(pdf,tenantData,score,imapactScoreArr);
  pdf.addPage();
  
  let startY = 20;
  pdf.setFont("helvetica", "normal");
    pdf.setFontSize(20);
    pdf.setTextColor('#6F00E7');
    pdf.text('RISK REGISTER', 10, startY);
    pdf.setTextColor('#000000');
    startY+=10;
  arr.map(a => {
    pdf.setFontSize(20);
    startY += 10;

    // move to new page when the current content width is less than the page width.
    const autopageChange = () => {
      let currentPageHeight = pdf.internal.pageSize.height;
      if (startY + 60 > currentPageHeight) {
        pdf.addPage();
        pdf.setFont("helvetica", "normal");
        startY = 10;
      }
    }

    autopageChange();
    pdf.setFont("helvetica", "normal");
    pdf.text(a.label, 10, startY);
    pdf.line(10, startY + 5, pdf.getPageWidth() - 20, startY + 5);

    const data = renderData(rawJsonFormat, vulnerabilities, a.label);

    _.map(data, (raw, key) => {
      startY += 15;

      autopageChange();
      pdf.setFontSize(16);
      pdf.text(key, 12, startY);

      _.map(raw, (raw1, key1) => {
        startY += 10;

        autopageChange();
        pdf.setFontSize(12);
        pdf.setDrawColor(0);
        pdf.setFillColor(211, 211, 211);
        pdf.rect(12, startY - 6, pdf.getPageWidth() - 30, 10, 'FD');
        pdf.text(key1, 18, startY);

        _.map(raw1, (raw2, key2) => {
          startY += 10;

          autopageChange();
          pdf.setFontSize(12);
          pdf.text('\u2022 ' + key2, 18, startY);
          pdf.text(`Aggregate risk score: ${raw2.aggregate_risk_score.toString()}`, 150, startY);

          startY += 10;

          autopageChange();
          pdf.rect(20, startY, 50, 20);
          pdf.rect(80, startY, 50, 20);
          pdf.rect(140, startY, 50, 20);
          pdf.setFontSize(10);
          pdf.text('Aggregate likelihood score', 23, startY + 5);
          pdf.text("Aggregate impact score", 85, startY + 5);
          pdf.text("Aggregate risk score", 145, startY + 5);
          pdf.text(raw2.aggregate_likelihood_score.toString(), 45, startY + 15);
          pdf.text(raw2.aggregate_impact_score.toString(), 105, startY + 15);
          pdf.text(raw2.aggregate_risk_score.toString(), 165, startY + 15);

          startY += 30;

          autopageChange();
          const { header, tableData } = genrateVulnTable(raw2.controls);
          pdf.autoTable({
            head: header,
            body: tableData,
            startY,
            theme: 'plain',
            styles: {
              textColor: [0, 0, 0],
              lineWidth: 0.2,
              fontSize: 8,
              font: 'helvetica',
              fontStyle: 'normal'
            },
            headStyles: {
              fillColor: [229, 228, 226],
            },
          });

          startY = _.toInteger(pdf.lastAutoTable.finalY) + 10;
        })
      })
    })
  })
  const pageCountBeforeAddingFooter = pdf.internal.getNumberOfPages();
  for (let i = 1; i <= pageCountBeforeAddingFooter; i++) {
    addFooter(pdf, i);
  }
  const name = `${ConnectorName} - ${getDateFormat(moment())}.pdf`

  pdf.save(name);
};

async function getTenantDetail(name) {
  const response = await tenantServices.getTenant({per_page:-1,tenant_name:name});
  const tenantDetail = _.get(response, ['data', 'payload', 'data', '0'], null);
  return tenantDetail;

}

export const loadImage = url => new Promise(resolve => {
  const img = new Image()
  img.onload = () => resolve(url)
  img.src = url;
});

export const frontPage = async (pdf, tenantData) => {

  let startY = 10;
  pdf.setFont("helvetica", "normal");
  pdf.addImage(RISKAI_PDF_LOGO, 'png', 10, startY, pdf.internal.pageSize.width - 20, 160);
  startY += 180;
  pdf.addImage(PRIMARY_FULL_RISK_AI_PNG_LOGO, 'png', 20, startY, 35, 18);
  startY += 38;
  pdf.setFontSize(21);
  pdf.text('HIPAA Security Risk Analysis and', 20, startY);
  pdf.text('HIPAA Non-technical Evaluation', 20, startY + 10);
  pdf.text('for '+ tenantData?.site_name, 20, startY + 20);
  if(tenantData?.icon){
    try {
    const tenantDetail = await getTenantDetail(tenantData?.site_name);
    const icon =tenantDetail ?tenantDetail.icon:tenantData?.icon;
    const logo = await loadImage(icon);
    pdf.addImage(logo,'png',pdf.internal.pageSize.width -45,startY-10,25,25);
  } catch (error) {
      console.log(error);
  }
  }
    
  startY += 40;
  pdf.setFontSize(15);
  pdf.text(moment(tenantData?.created_at).format('MMMM D, YYYY'), 20, startY);
}
export const limitationOfRiskAnalysis = (pdf,customerName) => {
  pdf.addPage();
  let startY = 30;
  pdf.setFont("helvetica", "normal");
  pdf.setFontSize(20);
  pdf.setTextColor('#6F00E7');
  pdf.text('LIMITATIONS OF THE RISK ANALYSIS', 10, startY, textStyleOptions(pdf));
  startY += 15;
  pdf.setFontSize(12);
  pdf.setTextColor('#000000');

  pdf.text('The risk analysis is solely based on interviews and subjective observation, not objective technical reports or system/application testing.  It relies on information and documentation provided by '+customerName+' MSP and subject matter expert(s).\n\n' +
    "Control recommendations in this report are being made based on the information gathered and observations made during the completion of this engagement, taking into account best practices, resource constraints, and the controls that appear to be reasonable and appropriate for "+customerName+" environment.\n\n" +
    "Risk Analyses examine the information assets, threats, vulnerabilities and risks of an organization at a specific point in time. It is the responsibility of "+customerName+" to achieve, demonstrate, and maintain their cyber risk management program.  Therefore, RiskAI makes no representation or warranty as to whether "+customerName+" network and/or computer systems are secure from either an internal or an external attack or whether sensitive data is at risk of being compromised.\n", 10, startY, textStyleOptions(pdf));
}
export const riskaiDataGatheringProcess = (pdf,customerName) => {
  pdf.addPage();
  let startY = 30;
  pdf.setFont("helvetica", "normal");
  pdf.setFontSize(20);
  pdf.setTextColor('#6F00E7');
  pdf.text('RISKAI DATA GATHERING PROCESS', 10, startY, textStyleOptions(pdf));
  startY += 15;
  pdf.setFontSize(12);
  pdf.setTextColor('#000000');
  pdf.text('RiskAI uses its proprietary data gathering technique to obtain control information from the relevant systems that are used to create, receive, maintain and/or transmit ePHI. The below-listed techniques are used to gather the above-mentioned data:\n\n' +
    "\u2022  Direct API/Integration\n" +
    "\u2022  Robotic Process Automation\n" +
    "\u2022  Guided Discovery\n\n" +
    "Once the data is gathered, RiskAI automates the calculation of risk by applying relevant controls to the reasonably anticipated threats and vulnerabilities, as seen below:"
    , 10, startY, textStyleOptions(pdf));
  startY += 70;
  pdf.addImage(AUTOMATED_RISK_TREATMENT, 'png', 20, startY, pdf.internal.pageSize.width - 50, 50);
  startY += 70;
  pdf.text("RiskAI also conducted an interview with "+customerName+" Managed Service Provider (Computer Service Innovations) who was identified as the appropriate subject matter expert of "+customerName+" Azure Active Directory Environment. CSI provided information about the configuration policies adopted and fully implemented by "+customerName+". \n" +
    "In addition to the historical information provided by "+customerName+" workforce, the RiskAI team used its professional experience and knowledge to estimate the other three factors.\n",
    10, startY, textStyleOptions(pdf));
}
export const addressingOCRRiskAnalysis = (pdf,customerName) => {
  pdf.addPage();
  let startY = 30;
  pdf.setFont("helvetica", "normal");
  pdf.setFontSize(20);
  pdf.setTextColor('#6F00E7');
  pdf.text('ADDRESSING OCR’S RISK ANALYSIS IN ALIGNMENT WITH OCR’S PHASE 2 AUDITS', 10, startY, textStyleOptions(pdf));
  startY += 25;
  pdf.setFontSize(12);
  pdf.setTextColor('#000000');
  pdf.text("In April 2016, OCR released its Phase 2 HIPAA Audit Program for reviews of HIPAA Privacy, Security and Breach Notification Rule compliance.  This Audit Program sets out the specific protocol to be followed to determine if the organization has met each Rule’s applicable requirements.  For each applicable requirement, the protocol specifies a Key Activity, Established Performance Criteria, and one or more Audit Inquiry tasks.  For the Risk Analysis requirement at 45 CFR §164.308(a)(1)(ii)(A), there are seven Audit Inquiry tasks.  Four of these tasks address the organization’s current Risk Analysis process, and include:" +
    "\n\n1. Has the entity conducted an accurate and thorough assessment of the potential risks and vulnerabilities to the confidentiality, integrity, and availability of all the ePHI it creates, receives, maintains, or transmits?\n" +
    "2. Determine how the entity has implemented the requirements\n" +
    "3. Obtain and review the written risk analysis or other record(s) that documents that an accurate and thorough assessment of the risks and vulnerabilities to the confidentiality, integrity, and availability of all ePHI was been conducted.  Evaluate and determine whether the risk analysis or other documentation contains:\n" +
    "  \u2022   A defined scope that identifies all of its systems that create, transmit, maintain, or transmit ePHI\n" +
    "  \u2022   Details of identified threats and vulnerabilities\n" +
    "  \u2022   Assessment of current security measures\n" +
    "  \u2022   Impact and likelihood analysis\n" +
    "  \u2022   Risk rating\n\n" +
    "4. Obtain and review documentation regarding the written risk analysis or other documentation that immediately preceded the current risk analysis or other record, if any.  Evaluate and determine if the risk analysis has been reviewed and updated on a periodic basis, in response to changes in the environment and/or operations, security incidents, or occurrence of a significant event.\n" +
    "The RiskAI Risk Analysis process helps organizations answer the questions these Audit Inquiry tasks pose regarding the organization’s compliance with this HIPAA Security Rule requirement.", 10, startY, textStyleOptions(pdf));
}
export const riskAnalysisMethodology = (pdf,customerName) => {
  pdf.addPage();
  let startY = 30;
  pdf.setFont("helvetica", "normal");
  pdf.setFontSize(20);
  pdf.setTextColor('#6F00E7');
  pdf.text('RISK ANALYSIS METHODOLOGY', 10, startY);
  startY += 10;
  pdf.setFontSize(16);
  pdf.text('BACKGROUND', 10, startY);
  startY += 10;
  pdf.setFontSize(12);
  pdf.setTextColor('#000000');
  pdf.text('RiskAI’s risk analysis algorithm utilizes an industry-accepted formula for determining risk:', 10, startY);
  startY += 10;
  pdf.setFontSize(14);
  pdf.text(`RISK = IMPACT X LIKELIHOOD`, (pdf.internal.pageSize.width /2)-50, startY);
  startY += 10;
  pdf.setFontSize(11);
  pdf.text(`Likelihood is the estimated probability of an adverse event to the organization considering the ability of a specific threat to exploit a specific vulnerability based on controls that are or are not in place for the specific system and associated devices.`, 10, startY,
    textStyleOptions(pdf));
  startY += 25;
  pdf.text(`Likelihood is automatically calculated within RiskAI as a function of the controls in place to reduce the probability of a threat exploiting a vulnerability.`, 10, startY,
    textStyleOptions(pdf));
  startY += 15;
  pdf.text(`Impact is the estimated magnitude of harm that can be expected to the organization if the confidentiality, integrity or availability of sensitive information was compromised were the specific threat to exploit the specific vulnerability given the predisposing conditions and controls currently in place for the specific system and associated devices.`, 10, startY,
    textStyleOptions(pdf));
  startY += 30;
  pdf.text("Impact is automatically calculated within RiskAI as a function of the below criteria:\n1. Volume of data within the system\n2. Type of sensitive data within the system\n3. Cloud vs. on-prem system\n4. Number of users who have access to the system", 10, startY, textStyleOptions(pdf));

  startY += 40;
  pdf.setFontSize(12);
  pdf.text("By applying the above-mentioned formula, RiskAI categorizes risks as Low, Medium, High and Critical as illustrated in the 10 X 10 matrix shown below.  The categorization of each risk will assist "+customerName+" to prioritize its risk remediation efforts.  Categorizing risks in this way enables prioritization and facilitates risk management decisions.", 10, startY, textStyleOptions(pdf));

  pdf.addPage();
  pdf.setFont("helvetica", "normal");
  startY = 20;
  pdf.addImage(RISK_REGISTER, 'png', 10, startY, pdf.internal.pageSize.width - 30, 160);
  startY += 180;
  pdf.setFontSize(12);
  pdf.text("The HIPAA Security Rule does not specify exactly how a risk analysis should be conducted. This guidance, in turn, references the NIST Risk Management Framework (“RMF”) and several specific documents such as NIST SP800-30 Revision 1 “Guide for Conducting Risk Assessments”.  This NIST publication offers a comprehensive approach to completing a risk analysis.  Threats in the environment are identified, and then vulnerabilities in the information assets are assessed.  Threats are then matched to vulnerabilities to describe risk.  The RiskAI Risk Analysis and Risk Management Methodology rigorously follow OCR guidance and the NIST Risk Management Framework.\n\nThe OCR’s “Guidance on Risk Analysis Requirements under the HIPAA Security Rule” describes nine (9) essential elements a Risk Analysis must incorporate, regardless of the methodology employed.  These elements are as follows:\n",
    10, startY, textStyleOptions(pdf));

  pdf.addPage();
  pdf.setFont("helvetica", "normal");
  startY = 20;
  pdf.addImage(ANALYSIS_ELEMENTS, 'png', 10, startY, pdf.internal.pageSize.width - 30, 100);
  startY += 120;

  pdf.text("This report and the comprehensive information captured within "+customerName+" subscription to the RiskAI software, provide evidence of "+customerName+" full compliance with elements 1-8 from the above list for the information assets reviewed during the engagement.  Compliance with element 9, Periodic Review and Updates to the Risk Analysis, can be illustrated by "+customerName+" repeating this process periodically as required.  RiskAI strongly recommends that a risk analysis be completed annually (at a minimum) and upon any material changes in "+customerName+" information systems organization, people, processes, and/or technology. The data populated in the RiskAI software during this engagement provides a baseline foundation and facilitates a workflow to enable continual update, documenting ongoing versions of "+customerName+" risk analyses and, just as importantly, facilitate and document "+customerName+" response to risks identified that exceed the risk threshold.",
    10, startY, textStyleOptions(pdf))
}
export const executiveSummary = (pdf,customerName,score) => {
  pdf.addPage();
  let startY = 30;
  pdf.setFont("helvetica", "normal");
  pdf.setFontSize(20);
  pdf.setTextColor('#6F00E7');
  pdf.text('EXECUTIVE SUMMARY – RISK ANALYSIS', 10, startY);
  startY += 10;
  pdf.setTextColor('#000000');
  pdf.setFontSize(12);
  const text1 = "RiskAI performed both a HIPAA Security Risk Analysis and a HIPAA Non-technical Evaluation of "+customerName+" environment. The Risk Analysis was performed on the relevant information systems that are used to create, receive, maintain and/or transmit electronic Protected Health Information (ePHI) through review of provided documentation, interviews, and analysis of controls against asset/threat/vulnerability combinations gathered through the RiskAI Proprietary data gathering technique.  Actual penetration testing of "+customerName+" information security controls was considered out-of-scope for this specific engagement, as agreed, but should be performed separately to further test the efficacy of administrative, physical and technical controls.\n\n" +
    "The RiskAI software was used to facilitate the methodology specifically outlined in U.S. Department of Health and Human Services (HHS) Office for Civil Rights (“OCR”) “Guidance on Risk Analysis Requirements under the HIPAA Security Rule” and the underlying National Institute of Standards and Technology (NIST) Special Publications on performing a risk assessment.  RiskAI’s methodology addresses all pertinent Audit Procedures specified in the 2016 OCR HIPAA Audit Protocol.\n\n" +
    "As part of this engagement, relevant systems and associated devices deemed to create, receive, maintain and/or transmit ePHI were entered into the RiskAI software and analyzed for relevant threats, vulnerabilities, and current controls.  For each threat and vulnerability scenario a separate risk rating was determined based on the likelihood that a specific threat could/will eventually exploit a specific vulnerability and the overall impact to "+customerName+" should such an adverse event take place.  The RiskAI™ software now retains "+customerName+" security risk posture and can be used as a management and reporting solution to prioritize and track implementation of relevant risk management activities.  A complete prioritized inventory of risks to the confidentiality, integrity and availability of ePHI is populated within RiskAI within the Risk Analysis section.   Risks are categorized numerically between 1-100.\n\n";
  pdf.text(text1,
    10, startY,
    textStyleOptions(pdf));

  pdf.addPage();
  startY = 30;
  pdf.setFont("helvetica", "normal");
  pdf.setFontSize(12);
  pdf.text("From RiskAI’s analysis and the data maintained within the RiskAI software, a summary of "+customerName+" identified risks follow in the table below:", 10, startY, textStyleOptions(pdf));
  startY += 15;
  pdf.setFontSize(14);
  pdf.text(`Risk score: ${score||''}`, (pdf.internal.pageSize.width /2)-40, startY);
  startY += 10;
  pdf.addImage(RISK_CATEGORY, 'png', (pdf.internal.pageSize.width / 2) - 40, startY, 80, 50);
  startY += 70;
  pdf.setFontSize(12);
  pdf.text("This report highlights the risks determined to be higher than "+customerName+" risk threshold which represent the most significant areas of risk to the ePHI that "+customerName+" creates, receives, maintains or transmits.  The Risk Threshold set by "+customerName+" has been set at 45 as seen below:",
    10, startY, textStyleOptions(pdf));
  startY += 35;
  pdf.setFontSize(14);
  pdf.text(`Your risk threshold: ${'45'}`, (pdf.internal.pageSize.width /2)-40, startY);
  startY += 10;
  pdf.setFontSize(12);

  pdf.text("RiskAI has been fully populated with system information, associated devices, threats and vulnerabilities, related controls, and risk calculations for all assets included in the analysis.  RiskAI essentially becomes a database repository for ongoing risk analysis and risk management activities created to meet explicit HIPAA Security Rule requirements and the OCR audit protocol pertaining to the Risk Analysis requirement at 45 CFR §164.308(a)(1)(ii)(A).",
    10, startY, textStyleOptions(pdf))
}

export const riskAnalysisInformationSysytem = (pdf,customerName,imapactScoreArr) => {
  pdf.addPage();
  let startY = 30;
  pdf.setFont("helvetica", "normal");
  pdf.setFontSize(20);
  pdf.setTextColor('#6F00E7');
  pdf.text('RISK ANALYSIS SCOPE', 10, startY);
  startY += 10;
  pdf.setFontSize(16);
  pdf.text('INFORMATION SYSTEMS', 10, startY);
  startY += 10;
  pdf.setTextColor('#000000');
  pdf.setFontSize(14);
  imapactScoreArr?.map(impact=>{
    pdf.text(`${impact.name} : ${impact.impact_score}`, (pdf.internal.pageSize.width/2)-50, startY);
    startY += 10;
  })

  pdf.setFontSize(12);
  pdf.setTextColor('#000000');
  pdf.text('The relevant systems that are used to create, receive, maintain and/or transmit ePHI are Athenahealth and MEDHOST with access via Active Directory. According to the integration with the '+customerName+' AD, there are 886 devices across 1034 users. As is standard in RiskAI’s Risk Analysis process, Athenahealth provided their HITRUST r2 certification to help better understand the security capabilities of the EHR application and MEDHOST provided their 2023 SOC report. Both reports are embedded below:', 10, startY,textStyleOptions(pdf));
  startY+=40;
  pdf.setTextColor('#6F00E7');
  pdf.setFont("Zapfdingbats");
  pdf.text('v',10,startY);
  pdf.text('v',10,startY+6);
  pdf.setFont("helvetica", "normal");
  pdf.setFontSize('11');
  pdf.textWithLink('Athenahealth – 2022 HITRUST r2 Certification',14,startY,{url:'https://drive.google.com/file/d/1cEnLGzBunSGUzvMy7rL_OyG7vl8KZgLb/view?usp=drive_link'})
  startY+=6;
  pdf.textWithLink('MEDHOST – 2023 SOC 1 Type II Report',14,startY,{url:'https://drive.google.com/file/d/1mViskb-J2vUbhD0CQIWNP1-AFJ5peIBg/view?usp=drive_link'})
  pdf.setTextColor('#000000');

}

export const StaticPages = async (pdf,tenantData,score,imapactScoreArr) =>{
  const customerName = tenantData?.site_name;
    await frontPage(pdf,tenantData);
    await executiveSummary(pdf,customerName,score);
    await riskAnalysisMethodology(pdf,customerName)
    await addressingOCRRiskAnalysis(pdf,customerName);
    await riskaiDataGatheringProcess(pdf,customerName);
    await limitationOfRiskAnalysis(pdf,customerName);
    await riskAnalysisInformationSysytem(pdf,customerName,imapactScoreArr);

}

import React, { useState } from 'react';
import { Box, Divider, FormControl, MenuItem, Select, styled, TextField, Typography } from '@mui/material';
import { USER, IC_CALENDER, IC_DOWN_CIRCLE, IC_QUESTION } from 'assets/constants/imageConst';
import { Chip } from 'components/common';
import { Button } from 'components/common/FormControls';
import Modal from 'components/common/Modal/Modal';
import Icon from 'components/common/Icon/Icon';

const CustomBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.75, 1),
  background: theme.palette.background.secondary4,
  borderRadius: theme.palette.borderRadius.main,
}));

function CreateTicketModal({ isOpen, handleCloseModal }) {
  const [selectValue, setSelectValue] = useState();
  const handleChange = (e) => {
    setSelectValue(e.target.value);
  };
  return (
    <Modal open={isOpen} title="Create ticket" handleClose={handleCloseModal}>
      <Box>
        <CustomBox>
          <Typography variant="body2">Implement device tracking</Typography>
        </CustomBox>
        <Box my={2}>
          <Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
            <Box sx={{ width: '30%' }}>
              <Box sx={{ display: 'flex', columnGap: '8px', alignItems: 'center' }}>
                <Icon src={IC_QUESTION} size="small" />
                <Typography variant="body2" color="secondary">
                  Risk response
                </Typography>
              </Box>
            </Box>
            <Box sx={{ width: '70%' }}>
              <Chip
                // icon={<Icon src={riskResponse[0].icon} className={riskResponse[0].class} />}
                // color={riskResponse[0].color}
                label="PES-14 Device tracking"
              />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
            <Box sx={{ width: '30%' }}>
              <Box sx={{ display: 'flex', columnGap: '8px', alignItems: 'center' }}>
                <Icon src={USER} className="ic_small" size="small" />
                <Typography variant="body2" color="secondary">
                  Activity owner
                </Typography>
              </Box>
            </Box>
            <Box sx={{ width: '70%' }}>
              <Typography variant="body2" color="secondary">
                Empty
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
            <Box sx={{ width: '30%' }}>
              <Box sx={{ display: 'flex', columnGap: '8px', alignItems: 'center' }}>
                <Icon src={IC_CALENDER} className="ic_small" size="small" />
                <Typography variant="body2" color="secondary">
                  Due date
                </Typography>
              </Box>
            </Box>
            <Box sx={{ width: '70%' }}>
              <Typography variant="body2">12/13/2022</Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
            <Box sx={{ width: '30%' }}>
              <Box sx={{ display: 'flex', columnGap: '8px', alignItems: 'center' }}>
                <Icon src={IC_DOWN_CIRCLE} className="ic_small" size="small" />
                <Typography variant="body2" color="secondary">
                  Status
                </Typography>
              </Box>
            </Box>
            <Box sx={{ width: '70%' }}>
              <FormControl fullWidth>
                <Select
                  value={selectValue}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  size="small"
                >
                  <MenuItem value="Incomplete">
                    <Chip label="Incomplete" />
                  </MenuItem>
                  <MenuItem value="In progress">
                    <Chip label="In progress" color="warning" />
                  </MenuItem>
                  <MenuItem value="Complete">
                    <Chip label="Complete" color="success" />
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Box>

        <Divider />
        <Box mt={2}>
          <TextField placeholder="Describe your action plan" fullWidth multiline rows={2} maxRows={4} />
        </Box>
        <Box mt={2} sx={{ textAlign: 'end' }}>
          <Button color="secondary" sx={{ mr: 1 }} onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="filled">Create task</Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default CreateTicketModal;

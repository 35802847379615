import React, { useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useSelector } from 'react-redux';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton,
} from '@mui/material';
import LockResetIcon from '@mui/icons-material/LockReset';
import LinkIcon from '@mui/icons-material/Link';
import _ from 'lodash';
import { IC_DUSTBIN, IC_EDIT, USER } from 'assets/constants/imageConst';
import { Avatar } from 'components/common';
import { NO_RECORD } from 'assets/constants/const';
import CommonLoader from 'components/common/Loader/CommonLoader';
import AsyncImage from 'components/common/AsyncImage';
import ConfirmationModal from 'components/common/Modal/ConfirmationModal';
import { Roles, shouldBeMasked } from 'utils/general';

const tableHeader = [
  { field: 'profile_pic', title: '' },
  { field: 'email', title: 'Email' },
  { field: 'name', title: 'Name' },
  { field: 'role', title: 'Role' },
  { field: 'action', title: '' },
];

function userTable({
  getUserList,
  handleAddModal,
  handleReset,
  handleDelete,
  dataPerPage,
  setDataPerPage,
  handleRolesModal
}) {
  const { userProfile } = useSelector((state) => state.common);
  const { userData, loading } = useSelector((state) => state.users);
  const { loading: userLoaing } = useSelector((state) => state.user);
  const tableData=userData?.data ?? [];
  const currentPage=userData?.current_page ?? 1;
  const total=userData?.total ?? 0;

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openResetModal, setOpenResetModal] = useState(false);

  const handleChangePage = (event, newPage) => {
    getUserList({ page: newPage + 1, per_page: dataPerPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setDataPerPage(event.target.value);
    getUserList({ page: 0, per_page: parseInt(event.target.value, 10) });
  };

  const getRole = (userDetail) => {
    if (userDetail?.role == 'TenantUser') {
      const userRole = _.get(userDetail, ['tenants', '0', 'role'], userDetail?.role);
      switch (userRole) {
        case 'Admin':
          return Roles.TENANT_ADMIN;
        case 'Auditor':
          return Roles.TENANT_AUDITIOR;
        case 'Maintainer':
          return Roles.TENANT_MAINTAINER;
      }
    }
    return userDetail?.role || '--';
  };

  return (
    <>
      <TableContainer sx={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
        <Table sx={{ minWidth: 650 }} size="medium" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {tableHeader?.map((th) => (
                <TableCell align="left" key={th.field}>
                  <Typography variant="subtitle2" color="secondary">
                    {th.title}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          {loading||userLoaing ? (
            <TableBody>
              <TableCell colSpan={6} sx={{ textAlign: 'center', py: 4 }}>
                <CommonLoader height={'100vh'} />
              </TableCell>
            </TableBody>
          ) : (
            <TableBody>
              {tableData?.map((row, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
                      {row?.profile_pic ? (
                        <AsyncImage fileName={row?.profile_pic} />
                      ) : (
                        <Avatar size="small">
                          <ReactSVG src={USER} />
                        </Avatar>
                      )}
                    </TableCell>
                    <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
                      {row.user_email || '--'}
                    </TableCell>

                    <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
                      {`${row.first_name} ${row.last_name}`}
                    </TableCell>

                    <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
                      {getRole(row)}
                    </TableCell>

                    <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
                      {row.id !== userProfile.id && (
                        <Box sx={{ display: 'flex', columnGap: '16px' }}>
                          <Avatar variant="rounded" size="small">
                            <IconButton onClick={() => handleAddModal(true, row)}>
                              <ReactSVG src={IC_EDIT} />
                            </IconButton>
                          </Avatar>

                          <Avatar variant="rounded" size="small">
                            <IconButton onClick={() => setOpenDeleteModal(row.uuid)}>
                              <ReactSVG src={IC_DUSTBIN} />
                            </IconButton>
                          </Avatar>
                          <Avatar variant="rounded" size="small">
                            <IconButton
                              onClick={() => {
                                setOpenResetModal(row.uuid);
                              }}
                              aria-label="reset password"
                              size="small"
                            >
                              <LockResetIcon />
                            </IconButton>
                          </Avatar>
                          {row.role == 'TenantUser' && [Roles.SUPER_USER, Roles.CHANNEL_PARTNER].includes(userProfile?.role) &&
                          <Avatar variant="rounded" size="small">
                            <IconButton
                              onClick={() => {
                                handleRolesModal(true,row);
                              }}
                              aria-label="link tenant"
                              size="small"
                            >
                              <LinkIcon />
                            </IconButton>
                          </Avatar>
              }
                        </Box>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
              {total < 1 && (
                <TableRow>
                  <TableCell colSpan={6} sx={{ textAlign: 'center', py: 4 }}>
                    {NO_RECORD}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
        {!(loading||userLoaing) && (
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={total}
            rowsPerPage={dataPerPage}
            page={currentPage - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </TableContainer>
      <ConfirmationModal
        title="Are you sure you want to delete this user?"
        open={openDeleteModal}
        handleClose={() => {
          setOpenDeleteModal(false);
        }}
        handleSubmit={() => {
          handleDelete(openDeleteModal);
          setOpenDeleteModal(false);
        }}
      />

      <ConfirmationModal
        title="Are you sure you want to reset the password?"
        open={openResetModal}
        handleClose={() => {
          setOpenResetModal(false);
        }}
        handleSubmit={() => {
          handleReset(openResetModal);
          setOpenResetModal(false);
        }}
      />
    </>
  );
}

export default userTable;

import { Box, Modal, Typography } from '@mui/material';
import React from 'react';
import { Button } from '../FormControls';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '8px',
  p: 4,
};

function ConfirmationModal({ open, handleClose, handleSubmit, title, subtitle, submitText, cancelText }) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box mb={2}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {title}
          </Typography>
          <Typography id="modal-modal-description" variant="body2" sx={{ mt: 0.5 }}>
            {subtitle}
          </Typography>
        </Box>
        <Box display="flex" mt={2}>
          <Button variant="filled" onClick={handleSubmit} sx={{ mr: 1 }}>
            {submitText}
          </Button>
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            {cancelText}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

ConfirmationModal.defaultProps = {
  submitText: 'Confirm',
  cancelText: 'Cancel',
};

export default ConfirmationModal;

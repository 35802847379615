// ----------------------------------------------------------------------

import {
  IC_ANALYSIS,
  IC_DASHBOARD,
  FILE,
  IC_FILE_RIGHTS,
  IC_LIGHTNING,
  IC_RISK_MANAGEMENT,
  IC_SHARE,
  IC_PEOPLES,
} from 'assets/constants/imageConst';
import ROUTE from 'assets/constants/route';
import { shouldBeMasked } from 'utils/general';
import {
  DASHBOARD,
  RISK_AI_LIGHTENING,
  RISK_ANALYSIS,
  COMPLIANCE,
  COMPLIANCE_HIPPA,
  RISK_ANALYSIS_CONTROL_LIBRARY,
  RISK_ANALYSIS_INFORMATION_SYSTEMS,
  RISK_ANALYSIS_IT_ASSESTS,
  RISK_MANAGMENT,
  COMPLIANCE_SOC,
  COMPLIANCE_HITRUST,
  COMPLIANCE_PCI,
  COMPLIANCE_ISO,
  TECHNICAL_TESTING,
  THIRD_PARTIES,
  USER_MANAGMENT,
  CONTROL_MANAGEMENT,
  CONTROL_MANAGEMENT_CONTROLS,
  CONTROL_MANAGEMENT_FRAMEWORKS,
  TENANT_MANAGMENT,
} from 'utils/permissionUtils';

// const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: <img src={IC_DASHBOARD} />,
    rbacAction: DASHBOARD,
  },
  {
    title: 'RiskAI Lightening',
    path: ROUTE.LIGHTNING_INTEGRATION,
    icon: <img src={IC_LIGHTNING} />,
    rbacAction: RISK_AI_LIGHTENING,
  },
  // {
  //   title: 'Personnel',
  //   icon: <img src={IC_PEOPLES} />,
  //   isMulti: true,

  // },
  {
    title: 'Compliance',
    icon: <img src={FILE} />,
    isMulti: true,
    rbacAction: COMPLIANCE,
    items: [
      {
        title: 'HIPAA',
        path: '/compliance/hipaa',
        rbacAction: COMPLIANCE_HIPPA,
      },
    ].concat(
      shouldBeMasked()
        ? [
            {
              title: 'SOC 2',
              path: '/soc',
              rbacAction: COMPLIANCE_SOC,
            },
            {
              title: 'HITRUST',
              path: '/hitrust',
              rbacAction: COMPLIANCE_HITRUST,
            },
            {
              title: 'PCI',
              path: '/pci',
              rbacAction: COMPLIANCE_PCI,
            },
            {
              title: 'ISO 27001',
              path: '/iso',
              rbacAction: COMPLIANCE_ISO,
            },
          ]
        : []
    ),
  },
  {
    title: 'Risk Analysis',
    icon: <img src={IC_ANALYSIS} />,
    isMulti: true,
    rbacAction: RISK_ANALYSIS,
    items: [
      {
        title: 'Information systems',
        path: '/risk-analysis',
        rbacAction: RISK_ANALYSIS_INFORMATION_SYSTEMS,
      },
    ]
      .concat(
        shouldBeMasked()
          ? [
              {
                title: 'IT assets',
                path: '/it-assets',
                rbacAction: RISK_ANALYSIS_IT_ASSESTS,
              },
            ]
          : []
      )
      .concat([
        {
          title: 'Control  library',
          path: '/control-library',
          rbacAction: RISK_ANALYSIS_CONTROL_LIBRARY,
        },
      ]),
  },
]
  .concat(
    shouldBeMasked()
      ? [
          {
            title: 'Risk Management',
            path: '/risk-management',
            icon: <img src={IC_RISK_MANAGEMENT} />,
            rbacAction: RISK_MANAGMENT,
          },
          {
            title: 'Technical Testing',
            path: '/technical-testing',
            icon: <img src={IC_FILE_RIGHTS} />,
            rbacAction: TECHNICAL_TESTING,
          },

          {
            title: 'Third parties',
            path: '/third-parties',
            icon: <img src={IC_SHARE} />,
            rbacAction: THIRD_PARTIES,
          },
        ]
      : []
  )
  .concat([
    {
      title: 'User managment',
      path: '/users',
      icon: <img src={IC_PEOPLES} />,
      rbacAction: USER_MANAGMENT,
    },
    {
      title: 'Control Management',
      icon: <img src={IC_ANALYSIS} />,
      isMulti: true,
      rbacAction: CONTROL_MANAGEMENT,

      items: [
        {
          title: 'Controls',
          path: '/control-management',
          rbacAction: CONTROL_MANAGEMENT_CONTROLS,
        },
        {
          title: 'Frameworks',
          path: '/framework-management',
          rbacAction: CONTROL_MANAGEMENT_FRAMEWORKS,
        },
      ],
    },
    {
      title: 'Tenant managment',
      path: '/tenants',
      icon: <img src={IC_PEOPLES} />,
      rbacAction: TENANT_MANAGMENT,
    },
  ]);

export default navConfig;

import api from './apis/api';

class RiskAnalysisServices {
  getRiskAnalysis(uuid) {
    return api.riskAnalysis.getRiskAnalysis(uuid);
  }
  getConnectorRuns(uuid) {
    return api.riskAnalysis.getConnectorRuns(uuid);
  }
  updateRiskScore(data) {
    return api.riskAnalysis.updateRiskScore(data);
  }
  getScore(data) {
    return api.riskAnalysis.getScore(data);
  }
  getScoreResponseRatio(data) {
    return api.riskAnalysis.getScoreResponseRatio(data);
  }
  getProgressScore(uuid) {
    return api.riskAnalysis.getProgressScore(uuid);
  }
  getVulnerabilityList() {
    return api.riskAnalysis.getVulnerabilityList();
  }
  getConnectorNoteAndDcoument(uuid) {
    return api.riskAnalysis.getConnectorNoteAndDcoument(uuid)
  }
}

export default new RiskAnalysisServices();

import userService from 'services/userServices';
import * as actionTypes from './actionTypes';

export const addUser = (data) => ({
  type: actionTypes.ADD_USER,
  payload: userService.addUser(data),
});

export const updateUser = (uuid,data) => ({
  type: actionTypes.UPDATE_USER,
  payload: userService.updateUser(uuid,data)
});

export const deleteUser = (uuid) => ({
  type: actionTypes.DELETE_USER,
  payload: userService.deleteUser(uuid)
});

export const resetPassword = (uuid) => ({
  type: actionTypes.RESET_PASSWORD,
  payload: userService.resetPassword(uuid)
});


export const getUser = (uuid,queryParams) => ({
  type: actionTypes.GET_USER,
  payload: userService.getUser(uuid,queryParams)
});

export const assignRoles = (data) => ({
  type: actionTypes.ASSIGN_ROLES,
  payload: userService.assignRoles(data)
});


import React from 'react';
import { ReactSVG } from 'react-svg';
import { Box, Divider, IconButton, Modal as MUIModal, Typography } from '@mui/material';
import { IC_CROSS } from 'assets/constants/imageConst';

function Modal({ open, handleClose, width = 600, title, children }) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: width,
    bgcolor: 'background.paper',
    borderRadius: '8px',
  };
  const titleStyle = {
    p: 2,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  };
  const bodyStyle = {
    p: 2,
    maxHeight: '600px',
    overflow: 'auto',
  };

  return (
    <MUIModal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={titleStyle}>
          <Typography variant="subtitle1">{title}</Typography>
          <IconButton onClick={handleClose}>
            <ReactSVG src={IC_CROSS} />
          </IconButton>
        </Box>
        <Divider />
        <Box sx={bodyStyle}>{children}</Box>
      </Box>
    </MUIModal>
  );
}

export default Modal;

import tenantServices from 'services/tenantServices';
import * as actionTypes from './actionTypes';



export const getTenant = (params) => ({
  type: actionTypes.GET_TENANT,
  payload: tenantServices.getTenant(params)
});

export const getTenantNoteAndDocument = (uuid) => ({
  type: actionTypes.GET_TENANT_NOTES_DOCUMENT_COUNT,
  payload: tenantServices.getTenantNoteAndDocument(uuid)
});

export const AddTenant = (data) => ({
  type: actionTypes.ADD_TENANT,
  payload: tenantServices.AddTenant(data),
});

export const updateTenant = (uuid,data) => ({
  type: actionTypes.UPDATE_TENANT,
  payload: tenantServices.updateTenant(uuid,data)
});

export const getTenantFrameworks = (uuid) => ({
  type: actionTypes.GET_TENANT_FRAMEWORKS,
  payload: tenantServices.getTenantFrameworks(uuid)
});

export const AddTenantFrameworks = (uuid,data) => ({
  type: actionTypes.ADD_TENANT_FRAMEWORKS,
  payload: tenantServices.AddTenantFrameworks(uuid,data),
});
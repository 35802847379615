import React from 'react';
import PropTypes from 'prop-types';
import { Alert as MUIAlert, styled } from '@mui/material';

Alert.propTypes = {
  children: PropTypes.node,
};

const CustomAlert = styled(MUIAlert)(({ theme }) => ({
  borderRadius: '8px',
  padding: '16px 16px 16px 8px',
  fontSize: '13px',
  fontWeight: '400',
  lineHeight: '16px',
  alignItems: 'center',
  '&.MuiAlert-warning': {
    background: theme.palette.background.linearWarning,
    border: theme.palette.border.solidWarning,
    '.MuiAlert-icon': {
      svg: {
        path: {
          fill: `${theme.palette.warning.main} !important`,
        },
      },
    },
  },
  '&.MuiAlert-success': {
    background: theme.palette.background.linearSuccess,
    border: theme.palette.border.solidSuccess,
    '.MuiAlert-icon': {
      svg: {
        path: {
          fill: `${theme.palette.success.main} !important`,
        },
      },
    },
  },
}));

function Alert({ children, ...rest }) {
  return <CustomAlert {...rest}>{children}</CustomAlert>;
}

export default Alert;

import api from './apis/api';

class TenantServices {
  getTenant(params) {
    return api.tenant.getTenant(params);
  }
  getTenantNoteAndDocument(uuid) {
    return api.tenant.getTenantNoteAndDocument(uuid)
  }

  AddTenant(data) {
    return api.tenant.AddTenant(data);
  }

  updateTenant(uuid, data) {
    return api.tenant.updateTenant(uuid, data);
  }

  getTenantFrameworks(uuid) {
    return api.tenant.getTenantFrameworks(uuid)
  }

  AddTenantFrameworks(uuid,data) {
    return api.tenant.AddTenantFrameworks(uuid,data);
  }
}

export default new TenantServices();

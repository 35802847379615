import React from 'react';
import { ReactSVG } from 'react-svg';
import { Box, LinearProgress, Typography } from '@mui/material';
import { PRIMARY_RISK_AI_LOGO, RIGHT_LOADING_ARROW, LEFT_LOADING_ARROW } from 'assets/constants/imageConst';
import ImageWrapper from 'components/common/Wrapper/ImageWrapper';
import Icon from 'components/common/Icon/Icon';

const WorkspaceCreationLoader = ({ logo, byName }) => {
  return (
    <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box sx={{ textAlign: 'center' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} mb={5}>
          <Box mr={3}>
            <ImageWrapper background="#fff" size="default" padding="12px">
              <Icon src={PRIMARY_RISK_AI_LOGO} size="large" />
            </ImageWrapper>
          </Box>
          <Box>
            <ReactSVG src={RIGHT_LOADING_ARROW} />
            <ReactSVG src={LEFT_LOADING_ARROW} />
          </Box>
          <Box ml={3}>
            {logo && (
              <ImageWrapper background="#fff" size="default" padding="12px">
                <img src={logo} width="100%" height="100%" />
              </ImageWrapper>
            )}
          </Box>
        </Box>
        <Box>
          <Typography variant="h6" mb={1}>
            Setting up your integration
          </Typography>
          <Typography variant="body2" mb={5} color="text.secondary">
            Please hang on while we’re establishing connection with {byName} Workspace.
          </Typography>
          <LinearProgress />
        </Box>
      </Box>
    </Box>
  );
};

export default WorkspaceCreationLoader;

import React from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceDot,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Box, Typography } from '@mui/material';
import moment from 'moment/moment';
import { BACKEND_SHORT_DATE } from 'utils/date';

const renderLegend = (props) => {
  const { payload } = props;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '16px' }}>
      {payload.map((entry, index) => (
        <Box key={`item-${index}`} sx={{ display: 'flex', alignItems: 'center', columnGap: '4px' }}>
          <Box
            sx={{
              backgroundColor: entry.type === 'circle' ? entry.color : '',
              borderRadius: '50%',
              height: '8px',
              width: '8px',
              border: entry.type === 'dashed' ? `1px dashed ${entry.color}` : ' ',
            }}
          />
          <Typography sx={{ fontSize: '14px', color: '#4F4A5599' }}>{entry.value}</Typography>
        </Box>
      ))}
    </Box>
  );
};

const getTicks = (startDate, num) => {
  const ticks = [startDate];
  for (let i = 1; i < num; i++) {
    ticks.push(
      Number(
        moment(startDate)
          .add(7 * i, 'days')
          .format('x')
      )
    );
  }
  return ticks;
};

const dateFormatter = (date) => {
  return moment(date).format('MMM DD');
};

const CustomTooltip = (props) => {
  const { active, payload } = props;
  if (active) {
    const currData = payload && payload.length ? payload[0].payload : null;
    if (currData?.average && currData?.lastAverage) {
      return (
        <Box sx={{ background: '#fff', borderRadius: '4px', padding: '8px' }}>
          <Typography variant="body2">This Month: {currData?.average}</Typography>
          <Typography variant="body2">Last Month: {currData?.lastAverage}</Typography>
        </Box>
      );
    } else {
      if (currData?.average || currData?.lastAverage) {
        return (
          <Box sx={{ background: '#fff', borderRadius: '4px', padding: '8px' }}>
            <Typography variant="body2">{currData?.average || currData?.lastAverage || ''}</Typography>
          </Box>
        );
      }
    }
  }

  return null;
};

const renderReference = (props) => {
  const { cx, cy } = props;
  return (
    <g cx={cx} cy={cy}>
      <circle fill="#3F31FA" r="3" cx={cx} cy={cy} />
    </g>
  );
};

function TrendGraph({ data, height = '350px' }) {
  const startDate = data[0].date;
  const ticks = getTicks(startDate, 5);
  const lastValue = data.find((v) => moment(v.date).format(BACKEND_SHORT_DATE) === moment().format(BACKEND_SHORT_DATE));

  const domain = [(dataMin) => dataMin, 'auto'];
  return (
    <ResponsiveContainer height={height}>
      <LineChart margin={{ left: 17, right: 15 }} height={height} data={data}>
        <CartesianGrid vertical={false} horizontal={true} stroke="#4F4A553B" strokeOpacity={0.3} />
        <XAxis
          dataKey="date"
          tickFormatter={dateFormatter}
          tick={{ fontSize: 12, color: '#4F4A5599', fill: '#4F4A5599' }}
          domain={domain}
          scale="time"
          tickMargin={5}
          type="number"
          ticks={ticks}
          allowDuplicatedCategory={false}
          tickLine={false}
          axisLine={false}
        />
        <YAxis
          tick={{ fontSize: 12, color: '#4F4A5599', fill: '#4F4A5599' }}
          orientation="right"
          type="number"
          axisLine={false}
          tickLine={false}
          ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          tickMargin={10}
        />
        <Tooltip content={<CustomTooltip />} cursor={false} />
        <Legend
          verticalAlign="top"
          height={36}
          align="right"
          payload={[
            { value: 'This month', type: 'circle', id: 'This month', color: '#8884d8' },
            {
              value: 'Last month',
              id: 'last_month',
              type: 'circle',
              color: '#CDD4E8',
            },
          ]}
          content={renderLegend}
          wrapperStyle={{ right: '157px', top: '-34px', width: 'auto' }}
        />
        {lastValue?.average > 0 && <ReferenceDot x={lastValue?.date} y={lastValue?.average} shape={renderReference} />}
        <Line dataKey="average" name="this month" type="monotone" stroke="#3F31FA" dot={false} />
        <Line dataKey="lastAverage" name="Last Week" type="monotone" stroke="#CDD4E8" dot={false} />
      </LineChart>
    </ResponsiveContainer>
  );
}

export default React.memo(TrendGraph);

import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText, InputLabel, MenuItem, OutlinedInput, Select,Paper } from '@mui/material';


const Dropdown = ({ options, id, label, name, error,value,helperText, placeholder,onChange,autoWidth }) => {

  return (
    <FormControl fullWidth>
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        labelId={id}
        autoWidth
        id={name}
        name={name}
        label={"label"}
        displayEmpty
        input={<OutlinedInput />}
        onChange={onChange}
        value={value}
        renderValue={(selected) => {
          if (selected) {
            const menuItem = options.find((option) => option.value === selected);
            return menuItem ? menuItem.label : selected;
          } else return placeholder;
        }}
        error={Boolean(error)}
        MenuProps={{
          PaperProps:{
            style:{
              maxHeight:'200px',
              overflowY:'auto'
            }
          }
        }}
      >
        {placeholder && (
          <MenuItem disabled value="">
            {placeholder}
          </MenuItem>
        )}
        {options?.length > 0 &&
          options.map((option) => (
            <MenuItem value={option.value} key={option.value}>
              {option.label}
            </MenuItem>
          ))}
      </Select>
      <FormHelperText error={Boolean(error)}>{helperText || error}</FormHelperText>
    </FormControl>
  );
};

Dropdown.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.string,
  variant: PropTypes.string,
  options: PropTypes.array,
};

Dropdown.defaultProps = {
  name: '',
  id: '',
  label: '',
  helperText: '',
  variant: 'standard',
  options: [],
};

export default Dropdown;

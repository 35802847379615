import { Tooltip as MUITooltip } from '@mui/material';

const Tooltip = ({ width = '200px', children, title }) => {
  return (
    <MUITooltip
      componentsProps={{
        tooltip: {
          sx: {
            maxWidth: width,
          },
        },
      }}
      title={title}
      placement="top"
      arrow
    >
      {children}
    </MUITooltip>
  );
};
export default Tooltip;

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';

// MUI
import { Box, styled, IconButton } from '@mui/material';

// Internal
import { IC_FILTER, IC_UPDATE, IC_SEARCH } from 'assets/constants/imageConst';
import { Button, SearchInput } from 'components/common/FormControls';
import MultiFilter from './MultiFilter';
import { IC_PLUS } from 'assets/constants/imageConst';
import Icon from 'components/common/Icon/Icon';
import ButtonDropdown from 'components/common/FormField/Select/ButtonDropdown';
import ControlTable from './ControlTable';
import * as actionTypes from 'store/actions/actionTypes';
import { getFilteredData, search, shouldBeMasked } from 'utils/general';
import { getControlLibraryList, saveControlLibraryResponse } from 'store/actions/controlLibrary';
import { toast } from 'react-toastify';

const saveMenu = [
  { label: 'Update current view', icon: IC_UPDATE },
  { label: 'Create new view', icon: IC_PLUS },
];

const FilteredComponent = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '20px',
});

const RightSideFilterComponent = styled(Box)({ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' });

const CustomRound = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  width: '12px',
  height: '12px',
  borderRadius: '50%',
  marginLeft: theme.spacing(0.5),
  color: theme.palette.common.white,
  fontSize: '10px',
  lineHeight: '12px',
}));

const AllControls = ({ controls }) => {
  const dispatch = useDispatch();
  const { filteredData, responseFilterValue, updateTabValue, frameworkFilterValue, controlLibraryList } = useSelector(
    (state) => state.controlsLibrary
  );
  const { tenantData, userProfile } = useSelector((state) => state.common);

  const [isSearchOpen, setSearchOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const handleFrameworkFilter = (name, value) => {
    const filter = frameworkFilterValue.map((v) => (v.framework_name === name ? { ...v, selected: value } : v));
    const finalFilteredData = getFilteredData(controlLibraryList, filter, responseFilterValue);

    if (filter.filter((v) => v.selected).length !== 1) {
      dispatch({
        type: actionTypes.SET_TAB_VALUE,
        payload: 0,
      });
    }

    dispatch({
      type: actionTypes.SET_FRAMEWORK_FILTER,
      payload: { filter, data: finalFilteredData },
    });
  };

  const handleResponseFilter = (name, value) => {
    const filter = responseFilterValue.map((v) => (v.name === name ? { ...v, selected: value } : v));
    const selectedFrameworkControls = getFilteredData(controlLibraryList, frameworkFilterValue, filter);

    dispatch({
      type: actionTypes.SET_RESPONSE_FILTER,
      payload: { filter, data: selectedFrameworkControls },
    });
  };

  const handleFrameworkSearch = (e) => {
    const targetVlaue = e.target.value;
    let filteredValue = search('framework_code', targetVlaue, frameworkFilterValue);
    dispatch({
      type: actionTypes.SET_FRAMEWORK_FILTER,
      payload: { data: filteredData, filter: filteredValue },
    });
    setSearchValue(targetVlaue);
  };

  const handleResetFilter = () => {
    dispatch({
      type: actionTypes.CLEAR_CONTROLS_LIBRARY_FILTER,
    });
    setSearchValue('');
  };

  const handleControlResponse = (control, value) => {
    const updatedControl = { ...control, response: value, created_by: userProfile?.uuid };
    dispatch({
      type: actionTypes.SET_CONTROL_REPONSE,
      payload: updatedControl,
    });
  };

  const saveResponseHandler = () => {
    const formattedData = controlLibraryList?.filter((r) => r?.response);

    const data = {
      data: {
        control_json: {
          controls: formattedData,
        },
      },
    };
    dispatch(saveControlLibraryResponse(tenantData?.uuid, data)).then(() => {
      toast.success('Control library updated successfully');
      dispatch(getControlLibraryList(tenantData?.uuid));
    });
  };

  const filterCount =
    responseFilterValue?.filter((v) => v.selected).length + frameworkFilterValue.filter((v) => v.selected).length;

  return (
    <Box sx={{ position: 'relative' }}>
      <FilteredComponent>
        <Box>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            startIcon={<ReactSVG src={IC_FILTER} size="small" />}
            onClick={() => setOpenFilter(!openFilter)}
          >
            Filters
            {filterCount > 0 && <CustomRound>{filterCount}</CustomRound>}
          </Button>
        </Box>
        <RightSideFilterComponent>
          {shouldBeMasked() && (
            <>
              {isSearchOpen ? (
                <SearchInput
                  onblur={() => setSearchOpen(false)}
                  searchValue={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              ) : (
                <IconButton onClick={() => setSearchOpen(true)}>
                  <Icon src={IC_SEARCH} size="small" />
                </IconButton>
              )}
            </>
          )}
          <Button variant="text" onClick={handleResetFilter} sx={{ mx: 1 }}>
            Reset filters
          </Button>
          {/* {shouldBeMasked() && ( */}
          <Button variant="filled" onClick={saveResponseHandler}>
            Save
          </Button>
          {/* )} */}
        </RightSideFilterComponent>
      </FilteredComponent>
      {openFilter && (
        <MultiFilter
          setOpenFilter={setOpenFilter}
          frameworkFilterValue={frameworkFilterValue}
          responseFilterValue={responseFilterValue}
          handleFrameworkFilter={handleFrameworkFilter}
          handleResponseFilter={handleResponseFilter}
          handleFrameworkSearch={handleFrameworkSearch}
          searchValue={searchValue}
        />
      )}
      <ControlTable tableData={controls} handleControlResponse={handleControlResponse} updateTab={updateTabValue} />
    </Box>
  );
};
export default AllControls;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { BACKEND_DATE_FORMAT2 } from 'utils/date';
import moment from 'moment/moment';
var momentTZ = require('moment-timezone');

const UpdatedDate = ({ date, dateFormat = BACKEND_DATE_FORMAT2 }) => {
  const [displayDate, setDisplayDate] = useState(
    moment(momentTZ.tz(date, dateFormat, 'utc').tz(momentTZ.tz.guess())).format('x')
  );
  const [count, setCount] = useState(1);

  useEffect(() => {
    let timeInterval;
    if (date) {
      setDisplayDate(moment(momentTZ.tz(date, dateFormat, 'utc').tz(momentTZ.tz.guess())).format('x'));
      timeInterval = setInterval(() => {
        setDisplayDate((d) => Number(d) + count);
        setCount((count) => count + 1);
      }, 1 * 60 * 1000);
    }

    return () => {
      clearInterval(timeInterval);
      setCount(1);
      setDisplayDate(moment(momentTZ.tz(date, dateFormat, 'utc').tz(momentTZ.tz.guess())).format('x'));
    };
  }, [date]);

  return <>{moment(Number(displayDate)).fromNow() || ''}</>;
};

UpdatedDate.propTypes = {
  date: PropTypes.string,
};

export default UpdatedDate;

import { PieChart, Pie, ResponsiveContainer } from 'recharts';
// MUI
import { Typography, Divider, Box, Grid, styled } from '@mui/material';
// Internal
import WrapperBox from 'components/common/Wrapper/WrapperBox';

const GraphBox = styled(Box)({ display: 'flex', flexDirection: 'column', rowGap: '8px' });
const GraphInnerBox = styled(Box)({
  display: 'flex',
  columnGap: '12px',
  justifyContent: 'flex-start',
  alignItems: 'center',
});

const numericDataHandler = (data) => {
  const totalValue = data.reduce((total, current) => ({ value: +total.value + +current.value }), { value: 0 })?.value;
  return (
    <GraphBox>
      {data.map((d, index) => (
        <Box sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }} key={index}>
          {d.icon}
          <GraphInnerBox>
            <Typography variant="body2" sx={{ width: '55px' }}>
              {d.name}
            </Typography>
            <Typography variant="body2" color="secondary" sx={{ width: '68.25px' }}>
              {d.value}
            </Typography>
            <Typography variant="body2" color="secondary" sx={{ width: '68.25px' }}>
              {d.percentage}
            </Typography>
          </GraphInnerBox>
        </Box>
      ))}
      <Divider />
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <svg height="8" width="8" style={{ marginRight: '6px' }}></svg>
        <GraphInnerBox>
          <Typography variant="body2" sx={{ width: '55px' }}>
            Total
          </Typography>
          <Typography variant="body2" color="secondary" sx={{ width: '68.25px' }}>
            {totalValue}
          </Typography>
          <Typography variant="body2" color="secondary" sx={{ width: '68.25px' }}>
            100%
          </Typography>
        </GraphInnerBox>
      </Box>
    </GraphBox>
  );
};

// Pie Graph Data Start

const data01 = [
  {
    name: 'Group A',
    value: 24,
    fill: '#6F00E7',
  },
  {
    name: 'Group B',
    value: 52,
    fill: '#A047FF',
  },
  {
    name: 'Group C',
    value: 24,
    fill: 'rgba(111, 0, 231, 0.3)',
  },
];
const data02 = [
  {
    name: 'Group A',
    value: 100,
    fill: '#6F00E7',
  },
  {
    name: 'Group B',
    value: 0,
    fill: '#A047FF',
  },
];

const data03 = [
  {
    name: 'Group 0',
    value: 2,
    fill: '#36C395',
  },
  {
    name: 'Group A',
    value: 88,
    fill: '#6F00E7',
  },
  {
    name: 'Group B',
    value: 9,
    fill: '#A047FF',
  },
  {
    name: 'Group C',
    value: 0,
    fill: 'rgba(111, 0, 231, 0.3)',
  },
];
const data04 = [
  {
    name: 'Group A',
    value: 6,
    fill: '#6F00E7',
  },
  {
    name: 'Group B',
    value: 0,
    fill: '#A047FF',
  },
  {
    name: 'Group C',
    value: 94,
    fill: 'rgba(111, 0, 231, 0.3)',
  },
];

// Pie Graph Data End

const severity = [
  {
    icon: (
      <svg height="8" width="8" style={{ marginRight: '6px' }}>
        <circle cx="4" cy="4" r="4" fill="#6F00E7" />
      </svg>
    ),
    name: 'Severity 5',
    value: '251',
    percentage: '24%',
  },
  {
    icon: (
      <svg height="8" width="8" style={{ marginRight: '6px' }}>
        <circle cx="4" cy="4" r="4" fill="#A047FF" />
      </svg>
    ),
    name: 'Severity 4',
    value: '534',
    percentage: '52%',
  },
  {
    icon: (
      <svg height="8" width="8" style={{ marginRight: '6px' }}>
        <circle cx="4" cy="4" r="4" fill="rgba(111, 0, 231, 0.3)" />
      </svg>
    ),
    name: 'Severity 3',
    value: '248',
    percentage: '24%',
  },
];

const type = [
  {
    icon: (
      <svg height="8" width="8" style={{ marginRight: '6px' }}>
        <circle cx="4" cy="4" r="4" fill="#6F00E7" />
      </svg>
    ),
    name: 'Confirmed',
    value: '1033',
    percentage: '100%',
  },
  {
    icon: (
      <svg height="8" width="8" style={{ marginRight: '6px' }}>
        <circle cx="4" cy="4" r="4" fill="#A047FF" />
      </svg>
    ),
    name: 'Potential',
    value: '0',
    percentage: '0%',
  },
];

const status = [
  {
    icon: (
      <svg height="8" width="8" style={{ marginRight: '6px' }}>
        <circle cx="4" cy="4" r="4" fill="#36C395" />
      </svg>
    ),
    name: 'New',
    value: '27',
    percentage: '2%',
  },
  {
    icon: (
      <svg height="8" width="8" style={{ marginRight: '6px' }}>
        <circle cx="4" cy="4" r="4" fill="#6F00E7" />
      </svg>
    ),
    name: 'Active',
    value: '1004',
    percentage: '88%',
  },
  {
    icon: (
      <svg height="8" width="8" style={{ marginRight: '6px' }}>
        <circle cx="4" cy="4" r="4" fill="#A047FF" />
      </svg>
    ),
    name: 'Fixed',
    value: '108',
    percentage: '9%',
  },
  {
    icon: (
      <svg height="8" width="8" style={{ marginRight: '6px' }}>
        <circle cx="4" cy="4" r="4" fill="rgba(111, 0, 231, 0.3)" />
      </svg>
    ),
    name: 'Reopened',
    value: '2',
    percentage: '0%',
  },
];
const age = [
  {
    icon: (
      <svg height="8" width="8" style={{ marginRight: '6px' }}>
        <circle cx="4" cy="4" r="4" fill="#6F00E7" />
      </svg>
    ),
    name: '30 Days',
    value: '66',
    percentage: '6%',
  },
  {
    icon: (
      <svg height="8" width="8" style={{ marginRight: '6px' }}>
        <circle cx="4" cy="4" r="4" fill="#A047FF" />
      </svg>
    ),
    name: '60 Days',
    value: '0',
    percentage: '0%',
  },
  {
    icon: (
      <svg height="8" width="8" style={{ marginRight: '6px' }}>
        <circle cx="4" cy="4" r="4" fill="rgba(111, 0, 231, 0.3)" />
      </svg>
    ),
    name: '90 Days',
    value: '962',
    percentage: '94%',
  },
];

const resultSummaryData = [
  { title: 'Vulnerability Distribution by Severity', graphData: data01, numericData: severity },
  { title: 'Vulnerability Distribution by Type', graphData: data02, numericData: type },
  { title: 'Vulnerability Distribution by Status', graphData: data03, numericData: status },
  { title: 'Vulnerability Distribution by Age', graphData: data04, numericData: age },
];

const ResultSummary = () => {
  return (
    <Box>
      <Grid container spacing={2}>
        {resultSummaryData.map((rs, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <WrapperBox padding="0" key={index}>
              <Box p={2}>
                <Typography variant="subtitle2">{rs.title}</Typography>
              </Box>
              <Divider />
              <Box p={2} sx={{ display: 'flex', columnGap: '40px', alignItems: 'center' }}>
                <PieChart width={200} height={200}>
                  <Pie
                    data={rs.graphData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    innerRadius={56}
                    outerRadius={70}
                    fill="#82ca9d"
                    stroke={0.1}
                    startAngle={-270}
                  />
                </PieChart>
                {numericDataHandler(rs.numericData)}
              </Box>
            </WrapperBox>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
export default ResultSummary;

import * as actionTypes from '../actions/actionTypes';
import { uniqBy } from 'lodash';

const responseFilter = [
  {
    name: 'Yes',
    value: 'yes',
    code: 'Yes',
    selected: false,
    display: true,
  },
  {
    name: 'In Progress',
    value: 'inprogress',
    code: 'In Progress',
    selected: false,
    display: true,
  },
  {
    name: 'No',
    value: 'no',
    code: 'No',
    selected: false,
    display: true,
  },
];

const defaultState = {
  loading: false,
  controlLibraryList: [],
  error: null,
  frameworkError: null,
  frameworkLoading: false,
  tenantFramework: [],
  responseFilterValue: responseFilter,
  updateTabValue: 0,
  filteredData: [],
  totalPercentage: 0,
  totalRespondControl: 0,
  totalControl: 0,
  frameworkFilterValue: [],
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_CONTROL_LIBRARY_LIST_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case actionTypes.GET_CONTROL_LIBRARY_LIST_SUCCESS: {
      const controls = action?.payload?.data?.payload?.controls;
      return {
        ...state,
        loading: false,
        error: null,
        controlLibraryList: controls,
        filteredData: controls,
        totalRespondControl: controls.filter((value) => value?.response).length,
        totalControl: controls?.length,
        totalPercentage: Math.round((controls.filter((value) => value?.response).length / controls?.length) * 100),
      };
    }
    case actionTypes.GET_CONTROL_LIBRARY_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actionTypes.GET_CONTROL_LIBRARY_FRAMEWORK_START:
      return {
        ...state,
        frameworkError: null,
        frameworkLoading: true,
      };

    case actionTypes.GET_CONTROL_LIBRARY_FRAMEWORK_SUCCESS: {
      const tenantFrameworks = action?.payload?.data?.payload?.tenant_frameworks;
      const uniqueFrameworks = uniqBy(
        tenantFrameworks.map((v) => ({ ...v, display: true, selected: false })),
        'framework_code'
      ).sort((a, b) => (a.id < b.id ? 1 : b.id < a.id ? -1 : 0));

      return {
        ...state,
        frameworkLoading: false,
        frameworkError: null,
        tenantFramework: uniqueFrameworks,
        frameworkFilterValue: uniqueFrameworks,
      };
    }
    case actionTypes.GET_CONTROL_LIBRARY_FRAMEWORK_ERROR:
      return {
        ...state,
        frameworkLoading: false,
        frameworkError: action.payload,
      };

    case actionTypes.SET_TAB_VALUE:
      return {
        ...state,
        updateTabValue: action.payload,
      };
    case actionTypes.SET_FRAMEWORK_FILTER:
      return {
        ...state,
        frameworkFilterValue: action.payload.filter,
        filteredData: action.payload.data,
      };

    case actionTypes.SET_RESPONSE_FILTER:
      return {
        ...state,
        responseFilterValue: action.payload.filter,
        filteredData: action.payload.data,
      };
    case actionTypes.CLEAR_CONTROLS_LIBRARY_FILTER:
      return {
        ...state,
        filteredData: state.controlLibraryList,
        responseFilterValue: responseFilter,
        frameworkFilterValue: state.tenantFramework,
        updateTabValue: 0,
      };
    case actionTypes.SAVE_CONTROL_LIBRARY_RESPONSE_START:
      return {
        ...state,
        responseSaveLoading: true,
        responseSaveError: null,
      };
    case actionTypes.SAVE_CONTROL_LIBRARY_RESPONSE_SUCCESS:
      return {
        ...state,
        responseSaveLoading: false,
        responseSaveError: null,
        controlLibraryList: action?.payload?.data?.payload?.controls,
      };
    case actionTypes.SAVE_CONTROL_LIBRARY_RESPONSE_ERROR:
      return {
        ...state,
        responseSaveLoading: false,
        responseSaveError: action.payload,
      };
    case actionTypes.SET_CONTROL_REPONSE: {
      let updatedFilteredControls = state.filteredData.map((value) =>
        value.code === action.payload.code ? { ...action.payload } : value
      );
      let updatedControls = state.controlLibraryList.map((value) =>
        value.code === action.payload.code ? { ...action.payload } : value
      );
      const totalRespondControl = updatedControls.filter((value) => value.response).length;
      const totalControl = updatedControls.length;
      const totalPercentage = Math.round((totalRespondControl / totalControl) * 100);
      return {
        ...state,
        controlLibraryList: updatedControls,
        filteredData: updatedFilteredControls,
        totalRespondControl,
        totalPercentage,
        totalControl,
      };
    }

    default:
      return state;
  }
};

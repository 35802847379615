import React, { useEffect } from 'react';
import { uniqBy } from 'lodash';

// MUI
import { Typography, Box, styled } from '@mui/material';
// Internal
import { IC_SWITCH_DOUBLE } from 'assets/constants/imageConst';
import Tabs from 'components/common/FormField/Tabs/Tabs';
import AllControls from './AllControls';
import { breadcrumbsData, controlsImages } from 'assets/constants/const';
import { setBreadcrumbs } from 'store/actions/breadcrumbs';
import { useDispatch, useSelector } from 'react-redux';
import * as actionTypes from 'store/actions/actionTypes';
import { getFilteredData } from 'utils/general';
import { getControlLibraryFramework, getControlLibraryList } from 'store/actions/controlLibrary';
import CommonLoader from 'components/common/Loader/CommonLoader';
import { getTenantNoteAndDocument } from 'store/actions/tenant';

const CustomContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  rowGap: '6px',
});

const ControlLibrary = () => {
  const dispatch = useDispatch();
  const {
    loading,
    updateTabValue,
    totalPercentage,
    totalRespondControl,
    frameworkLoading,
    tenantFramework,
    totalControl,
    filteredData,
    controlLibraryList,
    frameworkFilterValue,
    responseFilterValue,
  } = useSelector((state) => state.controlsLibrary);
  const { tenantData } = useSelector((state) => state.common);

  const handleTabValue = (value) => {
    let name = tenantFramework[value - 1]?.framework_name;
    const filter = tenantFramework.map((v) => (v.framework_name === name ? { ...v, selected: value } : v));
    const finalFilteredData = getFilteredData(controlLibraryList, filter, responseFilterValue);

    dispatch({
      type: actionTypes.SET_FRAMEWORK_FILTER,
      payload: { data: finalFilteredData, filter },
    });
    dispatch({
      type: actionTypes.SET_TAB_VALUE,
      payload: value,
    });
  };

  useEffect(() => {
    dispatch(setBreadcrumbs(breadcrumbsData.controlsLibrary));
    dispatch(getControlLibraryList(tenantData?.uuid));
    dispatch(getControlLibraryFramework(tenantData?.uuid));
    if (tenantData?.uuid) dispatch(getTenantNoteAndDocument(tenantData?.uuid));
  }, []);

  // TabData
  const tabs = tenantFramework?.map((tf) => {
    const controlsAccociatedWithFramework = controlLibraryList?.filter((control) =>
      control?.frameworks?.includes(tf?.framework_id)
    );

    const totalRespondControls = controlsAccociatedWithFramework?.filter((value) => value?.response).length;

    const percentage = Math.round((totalRespondControls / controlsAccociatedWithFramework?.length) * 100);

    return {
      name: tf.framework_name,
      code: tf.framework_code,
      icon: tf.framework_icon,
      totalControls: controlsAccociatedWithFramework?.length,
      totalRespondControls,
      percentage,
      data: controlsAccociatedWithFramework,
    };
  });

  let tabInfo = tabs.map((value, index) => {
    return {
      id: index + 1,
      img: value.icon || controlsImages[value.name],
      icon: value.icon || IC_SWITCH_DOUBLE,
      title: value.name,
      content: (
        <CustomContent>
          <Typography variant="subtitle1">{value?.percentage}%</Typography>
          <Typography variant="caption" color="secondary">
            {value?.totalRespondControls}/{value?.totalControls} controls answered
          </Typography>
        </CustomContent>
      ),
      component: <AllControls controls={value?.data} />,
    };
  });
  tabInfo.unshift({
    id: 0,
    icon: IC_SWITCH_DOUBLE,
    title: 'All controls',
    content: (
      <CustomContent>
        <Typography variant="subtitle1">{totalPercentage}%</Typography>
        <Typography variant="caption" color="secondary">
          {totalRespondControl}/{controlLibraryList?.length} controls answered
        </Typography>
      </CustomContent>
    ),
    component: <AllControls controls={filteredData} frameworkFilterValue={frameworkFilterValue} />,
  });

  return loading || frameworkLoading ? (
    <Box sx={{ height: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
      <CommonLoader />
    </Box>
  ) : (
    <Box>
      <Typography variant="h5">Control library</Typography>
      <Box mt={6}>
        <Typography variant="overline" color="secondary">
          Saved Views
        </Typography>
        <Tabs tabInfo={tabInfo} handleTabValue={handleTabValue} updateTab={updateTabValue} />
      </Box>
    </Box>
  );
};
export default ControlLibrary;

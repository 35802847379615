import { shouldBeMasked } from 'utils/general';
import {
  IC_SPY,
  IC_USER_GROUP,
  IC_BUG,
  USER,
  GOOGLE_SMALL,
  MICROSOFT_SMALL,
  IC_SHARE_ALT2,
  SLACK_LOGO,
  G_DRIVE_LOGO,
  MICROSOFT_AD_LOGO,
  GOOGLE_LOGO,
  AWS_LOGO,
  EPIC_LOGO,
  ATHENAHEALTH_LOGO,
  AZURE_LOGO,
  ONEDRIVE_LOGO,
  AZURE_SVG_LOGO,
  IC_TORNADO,
  IC_WHEATHER,
  IC_ENTROPY,
  IC_LIGHTNING_PRIMARY,
  IC_FLOOD,
  IC_CONNECTIVITY,
  IC_FIRE,
  IC_EARTHQUAKE,
  NIST_LOGO,
  HIPAA_LOGO,
  AICPA_LOGO,
  SIG_LOGO,
  FED_RAMP_LOGO,
  COBIT_LOGO,
  ISO27001_LOGO,
  CUSTOM_CONNECTOR,
} from './imageConst';
import ROUTE from './route';

export const breadcrumbsData = {
  dashboard: [{ path: '/dashboard', label: 'Dashboard' }],
  lightening: [{ path: ROUTE.LIGHTNING_INTEGRATION, label: 'RiskAI Lightening' }],
  lightningConnectors: {
    GSuite: [
      { path: ROUTE.LIGHTNING_INTEGRATION, label: 'RiskAI Lightening' },
      { path: '', label: 'Google Workspace' },
    ],
    'MS AD': [
      { path: ROUTE.LIGHTNING_INTEGRATION, label: 'RiskAI Lightening' },
      { path: '', label: 'Microsoft Active Directory' },
    ],
    GDrive: [
      { path: ROUTE.LIGHTNING_INTEGRATION, label: 'RiskAI Lightening' },
      { path: '', label: 'Google Drive' },
    ],
    'Azure AD': [
      { path: ROUTE.LIGHTNING_INTEGRATION, label: 'RiskAI Lightening' },
      { path: '', label: 'Azure Active Directory' },
    ],
    CustomConnector: [
      { path: ROUTE.LIGHTNING_INTEGRATION, label: 'RiskAI Lightening' },
      { path: '', label: 'Custom Connector' },
    ],
  },
  personnelHipaa: [
    { path: '/compliance/hipaa', label: 'Compliance' },
    { path: '', label: 'HIPAA Dashboard' },
  ],
  riskAnalysis: [
    { path: '/risk-analysis', label: 'Risk Analysis' },
    { path: '', label: 'Information Systems' },
  ],
  riskAnalysisConnectors: {
    GSuite: [
      { path: '/risk-analysis', label: 'Risk Analysis' },
      { path: '', label: 'Google Workspace' },
    ],
    'MS AD': [
      { path: '/risk-analysis', label: 'Risk Analysis' },
      { path: '', label: 'Microsoft Active Directory' },
    ],
    GDrive: [
      { path: '/risk-analysis', label: 'Risk Analysis' },
      { path: '', label: 'Google Drive' },
    ],
    'Azure AD': [
      { path: '/risk-analysis', label: 'Risk Analysis' },
      { path: '', label: 'Azure Active Directory' },
    ],
  },
  controlsLibrary: [
    { path: '/risk-analysis', label: 'Risk Analysis' },
    { path: '', label: 'Control Library' },
  ],
  userManagment: [{ path: '/users', label: 'User Managment' }],
  tenantManagment: [{ path: '/tenant', label: 'Tenant Managment' }],
  controlManagement: [
    { path: '/control-management', label: 'Control Managment' },
    { path: '', label: 'Controls' },
  ],
  frameworks: [
    { path: '/control-management', label: 'Control Managment' },
    { path: '', label: 'Frameworks' },
  ],
};

export const connectorsName = {
  GOOGLE: 'GSuite',
  MICROSOFT: 'MS AD',
  GDRIVE: 'GDrive',
  AZURE: 'Azure AD',
  CISCO: 'Cisco',
  AWS: 'AWs',
  EPIC: 'Epic',
  CUSTOM: 'custom',
  VENDOR: 'vendor',
};

export const connectorData = {
  GSuite: {
    logo: GOOGLE_LOGO,
    title: 'Google Workspace',
    description:
      'Google Workspace helps you and your team collaborate thanks to cloud infrastructure while keeping your information and devices safe.',
    byName: 'Google',
    setting: 'These informations will be used to connect with your Google Workspace environment.',
    pdf: '/helpdocs/Risk_AI_GSuite_Connection_Steps.pdf',
    userConfig: 'Google Client',
    connectingText: 'Google',
  },
  'MS AD': {
    logo: MICROSOFT_AD_LOGO,
    title: 'Microsoft Active Directory',
    description:
      'Active Directory helps you secure your environment with multi-factor authentication and access management.',
    byName: 'Microsoft',
    setting: 'These informations will be used to connect with your Microsoft Active Directory environment.',
    pdf: '/helpdocs/Risk_AI _MSAD_Connection_Steps.pdf',
    userConfig: 'Microsoft Active Directory',
    connectingText: 'Microsoft',
  },
  GDrive: {
    logo: G_DRIVE_LOGO,
    title: 'Google Drive',
    description:
      'Google Workspace helps you and your team collaborate thanks to cloud infrastructure while keeping your information and devices safe.',
    byName: 'Google',
    setting: 'These informations will be used to connect with your Google Workspace environment.',
    pdf: '/helpdocs/Risk_AI_Google_Drive_Connection_Steps.pdf',
    userConfig: 'Google Client',
    connectingText: 'Google Drive',
  },
  'Azure AD': {
    logo: AZURE_LOGO,
    title: 'Azure Active Directory',
    description:
      'Active Directory helps you secure your environment with multi-factor authentication and access management.',
    byName: 'Microsoft',
    setting: 'These informations will be used to connect with your Azure Active Directory environment.',
    pdf: '/helpdocs/Risk_AI_Azure_AD_Connection_Steps.pdf',
    userConfig: 'Azure Active Directory',
    connectingText: 'Microsoft',
  },
  CustomConnector: {
    logo: CUSTOM_CONNECTOR,
    title: 'Custom Connector',
    description:
      'Custom Connector helps you secure your environment with multi-factor authentication and access management.',
    byName: '',
    setting: 'These informations will be used to connect with your Custom Connector environment.',
    connectingText: 'Custom Connector',
  },
};

export const riskAnalysisConnectors = []
  .concat(
    shouldBeMasked()
      ? [
          {
            icon: ATHENAHEALTH_LOGO,
            integrationName: 'Athenahealth EMR',
            path: '',
            type: 'AthenaHealth',
          },
          {
            integrationName: 'AWS',
            icon: AWS_LOGO,
            path: '',
            type: 'AWs',
          },
        ]
      : []
  )
  .concat([
    {
      icon: AZURE_LOGO,
      small_icon: AZURE_SVG_LOGO,
      integrationName: 'Azure Active Directory',
      path: '/risk-analysis/Azure AD',
      by: 'Microsoft',
      type: 'Azure AD',
      userConfig: 'Microsoft Active Directory',
    },
  ])
  .concat(
    shouldBeMasked()
      ? [
          {
            integrationName: 'Epic',
            icon: EPIC_LOGO,
            path: '',
            type: 'Epic',
          },
        ]
      : []
  )
  .concat([
    {
      integrationName: 'Google Workspace',
      icon: GOOGLE_LOGO,
      small_icon: GOOGLE_SMALL,
      path: '/risk-analysis/GSuite',
      type: 'GSuite',
      by: 'Google',
      userConfig: 'Google Client',
    },
    {
      integrationName: 'Google Drive',
      icon: G_DRIVE_LOGO,
      small_icon: GOOGLE_SMALL,
      path: '/risk-analysis/GDrive',
      type: 'GDrive',
      by: 'Google',
      userConfig: 'Google Client',
    },
    {
      integrationName: 'Microsoft Active Directory',
      icon: MICROSOFT_AD_LOGO,
      small_icon: MICROSOFT_SMALL,
      path: '/risk-analysis/MS AD',
      type: 'MS AD',
      by: 'Microsoft',
      userConfig: 'Microsoft Active Directory',
    },
  ])
  .concat(
    shouldBeMasked()
      ? [
          {
            icon: ONEDRIVE_LOGO,
            integrationName: 'Microsoft OneDrive',
            path: '',
            type: 'MS_ONE_DRIVE',
          },
          {
            icon: SLACK_LOGO,
            integrationName: 'Slack',
            path: '',
            type: 'SLACK',
          },
        ]
      : []
  );

export const IconMapping = {
  'Thief or person who finds a device': IC_SPY,
  'System hacker': IC_SPY,
  'Wireless Guests': IC_USER_GROUP,
  'Service providers': IC_SHARE_ALT2,
  Malware: IC_BUG,
  'Careless users': USER,
  'Malicious user': IC_SPY,
  'Inclement Weather': IC_WHEATHER,
  Fire: IC_FIRE,
  'Power outage': IC_LIGHTNING_PRIMARY,
  'Network Connectivity Outage': IC_CONNECTIVITY,
  'Flood (Geographical Applicability)': IC_FLOOD,
  'Electrical Incident': IC_LIGHTNING_PRIMARY,
  Entropy: IC_ENTROPY,
  'Earthquake (Geographically Applicable)': IC_EARTHQUAKE,
  'Tornado (Geographically Applicable)': IC_TORNADO,
};

/// Json Object For Threats control
export const rawJsonFormat = {
  'Lost Device': {
    'Thief or person who finds a device': {
      'Access/Theft of Sensitive Data': [
        'Inadequate Device Authentication',
        'Inadequate Device Encryption',
        'Deficient Physical Security',
        'Mishandling of Devices',
        'Inadequate Device Backup',
      ],
      'Theft of Equipment': ['Deficient Physical Security'],
    },
  },
  'Outside Threats': {
    'System hacker': {
      'Lack of Vendor Due Diligence': ['Insufficient Data Backups'],
      'Access/Theft of Sensitive Data': [
        'Endpoint Leakage',
        'Insufficient Network Capacity',
        'Insecure Network Configuration',
        'Insecure Device Configuration',
        'Inadequate User/Device Authentication',
        'Excessive User Permissions',
        'Deficient Physical Security',
        'Untrained Staff',
        'Hardware Deficiencies',
      ],
      'Denial of Service': ['Insufficient Network Capacity', 'Insecure Network Configuration'],
      'Social Engineering': ['Untrained Staff'],
      'Corruption/Destruction of Data': ['Hardware Deficiencies'],
      'Insecure User Management': ['Inadequate Device Authentication', 'Excessive User Permissions'],
    },
    'Wireless Guests': { 'Wireless Snooping/Access to Systems': ['Wireless Network Deficiencies'] },
    'Service providers': {
      'Corruption/Destruction of Data': ['Insufficient Data Validation'],
      'Lack of Vendor Due Dilligence': ['Vendor-based Vulnerabilities and Availability'],
      'Service Disruption': ['Vendor-based Vulnerabilities and Availability'],
      'Data Loss/Data Leakage': ['Vendor-based Vulnerabilities and Availability'],
    },
    Malware: {
      'Access/Theft of Sensitive Data': [
        // 'Endpoint Leakage',
        'Anti-Malware Deficiencies',
        // 'Mishandling of Devices',
        // 'Anti-Malware Deficiencies - Internal',
        // 'Anti-Malware Deficiencies - External',
      ],
      'Corruption/Destruction of Data': ['Insufficient Data Validation', 'Inadequate Device Backup'],
      Others: ['System/Application Weakness'],
    },
  },
  'User Threats': {
    'Careless users': {
      'Access/Theft of Sensitive Data': [
        'Endpoint Leakage',
        'Mishandling of Devices',
        // 'Anti-Malware Deficiencies - Internal',
        // 'Anti-Malware Deficiencies - External',
        // 'Insufficient Network Capacity',
        // 'Insecure Network Configuration',
        // 'Insecure Device Configuration',
        // 'Inadequate Device Authentication',
        // 'Excessive User Permissions',
        // 'Deficient Physical Security',
        // 'Untrained Staff',
        // 'Hardware Deficiencies',
      ],
      'Weak Credentials': ['Exploitation of Weak Credentials'],
      'Social Engineering': ['Untrained Staff'],
      'Corruption/Destruction of Data': ['Inadequate Device Backup', 'Insufficient Data Validation'],
      'Physical Damage/Loss': ['Physical Accidents', 'Endpoint Leakage', 'Deficient Physical Security'],
    },
    'Malicious user': {
      'Access/Theft of Sensitive Data': [
        'Endpoint Leakage',
        'Excessive User Permissions',
        'Inadequate User/Device Authentication',
      ],
      'Weak Credentials': ['Exploitation of Weak Credentials'],
      'Social Engineering': ['Untrained Staff'],
      'Corruption/Destruction of Data': ['Inadequate Device Backup', 'Insufficient Data Validation'],
    },
  },
  'Natural Disasters': {
    'Inclement Weather': {
      'Physical Damage': ['Lack of Equipment Redundancy'],
      'Overheating Systems': ['Insufficient HVAC'],
      'Personnel Unavailability': ['Lack of Personnel'],
    },

    Fire: { 'Physical Damage': ['Fire Protection Insufficiencies', 'Lack of Equipment Redundancy'] },
    'Power outage': { 'Loss of Power': ['Lack of Power Capacity'] },
    'Network Connectivity Outage': {
      'Loss of Internet Connectivity': ['Network Provider Deficiencies'],
    },
    'Flood (Geographical Applicability)': { 'Water Damage to Equipment': ['Lack of Equipment Redundancy'] },
    'Electrical Incident': { 'Electrical Damage to Equipment': ['Lack of Power Capacity'] },
    Entropy: { 'Hardware Failure': ['Outdated Equipment'] },
    'Earthquake (Geographically Applicable)': {
      'Physical Damage': ['Lack of Equipment Redundancy'],
      'Personnel Unavailability': ['Lack of Personnel'],
    },
    'Tornado (Geographically Applicable)': {
      'Physical Damage': ['Lack of Equipment Redundancy'],
      'Personnel Unavailability': ['Lack of Personnel'],
    },
  },
};

export const tasksList = [
  {
    title: 'Implement MFA',
    subtitle: 'Implement MFA for all the devices that authenticate with {{integrationName}}',
    name: 'Jane Deo',
    status: 'In progress',
    date: 'December 9, 2022',
  },
  {
    title: 'Enforce Strong Password',
    subtitle: 'Enforce strong password complexity for all devices that authenticate with {{integrationName}}',
    name: 'Jane Deo',
    status: 'In progress',
    date: 'December 9, 2022',
  },
];

export const pnpsData = [
  {
    title: 'PnPs Written',
    subtitle: 'Completed 09/25/2022',
    status: 'Completed',
  },
  {
    title: 'PnPs Implemented',
    subtitle: 'Completed 09/25/2022',
    status: 'Completed',
  },
  {
    title: 'PnPs Enforced',
    subtitle: '95% done',
    status: 'Inprogress',
  },
];

export const pnpsData2 = [
  {
    title: 'PnPs Written',
    subtitle: 'Completed 09/25/2022',
    status: 'Completed',
  },
  {
    title: 'PnPs Implemented',
    subtitle: 'Completed 09/25/2022',
    status: 'Completed',
  },
  {
    title: 'PnPs Enforced',
    subtitle: 'Completed 09/25/2022',
    status: 'Completed',
  },
];

export const controlName = ['IAC-08', 'IAC-29', 'HRS-11'];

export const impactWithUsers = {
  '< 10': 1,
  '0-10': 1,
  '11-25': 2,
  '26-50': 3,
  '51-100': 4,
  '101-250': 5,
  '251-500': 6,
  '501-1000': 7,
  '1001-5000': 8,
  '5001-10000': 9,
  '10000-null': 10,
  '> 10000': 10,
};

export const impactWithRecords = {
  '< 100': 3,
  '0-100': 3,
  '101-250': 4,
  '251-499': 5,
  '500-1000': 6,
  '1001-5000': 7,
  '5001-10000': 8,
  '10001-25000': 8,
  '25001-50000': 9,
  '50001-100000': 9,
  '100001-null': 10,
  '> 100001': 10,
};

export const impactWithSensitiveData = {
  None: 1,
  PII: 5,
  PCI: 5,
  PHI: 7,
  'Company Confidential': 3,
  Other: 3,
  'Combination of PII/PCI': 8,
  'Combination of PII/PHI': 8,
};

export const NO_RECORD = 'No record found';

export const controlsImages = {
  NIST: NIST_LOGO,
  HIPAA: HIPAA_LOGO,
  'AICPA (SOC 2)': AICPA_LOGO,
  SharedAssessmentsSIG: SIG_LOGO,
  FedRAMP: FED_RAMP_LOGO,
  COBIT: COBIT_LOGO,
  'ISO-27001-v2013': ISO27001_LOGO,
};

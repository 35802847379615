import React from 'react';
import CustomSlider from 'react-slick';
import { IconButton, styled } from '@mui/material';
// Internal
import Icon from '../Icon/Icon';
import { IC_RIGHT, IC_LEFT } from 'assets/constants/imageConst';

const opacityHandler = (classes) => {
  const isDisabled = classes.split(' ').includes('slick-disabled');
  return isDisabled
    ? {
        opacity: 0.5,
        cursor: 'default',
      }
    : {
        opacity: 1,
      };
};

const NextArrow = (props) => {
  return (
    <IconButton
      onClick={props.onClick}
      sx={{ position: 'absolute', top: '-40px', right: 0, ...opacityHandler(props?.className) }}
    >
      <Icon size="small" src={IC_RIGHT} className={props.className} />
    </IconButton>
  );
};

const PrevArrow = (props) => {
  return (
    <IconButton
      onClick={props.onClick}
      sx={{ right: '40px', position: 'absolute', top: '-40px', ...opacityHandler(props?.className) }}
    >
      <Icon size="small" src={IC_LEFT} className={props.className} />
    </IconButton>
  );
};

const SliderStyled = styled(CustomSlider)(() => ({
  '.slick-track': {
    columnGap: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '.slick-track .slick-current + div > div': {
    transform: 'scale(0.8)',
    margin: '-44px',
  },
  '.slick-arrow.slick-prev': {
    top: '40px',
    left: 0,
    right: 0,
    '&:before': {
      display: 'none',
    },
  },
  '.slick-arrow.slick-next': {
    top: '40px',
    left: 0,
    right: 0,
    '&:before': {
      display: 'none',
    },
  },
  '.MuiButtonBase-root': {
    padding: 0,
  },
}));

const Slider = ({ children, settings }) => {
  const defaultSettings = {
    dots: false,
    accessibility: true,
    adaptiveHeight: true,
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    ...settings,
  };

  return <SliderStyled {...defaultSettings}>{children}</SliderStyled>;
};
export default Slider;

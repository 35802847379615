import { Box, styled } from '@mui/material';

export const spaceBetweenStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const scrollStyle = {
  '::-webkit-scrollbar': {
    width: '6px',
  },
  '::-webkit-scrollbar-track': {
    borderRadius: '5px',
  },
  '::-webkit-scrollbar-thumb': {
    background: '#4F4A55',
    borderRadius: '10px',
  },
  '::-webkit-scrollbar-thumb:hover': {
    background: '#4F4A55',
  },
};

export const commentBoxScroll = {
  '::-webkit-scrollbar': {
    width: '14px',
  },
  '::-webkit-scrollbar-thumb': {
    border: '4px solid rgba(0, 0, 0, 0)',
    backgroundClip: 'padding-box',
    borderRadius: '9999px',
    backgroundColor: '#AAAAAA',
  },
};

export const controlsScroll = {
  '::-webkit-scrollbar': {
    width: '6px',
    height: '6px',
  },
  '::-webkit-scrollbar-thumb': {
    borderRadius: '5px',
    padding: '10px 4px',
    backgroundColor: '#4F4A55',
  },
};

export const TabsWrapper = styled(Box)(() => ({
  height: '100%',
  '& .tab-panel': {
    maxHeight: 'calc(100% - 195px)',
    overflow: 'auto',
    ...commentBoxScroll,
    '>.MuiBox-root': {
      padding: '16px',
    },
  },
  '& .MuiTabs-root': {
    padding: '0 16px',
    '& .MuiTabs-scroller': {
      borderBottom: '0.5px solid #D3D3D3',
    },
  },
}));

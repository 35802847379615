import React from 'react';
import {
  SwipeableDrawer as MUISwipeableDrawer,
  DialogActions,
  DialogContent,
  Box,
  Typography,
  styled,
  Divider,
  IconButton,
} from '@mui/material';

// Internal
import Button from '../FormControls/Button';
import { IC_CROSS_CIRCLE } from 'assets/constants/imageConst';
import Icon from '../Icon/Icon';

const CloseIcon = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});
const StyledHeader = styled(Box)({
  padding: '24px 17px 16px 16px',
  rowGap: '10px',
});
const ContentStyle = styled(DialogContent)({ padding: 0 });

const SwipeableDrawer = ({
  children,
  anchor,
  open,
  onclose,
  onopen,
  isFooter = true,
  title,
  subTitle,
  onSave = () => {},
  cancelText,
  saveText,
  isHeaderDivider = true,
  subTitleComponent,
}) => {
  const CustomDrawer = styled((props) => <MUISwipeableDrawer {...props} />)(() => ({
    '& .MuiDrawer-paper': {
      minWidth: '390px',
      width: '390px',
      top: '80px',
      borderRadius: '8px',
      right: '10px',
      height: 'calc(100% - 80px - 10px)',
    },
  }));

  return (
    <CustomDrawer anchor={anchor} open={open} onClose={onclose} onOpen={onopen}>
      <StyledHeader>
        <CloseIcon>
          {title && title}
          <IconButton size="small" onClick={onclose}>
            <Icon src={IC_CROSS_CIRCLE} size="small" />
          </IconButton>
        </CloseIcon>
        {subTitleComponent
          ? subTitleComponent
          : subTitle && (
              <Typography mt={1} variant="subtitle1">
                {subTitle}
              </Typography>
            )}
      </StyledHeader>
      {isHeaderDivider && <Divider />}
      <ContentStyle>{children}</ContentStyle>
      {isFooter && <Divider />}
      {isFooter && (
        <DialogActions>
          {cancelText && (
            <Button onClick={onclose} color="secondary">
              {cancelText}
            </Button>
          )}
          {saveText && (
            <Button onClick={onSave} type="submit" variant="contained">
              {saveText}
            </Button>
          )}
        </DialogActions>
      )}
    </CustomDrawer>
  );
};
export default SwipeableDrawer;

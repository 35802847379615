import { useState } from 'react';
import _ from 'lodash';

// MUI
import { Typography, Box, Select, MenuItem, FormControl } from '@mui/material';

// Internal
import Title from 'components/common/Wrapper/Title';
import { SearchInput } from 'components/common/FormControls';
import NestedCollapse from '../Collapse';
import { spaceBetweenStyle } from 'assets/theme/styles';
import { shouldBeMasked } from 'utils/general';

const ConnectorData = ({ tabName, vulnerabilities, isInProgress, rawJson,tenant_connector_uuid }) => {
  const [filter, setFilter] = useState('All');

  const renderData = () => {
    let result = {};

    const json = rawJson[tabName];
    _.map(json, (value, key) => {
      let sources = {};
      _.map(value, (val, key2) => {
        const categories = {};
        _.map(val, (v) => {
          const findVulnerability = _.find(vulnerabilities, (vuln) => {
            return vuln.name == v;
          });
          if (findVulnerability) {
            categories[v] = findVulnerability;
          }
        });
        if (!_.isEmpty(categories)) {
          sources[key2] = categories;
        }
      });
      if (!_.isEmpty(sources)) {
        result[key] = sources;
      }
    });
    return result;
  };
  return (
    <>
      <Box sx={spaceBetweenStyle}>
        <Title
          title="Threats and vulnerabilities"
          subtitle="Find below the list of threat sources and vulnerabilities linked to the threat category."
        />
        {shouldBeMasked() && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body2" color="text.secondary">
              Filter by:
            </Typography>
            <Box mr={1}>
              <FormControl variant="standard" size="small" sx={{ m: 1, minWidth: 20 }}>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                  label="Age"
                  size="small"
                  autoWidth
                  sx={{ border: 0 }}
                  disableUnderline
                  renderValue={(value) => (
                    <Typography variant="body2" color="primary" sx={{ mt: '7px' }}>
                      {value}
                    </Typography>
                  )}
                >
                  <MenuItem value="All">All</MenuItem>
                  <MenuItem value="Saved">Saved</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box mr={3}>
              <SearchInput />
            </Box>
          </Box>
        )}
      </Box>
      {/* inside loop */}
      <Box>
        <NestedCollapse data={renderData()} tabName={tabName} isInProgress={isInProgress} tenant_connector_uuid={tenant_connector_uuid} />
      </Box>
    </>
  );
};

export default ConnectorData;

import * as actionTypes from '../actions/actionTypes';

export const defaultState = {
  error: null,
  loading: false,
  userData: null,
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_USERS_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case actionTypes.GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        userData: action.payload?.data?.payload,
      };

    case actionTypes.GET_USERS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const heatGraphData = [
  {
    impact: 0,
    b: [10, 0],
    c: [10, 10],
    d: [10, 10],
    e: [10, 10],
    f: [10, 10],
    g: [10, 10],
    h: [10, 10],
    i: [10, 10],
    j: [10, 10],
  },
  {
    impact: 0,
    b: [10, 0],
    c: [10, 10],
    d: [10, 10],
    e: [10, 10],
    f: [10, 10],
    g: [10, 10],
    h: [10, 10],
    i: [10, 10],
    j: [10, 10],
  },
  {
    impact: 0,
    b: [10, 0],
    c: [10, 10],
    d: [10, 10],
    e: [10, 10],
    f: [10, 10],
    g: [10, 10],
    h: [10, 10],
    i: [10, 10],
    j: [10, 10],
  },
  {
    impact: 0,
    b: [10, 0],
    c: [10, 10],
    d: [10, 10],
    e: [10, 10],
    f: [10, 10],
    g: [10, 10],
    h: [10, 10],
    i: [10, 10],
    j: [10, 10],
  },
  {
    impact: 0,
    b: [10, 0],
    c: [10, 10],
    d: [10, 10],
    e: [10, 10],
    f: [10, 10],
    g: [10, 10],
    h: [10, 10],
    i: [10, 10],
    j: [10, 10],
  },
  {
    impact: 0,
    b: [10, 0],
    c: [10, 10],
    d: [10, 10],
    e: [10, 10],
    f: [10, 10],
    g: [10, 10],
    h: [10, 10],
    i: [10, 10],
    j: [10, 10],
  },
  {
    impact: 0,
    b: [10, 0],
    c: [10, 10],
    d: [10, 10],
    e: [10, 10],
    f: [10, 10],
    g: [10, 10],
    h: [10, 10],
    i: [10, 10],
    j: [10, 10],
  },
  {
    impact: 1,
    // a: [1, 0],
    b: [10, 0],
    c: [10, 10],
    d: [10, 10],
    e: [10, 10],
    f: [10, 10],
    g: [10, 10],
    h: [10, 10],
    i: [10, 10],
    j: [10, 10],
  },
  {
    impact: 1,
    b: [1, 0],
    c: [10, 1],
    d: [10, 10],
    e: [10, 10],
    f: [10, 10],
    g: [10, 10],
    h: [10, 10],
    i: [10, 10],
    j: [10, 10],
  },
  {
    impact: 1,
    b: [1, 0],
    c: [10, 1],
    d: [10, 10],
    e: [10, 10],
    f: [10, 10],
    g: [10, 10],
    h: [10, 10],
    i: [10, 10],
    j: [10, 10],
  },
  {
    impact: 1,
    b: [1, 0],
    c: [10, 1],
    d: [10, 10],
    e: [10, 10],
    f: [10, 10],
    g: [10, 10],
    h: [10, 10],
    i: [10, 10],
    j: [10, 10],
  },
  {
    impact: 2,
    a: [0, 0],
    b: [1, 0],
    c: [10, 1],
    d: [10, 10],
    e: [10, 10],
    f: [10, 10],
    g: [10, 10],
    h: [10, 10],
    i: [10, 10],
    j: [10, 10],
  },

  {
    impact: 2,
    a: [0, 0],
    b: [1, 0],
    c: [2, 1],
    d: [10, 2],
    e: [10, 10],
    f: [10, 10],
    g: [10, 10],
    h: [10, 10],
    i: [10, 10],
    j: [10, 10],
  },
  {
    impact: 2,
    a: [0, 0],
    b: [1, 0],
    c: [2, 1],
    d: [10, 2],
    e: [10, 10],
    f: [10, 10],
    g: [10, 10],
    h: [10, 10],
    i: [10, 10],
    j: [10, 10],
  },
  {
    impact: 2,
    a: [0, 0],
    b: [1, 0],
    c: [2, 1],
    d: [10, 2],
    e: [10, 10],
    f: [10, 10],
    g: [10, 10],
    h: [10, 10],
    i: [10, 10],
    j: [10, 10],
  },
  {
    impact: 3,
    a: [0, 0],
    b: [1, 0],
    c: [2, 1],
    d: [10, 2],
    e: [10, 10],
    f: [10, 10],
    g: [10, 10],
    h: [10, 10],
    i: [10, 10],
    j: [10, 10],
  },

  {
    impact: 3,
    a: [0, 0],
    b: [1, 0],
    c: [2, 1],
    d: [3, 2],
    e: [10, 3],
    f: [10, 10],
    g: [10, 10],
    h: [10, 10],
    i: [10, 10],
    j: [10, 10],
  },
  {
    impact: 3,
    a: [0, 0],
    b: [1, 0],
    c: [2, 1],
    d: [3, 2],
    e: [10, 3],
    f: [10, 10],
    g: [10, 10],
    h: [10, 10],
    i: [10, 10],
    j: [10, 10],
  },
  {
    impact: 3,
    a: [0, 0],
    b: [1, 0],
    c: [2, 1],
    d: [3, 2],
    e: [10, 3],
    f: [10, 10],
    g: [10, 10],
    h: [10, 10],
    i: [10, 10],
    j: [10, 10],
  },
  {
    impact: 4,
    a: [0, 0],
    b: [1, 0],
    c: [2, 1],
    d: [3, 2],
    e: [10, 3],
    f: [10, 10],
    g: [10, 10],
    h: [10, 10],
    i: [10, 10],
    j: [10, 10],
  },
  {
    impact: 4,
    a: [0, 0],
    b: [1, 0],
    c: [2, 1],
    d: [3, 2],
    e: [4, 3],
    f: [10, 4],
    g: [10, 10],
    h: [10, 10],
    i: [10, 10],
    j: [10, 10],
  },
  {
    impact: 4,
    a: [0, 0],
    b: [1, 0],
    c: [2, 1],
    d: [3, 2],
    e: [4, 3],
    f: [10, 4],
    g: [10, 10],
    h: [10, 10],
    i: [10, 10],
    j: [10, 10],
  },
  {
    impact: 4,
    a: [0, 0],
    b: [1, 0],
    c: [2, 1],
    d: [3, 2],
    e: [4, 3],
    f: [10, 4],
    g: [10, 10],
    h: [10, 10],
    i: [10, 10],
    j: [10, 10],
  },
  {
    impact: 5,
    a: [0, 0],
    b: [1, 0],
    c: [2, 1],
    d: [3, 2],
    e: [4, 3],
    f: [10, 4],
    g: [10, 10],
    h: [10, 10],
    i: [10, 10],
    j: [10, 10],
  },
  {
    impact: 5,
    a: [0, 0],
    b: [1, 0],
    c: [2, 1],
    d: [3, 2],
    e: [4, 3],
    f: [5, 4],
    g: [10, 5],
    h: [10, 10],
    i: [10, 10],
    j: [10, 10],
  },
  {
    impact: 5,
    a: [0, 0],
    b: [1, 0],
    c: [2, 1],
    d: [3, 2],
    e: [4, 3],
    f: [5, 4],
    g: [10, 5],
    h: [10, 10],
    i: [10, 10],
    j: [10, 10],
  },
  {
    impact: 5,
    a: [0, 0],
    b: [1, 0],
    c: [2, 1],
    d: [3, 2],
    e: [4, 3],
    f: [5, 4],
    g: [10, 5],
    h: [10, 10],
    i: [10, 10],
    j: [10, 10],
  },
  {
    impact: 5,
    a: [0, 0],
    b: [1, 0],
    c: [2, 1],
    d: [3, 2],
    e: [4, 3],
    f: [5, 4],
    g: [10, 5],
    h: [10, 10],
    i: [10, 10],
    j: [10, 10],
  },
  {
    impact: 5,
    a: [0, 0],
    b: [1, 0],
    c: [2, 1],
    d: [3, 2],
    e: [4, 3],
    f: [5, 4],
    g: [10, 5],
    h: [10, 10],
    i: [10, 10],
    j: [10, 10],
  },
  {
    impact: 5,
    a: [0, 0],
    b: [1, 0],
    c: [2, 1],
    d: [3, 2],
    e: [4, 3],
    f: [5, 4],
    g: [10, 5],
    h: [10, 10],
    i: [10, 10],
    j: [10, 10],
  },
  {
    impact: 5,
    a: [0, 0],
    b: [1, 0],
    c: [2, 1],
    d: [3, 2],
    e: [4, 3],
    f: [5, 4],
    g: [10, 5],
    h: [10, 10],
    i: [10, 10],
    j: [10, 10],
  },
  {
    impact: 5,
    a: [0, 0],
    b: [1, 0],
    c: [2, 1],
    d: [3, 2],
    e: [4, 3],
    f: [5, 4],
    g: [10, 5],
    h: [10, 10],
    i: [10, 10],
    j: [10, 10],
  },
  {
    impact: 6,
    a: [0, 0],
    b: [1, 0],
    c: [2, 1],
    d: [3, 2],
    e: [4, 3],
    f: [5, 4],
    g: [10, 5],
    h: [10, 10],
    i: [10, 10],
    j: [10, 10],
  },
  {
    impact: 6,
    a: [0, 0],
    b: [1, 0],
    c: [2, 1],
    d: [3, 2],
    e: [4, 3],
    f: [5, 4],
    g: [6, 5],
    h: [10, 6],
    i: [10, 10],
    j: [10, 10],
  },
  {
    impact: 6,
    a: [0, 0],
    b: [1, 0],
    c: [2, 1],
    d: [3, 2],
    e: [4, 3],
    f: [5, 4],
    g: [6, 5],
    h: [10, 6],
    i: [10, 10],
    j: [10, 10],
  },
  {
    impact: 6,
    a: [0, 0],
    b: [1, 0],
    c: [2, 1],
    d: [3, 2],
    e: [4, 3],
    f: [5, 4],
    g: [6, 5],
    h: [10, 6],
    i: [10, 10],
    j: [10, 10],
  },
  {
    impact: 7,
    a: [0, 0],
    b: [1, 0],
    c: [2, 1],
    d: [3, 2],
    e: [4, 3],
    f: [5, 4],
    g: [6, 5],
    h: [10, 6],
    i: [10, 10],
    j: [10, 10],
  },
  {
    impact: 7,
    a: [0, 0],
    b: [1, 0],
    c: [2, 1],
    d: [3, 2],
    e: [4, 3],
    f: [5, 4],
    g: [6, 5],
    h: [7, 6],
    i: [10, 7],
    j: [10, 10],
  },
  {
    impact: 7,
    a: [0, 0],
    b: [1, 0],
    c: [2, 1],
    d: [3, 2],
    e: [4, 3],
    f: [5, 4],
    g: [6, 5],
    h: [7, 6],
    i: [10, 7],
    j: [10, 10],
  },
  {
    impact: 7,
    a: [0, 0],
    b: [1, 0],
    c: [2, 1],
    d: [3, 2],
    e: [4, 3],
    f: [5, 4],
    g: [6, 5],
    h: [7, 6],
    i: [10, 7],
    j: [10, 10],
  },
  {
    impact: 7,
    a: [0, 0],
    b: [1, 0],
    c: [2, 1],
    d: [3, 2],
    e: [4, 3],
    f: [5, 4],
    g: [6, 5],
    h: [7, 6],
    i: [10, 7],
    j: [10, 10],
  },
  {
    impact: 8,
    a: [0, 0],
    b: [1, 0],
    c: [2, 1],
    d: [3, 2],
    e: [4, 3],
    f: [5, 4],
    g: [6, 5],
    h: [7, 6],
    i: [10, 7],
    j: [10, 10],
  },
  {
    impact: 8,
    a: [0, 0],
    b: [1, 0],
    c: [2, 1],
    d: [3, 2],
    e: [4, 3],
    f: [5, 4],
    g: [6, 5],
    h: [7, 6],
    i: [8, 7],
    j: [10, 8],
  },
  {
    impact: 8,
    a: [0, 0],
    b: [1, 0],
    c: [2, 1],
    d: [3, 2],
    e: [4, 3],
    f: [5, 4],
    g: [6, 5],
    h: [7, 6],
    i: [8, 7],
    j: [10, 8],
  },
  {
    impact: 8,
    a: [0, 0],
    b: [1, 0],
    c: [2, 1],
    d: [3, 2],
    e: [4, 3],
    f: [5, 4],
    g: [6, 5],
    h: [7, 6],
    i: [8, 7],
    j: [10, 8],
  },
  {
    impact: 9,
    a: [0, 0],
    b: [1, 0],
    c: [2, 1],
    d: [3, 2],
    e: [4, 3],
    f: [5, 4],
    g: [6, 5],
    h: [7, 6],
    i: [8, 7],
    j: [10, 8],
    k: [10, 10],
  },
  {
    impact: 9,
    a: [0, 0],
    b: [1, 0],
    c: [2, 1],
    d: [3, 2],
    e: [4, 3],
    f: [5, 4],
    g: [6, 5],
    h: [7, 6],
    i: [8, 7],
    j: [9, 8],
    k: [10, 9],
  },
  {
    impact: 9,
    a: [0, 0],
    b: [1, 0],
    c: [2, 1],
    d: [3, 2],
    e: [4, 3],
    f: [5, 4],
    g: [6, 5],
    h: [7, 6],
    i: [8, 7],
    j: [9, 8],
    k: [10, 9],
  },
  {
    impact: 9,
    a: [0, 0],
    b: [1, 0],
    c: [2, 1],
    d: [3, 2],
    e: [4, 3],
    f: [5, 4],
    g: [6, 5],
    h: [7, 6],
    i: [8, 7],
    j: [9, 8],
    k: [10, 9],
  },
  {
    impact: 9,
    a: [0, 0],
    b: [1, 0],
    c: [2, 1],
    d: [3, 2],
    e: [4, 3],
    f: [5, 4],
    g: [6, 5],
    h: [7, 6],
    i: [8, 7],
    j: [9, 8],
    k: [10, 9],
  },
  {
    impact: 10,
    a: [0, 0],
    b: [1, 0],
    c: [2, 1],
    d: [3, 2],
    e: [4, 3],
    f: [5, 4],
    g: [6, 5],
    h: [7, 6],
    i: [8, 7],
    j: [9, 8],
    k: [10, 9],
  },
  {
    impact: 10,
    a: [0, 0],
    b: [1, 0],
    c: [2, 1],
    d: [3, 2],
    e: [4, 3],
    f: [5, 4],
    g: [6, 5],
    h: [7, 6],
    i: [8, 7],
    j: [9, 8],
    k: [10, 10],
  },
  {
    impact: 10,
    a: [0, 0],
    b: [1, 0],
    c: [2, 1],
    d: [3, 2],
    e: [4, 3],
    f: [5, 4],
    g: [6, 5],
    h: [7, 6],
    i: [8, 7],
    j: [9, 8],
    k: [10, 10],
  },
  {
    impact: 10,
    a: [0, 0],
    b: [1, 0],
    c: [2, 1],
    d: [3, 2],
    e: [4, 3],
    f: [5, 4],
    g: [6, 5],
    h: [7, 6],
    i: [8, 7],
    j: [9, 8],
    k: [10, 9],
  },
  {
    impact: 10,
    a: [0, 0],
    b: [1, 0],
    c: [2, 1],
    d: [3, 2],
    e: [4, 3],
    f: [5, 4],
    g: [6, 5],
    h: [7, 6],
    i: [8, 7],
    j: [9, 8],
    k: [10, 9],
  },
  {
    impact: 11,
    a: [0, 0],
    b: [1, 0],
    c: [2, 1],
    d: [3, 2],
    e: [4, 3],
    f: [5, 4],
    g: [6, 5],
    h: [7, 6],
    i: [8, 7],
    j: [9, 8],
    k: [10, 9],
  },
  {
    impact: 11,
    a: [0, 0],
    b: [1, 0],
    c: [2, 1],
    d: [3, 2],
    e: [4, 3],
    f: [5, 4],
    g: [6, 5],
    h: [7, 6],
    i: [8, 7],
    j: [9, 8],
    k: [10, 10],
  },
];

export const dateWiseGraph = [
  {
    date: 1669573800000, //28-11
    value: 10,
    pre: 10,
  },
  {
    date: 1669660200000, //29-11
    value: 11,
    pre: 11,
  },
  {
    date: 1669746600000, //30-11
    value: 12,
    pre: 12,
  },
  {
    date: 1669833000000, //1-12
    value: 13,
    pre: 13,
  },
  {
    date: 1669919400000, //2-12
    value: 13,
    pre: 13,
  },
  {
    date: 1670005800000, //3-11
    value: 14,
    pre: 14,
  },
  {
    date: 1670092200000, //4-11
    value: 20,
    pre: 20,
  },
  {
    date: 1670178600000, //5-11
    value: 20,
    pre: 20,
  },
  {
    date: 1670265000000, //6-11
    value: 21,
    pre: 21,
  },
  {
    date: 1670351400000, //7-11
    value: 25,
    pre: 25,
  },
  {
    date: 1670437800000, //8-11
    value: 28,
    pre: 28,
  },
  {
    date: 1670524200000, //9-11
    value: 27,
    pre: 27,
  },
  {
    date: 1670610600000, //10-11
    value: 30,
    pre: 30,
  },
  {
    date: 1670697000000, //11-11
    value: 35,
    pre: 35,
  },
  {
    date: 1670783400000, //12-11
    value: 36,
    pre: 36,
  },
  {
    date: 1670869800000, //13-11
    value: 40,
    pre: 40,
  },
  {
    date: 1670956200000, //14-11
    value: 42,
    pre: 42,
  },
  {
    date: 1671042600000, //15-11
    value: 43,
    pre: 43,
  },
  {
    date: 1671129000000, //16-11
    value: 46,
    pre: 46,
  },
  {
    date: 1671215400000, //17-11
    value: 57,
    pre: 57,
  },
  {
    date: 1671301800000, //18-11
    value: 57,
    pre: 57,
  },
  {
    date: 1671388200000, //19-11
    value: 60,
    post: 60,
    pre: 60,
  },
  {
    date: 1671474600000, //20-11
    value: 62,
    post: 62,
  },
  {
    date: 1671561000000, //21-11
    value: 65,
    post: 65,
  },
  {
    date: 1671647400000, //22-11
    value: 66,
    post: 66,
  },
  {
    date: 1671733800000, //23-11
    value: 67,
    post: 67,
  },
  {
    date: 1671820200000, //24-11
    value: 70,
    post: 70,
  },
  {
    date: 1671906600000, //25-11
    value: 72,
    post: 72,
  },
  {
    date: 1671993000000, //26-11
    value: 78,
    post: 78,
  },
  {
    date: 1672079400000, //27-11
    value: 78,
    post: 78,
  },
];

import api from './apis/api';

class UsersServices {
  getUsers(params) {
    return api.users.getUsers(params);
  }

  getPermission(tenantId) {
    return api.users.getPermission(tenantId);
  }
}

export default new UsersServices();

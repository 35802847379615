import { useState, useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import { useDispatch, useSelector } from 'react-redux';

import {
  Modal,
  Box,
  Paper,
  Typography,
  IconButton,
  Divider,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Checkbox,
} from '@mui/material';

import { IC_CROSS_CIRCLE, IC_FILTER } from 'assets/constants/imageConst';
import Icon from 'components/common/Icon/Icon';
import { Button, SearchInput } from 'components/common/FormControls';
import MultiFilter from 'pages/RiskAnalysis/ControlLibrary/MultiFilter';
import * as actionTypes from 'store/actions/actionTypes';
import { getFilteredData, search } from 'utils/general';
import CommonLoader from 'components/common/Loader/CommonLoader';
import { NO_RECORD } from 'assets/constants/const';
import InfoIconComponent from 'components/common/InfoIcon/InfoIconComponent';
import GroupChip from 'components/common/GroupChip/GroupChip';
import { controlsScroll } from 'assets/theme/styles';

const FilteredComponent = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '20px',
  padding: '0 16px',
});

const CustomRound = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  width: '12px',
  height: '12px',
  borderRadius: '50%',
  marginLeft: theme.spacing(0.5),
  color: theme.palette.common.white,
  fontSize: '10px',
  lineHeight: '12px',
}));

const tableHeader = [
  { field: 'id', title: 'ID' },
  { field: 'control', title: 'Control' },
  { field: 'mappings', title: 'Mappings' },
];

const ControlsTable = ({ loading, tableData, tenantFramework, controlSelected, onSelectAllClick, onSingleSelect }) => {
  const rowCount = tableData?.length;
  const numSelected = controlSelected?.length;
  const selectedControlId = controlSelected?.map((c) => c.id);

  return (
    <TableContainer
      sx={{
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        maxHeight: '315px',
        height: '315px',
        overflow: 'auto',
        padding: '0 16px',
        ...controlsScroll,
      }}
    >
      <Table sx={{ height: '100%' }}>
        <TableHead>
          <TableRow
            sx={{
              '&.MuiTableRow-root': { verticalAlign: 'initial' },
              '& .MuiTableCell-root': {
                padding: '5px',
              },
            }}
          >
            <TableCell>
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={(e) => onSelectAllClick(e.target.checked)}
                inputProps={{
                  'aria-label': 'select all controls',
                }}
              />
            </TableCell>
            {tableHeader?.map((th) => (
              <TableCell align="left" key={th.title}>
                <Typography variant="caption" color="secondary">
                  {th.title}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        {loading ? (
          <TableBody>
            <TableRow>
              <TableCell colSpan={6} sx={{ textAlign: 'center', height: '100%' }}>
                <CommonLoader />
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            {rowCount >= 1 ? (
              tableData?.map((row, index) => {
                const mappedFrameworks = tenantFramework?.filter((tf) => row.frameworks.includes(tf.framework_id));

                return (
                  <TableRow
                    key={index}
                    sx={{
                      '&.MuiTableRow-root': { verticalAlign: 'initial' },
                      '& .MuiTableCell-root': {
                        padding: '5px',
                      },
                    }}
                  >
                    <TableCell>
                      <Checkbox
                        color="primary"
                        onChange={(e) => onSingleSelect(row)}
                        inputProps={{
                          'aria-label': 'select all desserts',
                        }}
                        checked={selectedControlId.includes(row.id)}
                      />
                    </TableCell>
                    <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
                      <Typography variant="body2">{row?.code}</Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
                      <Box sx={{ display: 'flex' }}>
                        {row.name}
                        <Tooltip title={row.description}>
                          <InfoIconComponent />
                        </Tooltip>
                      </Box>
                    </TableCell>

                    <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
                      <GroupChip data={mappedFrameworks?.map((f) => ({ ...f, name: f.framework_name }))} />
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={6} sx={{ textAlign: 'center', py: 4 }}>
                  {NO_RECORD}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

const AddControls = ({ open, onclose, vulnerability }) => {
  const dispatch = useDispatch();

  const [openFilter, setOpenFilter] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [controlsLoading, setControlsLoading] = useState(true);

  const [controlSelected, setControlSelected] = useState([]);

  const [headerSearch, setHeaderSearch] = useState('');
  const [finalData, setFinalData] = useState([]);
  const [controlList, setControlList] = useState([]);

  const { filteredData, responseFilterValue, frameworkFilterValue, controlLibraryList, tenantFramework, loading } =
    useSelector((state) => state.controlsLibrary);

  const { newlyAddedControls, totalVulnerabilities } = useSelector((state) => state.riskAnalysis);

  let uniqueTotalControls = [...new Set(totalVulnerabilities?.flatMap((obj) => Object.values(obj.controls)))];

  useEffect(() => {
    const totalControls = newlyAddedControls?.length
      ? [...newlyAddedControls, ...vulnerability.controls]
      : vulnerability?.controls;

    const arrayOfExistingControls = totalControls?.map((c) => c.code.toLowerCase());

    const newData = filteredData
      ?.map((d) => {
        const existControlResponse = uniqueTotalControls.find((control) => control.code == d.code && control.response);
        return { ...d, response: existControlResponse ? existControlResponse?.response : '' };
      })
      ?.filter((fd) => !arrayOfExistingControls.includes(fd.code.toLowerCase()));

    setFinalData(newData);
    setControlList(newData);
    setTimeout(() => {
      setControlsLoading(false);
    }, 0);
  }, [filteredData]);

  const addControlToVulnerability = () => {
    onclose();
    clearFrameworkFilter();
    dispatch({
      type: actionTypes.SET_NEW_CONTROLS_TO_VULNERABILITY,
      payload: { data: newlyAddedControls?.length ? [...newlyAddedControls, ...controlSelected] : controlSelected },
    });
  };

  const onSelectAllClick = (isAllSelect) => {
    let allControlSelected = [];
    if (isAllSelect) {
      allControlSelected = finalData?.map((d) => ({
        ...d,
        vulnerability_name: vulnerability.name,
        checked: true,
      }));
    }

    setControlSelected(allControlSelected);
  };

  const onSingleSelect = (control) => {
    const singleControl = controlSelected.find((cs) => cs.code == control.code);
    if (singleControl) {
      const remainingControl = controlSelected.filter((cs) => cs.code != control.code);
      setControlSelected(remainingControl);
    } else {
      setControlSelected((prev) => [...prev, { ...control, vulnerability_name: vulnerability.name }]);
    }
  };

  const handleFrameworkFilter = (name, value) => {
    const filter = frameworkFilterValue.map((v) => (v.framework_name === name ? { ...v, selected: value } : v));
    const finalFilteredData = getFilteredData(controlLibraryList, filter, responseFilterValue);

    if (filter.filter((v) => v.selected).length !== 1) {
      dispatch({
        type: actionTypes.SET_TAB_VALUE,
        payload: 0,
      });
    }

    dispatch({
      type: actionTypes.SET_FRAMEWORK_FILTER,
      payload: { filter, data: finalFilteredData },
    });
  };

  const clearFrameworkFilter = () => {
    dispatch({
      type: actionTypes.CLEAR_CONTROLS_LIBRARY_FILTER,
    });
    setSearchValue('');
    setHeaderSearch('');
  };

  const handleHeaderSearch = (e) => {
    const value = e.target.value;
    setHeaderSearch(value);
    let data = finalData;

    if (value) {
      const searchedData = controlList?.filter(
        (fd) =>
          fd['code'].toLowerCase().indexOf(value.toLowerCase()) > -1 ||
          fd['name'].toLowerCase().indexOf(value.toLowerCase()) > -1
      );
      data = searchedData;
    }
    setFinalData(data);
  };

  const handleFrameworkSearch = (e) => {
    const targetVlaue = e.target.value;
    let filteredValue = search('framework_code', targetVlaue, frameworkFilterValue);
    setSearchValue(targetVlaue);

    dispatch({
      type: actionTypes.SET_FRAMEWORK_FILTER,
      payload: { data: filteredData, filter: filteredValue },
    });
  };

  const filterCount = frameworkFilterValue.filter((v) => v.selected).length;

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason && reason === 'backdropClick') return;
        onclose();
        clearFrameworkFilter();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper
        sx={{
          position: 'absolute',
          width: '700px',
          top: '50%',
          left: '50%',
          height: '500px',
          transform: 'translate(-50%, -50%)',
          borderRadius: '8px',
        }}
      >
        <Box className="header" sx={{ padding: '16px', display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="subtitle1" component="div">
            Add Controls
          </Typography>
          <IconButton
            onClick={() => {
              onclose();
              clearFrameworkFilter();
            }}
            sx={{ padding: 0 }}
          >
            <Icon src={IC_CROSS_CIRCLE} size="small" />
          </IconButton>
        </Box>
        <Divider />
        <Box sx={{ position: 'relative', padding: '16px 0' }}>
          <FilteredComponent>
            <Box>
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                startIcon={<ReactSVG src={IC_FILTER} size="small" />}
                onClick={() => setOpenFilter(!openFilter)}
              >
                Filters
                {filterCount > 0 && <CustomRound>{filterCount}</CustomRound>}
              </Button>
            </Box>
            <SearchInput searchValue={headerSearch} onChange={handleHeaderSearch} />
          </FilteredComponent>
          {openFilter && (
            <MultiFilter
              setOpenFilter={setOpenFilter}
              frameworkFilterValue={frameworkFilterValue}
              handleFrameworkFilter={handleFrameworkFilter}
              handleFrameworkSearch={handleFrameworkSearch}
              searchValue={searchValue}
            />
          )}
          <ControlsTable
            tableData={finalData}
            loading={loading || controlsLoading}
            tenantFramework={tenantFramework}
            controlSelected={controlSelected}
            onSelectAllClick={onSelectAllClick}
            onSingleSelect={onSingleSelect}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '16px', columnGap: '8px' }}>
            <Button
              type="button"
              variant="text"
              color="secondary"
              onClick={() => {
                onclose();
                clearFrameworkFilter();
              }}
            >
              Cancel
            </Button>
            <Button variant="filled" onClick={addControlToVulnerability}>
              {`Add ${controlSelected?.length > 0 ? `(${controlSelected?.length})` : ''}`}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
};

export default AddControls;

import * as actionTypes from '../actions/actionTypes';
import _ from 'lodash';

export const defaultState = {
  loading: false,
  documentList: [],
  error: null,
  loadingControlLib: false,
  controlLibDocList: [],
  controlLibError: null,
  updateLoading: false,
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_DOCUMENTS_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case actionTypes.GET_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        documentList: _.get(action, ['payload', 'data', 'payload', 'data'], null),
      };
    }

    case actionTypes.GET_DOCUMENTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actionTypes.GET_CONTROL_LIBRARY_DOCUMENTS_START:
      return {
        ...state,
        loadingControlLib: true,
        controlLibError: null,
      };

    case actionTypes.GET_CONTROL_LIBRARY_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        loadingControlLib: false,
        controlLibDocList: _.get(action, ['payload', 'data', 'payload', 'data'], null),
      };
    }

    case actionTypes.GET_CONTROL_LIBRARY_DOCUMENTS_ERROR:
      return {
        ...state,
        loadingControlLib: false,
        controlLibError: action.payload,
      };

    case actionTypes.DOCUMENT_UPDATE_START:
      return {
        ...state,
        updateLoading: true,
        error: null,
      };

    case actionTypes.DOCUMENT_UPDATE_SUCCESS: {
      return {
        ...state,
        updateLoading: false,
      };
    }

    case actionTypes.DOCUMENT_UPDATE_ERROR:
      return {
        ...state,
        updateLoading: false,
        error: action.payload,
      };

    case actionTypes.DELETE_DOCUMENT_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case actionTypes.DELETE_DOCUMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case actionTypes.DELETE_DOCUMENT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

import { Box } from '@mui/material';
import { IC_QUESTION } from 'assets/constants/imageConst';
import React from 'react';
import Icon from '../Icon/Icon';

const InfoIconComponent = React.forwardRef(function InfoIconComponent(props, ref) {
  //  Spread the props to the underlying DOM element.
  return (
    <Box ml={1} {...props} ref={ref}>
      <Icon size="small" src={IC_QUESTION} />
    </Box>
  );
});

export default InfoIconComponent;

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// MUI
import { Typography, Box } from '@mui/material';

// Internal
import Tabs from '../../components/common/Tabs/Tabs';
import Integration from './Integration';
import { setBreadcrumbs } from 'store/actions/breadcrumbs';
import { breadcrumbsData } from 'assets/constants/const';
import { useSearchParams } from 'react-router-dom';
import { shouldBeMasked } from 'utils/general';
import { RISK_AI_LIGHTENING, RISK_AI_LIGHTENING_INTEGRATIONS } from 'utils/permissionUtils';

const RiskLightning = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');

  const defaultTab = tab === 'integration' ? (shouldBeMasked() ? 3 : 0) : 0;
  useEffect(() => {
    dispatch(setBreadcrumbs(breadcrumbsData.lightening));
  }, []);

  const tabInfo = shouldBeMasked()
    ? [
        {
          id: 0,
          label: <Typography variant="subtitle2"> My organization</Typography>,
          component: ' My Organization',
          rbacAction: RISK_AI_LIGHTENING,
        },
        {
          id: 1,
          label: <Typography variant="subtitle2">Key system info</Typography>,
          component: 'Key system info',
          rbacAction: RISK_AI_LIGHTENING,
        },
        {
          id: 2,
          label: <Typography variant="subtitle2">Key personnel info</Typography>,
          component: 'Key personnel info',
          rbacAction: RISK_AI_LIGHTENING,
        },
        {
          id: 3,
          label: (
            <Typography variant="subtitle2" color={tab === 'integration' ? 'primary' : ''}>
              Vendors and applications
            </Typography>
          ),
          component: <Integration />,
          rbacAction: RISK_AI_LIGHTENING_INTEGRATIONS,
        },
        {
          id: 4,
          label: <Typography variant="subtitle2">Reporting</Typography>,
          component: 'Reporting',
          rbacAction: RISK_AI_LIGHTENING,
        },
        {
          id: 5,
          label: <Typography variant="subtitle2">Third party access</Typography>,
          component: 'Third party access',
          rbacAction: RISK_AI_LIGHTENING,
        },
      ]
    : [
        {
          id: 0,
          label: (
            <Typography variant="subtitle2" color={tab === 'integration' ? 'primary' : ''}>
              Vendors and applications
            </Typography>
          ),
          component: <Integration />,
          rbacAction: RISK_AI_LIGHTENING_INTEGRATIONS,
        },
      ];

  return (
    <>
      <Box mt={4} mb={5}>
        <Typography variant="h5" mb={1}>
          RiskAI Lightening
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Manage all your information and integrations in just one place.
        </Typography>
      </Box>

      <Tabs tabInfo={tabInfo} defaultTab={defaultTab} />
    </>
  );
};
export default RiskLightning;

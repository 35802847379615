import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import _ from 'lodash';
import {
  getTenant
} from 'store/actions/tenant';

import { Button } from 'components/common/FormControls';
import Input from 'components/common/FormField/Input/Input';
import DropDown from 'components/common/FormField/Select/Dropdown';
import { userValidation } from 'utils/validationSchema';
import { Roles, TenantUserOption } from 'utils/general';
import { addUser, updateUser } from 'store/actions/user';
import CommonLoader from 'components/common/Loader/CommonLoader';

const AddUserModal = ({ userDetail, callUserList, handleAddModal }) => {
  const dispatch = useDispatch();
  const isAdd = _.isNil(userDetail);
  const { userProfile,tenantData:userTenantData  } = useSelector((state) => state.common);
  const { loading } = useSelector((state) => state.user);
  const { tenantData, loading: tenantLoading } = useSelector((state) => state.tenant);
  const loggedInUserRole = userProfile?.role;
  const loggedInUserChildRole= userTenantData?.role
  const roleIsTenantAdmin = () => {
    if(![Roles.SUPER_USER,Roles.CHANNEL_PARTNER].includes(loggedInUserRole)) {
      if (loggedInUserChildRole == Roles.TENANT_ADMIN)
      return true;
    }
    
    return false;
  }

  useEffect(() => {
    if(!roleIsTenantAdmin()){
      dispatch(getTenant({ per_page: -1 }));
    }
  }, [])

  const getRoleOptions = () => {
    let roles=[];
    let customOption;
    if(!isAdd && userDetail?.role == Roles.TENANT_USER && userDetail?.tenants.length > 0){
      customOption=TenantUserOption[userDetail?.tenants[0]?.role];
    }
    if (loggedInUserRole == Roles.SUPER_USER) {
      roles= [
        { label: 'Super user', value: Roles.SUPER_USER },
        { label: 'Channel partner', value: Roles.CHANNEL_PARTNER }];
      if (isAdd || (!isAdd && [Roles.SUPER_USER, Roles.CHANNEL_PARTNER].includes(userDetail?.role))) {
          roles = roles.concat([{ label: 'Tenant admin', value: Roles.TENANT_ADMIN },
          { label: 'Tenant maintainer', value: Roles.TENANT_MAINTAINER },
          { label: 'Tenant auditor', value: Roles.TENANT_AUDITIOR }])
        }
        else if(customOption){
          roles.push(customOption);
        }
    }
    else if(loggedInUserRole == Roles.CHANNEL_PARTNER) {
      roles= [
        { label: 'Tenant admin', value: Roles.TENANT_ADMIN },
        { label: 'Tenant maintainer', value: Roles.TENANT_MAINTAINER },
        { label: 'Tenant auditor', value: Roles.TENANT_AUDITIOR },
      ]
    }
    else if (loggedInUserRole == Roles.TENANT_USER && loggedInUserChildRole == Roles.TENANT_ADMIN ) {
      roles= [
        { label: 'Tenant admin', value: Roles.TENANT_ADMIN },
        { label: 'Tenant maintainer', value: Roles.TENANT_MAINTAINER },
        { label: 'Tenant auditor', value: Roles.TENANT_AUDITIOR },
      ]
    }
    return roles;
  }

  const getTenantOptions = () => {
    const data = tenantData?.data ?? [];
    let options = [];
    _.map(data, (ten => {
      options.push({
        label: ten.site_name,
        value: ten.id
      })
    }))
    return options;

  }

  const handleSubmitForm = (data) => {
    const promise = isAdd ? dispatch(addUser({ data: { ...data } })) : dispatch(updateUser(userDetail?.uuid, { data: { ...data } }));
    promise.then(() => {
      callUserList();
      handleAddModal(false, null);
      toast.success(isAdd ? 'User created successfully' : 'User updated successfully');
    })

  };

  const initialRole = () => {
    if (userDetail) {
      if (userDetail?.role == 'TenantUser') {
        const userRole = userDetail?.tenants[0]?.role;
        switch (userRole) {
          case Roles.TENANT_ADMIN: return Roles.TENANT_ADMIN;
          case Roles.TENANT_AUDITIOR: return Roles.TENANT_AUDITIOR;
          case Roles.TENANT_MAINTAINER: return Roles.TENANT_MAINTAINER;
        }
      }
      return userDetail?.role
    }
    return '';
  }
  

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: userDetail?.first_name || '',
      last_name: userDetail?.last_name || '',
      user_email: userDetail?.user_email || '',
      role: initialRole(),
      tenant_id: (isAdd && roleIsTenantAdmin())?_.get(userTenantData,['id'],'') :_.get(userDetail, ['tenants', '0', 'tenant_id'], null)
    },
    validationSchema: userValidation,
    validate: values => {
      const errors = {};
      if([Roles.TENANT_ADMIN, Roles.TENANT_AUDITIOR, Roles.TENANT_MAINTAINER].includes(values.role) && _.isNil(values.tenant_id)) {
        errors.tenant_id = "Tenant id is required";
      }
      return errors;
    },
    onSubmit: handleSubmitForm,
  });



  const { values, handleChange, touched, errors } = formik;
  const btnDisabled = () => {
    if (_.isEqual(formik.initialValues, values)) {
      return true
    }
    return false;

  }
 

  return loading || tenantLoading ? <CommonLoader /> : (
    <Box sx={{padding:'16px'}}>
      <Box mb={3}>
        <Input
          id="first_name"
          className="mb-3"
          fullWidth
          placeholder="First name"
          label="First name *"
          name="first_name"
          value={values.first_name}
          onChange={handleChange}
          error={touched.first_name && Boolean(errors.first_name)}
          helperText={touched.first_name && errors.first_name}
        />
      </Box>
      <Box mb={3}>
        <Input
          id="last_name"
          className="mb-3"
          fullWidth
          placeholder="Last name"
          label="Last name *"
          name="last_name"
          value={values.last_name}
          onChange={handleChange}
          error={touched.last_name && Boolean(errors.last_name)}
          helperText={touched.last_name && errors.last_name}
        />
      </Box>
      <Box mb={3}>
        <Input
          id="user_email"
          className="mb-3"
          fullWidth
          placeholder="Email"
          label="Email *"
          name="user_email"
          onChange={handleChange}
          disabled={!isAdd}
          value={values.user_email}
          error={touched.user_email && Boolean(errors.user_email)}
          helperText={touched.user_email && errors.user_email}
        />
      </Box>
      {(isAdd ||[Roles.SUPER_USER].includes(loggedInUserRole)|| (Roles.TENANT_USER == loggedInUserRole && Roles.TENANT_ADMIN == loggedInUserChildRole)) && 
      <Box mb={3}>
        <Typography variant="subtitle2">Role *</Typography>
        <DropDown
          options={getRoleOptions()}
          id="id"
          name="role"
          formik={formik}
          placeholder="Select role"
          error={touched.role && Boolean(errors.role)}
          helperText={touched.role && errors.role}
        />
      </Box>
}    
      {(isAdd || 
      (!isAdd && 
        [Roles.SUPER_USER, Roles.CHANNEL_PARTNER].includes(userDetail?.role))
         ) &&
      [Roles.TENANT_ADMIN, Roles.TENANT_AUDITIOR, Roles.TENANT_MAINTAINER].includes(values.role) &&
        <>
          {!roleIsTenantAdmin() &&
            <Box mb={3}>
              <Typography variant="subtitle2">Tenant </Typography>
              <DropDown
                options={getTenantOptions()}
                id="id"
                name="tenant_id"
                formik={formik}
                placeholder="Select tenant"
                error={touched.tenant_id && Boolean(errors.tenant_id)}
                helperText={touched.tenant_id && errors.tenant_id}
                disabled={roleIsTenantAdmin()}
              />
            </Box>
          }
        </>
      }
      <Box mt={3} mb={3} sx={{ display: 'flex', justifyContent: 'flex-end', columnGap: '10px' }}>
        <Button
          variant="contained"
          name="update"
          type="submit"
          onClick={formik.handleSubmit}
          disabled={btnDisabled()}
        >
          {isAdd ? 'Add' : 'Update'}
        </Button>
      </Box>
    </Box>
  );
};

export default AddUserModal;

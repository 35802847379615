import React from 'react';
import { Accordion as MUIAccordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';

import { UP_ARROW } from 'assets/constants/imageConst';
import { ReactSVG } from 'react-svg';

const Accordion = ({
  leftSideSummary = '',
  rightSideSummary = '',
  square = false,
  children,
  defaultExpanded = false,
  expandedPanelId = '',
  ...rest
}) => {
  const [expanded, setExpanded] = React.useState([]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? [...expanded, panel] : expanded.filter((exp) => exp !== panel));
  };

  return (
    <MUIAccordion
      expanded={expanded.includes(expandedPanelId)}
      onChange={handleChange(expandedPanelId)}
      sx={{
        '&.MuiAccordion-root': { borderRadius: '8px', backgroundColor: 'rgba(255, 255, 255, 0.5)' },
      }}
      {...rest}
    >
      <AccordionSummary
        expandIcon={<ReactSVG src={UP_ARROW} className={expanded ? 'up-arrow' : 'right-arrow'} />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{
          '& .MuiAccordionSummary-content': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            margin: '16px 0',
          },
        }}
      >
        {leftSideSummary}
        <Typography sx={{ marginRight: '14px' }}>{rightSideSummary}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ '&.MuiAccordionDetails-root': { padding: '0 16px' } }}>{children}</AccordionDetails>
    </MUIAccordion>
  );
};
export default Accordion;

import * as actionTypes from '../actions/actionTypes';

export const defaultState = {
  breadcrumbs: [{ path: '/dashboard', label: 'Dashboard' }],
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case actionTypes.SET_BREAD_CRUMBS:
      return {
        ...state,
        breadcrumbs: action.payload,
        error: null,
      };

    default:
      return state;
  }
};

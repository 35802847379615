//Lodash
import _,{ get } from 'lodash';
import { useSelector } from 'react-redux';

const userroles = {
    hidden: false,
    'read-only': 'read-only',
    'full-access': true,
};

const checkroles = (action) => {
    const { userPermissions } = useSelector((state) => state.common);
    const useractions = userPermissions;
    const granular_action =
        useractions && action ? useractions[action] : undefined;
    return get(userroles, granular_action, false);
};

const RBAC = (props) => {
    const role = checkroles(props.action);
    if (role == 'read-only') {
        return props.no ?? null;
    } else if (role) {
        return props.yes;
    }
    return null;
}

RBAC.defaultProps = {
    yes: null,
    no: null,
};

export { RBAC as CORERBAC };

export default RBAC;


// Example
// <RBAC 
//  action={PERMISSION_STRING}
//  yes={<Component />}
//  no={<AnotherComponent />}
// />
import React, { useState, useEffect, memo } from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'components/common/FormControls';
import { getUser } from 'store/actions/user';
import Avatar from './Avatar/Avatar';

const AsyncProfileImage = ({ uuid }) => {
  const dispatch = useDispatch();
  const [userRes, setUserRes] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (uuid) {
      dispatch(getUser(uuid,{is_profile:true})).then((res) => {
        setLoading(false);
        setUserRes(res?.value?.data?.payload);
      });
    }
  }, []);

  return !loading &&
    <Tooltip title={`${userRes?.first_name} ${userRes?.last_name}`} arrow placement="top" >
      <div>
      <Avatar size="small" src={userRes?.profile_pic_url} alt="Profile Pic" />
      </div>
    </Tooltip>
};
export default memo(AsyncProfileImage);

import { Box, Divider, Grid, Typography } from '@mui/material';
import { IC_WARNING_ALT, IC_LAPTOP, USER } from 'assets/constants/imageConst';
import React from 'react';
import { ReactSVG } from 'react-svg';
import { getLabelFromRiskProgress, getProgressColor } from 'utils/general';
import Chip from '../Chip/Chip';
import LinearProgressbar from '../Progressbar/LinearProgressbar';
import WrapperBox from '../Wrapper/WrapperBox';

function IntegrationRiskCard({ logo, padding = '24px ', title, device, user, progressValue, isCustomConnector }) {
  return (
    <WrapperBox padding={padding}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} mb={3}>
        <WrapperBox padding="5px" style={{ border: '1px solid #ECEAF4' }}>
          <img src={logo} height={50} width={50} />
        </WrapperBox>
        <Box ml={2}>
          <Typography variant="subtitle1" mb={1}>
            {title}
          </Typography>
          <Chip icon={<ReactSVG src={IC_WARNING_ALT} />} {...getLabelFromRiskProgress(progressValue)} />
        </Box>
      </Box>
      <Box mb={2}>
        <LinearProgressbar
          value={progressValue}
          label={
            <Typography variant="body1" ml={1}>
              {progressValue}%
            </Typography>
          }
          color={getProgressColor(progressValue, true)}
        />
      </Box>
      <Divider />
      <Box mt={2}>
        <Grid container>
          {!isCustomConnector && (
            <Grid item sm={6}>
              <Box>
                <Typography variant="body2" color="secondary" mb={1}>
                  Number of devices
                </Typography>
                {device === 0 ? '-' : <Chip label={device} icon={<ReactSVG src={IC_LAPTOP} />} />}
              </Box>
            </Grid>
          )}
          <Grid item sm={6}>
            <Box>
              <Typography variant="body2" color="secondary" mb={1}>
                Number of users
              </Typography>
              {user === 0 ? '-' : <Chip label={user} icon={<ReactSVG src={USER} />} />}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </WrapperBox>
  );
}

export default IntegrationRiskCard;

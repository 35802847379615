import api from './api';

class RiskAnalysisAPI {
  getRiskAnalysis(uuid) {
    return api.get(`/${uuid}/risk-analysis`);
  }
  getConnectorRuns(uuid) {
    return api.get(`/connectors/${uuid}/runs?last=true`);
  }
  updateRiskScore(data) {
    return api.post('/control_library', data);
  }
  getScore(data) {
    return api.post('/score', data);
  }
  getScoreResponseRatio(data) {
    return api.post('/score/response-ratio', data);
  }
  getProgressScore(tenant_uuid) {
    return api.get(`/${tenant_uuid}/progress-score`);
  }
  getVulnerabilityList() {
    return api.get('/threat-master');
  }
  getConnectorNoteAndDcoument(uuid) {
    return api.get(`/${uuid}/counts`);
  }
}

export default RiskAnalysisAPI;

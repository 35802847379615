import {
  Accordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  FormControl,
  MenuItem,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { connectorsName } from 'assets/constants/const';
import { IC_PLAY, IC_RIGHT2, IC_SEARCH } from 'assets/constants/imageConst';
import { Chip } from 'components/common';
import { Button, Tooltip } from 'components/common/FormControls';
import Icon from 'components/common/Icon/Icon';
import CommonLoader from 'components/common/Loader/CommonLoader';
import Modal from 'components/common/Modal/Modal';
import RBAC from 'components/RBAC';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { getConnectorRuns, addConnectorRuns } from 'store/actions/connectors';
import { getFormatDate, minuteDiff } from 'utils/date';
import { shouldBeMasked } from 'utils/general';
import { RISK_AI_LIGHTENING_INTEGRATIONS_WORKSPACE_INTEGRATION_RUNS_RUN_INTEGRATION } from 'utils/permissionUtils';

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ReactSVG src={IC_RIGHT2} />} {...props} />
))(({ theme }) => ({
  backgroundColor: '#f1eff7a6',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

export default function IntegrationTable() {
  const dispatch = useDispatch();
  const { getWorkspace, runLoading, integrationDetail } = useSelector((state) => state.connectors);
  const [filter, setFilter] = useState('All');
  const [riskScore, setRiskScore] = useState();
  const [isOpen, setOpen] = useState(false);
  let timeInterval;

  const isProcessing = getWorkspace?.data?.some((value) => value.status === 'processing');

  useEffect(() => {
    dispatch(getConnectorRuns(integrationDetail?.data?.tenant_connector_uuid));
  }, []);

  useEffect(() => {
    if (integrationDetail?.data?.tenant_connector_uuid && isProcessing && !isOpen) {
      timeInterval = setInterval(() => {
        // Call Integration Runs API
        dispatch(getConnectorRuns(integrationDetail?.data?.tenant_connector_uuid));
      }, 10000); // set for 10 sec
    }
    if (!isProcessing && isOpen) {
      clearInterval(timeInterval);
    }
    return () => {
      clearInterval(timeInterval);
    };
  }, [isProcessing, isOpen]);

  const handleRunIntegration = () => {
    dispatch(addConnectorRuns(integrationDetail?.data?.tenant_connector_uuid)).then(() => {
      dispatch(getConnectorRuns(integrationDetail?.data?.tenant_connector_uuid));
    });
  };

  const handleModal = (data) => {
    if (data && Object.keys(data).length > 0) {
      setRiskScore(data);
      setOpen(true);
    }
  };

  const handleCloseModal = () => {
    setRiskScore();
    setOpen(false);
  };

  if (runLoading) {
    return <CommonLoader height="200px" />;
  }
  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} mb={3} mt={6}>
        <Box>
          <Typography variant="subtitle1" mb={1}>
            Integration Runs
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Manage and learn more about your integration runs.
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {shouldBeMasked() && (
            <>
              <Typography variant="body2" color="text.secondary">
                Filter by:
              </Typography>
              <Box mr={1}>
                <FormControl variant="standard" size="small" sx={{ m: 1, minWidth: 20 }}>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    label="Age"
                    size="small"
                    autoWidth
                    sx={{ border: 0 }}
                    disableUnderline
                    renderValue={(value) => (
                      <Typography variant="body2" color="primary" sx={{ mt: '7px' }}>
                        {value}
                      </Typography>
                    )}
                  >
                    <MenuItem value={'All'}>All</MenuItem>
                    <MenuItem value={'Success'}>Success</MenuItem>
                    <MenuItem value={'Process'}>Process</MenuItem>
                    <MenuItem value={'Fail'}>Fail</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </>
          )}
          {shouldBeMasked() ? (
            <Box mr={3}>
              <Icon size="small" src={IC_SEARCH} />
            </Box>
          ) : null}
          {integrationDetail?.data?.type != connectorsName.CUSTOM && (
            <RBAC
              action={RISK_AI_LIGHTENING_INTEGRATIONS_WORKSPACE_INTEGRATION_RUNS_RUN_INTEGRATION}
              yes={
                <Button
                  disabled={isProcessing}
                  startIcon={<ReactSVG src={IC_PLAY} />}
                  variant="filled"
                  onClick={handleRunIntegration}
                >
                  Run integration
                </Button>
              }
            />
          )}
        </Box>
      </Box>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="medium" aria-label="a dense table">
          <TableHead sx={{ backgroundColor: '#FFFFFF80', borderRadius: '8px' }}>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle2" color="secondary">
                  Run ID
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="subtitle2" color="secondary">
                  Run Status
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="subtitle2" color="secondary">
                  Start Date
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="subtitle2" color="secondary">
                  End Date
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="subtitle2" color="secondary">
                  Execution Time
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getWorkspace &&
              getWorkspace?.data?.map((row) => {
                const userCount = row.output_json?.user_data?.length;
                const tooltipMSG = `${row.status === 'success' ? userCount : 0} users fetched `;
                return (
                  <TableRow key={row.uuid} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {row.uuid}
                    </TableCell>
                    <TableCell align="left">
                      {row.status === 'success' && userCount > 0 ? (
                        <Tooltip title={tooltipMSG} width="100px">
                          <div style={{ width: 'fit-content' }}>
                            <Chip
                              label={row.status}
                              color="success"
                              sx={{ textTransform: 'capitalize' }}
                              onClick={() => handleModal(row?.risk_score)}
                            />
                          </div>
                        </Tooltip>
                      ) : (
                        <Chip
                          label={row.status}
                          color={row.status === 'success' ? 'success' : 'warning'}
                          sx={{ textTransform: 'capitalize' }}
                        />
                      )}
                    </TableCell>
                    <TableCell align="left">{getFormatDate(row.start_time)}</TableCell>
                    <TableCell align="left">{getFormatDate(row.end_time)}</TableCell>
                    <TableCell align="left">
                      {row.start_time && row.end_time ? minuteDiff(row.start_time, row.end_time) : ''}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal open={isOpen} title="Risk Analysis" handleClose={handleCloseModal} width={800}>
        {riskScore?.threat_controls.map((threat) => (
          <Box key={threat.name}>
            <Typography variant="subtitle1" my={2}>
              {threat.name}
            </Typography>
            {threat.vulnerabilities.map((value) => (
              <Accordion key={value.name} sx={{ mb: 1 }} disableGutters>
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    backgroundColor: 'FFFFFF80',
                    '.MuiAccordionSummary-content': { justifyContent: 'space-between' },
                  }}
                >
                  <Typography>{value.name}</Typography>
                  <Box mr={1}>
                    <Chip label={`Aggregate risk score: ${value.aggregate_risk_score || 'NA'}`} />
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer>
                    <Table sx={{ minWidth: 650 }} size="medium" aria-label="a dense table">
                      <TableHead sx={{ backgroundColor: '#FFFFFF80', borderRadius: '8px' }}>
                        <TableRow>
                          <TableCell>
                            <Typography variant="subtitle2" color="secondary">
                              Control
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="subtitle2" color="secondary">
                              Likelihood score
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="subtitle2" color="secondary">
                              weight
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="subtitle2" color="secondary">
                              Impact score
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant="subtitle2" color="secondary">
                              Risk score
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {value.controls &&
                          value.controls?.map((row) => {
                            return (
                              <TableRow key={row.uuid} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                  {row.code + '-' + row.name}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {row.likelyhood_score}
                                </TableCell>
                                <TableCell align="left">{row.weight}</TableCell>
                                <TableCell align="left">{row.impact_score}</TableCell>
                                <TableCell align="left">{row.risk_score}</TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        ))}
      </Modal>
    </Box>
  );
}

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { Button } from 'components/common/FormControls';
import ConnectCard from '../Cards/ConnectCard';
import WorkspaceCreationLoader from './WorkspaceCreationLoader';
import Input from 'components/common/FormField/Input/Input';
import GsuitForm from './FormField/GsuitForm';
import MicrosoftForm from './FormField/MicrosoftForm';
import { breadcrumbsData, connectorData, connectorsName } from 'assets/constants/const';
import ROUTE from 'assets/constants/route';
import { integrationValidationSchema } from 'utils/validationSchema';
import { addConnector } from 'store/actions/connectors';
import { setBreadcrumbs } from 'store/actions/breadcrumbs';
import { getNumberFromWord, stringToObjectConvertor } from 'utils/general';
import GDriveForm from './FormField/GDriveForm';
import storage from 'services/storage';
import GDriveInheritRiskForm from './FormField/GDriveInheritRiskForm';
import AzureADForm from './FormField/AzureADForm';

export default function RiskLightningWorkspace() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  // const { user } = useAuth0();

  const { loading, integrationDetail, uploadedId } = useSelector((state) => state.connectors);
  const { userProfile,tenantData } = useSelector((state) => state.common);

  const tenantName = tenantData?.site_name;
  const workSpaceName = params?.id;
  const workSpaceData = connectorData[workSpaceName];

  const validationSchema = integrationValidationSchema[workSpaceName];

  const emailDomain = userProfile?.email ? userProfile.email.split('@')[1] : '';

  const bc = breadcrumbsData.lightningConnectors[workSpaceName];

  useEffect(() => {
    dispatch(setBreadcrumbs(bc));
  }, []);

  useEffect(() => {
    if (integrationDetail) {
      const uuid = integrationDetail?.data?.uuid;
      navigate(`/lightening/detail/${uuid}`);
    }
  }, [integrationDetail]);

  const handleSubmitForm = (values) => {
    const body = {
      data: {
        connection_detail: {
          email: values?.adminEmail || undefined,
          certificate: uploadedId?.data || undefined,
          dns_name: values?.dns_name || undefined,
          // public_ip_address: values?.public_ip_address,
          username: values?.username || undefined,
          password: values?.password || undefined,
          manual_impact_score:values?.impactScore,
          number_of_health_records: values?.numberOfHealthRecords
            ? stringToObjectConvertor(values?.numberOfHealthRecords)
            : undefined,
          number_of_user: values?.numberOfUserRecords
            ? stringToObjectConvertor(values?.numberOfUserRecords)
            : undefined,
          hippa_folder_path: values?.folderPath || undefined,
          system_priority: values?.systemPriority || undefined,
          system_hosting_model: values?.hostingModal || undefined,
          type_of_sensitive_data: values?.sensitiveData || undefined,
          application_id: values?.applicationId || undefined,
          client_secret: values?.clientSecret || undefined,
          directory_id: values?.directoryId || undefined,
        },
        description: values?.description,
        name: values?.accountName,
        tenant: tenantName,
        type: workSpaceName,
        // isManual: values.isManual || false,
        frequency: values?.pollingInterval ? values?.pollingInterval?.split(' ')[1] : undefined,
        interval: values?.pollingInterval ? getNumberFromWord(values?.pollingInterval?.split(' ')[0]) : undefined,
      },
    };
    dispatch(addConnector(body)).then(() => navigate(ROUTE.LIGHTNING_INTEGRATION));
  };

  const formik = useFormik({
    initialValues: {
      accountName: `${emailDomain} ${workSpaceData.title}`,
      description: 'Business App and Productivity Tool',
      pollingInterval: 'One Week',
      adminEmail:
        workSpaceName === connectorsName?.GDRIVE
          ? storage.get('gsuite_email')
          : workSpaceName === connectorsName.GOOGLE
          ? `developer.riskai@${emailDomain}`
          : '',
      dns_name: '',
      // public_ip_address: '',
      numberOfHealthRecords: '',
      numberOfUserRecords: '',
      folderPath: '',
      username: '',
      password: '',
      certificate: '',
      sensitiveData: '',
      hostingModal: '',
      systemPriority: '',
      applicationId: '',
      clientSecret: '',
      directoryId: '',
      impactScore: '',
      // isManual: false,
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmitForm,
  });

  const renderForm = () => {
    if (workSpaceName === connectorsName.GOOGLE) {
      return <GsuitForm formik={formik} />;
    } else if (workSpaceName === connectorsName.MICROSOFT) {
      return <MicrosoftForm formik={formik} />;
    } else if (workSpaceName === connectorsName.GDRIVE) {
      return <GDriveForm formik={formik} />;
    } else if (workSpaceName === connectorsName.AZURE) {
      return <AzureADForm formik={formik} />;
    }
  };

  if (loading) {
    return <WorkspaceCreationLoader logo={workSpaceData?.logo} byName={workSpaceData?.connectingText} />;
  }

  if (!workSpaceData) {
    return <></>;
  }

  const { values, handleChange, touched, errors } = formik;

  return (
    <Box>
      <Grid container spacing={0}>
        <Grid item sm={12} xs={12}>
          <ConnectCard
            logo={workSpaceData.logo}
            title={workSpaceData.title}
            byName={workSpaceData.byName}
            description={workSpaceData.description}
            secondaryButton={
              <Button
                type="button"
                variant="text"
                color="secondary"
                onClick={() => navigate(ROUTE.LIGHTNING_INTEGRATION)}
              >
                Cancel
              </Button>
            }
            primaryButton={
              <Button type="submit" variant="filled" onClick={formik.handleSubmit}>
                Connect
              </Button>
            }
          />
        </Grid>
      </Grid>
      <Box mt={4} mx={3}>
        <Grid container>
          <Grid item xs={4} sm={4}>
            <Box mb={4}>
              <Typography variant="subtitle1" mb={1}>
                Integration details
              </Typography>
              <Typography variant="body2" color="text.secondary">
                These information will be used for organization purposes only.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={8} sm={8}>
            <Box mb={3}>
              <Input
                id="accountName"
                className="mb-3"
                fullWidth
                placeholder="ex. My Account"
                label="Account Name"
                name="accountName"
                value={values.accountName}
                info="The account name or alias that uniquely define this integration."
                onChange={handleChange}
                error={touched.accountName && Boolean(errors.accountName)}
                helperText={touched.accountName && errors.accountName}
              />
            </Box>
            <Box mb={3}>
              <Input
                id="description"
                className="mb-3"
                fullWidth
                placeholder="Write a short description for your integration"
                label="Description"
                name="description"
                value={values.description}
                onChange={handleChange}
                error={touched.description && Boolean(errors.description)}
                helperText={touched.description && errors.description}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Divider light={false} />
      <Box mt={4} mx={3}>
        <Grid container spacing={0}>
          <Grid item xs={4} sm={4}>
            <Box mb={3} mr={3}>
              <Typography variant="subtitle1" mb={1}>
                Settings
              </Typography>
              <Typography variant="body2" color="text.secondary">
                These informations will be used to connect with your {workSpaceData?.title} environment.
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" color="text.secondary">
                Need help?{' '}
                <Typography component="a" variant="body2" color="primary" target="_blank" href={workSpaceData?.pdf}>
                  Read documentation
                </Typography>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={8} sm={8}>
            {/* <Box mb={2}>
              <FormControlLabel
                checked={values?.isManual}
                control={<Checkbox />}
                onChange={() => setFieldValue('isManual', !values.isManual)}
                label="Manual Setup"
              />
            </Box> */}
            {/* {values?.isManual ? '' : renderForm()} */}
            {renderForm()}
          </Grid>
        </Grid>
      </Box>
      {workSpaceName === connectorsName.GDRIVE && (
        <>
          <Divider light={false} />
          <Box mt={4} mx={3}>
            <GDriveInheritRiskForm formik={formik} />
          </Box>
        </>
      )}
    </Box>
  );
}

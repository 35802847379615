import axios from 'axios';
import { toast } from 'react-toastify';
import ConnectorAPI from './ConnectorAPI';
import RiskAnalysisAPI from './RiskAnalysisAPI';
import MeAPI from './MeAPI';
import UsersAPI from './UsersAPI';
import ControlsAPI from './ControlsAPI';
import UserAPI from './UserAPI';
import TenantAPI from './TenantAPI';
import FrameworksAPI from './FrameworksAPI'

const APIBaseUrl = `${process.env.REACT_APP_BASE_URL}/api`;
class API {
  auth0_Instance;
  __me = new MeAPI();
  __connector = new ConnectorAPI();
  __riskAnalysis = new RiskAnalysisAPI();
  __users = new UsersAPI();
  __controls = new ControlsAPI();
  __user = new UserAPI();
  __tenant = new TenantAPI();
  __frameworks =new FrameworksAPI();

  api = axios.create({
    baseURL: APIBaseUrl,
    transformRequest: [(data) => JSON.stringify(data)],
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  api2 = axios.create({
    baseURL: APIBaseUrl,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  });

  get connectors() {
    return this.__connector;
  }
  get me() {
    return this.__me;
  }
  get tenant() {
    return this.__tenant;
  }
  get riskAnalysis() {
    return this.__riskAnalysis;
  }
  get users() {
    return this.__users;
  }
  get controls() {
    return this.__controls;
  }
  get user() {
    return this.__user;
  }
  get frameworks() {
    return this.__frameworks;
  }

  get(url, ...args) {
    return this.sendRequestInternal(this.api.get, url, ...args);
  }

  post(url, ...args) {
    return this.sendRequestInternal(this.api.post, url, ...args);
  }

  postUpload(url, ...args) {
    return this.sendRequestInternal(this.api2.post, url, ...args);
  }

  patch(url, ...args) {
    return this.sendRequestInternal(this.api.patch, url, ...args);
  }

  delete(url, ...args) {
    return this.sendRequestInternal(this.api.delete, url, ...args);
  }

  put(url, ...args) {
    return this.sendRequestInternal(this.api.put, url, ...args);
  }

  async sendRequestInternal(requestFunc, url, ...args) {
    const { logout, getIdTokenClaims } = this.auth0_Instance;
    const rawToken = await getIdTokenClaims();
    const token = rawToken?.__raw;

    if (token) {
      this.api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      this.api2.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
    return requestFunc(url, ...args)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error?.response?.status === 403) {
          logout({
            returnTo: window.location.origin,
          });
        }
        toast.error(error?.response?.data?.payload?.message);
        console.log('error --- :: ', error);
        throw error;
      });
  }
}

export default new API();

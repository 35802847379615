import * as actionTypes from '../actions/actionTypes';

export const defaultState = {
  threshold_value: 50,
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case actionTypes.UPDATE_THRESHOLD_VALUE:
      return { ...state, threshold_value: action.payload };

    default:
      return state;
  }
};

import { useState } from 'react';
import { CartesianGrid, ResponsiveContainer, Scatter, Tooltip, XAxis, Label, YAxis, ComposedChart } from 'recharts';
import { Box, Typography } from '@mui/material';
// Internal
import { areaGraph } from './ComposedGraph/Graph';
import { heatGraphData } from 'assets/constants/graphData';
import Chip from '../Chip/Chip';

const COLORS = {
  1: 'rgb(54, 195, 149)',
  2: 'rgba(54, 195, 149, 0.5)',
  3: 'rgba(248, 231, 85, .5)',
  4: 'rgb(248, 231, 85)',
  5: 'rgba(254, 152, 0, 0.6)',
  6: 'rgb(254, 152, 0, 0.9)',
  7: 'rgb(254, 152, 0)',
  8: 'rgba(255, 0, 127, 0.6)',
  9: 'rgba(255, 0, 127, 0.9)',
  10: 'rgb(255, 0, 127)',
};

const renderScatter = (props) => {
  const { x, y, likelihood } = props;
  if (likelihood) {
    return (
      <g transform={`translate(${x},${y})`}>
        <circle fill={COLORS[likelihood]} r="3" cx="6" cy="6" />
      </g>
    );
  }
};

const HeatGraph = ({ scoreData }) => {
  const [tooltip, setTooltip] = useState([]);
  let dynamicData = [];

  const CustomTooltip = ({ active }) => {
    if (active && tooltip?.length > 0) {
      return (
        <Box
          sx={{
            padding: '8px',
            borderRadius: '8px',
            background: '#fff',
            textAlign: 'center',
          }}
        >
          {tooltip?.map((tt, index) => (
            <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 0.5 }}>
              <Typography variant="caption" display="inline">
                {tt?.name}
              </Typography>
              <Chip label={`Aggregate risk score: ${tt.aggregate_score}`} sx={{ ml: 1 }} />
            </Box>
          ))}
        </Box>
      );
    }

    return null;
  };

  scoreData?.forEach((element) => {
    const existData = dynamicData?.find((dd) => {
      return dd.impact === element?.aggregate_impact_score && dd.likelihood === element?.aggregate_likelihood_score;
    });

    if (existData) {
      const isExistVul = existData.vulnerabilities?.find((d) => d.name === element?.name);
      if (!isExistVul) {
        const indexOfData = dynamicData?.findIndex(
          (d) => d.impact === existData.impact && d.likelihood === existData.likelihood
        );

        dynamicData[indexOfData] = {
          ...dynamicData[indexOfData],
          vulnerabilities: [
            ...existData.vulnerabilities,
            { name: element?.name, aggregate_score: element?.aggregate_risk_score },
          ],
        };
      }
    } else {
      dynamicData.push({
        impact: element?.aggregate_impact_score,
        likelihood: element?.aggregate_likelihood_score,
        vulnerabilities: [{ name: element?.name, aggregate_score: element?.aggregate_risk_score }],
      });
    }
  });

  return (
    <ResponsiveContainer>
      <ComposedChart data={[...heatGraphData, ...dynamicData]}>
        <CartesianGrid vertical={false} stroke="#4F4A553B" strokeOpacity={0.3} />
        <XAxis
          axisLine={false}
          dataKey="impact"
          tick={{ fontSize: 11, color: '#4F4A5599', fill: '#4F4A5599' }}
          height={40}
          allowDuplicatedCategory={false}
          tickLine={false}
          ticks={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]}
          tickFormatter={(value) => (value <= 10 ? value : '')}
          tickMargin={5}
          margin={10}
        >
          <Label value="Impact" fill="#4F4A55" fontSize={10} position="insideBottom" offset={0} />
        </XAxis>
        <YAxis
          dataKey="likelihood"
          name="Likelihood"
          domain={[0, 10]}
          tick={{ fontSize: 11, color: '#4F4A5599', fill: '#4F4A5599' }}
          axisLine={false}
          tickLine={false}
          tickFormatter={(value) => (value % 2 === 0 ? value : '')}
          tickMargin={5}
          allowDuplicatedCategory={false}
          tickCount={10}
          type="number"
        >
          <Label value="Likelihood" angle={-90} fill="#4F4A55" fontSize={10} position="outsideLeft" />
        </YAxis>
        {areaGraph()}
        <Scatter
          shape={renderScatter}
          isAnimationActive={false}
          onMouseOver={(e) => {
            setTooltip(e?.vulnerabilities);
          }}
          onMouseLeave={() => setTooltip(null)}
        />
        <Tooltip content={<CustomTooltip />} cursor={false} wrapperStyle={{ outline: 'none' }} />
      </ComposedChart>
    </ResponsiveContainer>
  );
};
export default HeatGraph;

import React, { useEffect } from 'react';
import { ReactSVG } from 'react-svg';

// MUI
import { Box, Grid, Skeleton, styled, Typography } from '@mui/material';

// Internal
import { Chip, CircularProgressbar } from 'components/common';
import {
  IC_UP_TREND,
  PIE_GRAPH_SUCCESS,
  PIE_GRAPH_WARNING,
  PIE_GRAPH_ERROR,
  IC_LIGHTNING,
  IC_DOWN_TREND,
  IC_SWITCH_DOUBLE,
  FILE_DARK,
  IC_FILE_CHECK,
  IC_PRESENT_CHECK,
  IC_USER_GROUP,
  IC_SHARE_ALT2,
  IC_CHART_LINE_ALT1,
} from '../../assets/constants/imageConst';
import RiskAnalysis from './RiskAnalysis/RiskAnalysis';
import RiskManagement from './RiskManagement/RiskManagement';
import PolicyProcurement from './PoliyProcurement/PolicyProcurement';
import WorkforceTraining from './WorkforceTraining/WorkforceTraining';
import InfoIconComponent from 'components/common/InfoIcon/InfoIconComponent';
import WrapperBox from 'components/common/Wrapper/WrapperBox';
import Tabs from 'components/common/FormField/Tabs/Tabs';
import NonTechnicalEvaluation from './NonTechnicalEvaluation/NonTechnicalEvaluation';
import { useDispatch, useSelector } from 'react-redux';
import { setBreadcrumbs } from 'store/actions/breadcrumbs';
import { breadcrumbsData } from 'assets/constants/const';
import Avatar from 'components/common/Avatar/Avatar';
import TechnicalEvaluation from './TechnicalEvaluation/TechnicalEvaluation';
import VendorManagement from './VendorManagement/VendorManagement';
import { getFullName, getProgressColor, getTrendValues, shouldBeMasked } from 'utils/general';
import TrendGraph from './Graph/TrendGraph';
import { getRiskAnalysis, getScore } from 'store/actions/riskAnalysis';
import background from 'assets/images/frame1.png';
import { BACKEND_SHORT_DATE, getCurrentMonthDates, startOfLastMonth } from 'utils/date';
import moment from 'moment/moment';
import ButtonSlider from 'components/common/ButtonSlider/ButtonSlider';

const StyledImage = styled((props) => <img {...props} />)({
  position: 'absolute',
  top: '-6px',
  right: '-12px',
  maxWidth: 'fit-content',
});

const Dashboard = () => {
  const dispatch = useDispatch();
  const { tenantData, userProfile } = useSelector((state) => state.common);
  const { riskAnalysisScoreData, progressScore, tenant_connectors, loading } = useSelector(
    (state) => state.riskAnalysis
  );
  const site_name = tenantData?.site_name;

  const pieGraphValue = Math.round(progressScore?.control_response_ratio_average) || 0;
  const PIE_GRAPH_IMAGE =
    pieGraphValue <= 45 ? PIE_GRAPH_ERROR : pieGraphValue < 90 ? PIE_GRAPH_WARNING : PIE_GRAPH_SUCCESS;

  useEffect(() => {
    dispatch(setBreadcrumbs(breadcrumbsData.personnelHipaa));
  }, []);

  useEffect(() => {
    if (tenantData?.uuid) {
      dispatch(getRiskAnalysis(tenantData?.uuid));
      dispatch(
        getScore({
          connector_name: '',
          end_date: moment().format(BACKEND_SHORT_DATE),
          start_date: moment(startOfLastMonth()).format(BACKEND_SHORT_DATE),
          tenant_uuid: tenantData?.uuid,
        })
      );
    }
  }, [tenantData]);

  const tabInfo = [
    {
      id: 0,
      icon: IC_CHART_LINE_ALT1,
      title: 'Risk Analysis',
      content: (
        <Typography variant="subtitle1">{`${
          Math.round(progressScore?.control_response_ratio_average) || 0
        }%`}</Typography>
      ),
      component: (
        <RiskAnalysis
          aggregateRiskScore={Math.round(progressScore?.risk_score_average) || 0}
          riskScoreSinceLastWeek={getTrendValues(progressScore, 'risk_score_diff_since_seven_day')}
          tenant_connectors={tenant_connectors}
          loading={loading}
        />
      ),
    },
  ].concat(
    shouldBeMasked()
      ? [
          {
            id: 1,
            icon: IC_SWITCH_DOUBLE,
            title: 'Risk Management',
            content: (
              <Box sx={{ display: 'flex' }}>
                <Typography variant="subtitle1">45%</Typography>
                <Chip icon={<ReactSVG src={IC_DOWN_TREND} />} color="warning" label="-1.50%" />
              </Box>
            ),
            component: <RiskManagement />,
          },
          {
            id: 2,
            icon: FILE_DARK,
            title: 'Policies and Procedures',
            content: <Typography variant="subtitle1">75%</Typography>,
            component: <PolicyProcurement />,
          },
          {
            id: 3,
            icon: IC_USER_GROUP,
            title: 'Workforce Training',
            content: (
              <Box sx={{ display: 'flex', columnGap: '8px' }}>
                <Typography variant="subtitle1">85%</Typography>
                <Chip icon={<ReactSVG src={IC_UP_TREND} />} color="success" label="+2.00%" />
              </Box>
            ),
            component: <WorkforceTraining />,
          },
          {
            id: 4,
            title: 'Non-Technical Evaluation',
            icon: IC_FILE_CHECK,
            content: <Typography variant="subtitle1">65%</Typography>,
            component: <NonTechnicalEvaluation />,
          },
          {
            id: 5,
            icon: IC_PRESENT_CHECK,
            title: 'Technical Evaluation',
            content: <Typography variant="subtitle1">100%</Typography>,
            component: <TechnicalEvaluation />,
          },
          {
            id: 6,
            icon: IC_SHARE_ALT2,
            title: 'Vendor Management',
            content: (
              <Box sx={{ display: 'flex', columnGap: '8px' }}>
                <Typography variant="subtitle1">75%</Typography>
                <Chip icon={<ReactSVG src={IC_UP_TREND} />} color="success" label="+3.25%" />
              </Box>
            ),
            component: <VendorManagement />,
          },
        ]
      : []
  );

  let dates = getCurrentMonthDates();

  let graphData = [];
  if (riskAnalysisScoreData.length > 0) {
    dates.forEach((value) => {
      let obj = {};
      let currentObj = {};
      let lastObj = riskAnalysisScoreData.find((v) => v.date === moment(value.last).format(BACKEND_SHORT_DATE));
      if (lastObj) {
        obj = {
          lastAverage: lastObj?.average === 0 ? undefined : Math.round(Number(lastObj?.average)),
          date: value.current,
        };
      }
      if (value.current < Number(moment().format('x'))) {
        currentObj = riskAnalysisScoreData.find((v) => v.date === moment(value.current).format(BACKEND_SHORT_DATE));
        currentObj = {
          ...currentObj,
          average: currentObj?.average === 0 ? undefined : Math.round(Number(currentObj?.average)),
          date: Number(moment(currentObj.date, BACKEND_SHORT_DATE).format('x')),
        };
      }
      graphData.push({ ...obj, ...currentObj });
    });
  }

  return (
    <>
      <Typography display="inline" variant="h6" mb={0.5} color="secondary">
        Welcome back
      </Typography>
      <Typography display="inline" variant="italic20" mb={0.5} color="secondary">
        {` ${getFullName(userProfile?.first_name, userProfile?.last_name)}`}
      </Typography>
      <Typography display="inline" variant="h6" mb={0.5} ml={0.5} color="secondary">
        !
      </Typography>

      <Typography variant="h5" mb={4} sx={{ textTransform: 'capitalize' }}>
        {`${site_name || ''} dashboard`}
      </Typography>
      <Grid container spacing={2}>
        {shouldBeMasked() && (
          <Grid item xs={12} sm={4}>
            <WrapperBox padding="16px 24px" sx={{ textAlign: 'center', marginBottom: '16px' }}>
              <Typography variant="body2" mb={2} color="secondary" sx={{ display: 'flex', justifyContent: 'center' }}>
                HIPAA compliance implementation progress
                <InfoIconComponent />
              </Typography>
              {!loading ? (
                <>
                  <CircularProgressbar
                    isLabel={true}
                    valueLabel={<StyledImage height="160" width="160" src={PIE_GRAPH_IMAGE} />}
                    value={pieGraphValue}
                    size={130}
                    thickness={0.6}
                    color={getProgressColor(pieGraphValue, true)}
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} mb={0.5}>
                    <Typography variant="cabin" mr={0.5} sx={{ fontSize: '24px', lineHeight: '29px' }}>
                      {`${pieGraphValue}%`}
                    </Typography>
                    <Avatar
                      size="small"
                      bg={
                        getTrendValues(progressScore, 'control_response_ratio_diff_since_seven_day')?.color || 'success'
                      }
                    >
                      <ReactSVG
                        src={getTrendValues(progressScore, 'control_response_ratio_diff_since_seven_day')?.icon}
                      />
                    </Avatar>
                  </Box>
                  <Typography
                    variant="subtitle2"
                    color={`${
                      getTrendValues(progressScore, 'control_response_ratio_diff_since_seven_day')?.color
                    }.main`}
                    display="inline"
                    sx={{ fontWeight: 700 }}
                  >
                    {`${getTrendValues(progressScore, 'control_response_ratio_diff_since_seven_day')?.label || 0}% `}
                  </Typography>
                  <Typography variant="subtitle2" color="secondary" display="inline" sx={{ fontWeight: 400 }}>
                    since last week
                  </Typography>
                </>
              ) : (
                <Skeleton variant="rounded" width={'auto'} height={188} sx={{ mt: 1 }} />
              )}
            </WrapperBox>
            {shouldBeMasked() && (
              <WrapperBox
                padding="32px 24px"
                sx={{ background: `url(${background}), #7175E2`, backgroundRepeat: 'round' }}
              >
                <Box mb={2}>
                  <Chip
                    svgColor={false}
                    label="RiskAI Lightening"
                    sx={{ color: 'white' }}
                    icon={<ReactSVG src={IC_LIGHTNING} />}
                  />
                </Box>
                <Typography variant="subtitle2" color="white" mb={1.5}>
                  Increase your compliance score
                </Typography>
                <Typography variant="body2" color="text.whiteShade6">
                  You could increase your score by 12.5% by adding multi-factor authentication to your devices.
                </Typography>
              </WrapperBox>
            )}
          </Grid>
        )}
        <Grid item xs={12} sm={shouldBeMasked() ? 8 : 12}>
          <WrapperBox padding="24px">
            <Box p={0} sx={{ position: 'relative' }}>
              <Box>
                <Typography variant="subtitle1" mb={0.5}>
                  Risk Score Trend
                </Typography>
                <Typography variant="body2" color="secondary">
                  {`as of ${moment().format('MMM D, YYYY')}`}
                </Typography>
              </Box>
              {shouldBeMasked() && (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', position: 'absolute', right: 0, top: 0 }}>
                  <ButtonSlider name="Current Month" />
                </Box>
              )}
              {graphData.length > 0 && !loading ? (
                <TrendGraph data={graphData} height={344} />
              ) : (
                <Skeleton variant="rounded" width={'auto'} height={334} sx={{ mt: 1 }} />
              )}
            </Box>
          </WrapperBox>
        </Grid>
      </Grid>

      {/* Score Drill Down section */}
      <Box sx={{ mt: 6, mb: 2 }}>
        <Typography variant="subtitle1" mb={0.5}>
          Score drill down
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Learn more about your current score and practice.
        </Typography>
      </Box>
      <Tabs tabInfo={tabInfo} />
    </>
  );
};

export default Dashboard;

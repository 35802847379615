
import riskAnalysisServices from 'services/riskAnalysisServices';
import jsPDF from 'jspdf';
import _ from 'lodash';
import 'jspdf-autotable';
import { getDateFormat } from 'utils/date';
import moment from 'moment/moment';
import { addFooter, genrateVulnTable, renderData, StaticPages } from './PDFgenrator';
import { connectorsName, riskAnalysisConnectors } from 'assets/constants/const';

var momentTZ = require('moment-timezone');

async function fetchThreatMaster() {
    const vulnerabilities = await riskAnalysisServices.getVulnerabilityList();
    return _.get(vulnerabilities, ['data', 'payload'], {});
}

async function fetchConnecttors(connectors) {
    if (connectors.length == 0) {
        return [];
    }
    const combinedData = riskAnalysisConnectors?.map((r) => {
        const filteredData = connectors?.filter((d) => d.connector_name === r.type);
        if (filteredData) {
            return { ...filteredData[0], ...r };
        } else return r;
    });

    const customConnectorDetail = connectors
        ?.filter((d) => d.connector_type == connectorsName.CUSTOM)
        .map((cc) => ({
            ...cc,
            integrationName: cc?.tenant_connector_name,
        }));
    return [...combinedData, ...customConnectorDetail]
}

async function getConnectorRuns(tenantUUID) {
    const response = await riskAnalysisServices.getConnectorRuns(tenantUUID);
    const connector_data = _.get(response, ['data', 'payload', 'data', '0'], null);
    const totalVulnerabilities = [];
    connector_data?.risk_score?.threat_controls?.forEach((element) => {
        element.vulnerabilities?.forEach((element) => {
            totalVulnerabilities.push(element);
        });
    });
    return totalVulnerabilities;

}

const getImapctScores = (tenant_connectors)=>{
    let result=[]
    tenant_connectors.map(a=>{
        let connector= riskAnalysisConnectors.find(r=>a.connector_name == r.type);
        result.push({name:connector?connector.integrationName: a.tenant_connector_name,impact_score:a.risk_score.aggregate_impact_score});
    })
    return result;
}

export async function getriskAnalysisPDF(tenant_connectors,tenantData,score) {

    if(tenant_connectors && tenant_connectors?.length){
    const vulnerabilities = await fetchThreatMaster();
    const connectors = await fetchConnecttors(tenant_connectors);
    const imapactScoreArr = getImapctScores(tenant_connectors);
    const pdf = new jsPDF();
    await StaticPages(pdf,tenantData,score,imapactScoreArr);
    pdf.addPage();
    let startY = 20;
    pdf.setFont("helvetica", "normal");
    pdf.setFontSize(20);
    pdf.setTextColor('#6F00E7');
    pdf.text('RISK REGISTER', 10, startY);
    pdf.setTextColor('#000000');
    const autopageChange = () => {
        let currentPageHeight = pdf.internal.pageSize.height;
        if (startY + 50 > currentPageHeight) {
            pdf.addPage();
            startY = 10;
            pdf.setFont("helvetica", "normal");
        }
    }

    for (const con of connectors) {
        if (con && !con?.tenant_connector_uuid) {
            continue;
        }
        autopageChange();
        var textWidth = pdf.getStringUnitWidth(con?.integrationName) * 22 / pdf.internal.scaleFactor;
        var xPosition = (pdf.getPageWidth() - textWidth) / 2;

        pdf.setFontSize(20);
        startY += 10;
        pdf.text(con?.integrationName, xPosition, startY);
        startY += 10;

        const totalVulnerabilities = await getConnectorRuns(con?.tenant_connector_uuid);
        _.map(vulnerabilities, (vul, vulLabel) => {
            const data = renderData(vulnerabilities, totalVulnerabilities, vulLabel);
            _.map(data, (raw, key) => {
                startY += 15;

                autopageChange();
                pdf.setFontSize(16);
                pdf.text(key, 12, startY);

                _.map(raw, (raw1, key1) => {
                    startY += 10;

                    autopageChange();
                    pdf.setFontSize(12);
                    pdf.setDrawColor(0);
                    pdf.setFillColor(211, 211, 211);
                    pdf.rect(12, startY - 6, pdf.getPageWidth() - 30, 10, 'FD');
                    pdf.text(key1, 18, startY);

                    _.map(raw1, (raw2, key2) => {
                        startY += 10;

                        autopageChange();
                        pdf.setFontSize(12);
                        pdf.text('\u2022 ' + key2, 18, startY);
                        pdf.text(`Aggregate risk score: ${raw2?.aggregate_risk_score.toString()}`, 150, startY);

                        startY += 10;

                        autopageChange();
                        pdf.rect(20, startY, 50, 20);
                        pdf.rect(80, startY, 50, 20);
                        pdf.rect(140, startY, 50, 20);
                        pdf.setFontSize(10);
                        pdf.text('Aggregate likelihood score', 23, startY + 5);
                        pdf.text("Aggregate impact score", 85, startY + 5);
                        pdf.text("Aggregate risk score", 145, startY + 5);
                        pdf.text(raw2?.aggregate_likelihood_score.toString(), 45, startY + 15);
                        pdf.text(raw2?.aggregate_impact_score.toString(), 105, startY + 15);
                        pdf.text(raw2?.aggregate_risk_score.toString(), 165, startY + 15);

                        startY += 30;

                        autopageChange();
                        const { header, tableData } = genrateVulnTable(raw2?.controls);
                        pdf.autoTable({
                            head: header,
                            body: tableData,
                            startY,
                            theme: 'plain',
                            styles: {
                                textColor: [0, 0, 0],
                                lineWidth: 0.2,
                                fontSize: 8,
                                font: 'helvetica',
                                fontStyle: 'normal'
                            },
                            headStyles: {
                                fillColor: [229, 228, 226],
                            },
                        });

                        startY = _.toInteger(pdf.lastAutoTable.finalY) + 10;
                    })
                })
            })

        })
    }
    const pageCountBeforeAddingFooter = pdf.internal.getNumberOfPages();
    for (let i = 1; i <= pageCountBeforeAddingFooter; i++) {
        addFooter(pdf, i);
    }

    pdf.save(`RiskAnalysisReport - ${getDateFormat(moment())}.pdf`);
}
    return true;

}



import React, { useState } from 'react';
import PropTypes from 'prop-types';

// MUI
import { Box, Tab, Tabs as MUITabs } from '@mui/material';
import RBAC from 'components/RBAC';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      className="tab-panel"
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const Tabs = ({ tabInfo, onTabChange, defaultTab = 0 }) => {
  const [activeTab, setActiveTab] = useState(defaultTab);
  // Set Active Tab
  const tabHandler = (event, newVal) => {
    setActiveTab(newVal);
    onTabChange && onTabChange({ currentTab: newVal });
  };
  return (
    <>
      <Box mb={1}>
        <MUITabs variant="scrollable" value={activeTab} onChange={tabHandler} aria-label="basic tabs example">
          {tabInfo?.map((tab) =>
            tab.rbacAction ? (
              <RBAC
                key={`rbac-tab-header-${tab.id}`}
                action={tab.rbacAction}
                yes={
                  <Tab
                    label={tab.label}
                    {...a11yProps(tab.id)}
                    key={tab.id}
                    sx={{ opacity: activeTab == tab?.id ? 1 : '' }}
                  />
                }
              />
            ) : (
              <Tab
                label={tab.label}
                {...a11yProps(tab.id)}
                key={tab.id}
                sx={{ opacity: activeTab == tab?.id ? 1 : '' }}
              />
            )
          )}
        </MUITabs>
      </Box>
      {/* Render Component */}
      {tabInfo?.map((tab) =>
        tab.rbacAction ? (
          <RBAC
            action={tab.rbacAction}
            key={`rbac-tab-comp-${tab.id}`}
            yes={
              <TabPanel value={activeTab} index={tab.id} key={tab.id}>
                {tab.component}
              </TabPanel>
            }
          />
        ) : (
          <TabPanel value={activeTab} index={tab.id} key={tab.id}>
            {tab.component}
          </TabPanel>
        )
      )}
    </>
  );
};

Tabs.propTypes = {
  tabInfo: PropTypes.array,
  onTabChange: PropTypes.func,
};

Tabs.defaultProps = {
  tabInfo: [],
  onTabChange: () => {},
};
export default Tabs;

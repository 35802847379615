import { Box, Divider, IconButton, List, ListItem, Modal, Typography } from '@mui/material';
import { IC_CROSS, IC_SHARE_ALT3, IC_SORT } from 'assets/constants/imageConst';
import { spaceBetweenStyle } from 'assets/theme/styles';
import React from 'react';
import { ReactSVG } from 'react-svg';
import { Button, SearchInput } from '../FormControls';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  borderRadius: '8px',
  //   boxShadow: 24,
};
const titleStyle = {
  p: 2,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};
const bodyStyle = {
  p: 2,
};

export const listItem = [
  {
    name: 'AICPA SOC 2',
    description:
      ' Report on Controls at a Service Organization Relevant to Security, Availability, Processing Integrity,...',
  },
  {
    name: 'CIS/CSC',
    description:
      'CIS Controls Version 8 combines and consolidates the CIS Controls by activities, rather than by who manages...',
  },
  {
    name: 'CSA CCM',
    description:
      'Cybersecurity control framework for cloud computing. It is composed of 197 control objectives that are structured in...',
  },
  {
    name: 'CSA IoT SCF',
    description:
      'The Internet of Things (IoT) Security Controls Framework introduces the base-level security controls required to...',
  },
  {
    name: 'IEC 62443-4-2',
    description:
      'IEC 62443-4-2:2019 provides detailed technical control system component requirements (CRs) associated with the...',
  },
];

function FrameworksListModal({ open, handleClose }) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={titleStyle}>
          <Typography variant="subtitle1">Impacted frameworks list</Typography>
          <IconButton onClick={handleClose}>
            <ReactSVG src={IC_CROSS} />
          </IconButton>
        </Box>
        <Divider />
        <Box sx={bodyStyle}>
          <Box sx={spaceBetweenStyle} mb={1}>
            <Button variant="outlined" color="secondary" size="small" startIcon={<ReactSVG src={IC_SORT} />}>
              Sort by: A-Z
            </Button>
            <SearchInput />
          </Box>
          <Box>
            <List>
              {listItem.map((value, index) => (
                <React.Fragment key={value.name}>
                  {index !== 0 && <Divider />}
                  <ListItem sx={{ px: 0, py: 1.5 }} key={value.name}>
                    <Box display="inline-block" sx={{ width: '50%' }}>
                      <Typography variant="subtitle2">{value.name}</Typography>
                    </Box>

                    <Box display="inline-block" sx={{ width: '50%' }}>
                      <Box display="flex" sx={{ alignItems: 'flex-start' }}>
                        <Typography variant="body2" color="secondary">
                          {value.description}
                        </Typography>
                        <IconButton>
                          <ReactSVG src={IC_SHARE_ALT3} />
                        </IconButton>
                      </Box>
                    </Box>
                  </ListItem>
                </React.Fragment>
              ))}
            </List>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default FrameworksListModal;

import api from './api';

class ControlsAPI {
  getControls(params) {
    let queryParams = '';
    if (params) {
      queryParams = Object.keys(params)
        .map((key) => {
          return `${key}=${encodeURIComponent(params[key])}`;
        })
        .join('&');
    }
    return api.get(`controls?${queryParams}`);
  }

  getFrameworks(params) {
    let queryParams = '';
    if (params) {
      queryParams = Object.keys(params)
        .map((key) => {
          return `${key}=${encodeURIComponent(params[key])}`;
        })
        .join('&');
    }
    return api.get(`frameworks?${queryParams}`);
  }

  getControlLibraryList(tenant_uuid) {
    return api.get(`/control_library/${tenant_uuid}/controls`);
  }

  getControlLibraryFramework(uuid) {
    return api.get(`/tenant/${uuid}/frameworks`);
  }

  saveControlLibraryResponse(uuid, data) {
    return api.post(`control_library/${uuid}/controls`, data);
  }

  addUpdateControls(data) {
    return api.patch(`/controls`, data);
  }
  addControlsToVulnerability(tenant_connector_uuid, data) {
    return api.post(`/tenant_connector/${tenant_connector_uuid}/controls`, data);
  }
}

export default ControlsAPI;

import { Box, IconButton, InputBase, styled } from '@mui/material';
import { IC_ATTACH, IC_AT_SIGN, IC_SEND, USER1 } from 'assets/constants/imageConst';
import Avatar from 'components/common/Avatar/Avatar';
import React, { useState } from 'react';
import { ReactSVG } from 'react-svg';

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: '6px',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  svg: {
    path: {
      fill: `${theme.palette.common.white} !important`,
    },
  },
}));

function CommentBox({
  handleComments,
  isAttachmentIcon = true,
  isSignIcon = true,
  placeholder = 'Write your comment',
  profileImg = USER1,
  inputRows = 1,
}) {
  const [commentValue, setCommentValue] = useState('');

  const handleSubmitComment = () => {
    handleComments(commentValue);
    setCommentValue('');
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Box mr={1}>
        <Avatar size="small" src={profileImg} />
      </Box>
      <Box sx={{ background: '#4F4A550A', width: '100%', p: 0.5, borderRadius: '8px' }}>
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder={placeholder}
          inputProps={{ 'aria-label': 'search google maps' }}
          fullWidth
          multiline
          rows={inputRows}
          value={commentValue}
          onChange={(e) => setCommentValue(e.target.value)}
        />
        <Box sx={{ textAlign: 'right' }}>
          {isAttachmentIcon && (
            <IconButton size="small" sx={{ mr: 0.5 }}>
              <ReactSVG src={IC_ATTACH} />
            </IconButton>
          )}
          {isSignIcon && (
            <IconButton size="small" sx={{ mr: 1 }}>
              <ReactSVG src={IC_AT_SIGN} />
            </IconButton>
          )}
          <CustomIconButton size="small" onClick={handleSubmitComment}>
            <ReactSVG src={IC_SEND} />
          </CustomIconButton>
        </Box>
      </Box>
    </Box>
  );
}

export default CommentBox;

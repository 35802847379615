import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// MUI
import { Box, Tabs as MUITabs, styled, IconButton } from '@mui/material';
import Tab from './Tab';
import Icon from 'components/common/Icon/Icon';
import { IC_LEFT, IC_RIGHT } from 'assets/constants/imageConst';

const CustomTabs = styled(MUITabs)(() => {
  return {
    '&.MuiTabs-root': {
      '.MuiTabs-scrollButtons:nth-of-type(1)': {
        right: '30px !important',
      },
      '.MuiTabs-scrollButtons': {
        position: 'absolute',
        top: '-47px',
        right: 0,
        '&.Mui-disabled': {
          opacity: 0.5,
        },
      },
      '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
      },
      '& .MuiTabs-indicatorSpan': {
        maxWidth: 72,
        borderRadius: 20,
        width: '100%',
        backgroundColor: '#635ee7',
      },
    },
  };
});

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ mt: 3 }}>{children}</Box>}
    </div>
  );
};

const Tabs = ({ tabInfo, handleTabValue = () => {}, updateTab = 0 }) => {
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    setActiveTab(updateTab);
  }, [updateTab]);

  // Set Active Tab
  const tabHandler = (event, newVal) => {
    setActiveTab(newVal);
    handleTabValue(newVal);
  };

  return (
    <>
      <Box sx={{ mt: '20px', position: 'relative' }}>
        <CustomTabs
          variant="scrollable"
          TabIndicatorProps={{
            children: <span className="MuiTabs-indicatorSpan" />,
            style: { height: '4px' },
          }}
          ScrollButtonComponent={(props) => {
            return (
              <>
                {props.direction === 'left' ? (
                  <IconButton {...props}>
                    <Icon size="small" src={IC_LEFT} />
                  </IconButton>
                ) : (
                  <IconButton {...props}>
                    <Icon size="small" src={IC_RIGHT} />
                  </IconButton>
                )}
              </>
            );
          }}
          value={activeTab}
          onChange={tabHandler}
          aria-label="basic tabs example"
          scrollButtons="auto"
        >
          {tabInfo.map((tab) => (
            <Tab tabData={tab} {...a11yProps(tab.id)} key={tab.id} />
          ))}
        </CustomTabs>
      </Box>
      {/* Render Component */}
      {tabInfo.map((tab) => (
        <TabPanel value={activeTab} index={tab.id} key={tab.id}>
          {tab.component}
        </TabPanel>
      ))}
    </>
  );
};

Tabs.propTypes = {
  tabInfo: PropTypes.array,
  onTabChange: PropTypes.func,
};

Tabs.defaultProps = {
  tabInfo: [],
  onTabChange: () => {},
};
export default Tabs;

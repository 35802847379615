import { ReactSVG } from 'react-svg';
//MUI
import { Typography, Box, Grid } from '@mui/material';

// Internal
import { CircularProgressbar, Accordion, Tabs, Chip } from 'components/common';
import { Button } from 'components/common/FormControls';
import Title from 'components/common/Wrapper/Title';
import { FILE_DARK, IC_DOWNLOAD, IC_LIST, IC_CALENDER, IC_UP_TREND, IC_WARNING_ALT } from 'assets/constants/imageConst';
import WrapperBox from 'components/common/Wrapper/WrapperBox';
import InfoIconComponent from 'components/common/InfoIcon/InfoIconComponent';
import List from './List';
import Timeline from './Timeline';

const tabInfo = [
  {
    id: 0,
    label: <Chip label="List" icon={<ReactSVG src={IC_LIST} />} />,
    component: <List />,
  },
  {
    id: 1,
    label: <Chip label="Timeline" icon={<ReactSVG src={IC_CALENDER} />} />,
    component: <Timeline />,
  },
];

const riskData = [
  {
    id: 1,
    leftSideSummary: (
      <Box display="flex">
        <Typography variant="body2">Risk Mitigation</Typography>
        <InfoIconComponent />
      </Box>
    ),
    rightSideSummary: <Chip label="55 risks" icon={<ReactSVG src={IC_WARNING_ALT} />} color="warning" />,
    content: <Tabs tabInfo={tabInfo} />,
  },
  {
    id: 2,
    leftSideSummary: (
      <Box display="flex">
        <Typography variant="body2">Risk Transfer</Typography>
        <InfoIconComponent />
      </Box>
    ),
    rightSideSummary: <Chip label="0 risk" />,
    content: 'Risk Transfer',
  },
  {
    id: 3,
    leftSideSummary: (
      <Box display="flex">
        <Typography variant="body2">Risk Acceptance</Typography>
        <InfoIconComponent />
      </Box>
    ),
    rightSideSummary: <Chip label="7 risks" icon={<ReactSVG src={IC_WARNING_ALT} />} color="warning" />,
    content: 'Risk Acceptance',
  },
  {
    id: 4,
    leftSideSummary: (
      <Box display="flex">
        <Typography variant="body2">Risk Avoidance</Typography>
        <InfoIconComponent />
      </Box>
    ),
    rightSideSummary: <Chip label="5 risks" icon={<ReactSVG src={IC_WARNING_ALT} />} color="warning" />,
    content: 'Risk Avoidance',
  },
];

const RiskManagement = () => {
  return (
    <WrapperBox>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} mb={1}>
        <Title title="Risk Management" />
        <Box>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            startIcon={<ReactSVG src={IC_DOWNLOAD} className="download-small-icon" />}
          >
            Auditor report
          </Button>
        </Box>
      </Box>
      <Box mb={4}>
        <Chip label="45 CFR §164.308(a)(1)(ii)(A)" icon={<ReactSVG src={FILE_DARK} />} />
      </Box>
      <WrapperBox padding="16px">
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box display="flex" sx={{ alignItems: 'center' }}>
            <CircularProgressbar
              value={67}
              size={77}
              valueLabel={<Typography variant="h6">67%</Typography>}
              thickness={2}
              color="warning"
            />
            <Box ml={3}>
              <Typography variant="subtitle2" mb={1.25}>
                Overall Risk Score
              </Typography>
              <Box display="flex" sx={{ alignItems: 'center' }}>
                <Typography variant="caption" color="secondary" mr={0.5}>
                  Since last week
                </Typography>
                <Chip icon={<ReactSVG src={IC_UP_TREND} />} label="+2.00%" color="success" />
              </Box>
            </Box>
          </Box>
          <Box>
            <Button variant="outlined" color="secondary" size="small">
              View details
            </Button>
          </Box>
        </Box>
      </WrapperBox>
      <Grid container style={{ marginTop: '20px' }} spacing={2}>
        {riskData.map((data, index) => (
          <Grid key={index} item xs={12} sm={6}>
            <Accordion
              leftSideSummary={data.leftSideSummary}
              rightSideSummary={data.rightSideSummary}
              expandedPanelId={data.id}
            >
              {data.content}
            </Accordion>
          </Grid>
        ))}
      </Grid>
    </WrapperBox>
  );
};
export default RiskManagement;

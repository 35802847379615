import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';
import _ from 'lodash';

import {
  Avatar,
  Box,
  CircularProgress,
  List,
  ListItem,
  Modal,
  Toolbar,
  Drawer,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';

import { Button } from 'components/common/FormControls';
import Input from 'components/common/FormField/Input/Input';

import style from './profile.module.scss';
import { setBreadcrumbs } from 'store/actions/breadcrumbs';
import { breadcrumbsData } from 'assets/constants/const';
import { updateProfile, uploadProfile, me, changePassword } from 'store/actions/me';
import { profileValidation } from 'utils/validationSchema';
import { ACCOUNT_DELETE_ICON, ICON_DELETE, ICON_SETTING } from 'assets/constants/imageConst';
import { ReactSVG } from 'react-svg';
import DashedBox from 'components/common/Wrapper/DashedBox';
import CommonLoader from 'components/common/Loader/CommonLoader';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '8px',
  bgcolor: '#ffff',
  display: 'flex',
};

const InputField = styled(Input)(({ theme }) => ({
  '.MuiInputBase-root': {
    backgroundColor: alpha(theme.palette.text.primary, 0.04),
  },
  marginLeft: 0,
  width: '100%',
  variant: 'standard',
  '& fieldset': { border: 'none' },
  borderRadius: '8px',
  padding: '14px,8px',
}));

const formatPhoneNumber = (input) => {
  // Format the input as +1 (234) 567-8901
  const match = input.match(/^(\d{1})(\d{0,3})(\d{0,3})(\d{0,4})$/);
  if (match) {
    return `+${match[1]}${match[2] ? ` (${match[2]}` : ''}${match[3] ? `) ${match[3]}` : ''}${
      match[4] ? `-${match[4]}` : ''
    }`;
  }
  return input;
};

const validatePhoneNumber = (input) => /^\d{11}$/.test(input);

const Profile = ({ handleClose, isModalOpen }) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(1);
  const [isProfileSelected, setIsProfileSelected] = useState(false);
  const [error, setError] = useState('');

  const { userProfile, meLoading } = useSelector((state) => state.common);
  const { changePasswordLoading } = useSelector((state) => state.user);
  const { isUploadLoading } = useSelector((state) => state.connectors);

  const isLoading = isUploadLoading || meLoading;

  const initialValues = {
    first_name: userProfile?.first_name || '',
    last_name: userProfile?.last_name || '',
    phone_number: userProfile?.phone_number,
    profile_pic: userProfile?.profile_pic || undefined,
    email: userProfile?.email || '',
    password: '',
  };

  const updateProfileHandler = (values) => {
    const formData = new FormData();
    formData.append('file', values?.profile_pic);

    const isProfilePicChanged = values?.profile_pic instanceof File;

    if (!error || error == undefined) {
      let updatedData = {
        data: {
          first_name: values.first_name,
          last_name: values.last_name,
          phone_number: values.phone_number,
        },
      };

      if (isProfilePicChanged) {
        dispatch(uploadProfile(formData)).then((res) => {
          updatedData = { data: { ...updatedData?.data, profile_pic: res?.value?.data?.payload.data } };
          dispatch(updateProfile(updatedData)).then(() => {
            dispatch(me());
            toast.success('Profile updated successfully.');
          });
        });
      }

      if (!isProfilePicChanged) {
        if (isProfileSelected) {
          updatedData = { data: { ...updatedData?.data, profile_pic: null } };
        }
        dispatch(updateProfile(updatedData)).then(() => {
          dispatch(me());
          toast.success('Profile updated successfully.');
        });
      }

      handleClose();
      setIsProfileSelected(false);
    }
  };

  useEffect(() => {
    dispatch(setBreadcrumbs(breadcrumbsData.profile));
  }, []);

  const handleResetPassword = (values, setFieldValue) => {
    dispatch(changePassword({ data: { password: values.password } })).then(() => {
      toast.success('Password updated successfully. ');
      setFieldValue('password', '');
    });
  };

  const isresetBtnDisabled = (values) => {
    if (
      _.isEmpty(values.password) ||
      values.password.length < 8 ||
      !/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(values.password)
    ) {
      return true;
    }
    return false;
  };

  const loader = (
    <Box display="flex" justifyContent="center" alignItems="center" height="80%">
      <CircularProgress />
    </Box>
  );

  return isLoading ? (
    loader
  ) : (
    <Modal
      open={isModalOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Drawer
          sx={{
            width: 200,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: 200,
              boxSizing: 'border-box',
              backgroundColor: '#F2F1F7',
              padding: '16px',
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <Toolbar>
            <Typography variant="overline">ACCOUNT SETTINGS</Typography>
          </Toolbar>
          <List dense disablePadding>
            {[
              { id: 1, label: 'General settings', icon: <ReactSVG src={ICON_SETTING} /> },
              { id: 2, label: 'Account', icon: <ReactSVG src={ACCOUNT_DELETE_ICON} /> },
            ].map((item, index) => (
              <ListItem key={item.label} disablePadding>
                <ListItemButton
                  disableGutters
                  sx={{ padding: '12px', borderRadius: '8px' }}
                  selected={activeTab == item.id}
                  onClick={() => setActiveTab(item.id)}
                >
                  <ListItemIcon disablePadding sx={{ '&.MuiListItemIcon-root': { minWidth: '45px' } }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={<Typography variant="subtitle2">{item.label}</Typography>} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, width: '500px' }}>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={updateProfileHandler}
            validationSchema={profileValidation}
          >
            {({ errors, touched, values, handleChange, setFieldValue }) => {
              let profilePreview =
                values?.profile_pic instanceof File ? URL.createObjectURL(values?.profile_pic) : values?.profile_pic;

              return (
                <Form>
                  <Box style={{ padding: '40px 24px 24px 24px', height: '500px' }}>
                    <Box mb={3}>
                      <Typography variant="h6">{activeTab == 1 ? 'General settings' : 'Account'}</Typography>
                    </Box>
                    {changePasswordLoading && <CommonLoader height={'500px'} />}
                    <Box>
                      {activeTab == 1 && (
                        <>
                          <Typography mb={1} variant="subtitle2" component={Box}>
                            Your photo
                          </Typography>
                          <input
                            color="primary"
                            accept=".jpg, .png"
                            size="5000000" // 5 MB
                            type="file"
                            name="files"
                            onChange={(e) => {
                              const file = e.target.files[0];
                              setFieldValue('profile_pic', file);
                              setIsProfileSelected(false);
                            }}
                            id="icon-button-file"
                            style={{ display: 'none' }}
                          />
                          <label htmlFor="icon-button-file">
                            {values?.profile_pic && !isProfileSelected ? (
                              <div className={style.upload_profile_btn}>
                                <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '5px' }}>
                                  <Avatar
                                    alt="Avatar"
                                    name="profile_pic"
                                    src={profilePreview}
                                    imgProps
                                    sx={{ width: 40, height: 40, borderRadius: '8px' }}
                                  />
                                  <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    <Typography variant="subtitle2">me.png</Typography>
                                  </Box>
                                </Box>

                                <ReactSVG
                                  src={ICON_DELETE}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setIsProfileSelected(true);
                                  }}
                                />
                              </div>
                            ) : (
                              <DashedBox style={{ cursor: 'pointer' }}>
                                <Typography variant="body1" color="primary">
                                  Upload Profile Picture
                                </Typography>
                              </DashedBox>
                            )}
                          </label>

                          <Box sx={{ display: 'flex', columnGap: '16px' }} mt={3}>
                            <InputField
                              id="first_name"
                              className="mb-3"
                              placeholder="First name"
                              name="first_name"
                              label="First Name *"
                              value={values.first_name}
                              onChange={handleChange}
                              error={touched.first_name && Boolean(errors.first_name)}
                              helperText={touched.first_name && errors.first_name}
                            />
                            <InputField
                              id="last_name"
                              className="mb-3"
                              placeholder="Last name"
                              label="Last Name *"
                              name="last_name"
                              value={values.last_name}
                              onChange={handleChange}
                              error={touched.last_name && Boolean(errors.last_name)}
                              helperText={touched.last_name && errors.last_name}
                            />
                          </Box>
                          <Box mt={3}>
                            <InputField
                              className="mb-3"
                              placeholder="Phone"
                              label="Phone"
                              name="phone_number"
                              value={values.phone_number}
                              onChange={(e) => {
                                let input = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters

                                // Ensure that the input always starts with '+1'
                                if (!input.startsWith('1')) {
                                  input = '1' + input;
                                }
                                const formattedNumber = formatPhoneNumber(input);
                                setFieldValue('phone_number', formattedNumber);
                                setError(validatePhoneNumber(input) ? '' : 'Please enter valid number');
                              }}
                              error={error ? true : false}
                              helperText={error}
                            />
                          </Box>
                        </>
                      )}
                      {activeTab == 2 && (
                        <>
                          <Box mt={3}>
                            <InputField
                              id="email"
                              className="mb-3"
                              placeholder="Email"
                              label="Email *"
                              name="email"
                              value={values.email}
                              disabled={true}
                            />
                          </Box>
                          <Box mt={3}>
                            <InputField
                              id="password"
                              className="mb-3"
                              placeholder="Password"
                              label="Password *"
                              type="password"
                              name="password"
                              value={values.password}
                              onChange={handleChange}
                              info={"The password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one special character, and one numeric digit. "}
                            />
                          </Box>
                          <Box mt={3}>
                            <Button
                              variant="filled"
                              onClick={(e) => {
                                handleResetPassword(values, setFieldValue);
                              }}
                              disabled={isresetBtnDisabled(values)}
                            >
                              Reset Password
                            </Button>
                          </Box>
                        </>
                      )}
                    </Box>
                  </Box>
                  <Divider />
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '16px', columnGap: '10px' }}>
                    <Button type="button" variant="text" color="secondary" onClick={handleClose}>
                      Cancel
                    </Button>
                    {activeTab == 1 && (
                      <Button
                        variant="filled"
                        name="update"
                        type="submit"
                        color="primary"
                        disabled={isUploadLoading || activeTab == 2 ? true : false}
                      >
                        {isUploadLoading ? 'Saving...' : 'Save'}
                      </Button>
                    )}
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Box>
    </Modal>
  );
};

export default Profile;

import { Box, Divider, IconButton, ListItem as MUIListItem, ListItemText, Typography } from '@mui/material';
import { IC_EYE } from 'assets/constants/imageConst';
import React from 'react';
import { ReactSVG } from 'react-svg';
import Avatar from '../Avatar/Avatar';
import InfoIconComponent from '../InfoIcon/InfoIconComponent';

function ListItem({ icon, title, subtitle }) {
  return (
    <>
      <MUIListItem
        sx={{ px: 0, py: 1.25 }}
        secondaryAction={
          <IconButton edge="end" aria-label="eye">
            <Avatar size="small">
              <ReactSVG src={IC_EYE} />
            </Avatar>
          </IconButton>
        }
      >
        <Avatar sx={{ mr: 2 }} size="small">
          <ReactSVG src={icon} />
        </Avatar>
        <ListItemText
          primary={
            <Box sx={{ display: 'flex' }}>
              <Typography display="inline" variant="body2">
                {title}
              </Typography>
              <InfoIconComponent />
            </Box>
          }
          secondary={
            <Typography variant="body2" color="secondary">
              {subtitle}
            </Typography>
          }
        />
      </MUIListItem>
      <Divider />
    </>
  );
}

export default ListItem;

import React from 'react';
import { Box, Button, ButtonGroup } from '@mui/material';

const controlButtons = [
  { id: 'Yes', bgColor: 'rgba(54, 195, 149, 1)', label: 'Yes', textColor: 'white', value: 'yes' },
  {
    id: 'In Progress',
    bgColor: 'rgba(145, 158, 171, 0.16)',
    label: 'In Progress',
    textColor: 'black',
    value: 'inprogress',
  },
  { id: 'No', bgColor: 'rgba(255, 0, 127, 1)', label: 'No', textColor: 'white', value: 'no' },
];

function ControlResponseButton({ handleControlResponse, defaultValue, disabled }) {
  const handleButtonGroup = (cbId, value) => {
    handleControlResponse(value);
  };

  return (
    <ButtonGroup disableElevation size="small" sx={{ border: '1px solid rgb(189 182 182 / 50%); ' }}>
      {controlButtons.map((cb, index) => (
        <Button
          variant="text"
          key={index}
          onClick={() => handleButtonGroup(cb.id, cb.value)}
          size="small"
          disabled={disabled}
        >
          <Box
            sx={{
              backgroundColor: defaultValue === cb.value ? cb.bgColor : '',
              borderRadius: '6px',
              color: defaultValue === cb.value ? cb.textColor : 'rgba(79, 74, 85, 0.6)',
              fontSize: '12px',
              padding: '4px 8px',
              lineHeight: '15px',
              whiteSpace: 'nowrap',
            }}
          >
            {cb.label}
          </Box>
        </Button>
      ))}
    </ButtonGroup>
  );
}

export default ControlResponseButton;

import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, Typography } from '@mui/material';
import Icon from '../Icon/Icon';
import { IC_CHEVRON_LEFT, IC_CHEVRON_RIGHT } from 'assets/constants/imageConst';

const ButtonSlider = ({ name }) => {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          border: '1px solid #4F4A5599',
          //   padding: '4px 6px',
          borderRadius: '8px',
        }}
      >
        <IconButton size="small">
          <Icon src={IC_CHEVRON_LEFT} size="small" variant={'secondary'} />
        </IconButton>
        <Typography variant="progressLabel" color="secondary">
          {name}
        </Typography>
        <IconButton size="small">
          <Icon src={IC_CHEVRON_RIGHT} size="small" variant={'secondary'} />
        </IconButton>
      </Box>
    </Box>
  );
};

ButtonSlider.propTypes = {
  name: PropTypes.string,
};

export default ButtonSlider;

import React from 'react';
import PropTypes from 'prop-types';
import { Avatar as MUIAvatar } from '@mui/material';
import { styled } from '@mui/material/styles';

const sizes = {
  default: '32px',
  small: '24px',
  large: '40px',
};

const svgSizes = {
  default: '16px',
  small: '16px',
};

function Avatar({ children, size, bg, ...rest }) {
  const CustomAvatar = styled(MUIAvatar)(({ theme }) => ({
    backgroundColor: theme.palette.background[bg],
    lineHeight: 0,
    '&.MuiAvatar-root.MuiAvatar-rounded': {
      borderRadius: theme.palette.borderRadius.main,
    },
    width: sizes[size],
    height: sizes[size],
    svg: {
      width: svgSizes[size],
      height: svgSizes[size],
      fill: `${theme.palette.fill[bg]} !important`,
      path: {
        fill: `${theme.palette.fill[bg]} !important`,
        fillOpacity: 1,
      },
    },
  }));
  return <CustomAvatar {...rest}>{children}</CustomAvatar>;
}

Avatar.defaultProps = {
  size: 'default',
  bg: 'secondary',
};

Avatar.propTypes = {
  bg: PropTypes.oneOf(['primary', 'secondary', 'warning', 'success']),
  size: PropTypes.oneOf(['small', 'default', 'large']),
};

export default React.memo(Avatar);

import React, { useState } from 'react';
// Internal
import { List, ListItemButton, Box, ListItemText, Collapse, Typography, styled, Divider } from '@mui/material';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { IC_CHEVRON_UP, IC_RIGHT2 } from 'assets/constants/imageConst';
import FilterList from './FilterList';
import Icon from 'components/common/Icon/Icon';
import { shouldBeMasked } from 'utils/general';

const FilterWrapper = styled(Box)(({ theme }) => ({
  borderRadius: '8px',
  backgroundColor: theme.palette.common.white,
  boxShadow: '0px 0px 2px rgba(79, 74, 85, 0.08), 0px 2px 8px rgba(79, 74, 85, 0.12)',
  position: 'absolute',
  width: '280px',
  maxHeight: '487.5px',
  overflowY: 'auto',
  top: 35,
}));

const MultiFilter = ({
  setOpenFilter,
  frameworkFilterValue,
  responseFilterValue = '',
  handleFrameworkFilter,
  handleResponseFilter = () => {},
  handleFrameworkSearch,
  searchValue,
}) => {
  const [open, setOpen] = useState([]);

  const handleClick = (label) => {
    const index = open.indexOf(label);
    if (index > -1) {
      setOpen((pre) => pre.filter((p) => p !== label));
    } else {
      setOpen((pre) => [...pre, label]);
    }
  };

  let filterData = [
    {
      label: 'Frameworks',
      component: (
        <FilterList
          data={frameworkFilterValue?.map((f) => ({ ...f, name: f.framework_name, code: f.framework_code }))}
          isSearchField={shouldBeMasked() ? true : false}
          handleFilterChange={handleFrameworkFilter}
          handleSearch={handleFrameworkSearch}
          searchValue={searchValue}
        />
      ),
    },
  ];

  if (responseFilterValue) {
    filterData = [
      ...filterData,
      {
        label: 'Response',
        component: (
          <FilterList data={responseFilterValue} isSearchField={false} handleFilterChange={handleResponseFilter} />
        ),
      },
    ];
  }
  return (
    <ClickAwayListener onClickAway={() => setOpenFilter(false)}>
      <FilterWrapper component="nav" sx={{ zIndex: 9999 }}>
        <Box p={2} pb={1}>
          <Typography variant="cabin" color="secondary" sx={{ fontSize: '12px' }}>
            FILTERS
          </Typography>
        </Box>
        <List disablePadding sx={{ paddingBottom: '16px', px: 2 }}>
          {filterData.map((fd, index) => (
            <React.Fragment key={fd.label}>
              <ListItemButton onClick={() => handleClick(fd.label)} sx={{ px: 0, py: 0.5 }}>
                <ListItemText primary={<Typography variant="subtitle2">{fd.label}</Typography>} />
                {open.includes(fd.label) ? (
                  <Icon size="small" src={IC_CHEVRON_UP} />
                ) : (
                  <Icon size="small" src={IC_RIGHT2} variant="secondary" />
                )}
              </ListItemButton>

              <Collapse in={open.includes(fd.label)} timeout="auto" unmountOnExit>
                {fd.component}
              </Collapse>
              {index < filterData.length - 1 && <Divider sx={{ my: 1.5 }} />}
            </React.Fragment>
          ))}
        </List>
      </FilterWrapper>
    </ClickAwayListener>
  );
};
export default MultiFilter;

import * as actionTypes from '../actions/actionTypes';
import _ from 'lodash';

export const defaultState = {
  loading: false,
  notesList: [],
  error: null,
  controlLibraryLoading: false,
  controlLibraryNotesList: [],
  controlLibraryError: null,
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_NOTES_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case actionTypes.GET_NOTES_SUCCESS: {
      return {
        ...state,
        loading: false,
        notesList: _.get(action, ['payload', 'data', 'payload', 'data'], null),
      };
    }

    case actionTypes.GET_NOTES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actionTypes.CONTROL_LIBRARY_GET_NOTES_START:
      return {
        ...state,
        controlLibraryLoading: true,
        controlLibraryError: null,
      };

    case actionTypes.CONTROL_LIBRARY_GET_NOTES_SUCCESS: {
      return {
        ...state,
        controlLibraryLoading: false,
        controlLibraryNotesList: _.get(action, ['payload', 'data', 'payload', 'data'], null),
      };
    }

    case actionTypes.CONTROL_LIBRARY_GET_NOTES_ERROR:
      return {
        ...state,
        controlLibraryLoading: false,
        controlLibraryError: action.payload,
      };

    case actionTypes.ADD_NOTES_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case actionTypes.ADD_NOTES_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case actionTypes.ADD_NOTES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actionTypes.DELETE_NOTES_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case actionTypes.DELETE_NOTES_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case actionTypes.DELETE_NOTES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actionTypes.UPDATE_NOTES_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case actionTypes.UPDATE_NOTES_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case actionTypes.UPDATE_NOTES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

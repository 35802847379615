import { Badge as MUIBadge, styled } from '@mui/material';
import PropTypes from 'prop-types';

const sizes = {
  small: '12px',
  default: '14px',
  medium: '16px',
  large: '18px',
};

const CustomStyledBadge = styled(MUIBadge)(({ theme, size }) => ({
  '.MuiBadge-badge': {
    height: sizes[size],
    width: sizes[size],
    minWidth: sizes[size],
    lineHeight: '11px',
    fontSize: '10px',
    fontWeight: 400,
    '&.MuiBadge-colorSecondary': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
}));

const Badge = ({ badgeCount, children, color, size, ...rest }) => {
  return (
    <CustomStyledBadge badgeContent={badgeCount} color={color} size={size} {...rest}>
      {children}
    </CustomStyledBadge>
  );
};

Badge.defaultProps = {
  color: 'primary',
  size: 'small',
};
Badge.prototype = {
  color: PropTypes.oneOf(['primary', 'secondary', 'warning', 'success', 'error']),
  size: PropTypes.oneOf(['default', 'small', 'medium', 'large']),
};
export default Badge;

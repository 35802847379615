import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { ReactSVG } from 'react-svg';

import { Box, Stepper as CustomStepper, Step, StepLabel, Paper, Typography, Button, Modal } from '@mui/material';

import { CustomConnector } from 'components/RiskLightning/RiskLightningWorkspace/CustomConnector';
import { getInterval, getNumberFromWord, stringToObjectConvertor, objectToStringConvertor } from 'utils/general';
import { applicationDetailsSchema, impactSchema, settingsSchema, vendorDetailsSchema } from 'utils/validationSchema';
import { connectorsName } from 'assets/constants/const';
import { IC_TRUE } from 'assets/constants/imageConst';
import { addConnector, getIntegrationDetail, updateConnector } from 'store/actions/connectors';
import { uploadProfile } from 'store/actions/me';
import CommonLoader from '../Loader/CommonLoader';

const ActiveStepIcon = (step) => (
  <Button
    variant="contained"
    sx={{
      borderRadius: '50%',
      minWidth: '32px',
      height: '32px',
      width: '32px',
    }}
  >
    {step}
  </Button>
);

const completedStepIcon = () => (
  <Button
    variant="contained"
    sx={{
      borderRadius: '50%',
      minWidth: '32px',
      height: '32px',
      width: '32px',
      backgroundColor: '#36C395',
    }}
  >
    <ReactSVG src={IC_TRUE} />
  </Button>
);

const StepIcon = (step) => (
  <Button
    sx={{
      borderRadius: '50%',
      minWidth: '32px',
      height: '32px',
      width: '32px',
      backgroundColor: '#ddd9e1',
      color: '#4F4A55',
      '&:hover': {
        backgroundColor: '#ddd9e1',
      },
    }}
  >
    {step}
  </Button>
);

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '8px',
  bgcolor: '#ffff',
  display: 'flex',
  '&:focus-visible': {
    outline: 'none',
  },
};

const Stepper = ({ type, handleClose, uuid = null, refetch }) => {
  const dispatch = useDispatch();
  const stepperModal = useRef();
  const [activeStep, setActiveStep] = useState(0);
  const [isDetailLoading, setIsDetailLoading] = useState(false);
  const [formDetail, setFormDetail] = useState({});

  const [disabled, setDisabled] = useState(false);

  const { tenantData } = useSelector((state) => state.common);

  const tenantName = tenantData?.site_name;

  useEffect(() => {
    if (uuid) {
      setIsDetailLoading(true);
      dispatch(getIntegrationDetail(uuid)).then((res) => {
        const formResponse = res?.value?.data?.payload?.data;
        const formData = {
          logo: formResponse?.logo || null,
          name: formResponse?.tenant_connector_name || '',
          description: formResponse?.tenant_connector_description || '',
          impactScore: formResponse?.connection_details?.manual_impact_score || 'N/A',
          numberOfHealthRecords:
            formResponse?.connection_details?.number_of_health_records &&
            objectToStringConvertor(formResponse?.connection_details?.number_of_health_records),
          numberOfUsers:
            formResponse?.connection_details?.number_of_user &&
            objectToStringConvertor(formResponse?.connection_details?.number_of_user),
          typeOfSensitiveData: formResponse?.connection_details?.type_of_sensitive_data || '',
          systemHoistingModel: formResponse?.connection_details?.system_hosting_model || '',
          systemPriority: formResponse?.connection_details?.system_priority || '',
          accountName: formResponse?.connection_details?.account_name || '',
          systemOwner: formResponse?.connection_details?.system_owner || '',
          pollingInterval: formResponse?.polling_intrval
            ? getInterval(formResponse?.polling_intrval, formResponse?.polling_frequency)
            : 'One Week',
          customerId: formResponse?.connection_details?.customerId || '',
          adminEmail: formResponse?.connection_details?.adminEmail || '',
          folderPath: formResponse?.connection_details?.hippa_folder_path || '',
          contactName: formResponse?.connection_details?.contactName || '',
          contactEmail: formResponse?.connection_details?.contactEmail || '',
          contactPhoneNumber: formResponse?.connection_details?.contactPhoneNumber || '',
        };
        setFormDetail(formData);
        setIsDetailLoading(false);
      });
    }
  }, [uuid]);

  const handleNext = (values) => {
    if (isLastStep) {
      setDisabled(true);
      const isUploadLogo = formDetail?.logo instanceof File;
      if (isUploadLogo) {
        const formData = new FormData();
        formData.append('file', formDetail?.logo);
        dispatch(uploadProfile(formData)).then((res) => {
          handleCustomConnector({ ...formDetail, ...values, logo: res?.value?.data?.payload.data });
        });
      } else {
        handleCustomConnector({ ...formDetail, ...values, logo: '' });
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setFormDetail((prev) => ({ ...prev, ...values }));
    }
    stepperModal.current?.scrollTo(0, 0);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    stepperModal.current?.scrollTo(0, 0);
  };

  const commonField = {
    logo: formDetail?.logo || null,
    name: formDetail?.name || '',
    description: formDetail?.description || '',
  };

  const impactField = {
    impactScore: formDetail?.impactScore || 'N/A',
    numberOfHealthRecords: formDetail?.numberOfHealthRecords,
    numberOfUsers: formDetail.numberOfUsers,
    typeOfSensitiveData: formDetail?.typeOfSensitiveData || '',
    systemHoistingModel: formDetail?.systemHoistingModel || '',
    systemPriority: formDetail?.systemHoistingModel || '',
  };

  const appDetailField = {
    ...commonField,
    accountName: formDetail?.accountName || '',
    systemOwner: formDetail?.systemOwner || '',
  };

  const settingField = {
    pollingInterval: formDetail?.pollingInterval || 'One Week',
    customerId: formDetail?.customerId || '',
    adminEmail: formDetail?.adminEmail || '',
    folderPath: formDetail?.folderPath || '',
  };

  const vendorDetailField = {
    ...commonField,
    contactName: formDetail?.contactName || '',
    contactEmail: formDetail?.contactEmail || '',
    contactPhoneNumber: formDetail?.contactPhoneNumber || '',
  };

  const customConnectorField = {
    custom: {
      0: appDetailField,
      1: settingField,
      2: impactField,
    },
    vendor: {
      0: vendorDetailField,
      1: impactField,
    },
  };

  const customConnectorSchema = {
    custom: {
      0: applicationDetailsSchema,
      1: settingsSchema,
      2: impactSchema,
    },
    vendor: {
      0: vendorDetailsSchema,
      1: impactSchema,
    },
  };

  const handleCustomConnector = (values) => {
    const body = {
      data: {
        connection_detail: {
          number_of_health_records: values?.numberOfHealthRecords
            ? stringToObjectConvertor(values?.numberOfHealthRecords)
            : undefined,
          number_of_user: values?.numberOfUsers ? stringToObjectConvertor(values?.numberOfUsers) : undefined,
          system_priority: values?.systemPriority || undefined,
          system_hosting_model: values?.systemHoistingModel || undefined,
          type_of_sensitive_data: values?.typeOfSensitiveData || undefined,
          account_name: values?.accountName || undefined,
          system_owner: values?.systemOwner || undefined,
          contactName: values?.contactName || undefined,
          contactEmail: values?.contactEmail || undefined,
          contactPhoneNumber: values?.contactPhoneNumber || undefined,
          customerId: values?.customerId || undefined,
          adminEmail: values?.adminEmail || undefined,
          hippa_folder_path: values?.folderPath || undefined,
          manual_impact_score: values?.impactScore || undefined,
        },
        logo: values?.logo || undefined,
        description: values?.description || undefined,
        name: values?.name || undefined,
        tenant: tenantName,
        is_manual: true,
        type: type == connectorsName.VENDOR ? connectorsName.VENDOR : connectorsName.CUSTOM,
        frequency: values?.pollingInterval ? values?.pollingInterval?.split(' ')[1] : undefined,
        interval: values?.pollingInterval ? getNumberFromWord(values?.pollingInterval?.split(' ')[0]) : undefined,
      },
    };
    if (uuid) {
      dispatch(updateConnector({ data: { ...body?.data, uuid } })).then(() => {
        handleClose();
        refetch();
      });
    } else {
      dispatch(addConnector(body)).then(() => {
        handleClose();
        refetch();
      });
    }
    setDisabled(false);
  };

  const formikForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...customConnectorField[type][activeStep],
    },
    validationSchema: customConnectorSchema[type][activeStep],
    onSubmit: handleNext,
  });

  const { handleSubmit } = formikForm;

  const steps = {
    custom: [
      {
        step: 'STEP 1',
        label: 'Application details',
      },
      {
        step: 'STEP 2',
        label: 'Settings',
      },
      {
        step: 'STEP 3',
        label: 'Impact ratings',
        subTitle:
          'The following data will be used to assess the impact score of the system for purposes of risk analysis.',
      },
    ],
    vendor: [
      {
        step: 'STEP 1',
        label: 'Vendor details',
      },
      {
        step: 'STEP 2',
        label: 'Impact ratings',
        subTitle:
          'The following data will be used to assess the impact score of the system for purposes of risk analysis.',
      },
    ],
  };

  const isFirstStep = activeStep == 0;
  const isLastStep = activeStep == steps[type]?.length - 1;

  return (
    <Modal
      open={true}
      onClose={() => {}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper sx={modalStyle}>
        <CustomStepper
          activeStep={activeStep}
          orientation="vertical"
          sx={{
            width: '200px',
            background: '#F2F1F7',
            padding: '24px 16px',
            '& .MuiStepConnector-root': {
              flex: 0,
              '-webkit-flex': 0,
            },
            '&.MuiStepper-root': {
              borderRadius: ' 8px 0 0 8px',
            },
            '& .MuiStepConnector-line': {
              color: '#4F4A55',
              borderLeftWidth: '0.5px',
              minHeight: '48px',
              margin: '-7px 0 -7px 5px',
            },
          }}
        >
          {steps[type].map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                StepIconComponent={() =>
                  activeStep === index
                    ? ActiveStepIcon(index + 1)
                    : index < activeStep
                    ? completedStepIcon()
                    : StepIcon(index + 1)
                }
                sx={{ columnGap: '16px' }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '4px' }}>
                  <Typography variant="overline">{step.step}</Typography>
                  <Typography variant="subtitle2">{step.label}</Typography>
                </Box>
              </StepLabel>
            </Step>
          ))}
        </CustomStepper>

        <Box sx={{ width: '500px' }}>
          <Box className="step-content" sx={{ display: 'flex', flexDirection: 'column', rowGap: '24px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '8px', padding: '40px 24px 24px 24px' }}>
              <Typography variant="h6" component="div">
                {steps[type][activeStep].label}
              </Typography>
              {steps[type][activeStep]?.subTitle && (
                <Typography variant="body2" component="div">
                  {steps[type][activeStep]?.subTitle}
                </Typography>
              )}
            </Box>
            <Box
              ref={stepperModal}
              sx={{
                maxHeight: '370px',
                overflow: 'auto',
              }}
            >
              <Box className="form" sx={{ padding: '0 24px 24px 24px' }}>
                {isDetailLoading ? (
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '340px' }}>
                    <CommonLoader />
                  </Box>
                ) : (
                  <CustomConnector activeStep={activeStep} type={type} form={formikForm} uuid={uuid} />
                )}
              </Box>
            </Box>
          </Box>
          <Box
            className="step-footer"
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '16px',
              columnGap: '8px',
              alignItems: 'flex-end',
            }}
          >
            <Button sx={{ color: '#4F4A55' }} onClick={isFirstStep ? handleClose : handleBack} disabled={disabled}>
              {isFirstStep ? 'Cancel' : 'Previous'}
            </Button>

            <Button
              variant="contained"
              sx={{ borderRadius: '10px', fontWeight: 500 }}
              onClick={handleSubmit}
              disabled={disabled}
            >
              {isLastStep ? 'Finish' : 'Continue'}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
};
export default Stepper;

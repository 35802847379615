import riskAnalysisServices from 'services/riskAnalysisServices';
import * as actionTypes from './actionTypes';

export const getRiskAnalysis = (uuid) => ({
  type: actionTypes.GET_RISK_ANALYSIS,
  payload: riskAnalysisServices.getRiskAnalysis(uuid),
});
export const getConnectorRuns = (uuid) => ({
  type: actionTypes.GET_CONNECTOR_RUN,
  payload: riskAnalysisServices.getConnectorRuns(uuid),
});
export const getConnectorRunsInProgress = (uuid) => ({
  type: actionTypes.GET_CONNECTOR_RUN_INPROGRESS,
  payload: riskAnalysisServices.getConnectorRuns(uuid),
});
export const updateRiskScore = (data) => ({
  type: actionTypes.UPDATE_RISK_SCORE,
  payload: riskAnalysisServices.updateRiskScore(data),
});
export const getScore = (data) => ({
  type: actionTypes.GET_SCORE,
  payload: riskAnalysisServices.getScore(data),
});
export const getScoreResponseRatio = (data) => ({
  type: actionTypes.GET_SCORE_RESPONSE_RATIO,
  payload: riskAnalysisServices.getScoreResponseRatio(data),
});
export const getProgressScore = (uuid) => ({
  type: actionTypes.GET_PROGRESS_SCORE,
  payload: riskAnalysisServices.getProgressScore(uuid),
});

export const getVulnerabilityList = () => ({
  type: actionTypes.GET_VULNERABILITIES,
  payload: riskAnalysisServices.getVulnerabilityList(),
});

export const getConnectorNoteAndDcoument = (uuid) => ({
  type: actionTypes.GET_CONNECTOR_NOTES_DOCUMENT_COUNT,
  payload: riskAnalysisServices.getConnectorNoteAndDcoument(uuid),
});

import api from './api';

class UserAPI {


  AddUpdateFrameworks(data) {
    return api.patch('/frameworks', data);
  }
}

export default UserAPI;

import api from 'services/apis/api';
import lightningService from 'services/connectorServices';
import usersServices from 'services/usersServices';
import { Roles } from 'utils/general';
import * as actionTypes from './actionTypes';
import sessionStorage from 'services/sessionStorage';
import _ from 'lodash';


// export const me = () => ({
//   type: actionTypes.ME,
//   payload: api.me.me(),
// });

export const switchTenant = (data) => ({
  type: actionTypes.SWITCH_TENANT,
  payload: data
});

export const updateProfile = (data) => ({
  type: actionTypes.ME,
  payload: api.me.updateProfile(data),
});

export const uploadProfile = (data) => ({
  type: actionTypes.UPLOAD_CONNECTOR_CERTIFICATE,
  payload: lightningService.uploadProfilePic(data),
});

export const changePassword = (uuid, data) => ({
  type: actionTypes.CHANGE_PASSWORD,
  payload: api.me.changePassword(uuid, data)
});

export const userPermission = tenantId => ({
  type: actionTypes.USER_PERMISSION,
  payload: usersServices.getPermission(tenantId)
})



export const me = () => {
  return dispatch => {
    dispatch({ type: actionTypes.ME_START })
    api.me.me().then(res => {

      let userData = res?.data?.payload?.data;
      const storageTenantData = sessionStorage.get('SELECTED_TENANT');
      let userTenants = [];
      let tenantData = null;
      let tenantId = null;
      let permissionAPI =null;

      if (![Roles.SUPER_USER, Roles.CHANNEL_PARTNER].includes(userData?.role)) {
        userTenants = userData.user_tenant;
        tenantData = userData?.user_tenant[0];
        if (storageTenantData) {
          const findTenant = _.find(userTenants, (ten => {
            return ten.uuid == storageTenantData.uuid
          }));
          tenantData = findTenant ? storageTenantData : tenantData
        }
        tenantId = tenantData?.id;
        if(tenantId) permissionAPI = dispatch(userPermission(tenantId));
      }
      else {
        if (storageTenantData) tenantData = storageTenantData;
        permissionAPI = dispatch(userPermission());
      }
      dispatch({
        type: actionTypes.ME_SUCCESS,
        payload: {
          userTenants,
          tenantData,
          data: userData
        }
      });
      return permissionAPI;
    })
      .catch(err => {
        dispatch({
          type: actionTypes.ME_ERROR,
          payload: err
        })
      })
  }

}
import * as actionTypes from '../actions/actionTypes';

export const defaultState = {
  workspace: null,
  workspaceData: null,
  error: null,
  loading: false,
  runLoading: false,
  getWorkspace: null,
  connectors: null,
  integrationDetail: null,
  deleteIntegration: null,
  isIntegrationUpdate: false,
  isDetailLoading: false,
  uploadedId: null,
  isUploadLoading: false,
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case actionTypes.CONNECTOR_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case actionTypes.CONNECTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        integrationDetail: action.payload?.data?.payload,
        isIntegrationUpdate: action.payload?.isIntegrationUpdate || false,
      };

    case actionTypes.CONNECTOR_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // GET Connectors
    case actionTypes.CONNECTORS_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case actionTypes.CONNECTORS_SUCCESS:
      return {
        ...state,
        loading: false,
        connectors: action.payload?.data?.payload?.data,
        uploadedId: null,
        integrationDetail: null,
        deleteIntegration: null,
      };

    case actionTypes.CONNECTORS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actionTypes.GET_CONNECTOR_START:
      return {
        ...state,
        runLoading: true,
        error: null,
      };

    case actionTypes.GET_CONNECTOR_SUCCESS:
      return {
        ...state,
        runLoading: false,
        getWorkspace: action.payload?.data?.payload,
      };

    case actionTypes.GET_CONNECTOR_ERROR:
      return {
        ...state,
        runLoading: false,
        error: action.payload,
      };

    case actionTypes.GET_INTEGRATION_DETAIL_START:
      return {
        ...state,
        isDetailLoading: true,
        error: null,
      };
    case actionTypes.GET_INTEGRATION_DETAIL_SUCCESS:
      return {
        ...state,
        isDetailLoading: false,
        integrationDetail: action.payload?.data?.payload,
        uploadedId: null,
        isIntegrationUpdate: false,
      };
    case actionTypes.GET_INTEGRATION_DETAIL_ERROR:
      return {
        ...state,
        isDetailLoading: false,
        error: action.payload,
      };

    case actionTypes.UPLOAD_CONNECTOR_CERTIFICATE_START:
      return {
        ...state,
        isUploadLoading: true,
        error: null,
      };
    case actionTypes.UPLOAD_CONNECTOR_CERTIFICATE_SUCCESS:
      return {
        ...state,
        isUploadLoading: false,
        uploadedId: action.payload?.data?.payload,
      };
    case actionTypes.UPLOAD_CONNECTOR_CERTIFICATE_ERROR:
      return {
        ...state,
        isUploadLoading: false,
        error: action.payload,
      };

    case actionTypes.DELETE_INTEGRATION_DETAIL_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.DELETE_INTEGRATION_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteIntegration: action.payload?.data?.payload,
      };
    case actionTypes.DELETE_INTEGRATION_DETAIL_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actionTypes.REMOVE_INTEGRATION_DETAIL:
      return {
        ...state,
        loading: false,
        integrationDetail: null,
        deleteIntegration: null,
      };

    case actionTypes.SET_WORKSPACE_DATA: {
      return {
        ...state,
        workspaceData: action.payload,
      };
    }
    case actionTypes.DELETE_WORKSPACE_DATA: {
      return {
        ...state,
        workspaceData: null,
        workspace: null,
      };
    }
    default:
      return state;
  }
};

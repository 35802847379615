import { isNil } from 'lodash';
import Layout from 'components/Layout/Layout';
import HipaaDashboard from 'components/HipaaDashboard/Dashboard';
import RiskLightningWorkspace from 'components/RiskLightning/RiskLightningWorkspace/RiskLightningWorkspace';
// import Dashboard from 'pages/Dashboard/Dashboard';
import RiskLightning from 'pages/RiskLightning/RiskLightning';
import { useRoutes, Navigate } from 'react-router-dom';
import WorkspaceDetailPage from 'components/RiskLightning/RiskLightningWorkspace/WorkspaceDetailPage';
import EditRiskLightningWorkspace from 'components/RiskLightning/RiskLightningWorkspace/EditRiskLightningWorkspace';
import RiskAnalysis from 'pages/RiskAnalysis/RiskAnalysis';
import GoogleSystem from 'pages/RiskAnalysis/integrations/Google/GoogleSystem';
import ControlLibrary from 'pages/RiskAnalysis/ControlLibrary/ControlLibrary';
import UserManagment from 'pages/UserManagment/index';
import ComingSoonPage from 'components/Layout/pages/ComingSoonPage';
import {
  CONTROL_MANAGEMENT_CONTROLS,
  CONTROL_MANAGEMENT_FRAMEWORKS,
  DASHBOARD,
  hasPermission,
  RISK_AI_LIGHTENING,
  RISK_ANALYSIS,
  RISK_ANALYSIS_CONTROL_LIBRARY,
  RISK_ANALYSIS_INFORMATION_SYSTEMS,
  TENANT_MANAGMENT,
  THIRD_PARTIES,
  USER_MANAGMENT,
} from 'utils/permissionUtils';
import { useSelector } from 'react-redux';
import CustomConnector from 'components/RiskLightning/RiskLightningWorkspace/CustomConnector';
import CommonLoader from 'components/common/Loader/CommonLoader';
import ControlManagementPage from 'pages/ControlManagement';
import FrameworkManagementPage from 'pages/ControlManagement/Frameworks';
import TenantManagment from 'pages/TenantManagment';
// ----------------------------------------------------------------------

const Router = () => {
  const { userPermissions, userPermissionLoading, meLoading } = useSelector((state) => state.common);
  const PermissionRoute = ({ element, permission }) => {
    if (userPermissions) {
      if (isNil(userPermissions) || meLoading || userPermissionLoading) {
        return element;
      }
      return hasPermission(userPermissions, permission) ? element : <Navigate to="/dashboard" />;
    }
    return <CommonLoader height={'100vh'} />;
  };

  const routes = useRoutes([
    {
      path: '/',
      element: <Layout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard" /> },
        { path: 'dashboard', element: <HipaaDashboard /> },
        {
          path: 'compliance/',
          children: [
            { path: 'hipaa', element: <PermissionRoute element={<HipaaDashboard />} permission={DASHBOARD} /> },
          ],
        },
        {
          path: 'lightening',
          element: <PermissionRoute element={<RiskLightning />} permission={RISK_AI_LIGHTENING} />,
        },
        {
          path: 'lightening/:id',
          element: <PermissionRoute element={<RiskLightningWorkspace />} permission={RISK_AI_LIGHTENING} />,
        },
        // {
        //   path: 'lightening/custom-connector',
        //   element: <PermissionRoute element={<CustomConnector />} permission={RISK_AI_LIGHTENING} />,
        // },
        {
          path: 'lightening/edit/:id',
          element: <PermissionRoute element={<EditRiskLightningWorkspace />} permission={RISK_AI_LIGHTENING} />,
        },
        {
          path: 'lightening/detail/:id',
          element: <PermissionRoute element={<WorkspaceDetailPage />} permission={RISK_AI_LIGHTENING} />,
        },
        {
          path: 'risk-analysis',
          element: <PermissionRoute element={<RiskAnalysis />} permission={RISK_ANALYSIS_INFORMATION_SYSTEMS} />,
        },
        {
          path: 'risk-analysis/:id',
          element: <PermissionRoute element={<GoogleSystem />} permission={RISK_ANALYSIS} />,
        },
        {
          path: 'control-library',
          element: <PermissionRoute element={<ControlLibrary />} permission={RISK_ANALYSIS_CONTROL_LIBRARY} />,
        },
        { path: 'users', element: <PermissionRoute element={<UserManagment />} permission={USER_MANAGMENT} /> },
        { path: 'tenants', element: <PermissionRoute element={<TenantManagment />} permission={TENANT_MANAGMENT} /> },
        {
          path: 'control-management',
          element: <PermissionRoute element={<ControlManagementPage />} permission={CONTROL_MANAGEMENT_CONTROLS} />,
        },
        {
          path: 'framework-management',
          element: <PermissionRoute element={<FrameworkManagementPage />} permission={CONTROL_MANAGEMENT_FRAMEWORKS} />,
        },
        { path: '*', element: <PermissionRoute element={<ComingSoonPage />} permission={THIRD_PARTIES} /> },
      ],
    },
  ]);

  return routes;
};
export default Router;

import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

const PRIMARY = {
  lighter: alpha('#6F00E7', 0.08),
  light: alpha('#6F00E7', 0.12),
  main: '#6F00E7',
  dark: '#5900B8',
  darker: '#061B64',
  contrastText: '#fff',
};

const SECONDARY = {
  lighter: alpha('#4F4A55', 0.08),
  light: alpha('#4F4A55', 0.12),
  main: 'rgba(79, 74, 85, 0.6)', //'#3366FF'
  dark: '#4F4A55',
  darker: '#091A7A',
  contrastText: '#fff',
};

const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: '#fff',
};

const SUCCESS = {
  lighter: alpha('#36C395', 0.08),
  light: alpha('#36C395', 0.12),
  main: '#36C395',
  dark: '#229A16',
  darker: '#08660D',
  contrastText: GREY[800],
};

const WARNING = {
  lighter: alpha('#FE9800', 0.08),
  light: alpha('#FE9800', 0.12),
  main: '#FE9800',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: GREY[800],
};

const ERROR = {
  lighter: alpha('#FF007F', 0.08),
  light: alpha('#FF007F', 0.12),
  main: '#FF007F',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: '#fff',
};

const palette = {
  common: { black: '#000', white: '#fff' },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  divider: alpha(GREY[500], 0.24),
  text: {
    primary: '#4F4A55',
    secondary: alpha('#4F4A55', 0.6),
    disabled: alpha('#4F4A55', 0.3),
    whiteShade6: alpha('#fff', 0.6),
    link: PRIMARY.main,
  },
  background: {
    paper: '#fff',
    primary: PRIMARY.lighter,
    primaryDark: PRIMARY.dark,
    secondary: alpha('#4F4A55', 0.04),
    success: alpha(SUCCESS.main, 0.12),
    default: 'rgb(241, 239, 247)',
    neutral: GREY[200],
    whiteShade5: alpha('#fff', 0.5),
    whiteShade12: alpha('#fff', 0.12),
    secondary6: alpha('#4F4A55', 0.6),
    primaryLinear: 'linear-gradient(105.59deg, #3F31FA -0.01%, #6F00E7 80.45%)',
    linear: 'linear-gradient(0deg, rgba(111, 0, 231, 0.12), rgba(111, 0, 231, 0.12)), #FFFFFF',
    linear2: 'linear-gradient(0deg, rgba(111, 0, 231, 0.3), rgba(111, 0, 231, 0.3)), #FFFFFF',
    linearWarning: 'linear-gradient(0deg, rgba(254, 152, 0, 0.08), rgba(254, 152, 0, 0.08)), #FFFFFF',
    linearSuccess: 'linear-gradient(0deg, rgba(54, 195, 149, 0.08), rgba(54, 195, 149, 0.08)), #FFFFFF',
    warning: WARNING.lighter,
  },
  fill: {
    primary: PRIMARY.main,
    secondary: '#4F4A55',
  },
  action: {
    active: GREY[600],
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
  border: {
    dashedBorder: '0.5px dashed rgba(79, 74, 85, 0.23)',
    linearBorder: '0.5px solid rgba(79, 74, 85, 0.23)',
    solidBorder: '0.25px solid rgba(79, 74, 85, 0.23)',
    solidWarning: '1px solid rgba(254, 152, 0, 0.12)',
    solidSuccess: ' 1px solid rgba(54, 195, 149, 0.12)',
  },
  borderRadius: {
    main: '8px',
  },
};

export default palette;

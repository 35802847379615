import ThemeProvider from './assets/theme';
import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import Router from './routes';
import { Box, CircularProgress, Typography } from '@mui/material';

import api from './services/apis/api';
import { me } from 'store/actions/me';

const App = () => {
  api.auth0_Instance = useAuth0();
  const dispatch = useDispatch();
  let interval = 0;
  const { loginWithRedirect, isLoading, isAuthenticated } = useAuth0();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
    // Me API Call On Page Load and After Login
    if (!isLoading && isAuthenticated) {
      dispatch(me());
      // After Every 1 hour
      interval = setInterval(() => {
        dispatch(me());
      }, 1 * 60 * 60 * 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isLoading, isAuthenticated]);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          flexDirection: 'column',
        }}
      >
        <Box>
          <CircularProgress />
        </Box>
        <Box mt={3}>
          <Typography variant="body2" color="text.secondary">
            Please wait...
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <div>
      {isAuthenticated && (
        <ThemeProvider>
          <Router />
        </ThemeProvider>
      )}
    </div>
  );
};
export default App;

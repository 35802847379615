//MUi
import { List, ListItemText, ListItem, Grid, Badge } from '@mui/material';
import WrapperBox from 'components/common/Wrapper/WrapperBox';

const reportData1 = [
  { key: 'Source', value: 'Asset Groups' },
  { key: 'Operating System', value: 'All Operating System' },
  { key: 'Vulnerability Type', value: 'Confirmed' },
  { key: 'Business Risk Goal', value: '25% vulnerable hosts (less than or equal to)' },
];

const reportData2 = [
  {
    key: 'Business Risk Goal',
    value: 'On',
  },
  { key: 'Vulnerability Type', value: 'on' },
  { key: 'Vulnerability Status', value: 'on' },
  { key: 'Vulnerability Status', value: 'on' },
];

const ReportSettings = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <WrapperBox padding="4px 16px">
          <List component="nav" disablePadding>
            {reportData1?.map((rd, index) => (
              <>
                <ListItem
                  key={`${rd}-${index}`}
                  disableGutters
                  divider={reportData1.length !== index + 1}
                  sx={{ padding: '12px 0' }}
                >
                  <ListItemText
                    sx={{ width: '30%' }}
                    primary={rd.key}
                    primaryTypographyProps={{
                      variant: 'body2',
                      color: 'secondary',
                    }}
                  />
                  <ListItemText
                    sx={{ width: '70%' }}
                    primary={rd.value}
                    primaryTypographyProps={{
                      variant: 'body2',
                    }}
                  />
                </ListItem>
              </>
            ))}
          </List>
        </WrapperBox>
      </Grid>
      <Grid item xs={12} sm={6}>
        <WrapperBox padding="4px 16px">
          <List component="nav" disablePadding>
            {reportData2?.map((rd, index) => (
              <>
                <ListItem
                  key={`${rd}-${index}`}
                  disableGutters
                  divider={reportData2.length !== index + 1}
                  sx={{ padding: '12px 0' }}
                >
                  <ListItemText
                    sx={{ width: '40%' }}
                    primary={rd.key}
                    primaryTypographyProps={{
                      variant: 'body2',
                      color: 'secondary',
                    }}
                  />
                  <ListItemText
                    sx={{ width: '70%' }}
                    primary={
                      <>
                        <Badge color={'success'} sx={{ top: '-2px', left: '-8px' }} variant="dot"></Badge>
                        On
                      </>
                    }
                    primaryTypographyProps={{
                      variant: 'body2',
                    }}
                  />
                </ListItem>
              </>
            ))}
          </List>
        </WrapperBox>
      </Grid>
    </Grid>
  );
};
export default ReportSettings;

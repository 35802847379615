import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { FILE_DARK, IC_CHAT_ALT5 } from 'assets/constants/imageConst';
import { Avatar, SwipeableDrawer, Chip, Tabs } from 'components/common';
import Badge from 'components/common/Badge/Badge';
import { Tooltip } from 'components/common/FormControls';
import Icon from 'components/common/Icon/Icon';
import InfoIconComponent from 'components/common/InfoIcon/InfoIconComponent';
import ControlResponseButton from '../integrations/Google/ControlResponseButton';
import GroupChip from 'components/common/GroupChip/GroupChip';
import { NO_RECORD } from 'assets/constants/const';
import { shouldBeMasked } from 'utils/general';
import RBAC from 'components/RBAC';
import {
  RISK_ANALYSIS_CONTROL_LIBRARY_RESPONSE,
  RISK_ANALYSIS_INFORMATION_SYSTEMS_CONNECTOR_EDIT_DOCUMENT,
  RISK_ANALYSIS_INFORMATION_SYSTEMS_CONNECTOR_EDIT_NOTES,
} from 'utils/permissionUtils';
import NotesList from '../integrations/NotesList';
import {
  addControlLibraryNotes,
  getControlLibraryNotesList,
  deleteDocument,
  downloadDocument,
  addControlLibraryDocument,
  getControlLibraryDocumentList,
} from 'store/actions/connectors';
import DocumentList from '../integrations/DocumentList';
import CommonLoader from 'components/common/Loader/CommonLoader';
import { getTenantNoteAndDocument } from 'store/actions/tenant';
import { TabsWrapper } from 'assets/theme/styles';

const tableHeader = [
  { field: 'id', title: 'ID' },
  { field: 'control', title: 'Control' },
  { field: 'description', title: 'Common question' },
  { field: 'mappings', title: 'Mappings' },
  { field: 'response', title: 'Response' },
].concat(shouldBeMasked() ? [{ field: 'action', title: ' ' }] : []);

const WrappedText = styled(Box)({ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', width: '170px' });

function ControlTable({ tableData, handleControlResponse }) {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [currentActiveTab, setCurrentActiveTab] = useState(0);
  const [isDrawerOpen, setIsDraweropen] = useState(false);

  const [selectedControl, setSelectedControl] = useState({});

  const { tenantData } = useSelector((state) => state.common);
  const { tenantFramework, responseSaveLoading } = useSelector((state) => state.controlsLibrary);
  const { tenantNotesDocumentsCountLoading, tenantNotesDocumentsCount } = useSelector((state) => state.tenant);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const getCounts = () => {
    dispatch(getTenantNoteAndDocument(tenantData?.uuid));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Document
  const controlDocumentHandler = (row) => {
    setSelectedControl(row);
    setIsDraweropen(true);
    setCurrentActiveTab(1);
  };

  const getUpdatedDocumentList = () => {
    dispatch(
      getControlLibraryDocumentList({
        tenant_uuid: tenantData?.uuid,
        control_code: selectedControl.code,
      })
    );
  };

  const updateDocumentDetail = (data) => {
    const body = {
      data: {
        document_names: [
          {
            description: '',
            document_name: data?.docName,
            file_name: data?.pic,
          },
        ],
      },
    };
    return dispatch(addControlLibraryDocument(tenantData?.uuid, selectedControl?.code, body)).then((res) => {
      getUpdatedDocumentList();
      return res;
    });
  };

  // Notes
  const getNotes = () => {
    dispatch(getControlLibraryNotesList(tenantData?.uuid, selectedControl?.code));
  };

  const controlNotesHandler = (row) => {
    setSelectedControl(row);
    setIsDraweropen(true);
    setCurrentActiveTab(0);
  };

  const AddNotesHandler = (data) => {
    if (data) {
      dispatch(addControlLibraryNotes(tenantData?.uuid, selectedControl?.code, { data: { note: data } })).then(() => {
        getNotes();
      });
    }
  };

  const tabInfo = [
    {
      id: 0,
      label: 'Notes',
      component: (
        <RBAC
          action={RISK_ANALYSIS_INFORMATION_SYSTEMS_CONNECTOR_EDIT_NOTES}
          yes={<NotesList AddNotesHandler={AddNotesHandler} refetchData={getNotes} type={'CONTROL_LIBRARY'} />}
          no={
            <NotesList
              AddNotesHandler={AddNotesHandler}
              refetchData={getNotes}
              type={'CONTROL_LIBRARY'}
              viewOnly={true}
            />
          }
        />
      ),
    },
    {
      id: 1,
      label: 'Attachments',
      component: (
        <RBAC
          action={RISK_ANALYSIS_INFORMATION_SYSTEMS_CONNECTOR_EDIT_DOCUMENT}
          yes={
            <DocumentList
              selectedControl={selectedControl}
              updateDocumentDetail={updateDocumentDetail}
              deleteDocument={deleteDocument}
              downloadDocument={downloadDocument}
              refetchData={getUpdatedDocumentList}
              type={'CONTROL_LIBRARY'}
            />
          }
          no={
            <DocumentList
              selectedControl={selectedControl}
              updateDocumentDetail={updateDocumentDetail}
              deleteDocument={deleteDocument}
              downloadDocument={downloadDocument}
              refetchData={getUpdatedDocumentList}
              type={'CONTROL_LIBRARY'}
              viewOnly={true}
            />
          }
        />
      ),
    },
  ];

  return (
    <>
      <TableContainer sx={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {tableHeader?.map((th) => (
                <TableCell align="left" key={th.title}>
                  <Typography variant="caption" color="secondary">
                    {th.title}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {tenantNotesDocumentsCountLoading || responseSaveLoading ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={6} sx={{ textAlign: 'center', py: 4 }}>
                  <CommonLoader height={'100vh'} />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {tableData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => {
                const mappedFrameworks = tenantFramework?.filter((tf) => row.frameworks.includes(tf.framework_id));
                const count =
                  tenantNotesDocumentsCount &&
                  tenantNotesDocumentsCount?.find((a) => {
                    return a.code === row.code;
                  });
                const documentCount = count && count.document != 0 ? count.document : false;
                const noteCount = count && count.note != 0 ? count.note : false;
                return (
                  <TableRow key={index}>
                    <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
                      <Typography variant="body2">{row?.code}</Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
                      <Box sx={{ display: 'flex' }}>
                        {row.name}
                        <Tooltip title={row.description}>
                          <InfoIconComponent />
                        </Tooltip>
                      </Box>
                    </TableCell>

                    <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
                      <WrappedText title={row.question}>{row?.question}</WrappedText>
                    </TableCell>

                    <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
                      <GroupChip data={mappedFrameworks?.map((f) => ({ ...f, name: f.framework_name }))} />
                    </TableCell>

                    <TableCell align="left" sx={{ whiteSpace: 'nowrap', padding: 2 }}>
                      <RBAC
                        action={RISK_ANALYSIS_CONTROL_LIBRARY_RESPONSE}
                        yes={
                          <ControlResponseButton
                            handleControlResponse={(value) => handleControlResponse(row, value)}
                            defaultValue={row?.response}
                          />
                        }
                        no={
                          <ControlResponseButton
                            handleControlResponse={(value) => handleControlResponse(row, value)}
                            defaultValue={row?.response}
                            disabled={true}
                          />
                        }
                      />
                    </TableCell>

                    <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
                      <Box sx={{ display: 'flex', columnGap: '8px', alignItems: 'center' }}>
                        <RBAC
                          action={RISK_ANALYSIS_INFORMATION_SYSTEMS_CONNECTOR_EDIT_NOTES}
                          yes={
                            <Badge badgeCount={noteCount} color="secondary" invisible={!noteCount}>
                              <IconButton sx={{ padding: 0 }} onClick={() => controlNotesHandler(row)}>
                                <Avatar variant="rounded" size="small">
                                  <Icon src={IC_CHAT_ALT5} size="small" />
                                </Avatar>
                              </IconButton>
                            </Badge>
                          }
                          no={
                            <Badge badgeCount={noteCount} color="secondary" invisible={!noteCount}>
                              <IconButton sx={{ padding: 0 }} onClick={() => controlNotesHandler(row)}>
                                <Avatar variant="rounded" size="small">
                                  <Icon src={IC_CHAT_ALT5} size="small" />
                                </Avatar>
                              </IconButton>
                            </Badge>
                          }
                        />
                        <RBAC
                          action={RISK_ANALYSIS_INFORMATION_SYSTEMS_CONNECTOR_EDIT_DOCUMENT}
                          yes={
                            <IconButton sx={{ padding: 0 }} onClick={() => controlDocumentHandler(row)}>
                              <Badge badgeCount={documentCount} color="secondary" invisible={!documentCount}>
                                <Avatar variant="rounded" size="small">
                                  <Icon src={FILE_DARK} size="small" />
                                </Avatar>
                              </Badge>
                            </IconButton>
                          }
                          no={
                            <IconButton sx={{ padding: 0 }} onClick={() => controlDocumentHandler(row)}>
                              <Badge badgeCount={documentCount} color="secondary" invisible={!documentCount}>
                                <Avatar variant="rounded" size="small">
                                  <Icon src={FILE_DARK} size="small" />
                                </Avatar>
                              </Badge>
                            </IconButton>
                          }
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
              {tableData?.length < 1 && (
                <TableRow>
                  <TableCell colSpan={6} sx={{ textAlign: 'center', py: 4 }}>
                    {NO_RECORD}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={tableData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      {isDrawerOpen && (
        <SwipeableDrawer
          anchor="right"
          open={isDrawerOpen}
          onclose={() => {
            getCounts();
            setIsDraweropen(false);
          }}
          onopen={() => {}}
          isFooter={false}
          title={<Chip label={selectedControl?.code} />}
          subTitleComponent={
            <Typography variant="subtitle1" display="inline-flex">
              {selectedControl?.name}
              <InfoIconComponent />
            </Typography>
          }
          onSave={() => {}}
          isHeaderDivider={false}
        >
          <TabsWrapper>
            <Tabs
              tabInfo={tabInfo}
              defaultTab={currentActiveTab}
              onTabChange={({ currentTab }) => setCurrentActiveTab(currentTab)}
            />
          </TabsWrapper>
        </SwipeableDrawer>
      )}
    </>
  );
}

export default ControlTable;

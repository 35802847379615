import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Typography, Breadcrumbs, Link } from '@mui/material';
// components
//
import AccountPopover from './AccountPopover';
import { IC_ARROW_RIGHT, IC_LEFT, IC_MENU_ALT, IC_NOTIFICATION, IC_SEARCH } from 'assets/constants/imageConst';
import { useNavigate } from 'react-router-dom';
import style from './Header.module.scss';
import Icon from 'components/common/Icon/Icon';
import { useSelector } from 'react-redux';
import { getFullName, shouldBeMasked } from 'utils/general';
// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 64;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backgroundColor: 'transparent',
  color: theme.palette.text.primary,
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const Header = ({ onOpenNav, breadcrumbs }) => {
  const { userProfile } = useSelector((state) => state.common);

  const navigate = useNavigate();
  const handleClick = () => {
    navigate(-1);
  };

  const breadcrumbsList = () => {
    switch (breadcrumbs?.length) {
      case 1: {
        const { label } = breadcrumbs[0];
        return [
          <Typography variant="body2" key="2" color="text.primary">
            {label}
          </Typography>,
        ];
      }
      case 2: {
        const { path, label } = breadcrumbs[0];
        return [
          <Link underline="none" key="1" color="inherit" onClick={() => navigate(path)} sx={{ cursor: 'pointer' }}>
            <Typography key="2" color="text.disabled" variant="body2">
              {label}
            </Typography>
          </Link>,
          <Typography key="2" color="text.primary" variant="body2">
            {breadcrumbs[1]?.label}
          </Typography>,
        ];
      }
      default: {
        return [];
      }
    }
  };

  return (
    <StyledRoot>
      <StyledToolbar>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: 'text.primary',
            display: { lg: 'none' },
          }}
        >
          <ReactSVG src={IC_MENU_ALT} />
        </IconButton>
        <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
          <IconButton sx={{ ml: '-24px', mr: '10px', pb: '5px' }} color="inherit" onClick={handleClick} size="small">
            <Icon size="small" src={IC_LEFT} className={style.backArrow} />
          </IconButton>
          {/* search */}
          <Breadcrumbs separator={<Icon size="small" src={IC_ARROW_RIGHT} />} aria-label="breadcrumb">
            {breadcrumbsList()}
          </Breadcrumbs>
        </Box>

        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1 }} />
        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          {/* <NotificationsPopover /> */}
          {shouldBeMasked() && (
            <Box sx={{ pr: 2 }}>
              <IconButton>
                <Icon src={IC_SEARCH} size="small" />
              </IconButton>
              <IconButton>
                <Icon src={IC_NOTIFICATION} size="small" />
              </IconButton>
            </Box>
          )}
          <Typography variant="body2" sx={{ display: { xs: 'none', md: 'block' } }}>
            {getFullName(userProfile?.first_name, userProfile?.last_name)}
          </Typography>
          <AccountPopover profile_pic={userProfile?.profile_pic} />
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
};
Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default Header;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import _ from 'lodash';
// MUI
import { Box, Typography } from '@mui/material';

// Internal
import { Button } from 'components/common/FormControls';
import { IC_PLUS } from 'assets/constants/imageConst';

import ControlManagement from './ControlManagement';
import { breadcrumbsData } from 'assets/constants/const';
import { setBreadcrumbs } from 'store/actions/breadcrumbs';
import { getControls, getFrameworks } from 'store/actions/controls';
import ControlDrawer from './ControlDrawer';
import { SwipeableDrawer } from 'components/common';
import FrameworksListModal from 'components/common/Modal/FrameworksListModal';
import { shouldBeMasked } from 'utils/general';

const ControlManagementPage = () => {
  const dispatch = useDispatch();

  const [dataPerPage, setDataPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addModalData, setAddModalData] = useState(null);

  const [isAscending, setIsAscending] = useState(true);
  const [sortedField, setSortedField] = useState('code');

  const { controlList } = useSelector((state) => state.controls);

  let defaultParams = {
    page: currentPage,
    per_page: dataPerPage,
    order_by: isAscending ? 'asc' : 'desc',
    field: sortedField,
  };

  useEffect(() => {
    dispatch(setBreadcrumbs(breadcrumbsData.controlManagement));
    dispatch(getFrameworks({ per_page: -1 }));
  }, []);

  useEffect(() => {
    dispatch(getControls({ ...defaultParams, order_by: isAscending ? 'asc' : 'desc', field: sortedField, page: 1 }));
  }, [isAscending, sortedField]);

  const getControlList = (params) => {
    dispatch(getControls({ ...defaultParams, ...params }));
  };

  const handleAddModal = (value, obj) => {
    setIsOpen(value);
    setAddModalData(obj);
  };

  const handleChangePage = (event, newPage) => {
    getControlList({ page: newPage + 1, per_page: controlList?.controls?.length });
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setDataPerPage(event.target.value);
    getControlList({ page: 0, per_page: parseInt(event.target.value) });
  };

  return (
    <>
      <Box sx={{ position: 'relative' }}>
        <Box>
          <Typography variant="h5">Controls</Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} mb={3} mt={3}>
          <Button startIcon={<ReactSVG src={IC_PLUS} />} variant="filled" onClick={() => handleAddModal(true)}>
            Add Control
          </Button>
        </Box>
        <ControlManagement
          dataPerPage={dataPerPage}
          handleAddModal={handleAddModal}
          isAscending={isAscending}
          setIsAscending={setIsAscending}
          setSortedField={setSortedField}
          sortedField={sortedField}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Box>
      <SwipeableDrawer
        anchor="right"
        open={isOpen}
        onclose={() => setIsOpen(false)}
        onopen={() => {}}
        isFooter={true}
        title={_.isNil(addModalData) ? 'Add Control' : 'Update Control'}
        onSave={() => {}}
        cancelText="Close"
      >
        <Box sx={{ padding: '16px' }}>
          <ControlDrawer
            handleAddModal={handleAddModal}
            controlDetail={addModalData}
            setCurrentPage={setCurrentPage}
            defaultParams={defaultParams}
          />
        </Box>
      </SwipeableDrawer>
      {isModalOpen && <FrameworksListModal open={isModalOpen} handleClose={() => setIsModalOpen(false)} />}
    </>
  );
};
export default ControlManagementPage;

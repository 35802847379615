var momentTZ = require('moment-timezone');

export const BACKEND_DATE_FORMAT = 'DD/MM/YYYY HH:mm:ss';
export const FE_DATE_FORMAT = 'MMM D, YYYY, hh:mm A';
export const BACKEND_DATE_FORMAT2 = 'YYYY-MM-DD HH:mm:ss';
export const BACKEND_SHORT_DATE = 'YYYY-MM-DD';

export const getDateFormat = (date) => {
  return momentTZ(date).format(FE_DATE_FORMAT);
};

export const getFormatDate2 = (date) => {
  if (date) {
    return momentTZ.tz(date, BACKEND_DATE_FORMAT2, 'utc').tz(momentTZ.tz.guess()).format(FE_DATE_FORMAT);
  } else return '';
};

export const getFormatDate = (date) => {
  if (date) {
    return momentTZ.tz(date, BACKEND_DATE_FORMAT, 'utc').tz(momentTZ.tz.guess()).format(FE_DATE_FORMAT);
  } else return '';
};

export const minuteDiff = (date1, date2) => {
  var startTime = momentTZ(date1, BACKEND_DATE_FORMAT);
  var endTime = momentTZ(date2, BACKEND_DATE_FORMAT);
  var minutesDiff = endTime.diff(startTime, 'minutes');
  if (minutesDiff === 0) {
    return 'Less than a minute';
  } else if (minutesDiff === 1) {
    return 'In a minute';
  } else {
    return `${minutesDiff} minutes`;
  }
};

export function startOfMonth() {
  let date = new Date();
  return new Date(date.getFullYear(), date.getMonth(), 1);
}

export function startOfLastMonth() {
  let date = new Date();
  return new Date(date.getFullYear(), date.getMonth() - 1, 1);
}

export function getCurrentMonthDates() {
  let year = new Date().getFullYear();
  let month = new Date().getMonth();
  var date = new Date(year, month, 1);
  var lastDate = new Date(year, month - 1, 1);
  var days = [];
  while (date.getMonth() === month) {
    days.push({ current: new Date(date).getTime(), last: new Date(lastDate).getTime() });
    date.setDate(date.getDate() + 1);
    lastDate.setDate(lastDate.getDate() + 1);
  }
  return days;
}

import React from 'react';
import { AvatarGroup, Box, Divider, Grid, Typography } from '@mui/material';
import WrapperBox from 'components/common/Wrapper/WrapperBox';
import { Button } from 'components/common/FormControls';
import { ReactSVG } from 'react-svg';
import {
  FILE_DARK,
  IC_DOWNLOAD,
  IC_LOCK_CHECK,
  IC_HAND_HEADER,
  VINIT,
  USER2,
  DANIEL,
  ALEX,
} from 'assets/constants/imageConst';
import Title from 'components/common/Wrapper/Title';
import { Avatar, Chip, LinearProgressbar } from 'components/common';

const Card = ({ icon, title, subtitle, progress }) => {
  return (
    <WrapperBox padding="16px">
      <Box mb={2}>
        <Avatar sx={{ mb: 2 }}>
          <ReactSVG src={icon} />
        </Avatar>
        <Typography variant="body2" mb={1}>
          {title}
        </Typography>
        <Typography variant="body2" color="secondary">
          {subtitle}
        </Typography>
      </Box>
      <Divider />
      <Box mt={2}>
        <LinearProgressbar
          value={progress}
          label={
            <Typography variant="body2" ml={1}>
              {progress}%
            </Typography>
          }
          color="primary"
        />

        <AvatarGroup
          total={28}
          sx={{
            justifyContent: 'flex-end',
            mt: 1,
            '.MuiAvatarGroup-avatar': {
              height: '32px',
              width: '32px',
              fontSize: '12px',
              color: '#4F4A5599',
              background: '#F2F1F7',
              fontWeight: 500,
            },
          }}
        >
          <Avatar alt="Remy Sharp" src={VINIT} />
          <Avatar alt="Travis Howard" src={USER2} />
          <Avatar alt="Agnes Walker" src={DANIEL} />
          <Avatar alt="Trevor Henderson" src={ALEX} />
        </AvatarGroup>
      </Box>
    </WrapperBox>
  );
};

export default function WorkforceTraining() {
  return (
    <WrapperBox>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} mb={1}>
        <Title title="Workforce Training" />
        <Box>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            startIcon={<ReactSVG src={IC_DOWNLOAD} className="download-small-icon" />}
          >
            Auditor report
          </Button>
        </Box>
      </Box>
      <Box mb={5}>
        <Chip label="45 CFR §164.308(a)(1)(ii)(A)" icon={<ReactSVG src={FILE_DARK} />} />
      </Box>
      <Box>
        <Box mb={1}>
          <Typography variant="overline" color="secondary">
            Training modules
          </Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Card
              title="General security training"
              subtitle="Ensure your teams know about common phishing practices and best security practices. "
              progress={74}
              icon={IC_LOCK_CHECK}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card
              title="Compliance and Ethics"
              subtitle="Ensure your teams are aware of your company’s ethical principles."
              progress={72}
              icon={IC_HAND_HEADER}
            />
          </Grid>
        </Grid>
      </Box>
    </WrapperBox>
  );
}

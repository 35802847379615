import React from 'react';

import { Box, Typography } from '@mui/material';
import { dummyOptions } from 'assets/constants/options';
import Input from 'components/common/FormField/Input/Input';
import Dropdown from 'components/common/FormField/Select/Dropdown';

const GDriveForm = ({ formik }) => {
  return (
    <>
      <Box mb={2}>
        <Typography variant="subtitle2">Polling Interval</Typography>
        <Typography variant="body2" mb={1} color="text.secondary">
          The interval at which this integration will run
        </Typography>
        <Dropdown options={dummyOptions} id="id" name="pollingInterval" formik={formik} />
      </Box>
      <Box mb={3}>
        <Input
          id="adminEmail"
          className="mb-3"
          fullWidth
          disabled
          placeholder="ex. john@mycompany.com"
          label="Admin email"
          name="adminEmail"
          value={formik.values.adminEmail}
          onChange={formik.handleChange}
          // error={formik.touched.adminEmail && Boolean(formik.errors.adminEmail)}
          // helperText={formik.touched.adminEmail && formik.errors.adminEmail}
        />
      </Box>
      <Box mb={3}>
        <Input
          id="folderPath"
          className="mb-3"
          fullWidth
          placeholder="Google Drive Folder URL"
          label="Root Folder URL containing sensitive data"
          name="folderPath"
          value={formik.values.folderPath}
          onChange={formik.handleChange}
          error={formik.touched.folderPath && Boolean(formik.errors.folderPath)}
          helperText={formik.touched.folderPath && formik.errors.folderPath}
        />
      </Box>
    </>
  );
};

export default GDriveForm;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import _, { isNil } from 'lodash';

import { Box, Typography, styled, TextField, IconButton,Paper } from '@mui/material';

// Internal
import CommonLoader from 'components/common/Loader/CommonLoader';
import DashedBox from 'components/common/Wrapper/DashedBox';
import ConfirmationModal from 'components/common/Modal/ConfirmationModal';

import { IC_CHAT_ALT3, IC_DUSTBIN } from 'assets/constants/imageConst';
import { commentBoxScroll } from 'assets/theme/styles';
import Dropdown from 'components/common/FormField/Select/Dropdown1';
import { Button } from 'components/common/FormControls';
import { getTenant } from 'store/actions/tenant';
import { Roles } from 'utils/general';
import { Avatar } from 'components/common';
import { assignRoles } from 'store/actions/user';


const BoxWrapper = styled(Box)(() => ({
  maxHeight: 'calc(100% - 250px)',
    overflow: 'auto',
    padding:'16px',
    ...commentBoxScroll
}));

const NotesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
}));

const NoteList = styled(Box)(() => ({
  display: 'flex',
  columnGap: '16px',
}));

const ContentWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '8px',
  width: '100%',
  marginTop:'-6px'
}));

const HeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  columnGap: '8px',
  alignItems: 'center',
}));

const InnerContentWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

const CommentBoxWrapper = styled(Box)(() => ({
  position: 'absolute',
  width: 'calc(100% - 32px)',
  bottom: '2px',
}));



const TenantRolesMapping = ({ tenantDetail }) => {
  const dispatch = useDispatch();

  const { tenantData, loading: tenantLoading } = useSelector((state) => state.tenant);
  const [userTenants,setUserTenants]= useState(tenantDetail?.tenants);
  const [selectTenant, setSelectTenant] = useState('');
  const [selectRole, setSelectRole] = useState('');
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [customLoading, setCustomLoading] = useState(false);



  useEffect(() => {
      dispatch(getTenant({ per_page: -1 }));
  }, []);


  const handleDelete = (value,row) => {
    setDeleteData(row);
    setIsDeleteOpen(value);
  };

  const deleteNotesHandler =()=>{
    let result = []
    userTenants.map(ten=>{
      if(ten.tenant_id !== deleteData.id) {
        result.push(ten);
      }
    })
    const res ={
      data:{
        tenant_roles:[...result],
        user_uuid:tenantDetail?.uuid
      }
    };
    setCustomLoading(true);
    setIsDeleteOpen(false);
    dispatch(assignRoles(res)).then(()=>{
      setUserTenants([...result]);
      setSelectRole('');
      setSelectTenant('');
      setDeleteData(null);
      setCustomLoading(false);
    });
  }

 const handleSubmit = () =>{
    const newTenant ={
      "role": selectRole,
      "tenant_id": selectTenant
    }
    const res ={
      data:{
        tenant_roles:[
          ...userTenants,
          newTenant
        ],
        user_uuid:tenantDetail?.uuid
      }
    }
    setCustomLoading(true);
    dispatch(assignRoles(res)).then(response=>{
      setUserTenants([...userTenants,{
        role:selectRole,
        tenant_id:selectTenant
      }]);
      setSelectRole('');
      setSelectTenant('');
      setCustomLoading(false);
    })
  }

  const getTenantOptions = () => {
    const data = tenantData?.data ?? [];
    let options = [];
    _.map(data, (ten => {
      const findTenant = userTenants.find(a=>a.tenant_id == ten.id);
      if(!findTenant) {
        options.push({
          label: ten.site_name,
          value: ten.id
        })
      }
    }))
    return options;

  }

  const getRoleOptions = () => {
      return [
        { label: 'Tenant admin', value: Roles.TENANT_ADMIN },
        { label: 'Tenant maintainer', value: Roles.TENANT_MAINTAINER },
        { label: 'Tenant auditor', value: Roles.TENANT_AUDITIOR },
      ]
  }



  const tenantList = () =>{
    let result=[];
    const masterTenantData= tenantData?.data ?? [];
    (userTenants).map(ten=>{
      const findtenant = masterTenantData.find(a=>a.id == ten.tenant_id);
      if(findtenant){
        result.push({
          site_name:findtenant?.site_name,
          icon:findtenant?.icon,
          role:ten.role,
          ...findtenant
        })
      }
      
    });
    return result;
  }

  const btnDisabled=()=>{
    return _.isEmpty(selectRole) || !_.isNumber(selectTenant);
  }


  
  const dataList = tenantList()??[];

  return (
    <>
    {customLoading || tenantLoading ? <CommonLoader height={'50vh'} />:
    <BoxWrapper>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <NotesWrapper>
          {dataList.length > 0 ? (
            dataList.map((item, index) => (
              <>
                 <NoteList key={index} mt={1}>
                 {item?.icon?
                     <Avatar src={item?.icon} size="small" bg="primary" />:
                     <Avatar size="small" />}
                  <ContentWrapper>
                    <InnerContentWrapper>
                      <HeaderWrapper>
                        <Typography variant="subtitle2" sx={{ maxWidth: '200px' }} component={Box}>
                          {`${item?.site_name} `}
                        </Typography>
                        
                      </HeaderWrapper>
                      {dataList.length > 1 &&
                        <IconButton 
                          onClick={(e) => {
                            handleDelete(true,item);
                          }}
                        >
                          <ReactSVG src={IC_DUSTBIN} />
                        </IconButton>
}
                    </InnerContentWrapper>
                      <Typography variant="body2">{item?.role}</Typography>
                  </ContentWrapper>
                </NoteList>
              </>
            ))
          ) : (
            <DashedBox mt={2}>
              <ReactSVG src={IC_CHAT_ALT3} />
              <Box>
                <Typography variant="subtitle2">Nothing to display here!</Typography>
              </Box>
            </DashedBox>
          )}
        </NotesWrapper>
       
          <CommentBoxWrapper>
            <Box mb={3}>
              <Dropdown
                options={getTenantOptions()}
                id="id"
                name="tenant_id"
                value={selectTenant}
                placeholder="Select tenant"
                autoWidth={false}
                onChange={(e)=>setSelectTenant(e.target.value)}
              />
            </Box>
            <Box mb={3}>
              <Dropdown
                options={getRoleOptions()}
                id="id"
                name="role"
                value={selectRole}
                placeholder="Select Role"
                autoWidth={true}
                onChange={(e)=>setSelectRole(e.target.value)}
              />
            </Box>
            <Box mt={3} mb={3} sx={{ display: 'flex', justifyContent: 'flex-end', columnGap: '10px' }}>
        <Button
          variant="contained"
          name="update"
          type="submit"
          onClick={handleSubmit}
          disabled={btnDisabled()}
        >
          {"Add"}
        </Button>
      </Box>
          </CommentBoxWrapper>
      </Box>
      </BoxWrapper>
      }
      <ConfirmationModal
        title="Are you sure you want to delete this Tenant?"
        open={isDeleteOpen}
        handleClose={() => handleDelete(false)}
        handleSubmit={() => deleteNotesHandler()}
      />
    </>
  );
};
export default TenantRolesMapping;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import _ from 'lodash';

// MUI
import { Box, Typography } from '@mui/material';

// Internal
import { Button } from 'components/common/FormControls';
import { IC_PLUS } from 'assets/constants/imageConst';

import { breadcrumbsData } from 'assets/constants/const';
import { setBreadcrumbs } from 'store/actions/breadcrumbs';
import { getFrameworks } from 'store/actions/controls';
import FrameworkManagement from './FrameworkManagement';
import { SwipeableDrawer } from 'components/common';
import FrameworkDrawer from './FrameworkDrawer';
import { shouldBeMasked } from 'utils/general';

const FrameworkManagementPage = () => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);

  const [dataPerPage, setDataPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [addModalData, setAddModalData] = useState(null);

  const [isAscending, setIsAscending] = useState(true);
  const [sortedField, setSortedField] = useState('code');

  const { frameworkLoading, frameworkList } = useSelector((state) => state.controls);

  let defaultParams = {
    page: currentPage,
    per_page: dataPerPage,
    order_by: isAscending ? 'asc' : 'desc',
    field: sortedField,
  };

  useEffect(() => {
    dispatch(setBreadcrumbs(breadcrumbsData.controlManagement));
  }, []);

  useEffect(() => {
    dispatch(getFrameworks({ ...defaultParams, order_by: isAscending ? 'asc' : 'desc', field: sortedField, page: 1 }));
  }, [isAscending, sortedField]);

  const getFrameworkList = (params) => {
    dispatch(getFrameworks({ ...defaultParams, ...params }));
  };

  const handleAddModal = (value, obj) => {
    setIsOpen(value);
    setAddModalData(obj);
  };

  const handleChangePage = (event, newPage) => {
    getFrameworkList({ page: newPage + 1, per_page: dataPerPage });
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setDataPerPage(event.target.value);
    getFrameworkList({ page: 1, per_page: parseInt(event.target.value, 10) });
  };

  return (
    <>
      <Box sx={{ position: 'relative' }}>
        <Box>
          <Typography variant="h5">Frameworks</Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} mb={3} mt={3}>
          <Button startIcon={<ReactSVG src={IC_PLUS} />} variant="filled" onClick={() => handleAddModal(true)}>
            Add Framework
          </Button>
        </Box>
        <FrameworkManagement
          getFrameworkList={getFrameworkList}
          loading={frameworkLoading}
          dataPerPage={dataPerPage}
          setDataPerPage={setDataPerPage}
          handleAddModal={handleAddModal}
          isAscending={isAscending}
          setIsAscending={setIsAscending}
          sortedField={sortedField}
          setSortedField={setSortedField}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
        />
      </Box>
      <SwipeableDrawer
        anchor="right"
        open={isOpen}
        onclose={() => handleAddModal(false)}
        onopen={() => {}}
        isFooter={true}
        title={_.isNil(addModalData) ? 'Add Framework' : 'Update Framework'}
        onSave={() => {}}
        cancelText="Close"
      >
        <Box sx={{ padding: '16px' }}>
          <FrameworkDrawer
            handleAddModal={handleAddModal}
            frameworkDetail={addModalData}
            setCurrentPage={setCurrentPage}
            defaultParams={defaultParams}
          />
        </Box>
      </SwipeableDrawer>
    </>
  );
};
export default FrameworkManagementPage;

import React, { useState, useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FileUploader } from 'react-drag-drop-files';
import moment from 'moment';

import { Box, IconButton, Typography, Menu, MenuItem, styled } from '@mui/material';

import DashedBox from 'components/common/Wrapper/DashedBox';
import { Button } from 'components/common/FormControls';
import CommonLoader from 'components/common/Loader/CommonLoader';
import ConfirmationModal from 'components/common/Modal/ConfirmationModal';
import UpdatedDate from 'components/common/UpdatedDate/UpdatedDate';
import { Avatar, LinearProgressbar } from 'components/common';

import { IC_HORIZONTAL_DOTS } from 'assets/constants/imageConst';
import { FILE_DARK, ICON_DELETE, IC_DOWNLOAD, IC_FILE, IC_UPLOAD } from 'assets/constants/imageConst';
import { uploadProfile } from 'store/actions/me';
import { getFormatDate } from 'utils/date';
import AsyncImage from 'components/common/AsyncImage';

const CONTROL_LIBRARY = 'CONTROL_LIBRARY';

const fileTypes = ['JPG', 'PNG', 'PDF', 'DOCX'];

const BoxWrapper = styled(Box)(() => ({
  display: 'flex',
  columnGap: '16px',
}));
const ContentWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
  width: '100%',
}));

const FileListWrapper = styled(Box)(() => ({
  border: '0.5px solid  #4F4A553B',
  borderRadius: '8px',
  padding: '12px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  columnGap: '16px',
}));

const FileUploaderWrapper = styled(Box)(() => ({
  border: '0.5px solid  #4F4A553B',
  padding: '16px',
  borderRadius: '8px',
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  rowGap: '8px',
  position: 'absolute',
  width: 'calc(100% - 32px)',
  bottom: '12px',
  '& .document-file-upload': {
    outline: 'none !important',
  },
}));

const DocumentList = ({
  selectedControl = {},
  updateDocumentDetail = () => {},
  deleteDocument = () => {},
  downloadDocument = () => {},
  viewOnly = false,
  refetchData = () => {},
  type,
}) => {
  const dispatch = useDispatch();

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [progress, setProgress] = useState(0);
  const [uploadingFileName, setUploadingFileName] = useState('');

  const { isUploadLoading } = useSelector((state) => state.connectors);
  const { documentList, loading, updateLoading, controlLibDocList, loadingControlLib } = useSelector(
    (state) => state.documents
  );

  useEffect(() => {
    refetchData();
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const saveDocumentHandler = (file) => {
    let timer = 0;

    timer = setInterval(() => {
      setProgress((prev) => (prev < 100 ? prev + 20 : 100));
    }, 800);

    setUploadingFileName(file?.name);

    setTimeout(() => {
      const formData = new FormData();
      formData.append('file', file);
      dispatch(uploadProfile(formData)).then((res) => {
        updateDocumentDetail({ pic: res?.value?.data?.payload?.data, docName: file?.name }).then(() => {
          setProgress(0);
          setUploadingFileName('');
          clearInterval(timer);
        });
      });
    }, 5000);
  };

  const deleteDocumentHandler = (document_uuid) => {
    setIsDeleteOpen(false);
    dispatch(deleteDocument(document_uuid)).then(() => {
      refetchData();
      toast.success('Document Deleted Successfully!');
    });
  };

  const downloadDocumentHandler = (uuid, type, file_name) => {
    const extension = file_name?.split('.')[1];

    dispatch(downloadDocument(uuid, type, extension == 'pdf' ? true : false)).then((res) => {
      const url = window.URL.createObjectURL(res?.value?.data);
      const a = document.createElement('a');

      a.setAttribute('href', url);
      a.setAttribute('download', `${selectedControl?.name}-${getFormatDate(moment())}.${extension}`);

      a.style.display = 'none';

      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      toast.success('Download Document Successfully!');
    });
  };

  const dataList = type == CONTROL_LIBRARY ? controlLibDocList : documentList;

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, paddingBottom: '12px' }}>
          {loadingControlLib || isUploadLoading || updateLoading || loading ? (
            <CommonLoader />
          ) : dataList.length > 0 ? (
            dataList.map((doc, index) => (
              <>
                <BoxWrapper key={index} mt={1}>
                  <AsyncImage fileName={doc?.uploaded_by?.profile_pic} />
                  <ContentWrapper>
                    <BoxWrapper>
                      <Typography variant="subtitle2" sx={{ maxWidth: '180px' }}>
                        {`${doc?.uploaded_by?.first_name}  ${doc?.uploaded_by?.last_name} `}
                      </Typography>
                      <Typography variant="body2">
                        <UpdatedDate date={doc?.created_at} />
                      </Typography>
                    </BoxWrapper>
                    <FileListWrapper>
                      <Box sx={{ display: 'flex', columnGap: '8px', alignItems: 'center' }}>
                        <Avatar bg="primary" size="small">
                          <ReactSVG src={FILE_DARK} />
                        </Avatar>
                        <Typography component="div" variant="body2" sx={{ maxWidth: '165px', wordBreak: 'break-all' }}>
                          {doc?.document_name || '-'}
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', columnGap: '4px' }}>
                        <IconButton onClick={() => downloadDocumentHandler(doc?.uuid, 'download', doc?.document_name)}>
                          <ReactSVG src={IC_DOWNLOAD} className="download-small-icon" />
                        </IconButton>
                        {!viewOnly && (
                          <IconButton
                            onClick={(e) => {
                              handleClick(e);
                              setSelectedDocument(doc);
                            }}
                          >
                            <ReactSVG src={IC_HORIZONTAL_DOTS} />
                          </IconButton>
                        )}
                      </Box>
                    </FileListWrapper>
                  </ContentWrapper>
                </BoxWrapper>
              </>
            ))
          ) : (
            <DashedBox mt={2}>
              <ReactSVG src={IC_FILE} />
              <Box>
                <Typography variant="subtitle2">Nothing to display here!</Typography>
                <Typography variant="caption" color="secondary">
                  There&apos;s no files here yet, be the first to upload one!
                </Typography>
              </Box>
            </DashedBox>
          )}
        </Box>

        <Box sx={{ width: '100%', marginBottom: '12px' }}>
          {!viewOnly && (
            <>
              {progress > 0 ? (
                <>
                  <Typography variant="subtitle2" component={Box} mb={1}>
                    UPLOADING(1)
                  </Typography>
                  <FileListWrapper>
                    <Avatar bg="primary" size="small">
                      <ReactSVG src={FILE_DARK} />
                    </Avatar>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                      <Typography variant="body2" component="div">
                        {uploadingFileName}
                      </Typography>
                      <LinearProgressbar
                        variant="determinate"
                        value={progress}
                        label={
                          <Typography variant="body1" ml={1}>
                            {progress}%
                          </Typography>
                        }
                        color="primary"
                      />
                    </Box>
                    <IconButton onClick={() => {}}>
                      <ReactSVG src={ICON_DELETE} />
                    </IconButton>
                  </FileListWrapper>
                </>
              ) : (
                <FileUploaderWrapper>
                  <FileUploader
                    classes="document-file-upload"
                    // handleChange={saveDocumentHandler}
                    onSelect={saveDocumentHandler}
                    name="profile_pic"
                    types={fileTypes}
                    maxSize={5}
                    onDrop={saveDocumentHandler}
                  >
                    <Button
                      className="upload_doc_btn"
                      variant="filled"
                      sx={{ borderRadius: '8px', columnGap: '10px', width: 'fit-content' }}
                    >
                      <ReactSVG src={IC_UPLOAD} />
                      <Typography variant="subtitle2">Upload your file</Typography>
                    </Button>
                  </FileUploader>
                  <Typography variant="caption" color="secondary">
                    Works with .pdf, . txt, .xls, .png, .jpeg files under 5MB
                  </Typography>
                </FileUploaderWrapper>
              )}
            </>
          )}
        </Box>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={() => {
            setIsDeleteOpen(true);
            handleClose();
          }}
        >
          Delete
        </MenuItem>
      </Menu>
      {isDeleteOpen && (
        <ConfirmationModal
          title="Are you sure you want to delete this attachment?"
          open={isDeleteOpen}
          handleClose={() => setIsDeleteOpen(false)}
          handleSubmit={() => deleteDocumentHandler(selectedDocument?.uuid)}
        />
      )}
    </>
  );
};

export default DocumentList;

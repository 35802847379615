import { YAxis, XAxis, Legend, Line, CartesianGrid } from 'recharts';
import { Typography, MenuItem, Select, Box } from '@mui/material';
import { ReactSVG } from 'react-svg';
// Internal
import LineGraph from 'components/common/Graph/LineGraph';
import { Button } from 'components/common/FormControls';
import { useState } from 'react';
import { IC_RIGHT2 } from 'assets/constants/imageConst';

const renderLegend = (props) => {
  const { payload } = props;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '16px' }}>
      {payload.map((entry, index) => (
        <Box key={`item-${index}`} sx={{ display: 'flex', alignItems: 'center', columnGap: '4px' }}>
          <Box
            sx={{
              backgroundColor: entry.type === 'circle' ? entry.color : '',
              borderRadius: '50%',
              height: '8px',
              width: '8px',
              border: entry.type === 'dashed' ? `1px dashed ${entry.color}` : ' ',
            }}
          />
          <Typography sx={{ fontSize: '14px', color: '#4F4A5599' }}>{entry.value}</Typography>
        </Box>
      ))}
    </Box>
  );
};

const securityTrendData = [
  {
    name: 'Jan',
    td: 30,
  },
  {
    name: 'Feb',
    td: 34,
  },
  {
    name: 'Mar',
    td: 32,
  },
  {
    name: 'Apr',
    td: 40,
  },
  {
    name: 'May',
    td: 45,
  },
  {
    name: 'Jun',
    td: 50,
  },
  {
    name: 'Jul',
    td: 55,
  },
  {
    name: 'Aug',
    td: 55,
    pd: 55,
  },
  {
    name: 'Sep',
    pd: 60,
  },
  {
    name: 'Oct',
    pd: 70,
  },
  {
    name: 'Nov',
    pd: 79,
  },
  { name: 'Dec', pd: 86 },
];

const Timeline = () => {
  const [filter, setFilter] = useState('Year');

  const CustomYAxisTick = (props) => {
    let tickLabel = props.payload.value % 20 === 0 ? props.payload.value : '';
    return <text {...props}>{tickLabel}</text>;
  };

  return (
    <LineGraph
      height={350}
      data={securityTrendData}
      header={
        <Box sx={{ position: 'absolute', display: 'flex', columnGap: '10px', top: '-38px' }}>
          <Select
            size="small"
            variant="standard"
            autoWidth
            sx={{
              border: 0,
              '& .MuiSelect-select': { display: 'flex', justifyContent: 'center', alignItems: 'center' },
              color: '#4F4A5599',
            }}
            disableUnderline
            renderValue={(value) => <Typography variant="body2">{value}</Typography>}
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          >
            {['Year', 'Month', 'Days'].map((d) => (
              <MenuItem value={d} key={d}>
                {d}
              </MenuItem>
            ))}
          </Select>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<ReactSVG src={IC_RIGHT2} className="rotate180" />}
            endIcon={<ReactSVG src={IC_RIGHT2} />}
            size="small"
          >
            2022
          </Button>
        </Box>
      }
    >
      <CartesianGrid vertical={false} opacity={0.2} />
      <XAxis
        tick={{ fontSize: 12, color: '#4F4A5599', fill: '#4F4A5599' }}
        dataKey="name"
        axisLine={false}
        tickLine={false}
        interval="preserveStartEnd"
        tickMargin={10}
      />
      <YAxis
        orientation="right"
        tick={<CustomYAxisTick style={{ fontSize: 12, color: '#4F4A5599', fill: '#4F4A5599' }} />}
        type="number"
        axisLine={false}
        tickLine={false}
        ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
        domain={[0, 100]}
        tickCount={5}
        tickMargin={10}
      />
      <Legend
        verticalAlign="top"
        height={36}
        align="left"
        payload={[
          { value: 'Today', type: 'circle', id: 'today', color: '#8884d8' },
          {
            value: 'Predictive',
            id: 'predictive',
            type: 'dashed',
            color: '#8884d8',
          },
        ]}
        content={renderLegend}
      />
      <Line dataKey="td" name="Today" type="monotoneX" stroke="#8884d8" dot={false} />
      <Line
        dataKey="pd"
        name="Predictive"
        type="monotoneX"
        stroke="#8884d8"
        strokeDasharray="3 3"
        dot={{ strokeWidth: 1, r: 4, strokeDasharray: '' }}
      />
    </LineGraph>
  );
};
export default Timeline;

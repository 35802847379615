import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { Avatar, Box, FormControl, FormHelperText, Typography } from '@mui/material';
// Internal
import Input from 'components/common/FormField/Input/Input';
import { frameworkValidationSchema } from 'utils/validationSchema';
import DashedBox from 'components/common/Wrapper/DashedBox';
import { Button } from 'components/common/FormControls';
import { FileUploader } from 'react-drag-drop-files';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { ICON_DELETE } from 'assets/constants/imageConst';
import { ReactSVG } from 'react-svg';
import style from 'components/Profile/profile.module.scss';
import { AddUpdateFrameworks } from 'store/actions/frameworks';
import { getFrameworks } from 'store/actions/controls';
import { uploadProfile } from 'store/actions/me';
import { toast } from 'react-toastify';
import CommonLoader from 'components/common/Loader/CommonLoader';

const FrameworkDrawer = ({ handleAddModal, frameworkDetail, setCurrentPage, defaultParams }) => {
  const dispatch = useDispatch();
  const { isUploadLoading } = useSelector((state) => state.connectors);
  const { addFrameworksLoading } = useSelector((state) => state.frameworks);

  const [changeInProfile, setChangeInProfile] = useState(false);

  const isAdd = _.isNil(frameworkDetail);

  const frameworkHandler = (data) => {
    if (data.icon instanceof File && changeInProfile) {
      const formData = new FormData();
      formData.append('file', data.icon);
      dispatch(uploadProfile(formData)).then((res) => {
        const image_name = res?.value?.data?.payload?.data;
        const requestData = {
          ...data,
          icon: image_name,
        };
        handleAction(requestData);
      });
    } else if (changeInProfile && _.isEmpty(data.icon)) {
      data.icon = null;
      handleAction(data);
    } else {
      delete data.icon;
      handleAction(data);
    }
    setCurrentPage(1);
  };

  const handleAction = (values) => {
    const response = {
      data: {
        ...values,
      },
    };
    if (!isAdd) {
      response['data']['uuid'] = frameworkDetail?.uuid;
    }
    return dispatch(AddUpdateFrameworks(response)).then(() => {
      dispatch(getFrameworks({ ...defaultParams, page: 1 }));
      handleAddModal(false, null);
      toast.success(`Framework ${isAdd ? 'created' : 'updated'} successfully`);
    });
  };

  return isUploadLoading || addFrameworksLoading ? (
    <CommonLoader height={'50vh'} />
  ) : (
    <Formik
      initialValues={{
        name: frameworkDetail?.name ?? '',
        code: frameworkDetail?.code ?? '',
        icon: frameworkDetail?.icon ?? '',
      }}
      onSubmit={frameworkHandler}
      validationSchema={frameworkValidationSchema}
    >
      {({ errors, touched, values, handleChange, handleSubmit, setFieldValue, initialValues }) => {
        let profilePreview = values?.icon instanceof File ? URL.createObjectURL(values?.icon) : values?.icon;
        return (
          <Form>
            <Box mb={3}>
              <Input
                id="name"
                className="mb-3"
                fullWidth
                placeholder="Framework name"
                label="Framework name *"
                name="name"
                value={values.name}
                onChange={handleChange}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
              />
            </Box>
            <Box mb={3}>
              <Input
                id="code"
                className="mb-3"
                fullWidth
                placeholder="Framework code"
                label="Framework code *"
                name="code"
                value={values.code}
                onChange={handleChange}
                error={touched.code && Boolean(errors.code)}
                helperText={touched.code && errors.code}
              />
            </Box>
            <FormControl fullWidth>
              <Typography sx={{ marginBottom: '8px' }}>Profile pic</Typography>
              <input
                color="primary"
                accept=".jpg, .png"
                size="5000000"
                type="file"
                name="files"
                onChange={(e) => {
                  const file = e.target.files[0];
                  setFieldValue('icon', file);
                  setChangeInProfile(true);
                }}
                id="icon-button-file"
                style={{ display: 'none' }}
              />
              <label htmlFor="icon-button-file">
                {profilePreview ? (
                  <div className={style.upload_profile_btn}>
                    <Avatar alt="Avatar" name="icon" src={profilePreview} imgProps sx={{ width: 45, height: 45 }} />
                    <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
                      <ReactSVG
                        src={ICON_DELETE}
                        onClick={(e) => {
                          e.preventDefault();
                          setChangeInProfile(!changeInProfile);
                          setFieldValue('icon', '');
                        }}
                      />
                    </Box>
                  </div>
                ) : (
                  <DashedBox style={{ cursor: 'pointer' }}>
                    <Typography variant="body1" color="primary">
                      Upload Picture
                    </Typography>
                  </DashedBox>
                )}
              </label>
            </FormControl>

            <Box mt={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                type="submit"
                variant="contained"
                onClick={handleSubmit}
                disabled={_.isEqual(initialValues, values)}
              >
                {isAdd ? 'Add' : 'Update'}
              </Button>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};
export default FrameworkDrawer;

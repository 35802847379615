import api from './api';

class UsersAPI {
  getUsers(params) {
    let queryParams = '';
    if (params) {
      queryParams =
        Object.keys(params)
          .map(key => {
            return `${key}=${encodeURIComponent(params[key])}`;
          }).join('&');
    }
    return api.get(`/users?${queryParams}`);
  }

  getPermission(tenantId) {
    return api.get('/users/ui-permissions',{
      // headers:{
      //   'tenant-id':tenantId
      // }
    })
  }
}

export default UsersAPI;

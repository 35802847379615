import React from 'react';
import { Area } from 'recharts';

export function areaGraph() {
  return (
    <>
      <Area
        type="linear"
        dataKey="a"
        isAnimationActive={false}
        stroke=""
        fill="#36C3951F"
        activeDot={false}
        dot={false}
        connectNulls={true}
      />
      <Area
        type="linear"
        dataKey="b"
        isAnimationActive={false}
        stroke=""
        fill="#36C3951F"
        activeDot={false}
        dot={false}
        connectNulls={true}
      />
      <Area
        type="linear"
        dataKey="c"
        isAnimationActive={false}
        stroke=""
        fill="#D3E0621F"
        activeDot={false}
        dot={false}
        connectNulls={true}
      />
      <Area
        type="linear"
        dataKey="d"
        isAnimationActive={false}
        stroke=""
        fill="#FAD9461F"
        activeDot={false}
        dot={false}
        connectNulls={true}
      />
      <Area
        type="linear"
        dataKey="e"
        isAnimationActive={false}
        stroke=""
        fill="#FDB7211F"
        activeDot={false}
        dot={false}
        connectNulls={true}
      />
      <Area
        type="linear"
        dataKey="f"
        isAnimationActive={false}
        stroke=""
        fill="#FF96031F"
        activeDot={false}
        dot={false}
        connectNulls={true}
      />
      <Area
        type="linear"
        dataKey="g"
        isAnimationActive={false}
        stroke=""
        fill="#FF652C1F"
        activeDot={false}
        dot={false}
        connectNulls={true}
      />
      <Area
        type="linear"
        dataKey="h"
        isAnimationActive={false}
        stroke=""
        fill="#FF2E5A1F"
        activeDot={false}
        dot={false}
        connectNulls={true}
      />
      <Area
        type="linear"
        dataKey="i"
        isAnimationActive={false}
        stroke=""
        fill="#FF2F591F"
        activeDot={false}
        dot={false}
        connectNulls={true}
      />
      <Area
        type="linear"
        dataKey="j"
        isAnimationActive={false}
        stroke=""
        fill="#FF25611F"
        activeDot={false}
        dot={false}
        connectNulls={true}
      />
      <Area
        type="linear"
        dataKey="k"
        isAnimationActive={false}
        stroke=""
        fill="#FF007F1F"
        activeDot={false}
        dot={false}
        connectNulls={true}
      />
    </>
  );
}

// Me Call
export const ME = 'ME';
export const ME_START = 'ME_PENDING';
export const ME_SUCCESS = 'ME_FULFILLED';
export const ME_ERROR = 'ME_REJECTED';

export const USER_PERMISSION = 'USER_PERMISSION';
export const USER_PERMISSION_START = 'USER_PERMISSION_PENDING';
export const USER_PERMISSION_SUCCESS = 'USER_PERMISSION_FULFILLED';
export const USER_PERMISSION_ERROR = 'USER_PERMISSION_REJECTED';

export const SWITCH_TENANT = 'SWITCH_TENANT';
export const SWITCH_TENANT_START = 'SWITCH_TENANT_PENDING';
export const SWITCH_TENANT_SUCCESS = 'SWITCH_TENANT_FULFILLED';
export const SWITCH_TENANT_ERROR = 'SWITCH_TENANT_REJECTED';

//Breadcrumbs
export const SET_BREAD_CRUMBS = 'SET_BREAD_CRUMBS';

// User
export const USERS = 'USERS';
export const USERS_START = 'USERS_PENDING';
export const USERS_SUCCESS = 'USERS_FULFILLED';
export const USERS_ERROR = 'USERS_REJECTED';

// === Lightening Integration ===
export const SET_WORKSPACE_DATA = 'SET_WORKSPACE_DATA';
export const DELETE_WORKSPACE_DATA = 'DELETE_WORKSPACE_DATA';

export const CONNECTORS = 'CONNECTORS';
export const CONNECTORS_START = 'CONNECTORS_PENDING';
export const CONNECTORS_SUCCESS = 'CONNECTORS_FULFILLED';
export const CONNECTORS_ERROR = 'CONNECTORS_REJECTED';

export const CONNECTOR = 'CONNECTOR';
export const CONNECTOR_START = 'CONNECTOR_PENDING';
export const CONNECTOR_SUCCESS = 'CONNECTOR_FULFILLED';
export const CONNECTOR_ERROR = 'CONNECTOR_REJECTED';

export const GET_CONNECTOR = 'GET_CONNECTOR';
export const GET_CONNECTOR_START = 'GET_CONNECTOR_PENDING';
export const GET_CONNECTOR_SUCCESS = 'GET_CONNECTOR_FULFILLED';
export const GET_CONNECTOR_ERROR = 'GET_CONNECTOR_REJECTED';

export const POST_CONNECTOR = 'POST_CONNECTOR';
export const POST_CONNECTOR_START = 'POST_CONNECTOR_PENDING';
export const POST_CONNECTOR_SUCCESS = 'POST_CONNECTOR_FULFILLED';
export const POST_CONNECTOR_ERROR = 'POST_CONNECTOR_REJECTED';

export const GET_INTEGRATION_DETAIL = 'GET_INTEGRATION_DETAIL';
export const GET_INTEGRATION_DETAIL_START = 'GET_INTEGRATION_DETAIL_PENDING';
export const GET_INTEGRATION_DETAIL_SUCCESS = 'GET_INTEGRATION_DETAIL_FULFILLED';
export const GET_INTEGRATION_DETAIL_ERROR = 'GET_INTEGRATION_DETAIL_REJECTED';
export const DELETE_INTEGRATION_DETAIL_START = 'DELETE_INTEGRATION_DETAIL_PENDING';
export const DELETE_INTEGRATION_DETAIL_SUCCESS = 'DELETE_INTEGRATION_DETAIL_FULFILLED';
export const DELETE_INTEGRATION_DETAIL_ERROR = 'DELETE_INTEGRATION_DETAIL_REJECTED';
export const DELETE_INTEGRATION_DETAIL = 'DELETE_INTEGRATION_DETAIL';
export const REMOVE_INTEGRATION_DETAIL = 'REMOVE_INTEGRATION_DETAIL';

//Hipaa
export const GET_PROGRESS_SCORE = 'GET_PROGRESS_SCORE';
export const GET_PROGRESS_SCORE_START = 'GET_PROGRESS_SCORE_PENDING';
export const GET_PROGRESS_SCORE_SUCCESS = 'GET_PROGRESS_SCORE_FULFILLED';
export const GET_PROGRESS_SCORE_ERROR = 'GET_PROGRESS_SCORE_REJECTED';

// Risk Analysis
export const GET_RISK_ANALYSIS = 'GET_RISK_ANALYSIS';
export const GET_RISK_ANALYSIS_START = 'GET_RISK_ANALYSIS_PENDING';
export const GET_RISK_ANALYSIS_SUCCESS = 'GET_RISK_ANALYSIS_FULFILLED';
export const GET_RISK_ANALYSIS_ERROR = 'GET_RISK_ANALYSIS_REJECTED';
export const UPDATE_RISK_SCORE = 'UPDATE_RISK_SCORE';
export const UPDATE_RISK_SCORE_START = 'UPDATE_RISK_SCORE_PENDING';
export const UPDATE_RISK_SCORE_SUCCESS = 'UPDATE_RISK_SCORE_FULFILLED';
export const UPDATE_RISK_SCORE_ERROR = 'UPDATE_RISK_SCORE_REJECTED';
export const GET_SCORE = 'GET_SCORE';
export const GET_SCORE_START = 'GET_SCORE_PENDING';
export const GET_SCORE_SUCCESS = 'GET_SCORE_FULFILLED';
export const GET_SCORE_ERROR = 'GET_SCORE_REJECTED';
export const GET_SCORE_RESPONSE_RATIO = 'GET_SCORE_RESPONSE_RATIO';
export const GET_SCORE_RESPONSE_RATIO_START = 'GET_SCORE_RESPONSE_RATIO_PENDING';
export const GET_SCORE_RESPONSE_RATIO_SUCCESS = 'GET_SCORE_RESPONSE_RATIO_FULFILLED';
export const GET_SCORE_RESPONSE_RATIO_ERROR = 'GET_SCORE_RESPONSE_RATIO_REJECTED';

// Risk Analysis => Get connector runs
export const GET_CONNECTOR_RUN = 'GET_CONNECTOR_RUN';
export const GET_CONNECTOR_RUN_START = 'GET_CONNECTOR_RUN_PENDING';
export const GET_CONNECTOR_RUN_SUCCESS = 'GET_CONNECTOR_RUN_FULFILLED';
export const GET_CONNECTOR_RUN_ERROR = 'GET_CONNECTOR_RUN_REJECTED';
export const GET_CONNECTOR_RUN_INPROGRESS = 'GET_CONNECTOR_RUN_INPROGRESS';
export const GET_CONNECTOR_RUN_INPROGRESS_START = 'GET_CONNECTOR_RUN_INPROGRESS_PENDING';
export const GET_CONNECTOR_RUN_INPROGRESS_SUCCESS = 'GET_CONNECTOR_RUN_INPROGRESS_FULFILLED';
export const GET_CONNECTOR_RUN_INPROGRESS_ERROR = 'GET_CONNECTOR_RUN_INPROGRESS_REJECTED';

export const UPLOAD_CONNECTOR_CERTIFICATE = 'UPLOAD_CONNECTOR_CERTIFICATE';
export const UPLOAD_CONNECTOR_CERTIFICATE_START = 'UPLOAD_CONNECTOR_CERTIFICATE_PENDING';
export const UPLOAD_CONNECTOR_CERTIFICATE_SUCCESS = 'UPLOAD_CONNECTOR_CERTIFICATE_FULFILLED';
export const UPLOAD_CONNECTOR_CERTIFICATE_ERROR = 'UPLOAD_CONNECTOR_CERTIFICATE_REJECTED';
export const SET_VULNERABILITY_CONTROL = 'SET_VULNERABILITY_CONTROL';

// Risk Analysis -> Vulnerabilities
export const GET_VULNERABILITIES = 'GET_VULNERABILITIES';
export const GET_VULNERABILITIES_START = 'GET_VULNERABILITIES_PENDING';
export const GET_VULNERABILITIES_SUCCESS = 'GET_VULNERABILITIES_FULFILLED';
export const GET_VULNERABILITIES_ERROR = 'GET_VULNERABILITIES_REJECTED';

// Risk Analysis Threshold value
export const UPDATE_THRESHOLD_VALUE = 'UPDATE_THRESHOLD_VALUE';

//Control library
export const SET_CONTROL_REPONSE = 'SET_CONTROL_REPONSE';
export const SET_FRAMEWORK_FILTER = 'SET_FRAMEWORK_FILTER';
export const SET_RESPONSE_FILTER = 'SET_RESPONSE_FILTER';
export const CLEAR_CONTROLS_LIBRARY_FILTER = 'CLEAR_CONTROLS_LIBRARY_FILTER';
export const SET_TAB_VALUE = 'SET_TAB_VALUE';

// Document Upload
export const DOCUMENT_UPDATE = 'DOCUMENT_UPDATE';
export const DOCUMENT_UPDATE_START = 'DOCUMENT_UPDATE_PENDING';
export const DOCUMENT_UPDATE_SUCCESS = 'DOCUMENT_UPDATE_FULFILLED';
export const DOCUMENT_UPDATE_ERROR = 'DOCUMENT_UPDATE_REJECTED';

export const GET_DOCUMENTS = 'GET_DOCUMENTS';
export const GET_DOCUMENTS_START = 'GET_DOCUMENTS_PENDING';
export const GET_DOCUMENTS_SUCCESS = 'GET_DOCUMENTS_FULFILLED';
export const GET_DOCUMENTS_ERROR = 'GET_DOCUMENTS_REJECTED';

export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';
export const DELETE_DOCUMENT_START = 'DELETE_DOCUMENT_PENDING';
export const DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_FULFILLED';
export const DELETE_DOCUMENT_ERROR = 'DELETE_DOCUMENT_REJECTED';

export const DOWNLOAD_DOCUMENT = 'DOWNLOAD_DOCUMENT';
export const DOWNLOAD_DOCUMENT_START = 'DOWNLOAD_DOCUMENT_PENDING';
export const DOWNLOAD_DOCUMENT_SUCCESS = 'DOWNLOAD_DOCUMENT_FULFILLED';
export const DOWNLOAD_DOCUMENT_ERROR = 'DOWNLOAD_DOCUMENT_REJECTED';

// Control Library
export const GET_CONTROL_LIBRARY_LIST = 'GET_CONTROL_LIBRARY_LIST';
export const GET_CONTROL_LIBRARY_LIST_START = 'GET_CONTROL_LIBRARY_LIST_PENDING';
export const GET_CONTROL_LIBRARY_LIST_SUCCESS = 'GET_CONTROL_LIBRARY_LIST_FULFILLED';
export const GET_CONTROL_LIBRARY_LIST_ERROR = 'GET_CONTROL_LIBRARY_LIST_REJECTED';

export const GET_CONTROL_LIBRARY_FRAMEWORK = 'GET_CONTROL_LIBRARY_FRAMEWORK';
export const GET_CONTROL_LIBRARY_FRAMEWORK_START = 'GET_CONTROL_LIBRARY_FRAMEWORK_PENDING';
export const GET_CONTROL_LIBRARY_FRAMEWORK_SUCCESS = 'GET_CONTROL_LIBRARY_FRAMEWORK_FULFILLED';
export const GET_CONTROL_LIBRARY_FRAMEWORK_ERROR = 'GET_CONTROL_LIBRARY_FRAMEWORK_REJECTED';

export const GET_CONTROL_LIBRARY_DOCUMENTS = 'GET_CONTROL_LIBRARY_DOCUMENTS';
export const GET_CONTROL_LIBRARY_DOCUMENTS_START = 'GET_CONTROL_LIBRARY_DOCUMENTS_PENDING';
export const GET_CONTROL_LIBRARY_DOCUMENTS_SUCCESS = 'GET_CONTROL_LIBRARY_DOCUMENTS_FULFILLED';
export const GET_CONTROL_LIBRARY_DOCUMENTS_ERROR = 'GET_CONTROL_LIBRARY_DOCUMENTS_REJECTED';

// Notes
export const ADD_NOTES = 'ADD_NOTES';
export const ADD_NOTES_START = 'ADD_NOTES_PENDING';
export const ADD_NOTES_SUCCESS = 'ADD_NOTES_FULFILLED';
export const ADD_NOTES_ERROR = 'ADD_NOTES_REJECTED';

export const GET_NOTES = 'GET_NOTES';
export const GET_NOTES_START = 'GET_NOTES_PENDING';
export const GET_NOTES_SUCCESS = 'GET_NOTES_FULFILLED';
export const GET_NOTES_ERROR = 'GET_NOTES_REJECTED';

export const DELETE_NOTES = 'DELETE_NOTES';
export const DELETE_NOTES_START = 'DELETE_NOTES_PENDING';
export const DELETE_NOTES_SUCCESS = 'DELETE_NOTES_FULFILLED';
export const DELETE_NOTES_ERROR = 'DELETE_NOTES_REJECTED';

export const UPDATE_NOTES = 'UPDATE_NOTES';
export const UPDATE_NOTES_START = 'UPDATE_NOTES_PENDING';
export const UPDATE_NOTES_SUCCESS = 'UPDATE_NOTES_FULFILLED';
export const UPDATE_NOTES_ERROR = 'UPDATE_NOTES_REJECTED';

export const CONTROL_LIBRARY_GET_NOTES = 'CONTROL_LIBRARY_GET_NOTES';
export const CONTROL_LIBRARY_GET_NOTES_START = 'CONTROL_LIBRARY_GET_NOTES_PENDING';
export const CONTROL_LIBRARY_GET_NOTES_SUCCESS = 'CONTROL_LIBRARY_GET_NOTES_FULFILLED';
export const CONTROL_LIBRARY_GET_NOTES_ERROR = 'CONTROL_LIBRARY_GET_NOTES_REJECTED';

// Control Management
export const GET_CONTROLS = 'GET_CONTROLS';
export const GET_CONTROLS_START = 'GET_CONTROLS_PENDING';
export const GET_CONTROLS_SUCCESS = 'GET_CONTROLS_FULFILLED';
export const GET_CONTROLS_ERROR = 'GET_CONTROLS_REJECTED';

export const ADD_UPDATE_CONTROLS = 'ADD_UPDATE_CONTROLS';
export const ADD_UPDATE_CONTROLS_START = 'ADD_UPDATE_CONTROLS_PENDING';
export const ADD_UPDATE_CONTROLS_SUCCESS = 'ADD_UPDATE_CONTROLS_FULFILLED';
export const ADD_UPDATE_CONTROLS_ERROR = 'ADD_UPDATE_CONTROLS_REJECTED';

export const GET_FRAMEWORKS = 'GET_FRAMEWORKS';
export const GET_FRAMEWORKS_START = 'GET_FRAMEWORKS_PENDING';
export const GET_FRAMEWORKS_SUCCESS = 'GET_FRAMEWORKS_FULFILLED';
export const GET_FRAMEWORKS_ERROR = 'GET_FRAMEWORKS_REJECTED';

export const ADD_UPDATE_FRAMEWORKS = 'ADD_UPDATE_FRAMEWORKS';
export const ADD_UPDATE_FRAMEWORKS_START = 'ADD_UPDATE_FRAMEWORKS_PENDING';
export const ADD_UPDATE_FRAMEWORKS_SUCCESS = 'ADD_UPDATE_FRAMEWORKS_FULFILLED';
export const ADD_UPDATE_FRAMEWORKS_ERROR = 'ADD_UPDATE_FRAMEWORKS_REJECTED';

// Get image full path
export const GET_FILES = 'GET_FILES';
export const GET_FILES_START = 'GET_FILES_PENDING';
export const GET_FILES_SUCCESS = 'GET_FILES_FULFILLED';
export const GET_FILES_ERROR = 'GET_FILES_REJECTED';
//Users

export const GET_USERS = 'GET_USERS';
export const GET_USERS_START = 'GET_USERS_PENDING';
export const GET_USERS_SUCCESS = 'GET_USERS_FULFILLED';
export const GET_USERS_ERROR = 'GET_USERS_REJECTED';

export const GET_USER = 'GET_USER';
export const GET_USER_START = 'GET_USER_PENDING';
export const GET_USER_SUCCESS = 'GET_USER_FULFILLED';
export const GET_USER_ERROR = 'GET_USER_REJECTED';

export const ADD_USER = 'ADD_USER';
export const ADD_USER_START = 'ADD_USER_PENDING';
export const ADD_USER_SUCCESS = 'ADD_USER_FULFILLED';
export const ADD_USER_ERROR = 'ADD_USER_REJECTED';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_START = 'DELETE_USER_PENDING';
export const DELETE_USER_SUCCESS = 'DELETE_USER_FULFILLED';
export const DELETE_USER_ERROR = 'DELETE_USER_REJECTED';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_START = 'UPDATE_USER_PENDING';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_FULFILLED';
export const UPDATE_USER_ERROR = 'UPDATE_USER_REJECTED';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_START = 'RESET_PASSWORD_PENDING';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_FULFILLED';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_REJECTED';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_START = 'CHANGE_PASSWORD_PENDING';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_FULFILLED';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_REJECTED';

export const ASSIGN_ROLES = 'ASSIGN_ROLES';
export const ASSIGN_ROLES_START = 'ASSIGN_ROLES_PENDING';
export const ASSIGN_ROLES_SUCCESS = 'ASSIGN_ROLES_FULFILLED';
export const ASSIGN_ROLES_ERROR = 'ASSIGN_ROLES_REJECTED';

//Tenant
export const GET_TENANT = 'GET_TENANT';
export const GET_TENANT_START = 'GET_TENANT_PENDING';
export const GET_TENANT_SUCCESS = 'GET_TENANT_FULFILLED';
export const GET_TENANT_ERROR = 'GET_TENANT_REJECTED';

export const ADD_TENANT = 'ADD_TENANT';
export const ADD_TENANT_START = 'ADD_TENANT_PENDING';
export const ADD_TENANT_SUCCESS = 'ADD_TENANT_FULFILLED';
export const ADD_TENANT_ERROR = 'ADD_TENANT_REJECTED';

export const UPDATE_TENANT = 'UPDATE_TENANT';
export const UPDATE_TENANT_START = 'UPDATE_TENANT_PENDING';
export const UPDATE_TENANT_SUCCESS = 'UPDATE_TENANT_FULFILLED';
export const UPDATE_TENANT_ERROR = 'UPDATE_TENANT_REJECTED';

export const GET_TENANT_NOTES_DOCUMENT_COUNT = 'GET_TENANT_NOTES_DOCUMENT_COUNT';
export const GET_TENANT_NOTES_DOCUMENT_COUNT_START = 'GET_TENANT_NOTES_DOCUMENT_COUNT_PENDING';
export const GET_TENANT_NOTES_DOCUMENT_COUNT_SUCCESS = 'GET_TENANT_NOTES_DOCUMENT_COUNT_FULFILLED';
export const GET_TENANT_NOTES_DOCUMENT_COUNT_ERROR = 'GET_TENANT_NOTES_DOCUMENT_COUNT_REJECTED';

export const ADD_TENANT_FRAMEWORKS = 'ADD_TENANT_FRAMEWORKS';
export const ADD_TENANT_FRAMEWORKS_START = 'ADD_TENANT_FRAMEWORKS_PENDING';
export const ADD_TENANT_FRAMEWORKS_SUCCESS = 'ADD_TENANT_FRAMEWORKS_FULFILLED';
export const ADD_TENANT_FRAMEWORKS_ERROR = 'ADD_TENANT_FRAMEWORKS_REJECTED';

export const GET_TENANT_FRAMEWORKS = 'GET_TENANT_FRAMEWORKS';
export const GET_TENANT_FRAMEWORKS_START = 'GET_TENANT_FRAMEWORKS_PENDING';
export const GET_TENANT_FRAMEWORKS_SUCCESS = 'GET_TENANT_FRAMEWORKS_FULFILLED';
export const GET_TENANT_FRAMEWORKS_ERROR = 'GET_TENANT_FRAMEWORKS_REJECTED';

// Get tenant connector note and document count
export const GET_CONNECTOR_NOTES_DOCUMENT_COUNT = 'GET_CONNECTOR_NOTES_DOCUMENT_COUNT';
export const GET_CONNECTOR_NOTES_DOCUMENT_COUNT_START = 'GET_CONNECTOR_NOTES_DOCUMENT_COUNT_PENDING';
export const GET_CONNECTOR_NOTES_DOCUMENT_COUNT_SUCCESS = 'GET_CONNECTOR_NOTES_DOCUMENT_COUNT_FULFILLED';
export const GET_CONNECTOR_NOTES_DOCUMENT_COUNT_ERROR = 'GET_CONNECTOR_NOTES_DOCUMENT_COUNT_REJECTED';

// Response type
export const SAVE_CONTROL_LIBRARY_RESPONSE = 'SAVE_CONTROL_LIBRARY_RESPONSE';
export const SAVE_CONTROL_LIBRARY_RESPONSE_START = 'SAVE_CONTROL_LIBRARY_RESPONSE_PENDING';
export const SAVE_CONTROL_LIBRARY_RESPONSE_SUCCESS = 'SAVE_CONTROL_LIBRARY_RESPONSE_FULFILLED';
export const SAVE_CONTROL_LIBRARY_RESPONSE_ERROR = 'SAVE_CONTROL_LIBRARY_RESPONSE_REJECTED';

// Controls under vulnerability
export const ADD_CONTROLS_TO_VULNERABILITY = 'ADD_CONTROLS_TO_VULNERABILITY';
export const SET_NEW_CONTROLS_TO_VULNERABILITY = 'SET_NEW_CONTROLS_TO_VULNERABILITY';

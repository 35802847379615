import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { Box, Typography } from '@mui/material';
import { USER1 } from 'assets/constants/imageConst';
import Avatar from 'components/common/Avatar/Avatar';

CommentResponse.propTypes = {
  size: PropTypes.string,
  name: PropTypes.string,
  updatedDate: PropTypes.string,
  comment: PropTypes.string,
  image: PropTypes.string,
};

CommentResponse.defaultProps = {
  size: 'small',
  name: 'John Doe',
  updatedDate: new Date(),
  comment: '',
  image: USER1,
  sx: { display: 'block' },
};

function CommentResponse({ comment, size, name, updatedDate, image, sx }) {
  return (
    <Box sx={sx}>
      <Box display="flex" sx={{ alignItems: 'center' }} mb={1}>
        <Avatar src={image} size={size} />
        <Typography variant="subtitle2" ml={1}>
          {name}
        </Typography>
        <Typography variant="body2" color="secondary" ml={0.5}>
          {moment(Number(updatedDate)).fromNow()}
        </Typography>
      </Box>
      <Box>
        <Typography variant="body2" ml={size === 'small' ? 4 : 5}>
          {comment}
        </Typography>
      </Box>
    </Box>
  );
}

export default CommentResponse;

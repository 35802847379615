import { IC_CALL, IC_INPUT, IC_MESSAGE, SHIELD, IC_DASHBOARD_ALT2, ACCEPT, TRANSFER, AVOID } from './imageConst';

export const identityOptions = [
  {
    name: 'Approve a request on my Microsoft Authenticator app',
    value: 'approve',
    image: SHIELD,
  },
  {
    name: 'Use a verification code from my mobile app',
    value: 'mobileApp',
    image: IC_INPUT,
  },
  {
    name: 'Text +X XXXXXXXX40',
    value: 'text',
    image: IC_MESSAGE,
  },
  {
    name: 'Call +X XXXXXXXX40',
    value: 'call',
    image: IC_CALL,
  },
];

export const dummyOptions = [
  {
    label: 'One Week',
    value: 'One Week',
  },
  {
    label: 'Two Week',
    value: 'Two Week',
  },
];

export const numberOfRecords = [
  { label: '< 100', value: '< 100' },
  { label: '101-250', value: '101-250' },
  { label: '251-499', value: '251-499' },
  { label: '500-1000', value: '500-1000' },
  { label: '1001-5000', value: '1001-5000' },
  { label: '5001-10000', value: '5001-10000' },
  { label: '10001-25000', value: '10001-25000' },
  { label: '25001-50000', value: '25001-50000' },
  { label: '50001-100000', value: '50001-100000' },
  { label: '> 100001', value: '> 100001' },
];

export const healthImpactScore = {
  '< 100': 3,
  '101-250': 4,
  '251-499': 5,
  '500-1000': 6,
  '1001-5000': 7,
  '5001-10000': 8,
  '10001-25000': 8,
  '25001-50000': 9,
  '50001-100000': 9,
  '> 100001': 10,
};

export const usersImpactScore = {
  '< 10': 1,
  '11-25': 2,
  '26-50': 3,
  '51-100': 4,
  '101-250': 5,
  '251-500': 6,
  '501-1000': 7,
  '1001-5000': 8,
  '5001-10000': 9,
  '> 10000': 10,
};

export const sensitiveDataImpactScore = {
  None: 1,
  PII: 5,
  PCI: 5,
  PHI: 7,
  'Company Confidential': 3,
  Other: 3,
  'Combination of PII/PCI': 8,
  'Combination of PII/PHI': 8,
};

export const numberOfUsers = [
  { label: '< 10', value: '< 10' },
  { label: '11-25', value: '11-25' },
  { label: '26-50', value: '26-50' },
  { label: '51-100', value: '51-100' },
  { label: '101-250', value: '101-250' },
  { label: '251-500', value: '251-500' },
  { label: '501-1000', value: '501-1000' },
  { label: '1001-5000', value: '1001-5000' },
  { label: '5001-10000', value: '5001-10000' },
  { label: '> 10000', value: '> 10000' },
];

export const sensitiveData = [
  { label: 'None', value: 'None' },
  { label: 'PII', value: 'PII' },
  { label: 'PCI', value: 'PCI' },
  { label: 'PHI', value: 'PHI' },
  { label: 'Company Confidential', value: 'Company Confidential' },
  { label: 'Other', value: 'Other' },
  { label: 'Combination of PII/PCI', value: 'Combination of PII/PCI' },
  { label: 'Combination of PII/PHI', value: 'Combination of PII/PHI' },
];

export const hostingModal = [
  { label: 'Cloud', value: 'Cloud' },
  { label: 'On-prem', value: 'On-prem' },
  { label: 'Hybrid', value: 'Hybrid' },
];

export const systemPriority = [
  { label: 'Level 1', value: 'Level 1' },
  { label: 'Level 2', value: 'Level 2' },
  { label: 'Level 3', value: 'Level 3' },
  { label: 'N/A', value: 'N/A' },
];
// Risk Response
export const riskResponse = [
  { label: 'Mitigate', value: '1', icon: IC_DASHBOARD_ALT2, color: 'warning', class: 'warning-warning' },
  { label: 'Accept', value: '2', icon: ACCEPT, color: 'success', class: 'warning-success' },
  { label: 'Transfer', value: '3', icon: TRANSFER },
  { label: 'Avoid', value: '0', icon: AVOID, color: 'error', class: 'warning-error' },
];

import { combineReducers } from 'redux';
import connectors from './connectors';
import riskAnalysis from './riskAnalysis';
import common from './common';
import breadcrumbs from './breadcrumbs';
import controlsLibrary from './controlsLibrary';
import documents from './documents';
import notes from './notes';
import form from './form';
import users from './users';
import controls from './controls';
import user from './user';
import tenant from './tenant';
import frameworks from './frameworks';

const Reducers = combineReducers({
  common,
  connectors,
  riskAnalysis,
  breadcrumbs,
  controlsLibrary,
  form,
  documents,
  notes,
  users,
  controls,
  user,
  tenant,
  frameworks
});

export default Reducers;

import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Divider,
  List,
  Typography,
  MenuItem,
  IconButton,
  Popover,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { USERS } from 'assets/constants/users';
import { Avatar } from 'components/common';
import Profile from 'components/Profile/Profile';
import { IC_LOGOUT, USER } from 'assets/constants/imageConst';
import Icon from 'components/common/Icon/Icon';
import sessionStorage from 'services/sessionStorage';
import { switchTenant, userPermission } from 'store/actions/me';

export default function AccountPopover({ profile_pic }) {
  const { userTenants, tenantData } = useSelector((state) => state.common);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { logout, user } = useAuth0();

  const handleLogout = () => {
    sessionStorage.clearAll();
    logout({
      returnTo: window.location.origin,
    });
    // navigate("/login");
  };
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  const handleSwitchTenant = (data) => {
    dispatch(switchTenant(data));
    dispatch(userPermission(data.id));
    handleClose();
    navigate('/dashboard');
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
        }}
      >
        <Avatar src={profile_pic || USERS[user?.email]} alt="photoURL" />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>
        {userTenants?.length > 1 && (
          <>
            <Divider sx={{ borderStyle: 'dashed' }} />

            <List
              sx={{
                maxHeight: 100,
                position: 'relative',
                overflow: 'auto',
                textAlign: 'center',
              }}
            >
              {userTenants.map((option) => (
                <MenuItem
                  key={option.site_name}
                  onClick={() => handleSwitchTenant(option)}
                  selected={option.site_name == tenantData?.site_name}
                >
                  <ListItemText>{option.site_name}</ListItemText>
                </MenuItem>
              ))}
            </List>
          </>
        )}

        <Divider sx={{ borderStyle: 'dashed' }} />
        <MenuItem
          onClick={() => {
            setIsModalOpen(true);
            setOpen(false);
          }}
          sx={{ m: 1 }}
        >
          <ListItemIcon>
            <Icon src={USER} size="small" />
          </ListItemIcon>
          <ListItemText>Account</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          <ListItemIcon>
            <Icon src={IC_LOGOUT} size="small" variant="secondary" />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Popover>
      {isModalOpen && <Profile handleClose={() => setIsModalOpen(false)} isModalOpen={isModalOpen} />}
    </>
  );
}

import { Box, styled } from '@mui/material';
import React from 'react';

const sizes = {
  small: '40px',
  default: '56px',
  large: '72px',
};

function ImageWrapper({ children, size = 'small', padding = '4px', background, ...props }) {
  const CustomBox = styled(Box)(({ theme }) => ({
    border: theme.palette.border.linearBorder,
    background,
    borderRadius: theme.palette.borderRadius.main,
    textAlign: 'center',
    padding,
    width: sizes[size],
    height: sizes[size],
    display: 'flex',
    '.svg-wrapper': {
      width: sizes[size],
    },
  }));

  return <CustomBox {...props}>{children}</CustomBox>;
}

export default ImageWrapper;

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

FlexBox.propTypes = {
  children: PropTypes.node,
};

function FlexBox({ children, sx }) {
  return <Box sx={{ display: 'flex', alignItems: 'center', ...sx }}>{children}</Box>;
}

export default FlexBox;

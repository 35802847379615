import React, { useState } from 'react';
import { Checkbox, List, ListItemButton, Box, ListItem, FormControlLabel, Typography } from '@mui/material';
// Internal
import { SearchInput } from 'components/common/FormControls';
import { NO_RECORD } from 'assets/constants/const';

const FilterList = ({ data, isSearchField, count = 5, handleFilterChange, handleSearch, searchValue }) => {
  const [showAll, setShowAll] = useState(false);

  return (
    <Box>
      {isSearchField && (
        <Box mt={0.5}>
          <SearchInput onChange={handleSearch} searchValue={searchValue} />
        </Box>
      )}
      <List disablePadding dense sx={{ mt: 1 }}>
        {data
          .filter((value) => value.display)
          .filter((value, index) => (showAll ? true : index < count))
          .map((item, index) => (
            <ListItem disablePadding key={index} dense>
              <ListItemButton disableGutters sx={{ py: 0 }}>
                <FormControlLabel
                  checked={item.selected}
                  sx={{ width: '100%' }}
                  control={<Checkbox size="small" disableRipple sx={{ py: 0.5 }} />}
                  onChange={(e) => handleFilterChange(item.name, e.target.checked)}
                  label={
                    <Box>
                      <Typography variant="body2"> {item.code}</Typography>
                    </Box>
                  }
                />
              </ListItemButton>
            </ListItem>
          ))}
        {!data.some((value) => value.display) && (
          <Box sx={{ textAlign: 'center', mt: 1 }}>
            <Typography variant="body2">{NO_RECORD}</Typography>
          </Box>
        )}
      </List>
      {!showAll && data.filter((v) => v.display).length > count && (
        <Box
          sx={{ cursor: 'pointer', color: 'text.link', py: 0.5 }}
          onClick={() => {
            setShowAll(true);
          }}
        >
          <Typography variant="progressLabel">Show all...</Typography>
        </Box>
      )}
    </Box>
  );
};
export default FilterList;

import api from './api';

class MeAPI {
  me() {
    return api.get('/me');
  }
  updateProfile(data) {
    return api.patch('/me', data);
  }
  
  changePassword(data) {
    return api.put(`/me`, data);
  }
}

export default MeAPI;

import * as actionTypes from '../actions/actionTypes';

export const defaultState = {
  error: null,
  loading: false,
  controlList: null,
  frameworkError: null,
  frameworkLoading: false,
  frameworkList: null,
  addControlLoading:false
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_CONTROLS_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case actionTypes.GET_CONTROLS_SUCCESS:
      return {
        ...state,
        loading: false,
        controlList: action.payload?.data?.payload,
      };

    case actionTypes.GET_CONTROLS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

      case actionTypes.ADD_UPDATE_CONTROLS_START:
        return {
          ...state,
          addControlLoading: true,
          error: null,
        };
  
      case actionTypes.ADD_UPDATE_CONTROLS_SUCCESS:
        return {
          ...state,
          addControlLoading: false,
        };
  
      case actionTypes.ADD_UPDATE_CONTROLS_ERROR:
        return {
          ...state,
          addControlLoading: false,
          error: action.payload,
        };
  
        case actionTypes.GET_FRAMEWORKS_START:
      return {
        ...state,
        frameworkError: null,
        frameworkLoading: true,
      };

    case actionTypes.GET_FRAMEWORKS_SUCCESS:
      return {
        ...state,
        frameworkLoading: false,
        frameworkList: action.payload?.data?.payload,
      };

    case actionTypes.GET_FRAMEWORKS_ERROR:
      return {
        ...state,
        frameworkLoading: false,
        frameworkError: action.payload,
      };

    default:
      return state;
  }
};

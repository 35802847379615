import React from 'react';

import { Box, Grid, Typography, InputAdornment } from '@mui/material';
import Input from 'components/common/FormField/Input/Input';
import { numberOfUsers, numberOfRecords, sensitiveData, hostingModal, systemPriority } from 'assets/constants/options';
import Dropdown from 'components/common/FormField/Select/Dropdown';
import { IC_TARGET } from 'assets/constants/imageConst';
import ProgressCard from 'components/common/Card/ProgressCard';
import { Chip } from 'components/common';
import storage from 'services/storage';
import { impactWithRecords, impactWithSensitiveData, impactWithUsers } from 'assets/constants/const';
import { UP_ARROW } from 'assets/constants/imageConst';
import { ReactSVG } from 'react-svg';


const GDriveInheritRiskForm = ({ formik }) => {
  const impactScore = storage.get('gSuite_impact_score');
  return (
    <Grid container spacing={0}>
      <Grid sm={4} sx={12}>
        <Box mb={3} mr={3}>
          <Typography variant="subtitle1" mb={1}>
            Inherent risk
          </Typography>
          <Typography variant="body2" color="text.secondary">
            The following data will be used to assess the impact score of the system for purposes of risk analysis.
          </Typography>
        </Box>
        <Box mt={3} mr={3}>
          <ProgressCard icon={IC_TARGET} title="Aggregate impact score" progress={impactScore || 'NA'} />
        </Box>
      </Grid>
      <Grid item sx={12} sm={8}>
      <Box mb={3}>
      <Input
            id="impactScore"
            className="mb-3"
            fullWidth
            label="Impact Score"
            name="impactScore"
            placeholder="N/A"
            info="We automatically calculate your application impact scores depending on the application below. You can also override this field by entering any value between 1 and 10."
            value={formik?.values?.impactScore}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <ReactSVG
                    src={UP_ARROW}
                    className="down-arrow"
                    onClick={() => {
                      formik.setFieldValue('impactScore', typeof formik?.values.impactScore == 'number' ? formik?.values.impactScore + 1 : 1);
                    }}
                  />
                  <ReactSVG
                    src={UP_ARROW}
                    onClick={() => {
                      formik.setFieldValue(
                        'impactScore',
                        formik?.values.impactScore && formik?.values.impactScore > 1 ? formik?.values.impactScore - 1 : 1
                      );
                    }}
                  />
                </InputAdornment>
              ),
            }}
            // onChange={formik?.handleChange}
            error={formik.touched.impactScore && Boolean(formik.errors.impactScore)}
            helperText={formik.touched.impactScore && formik.errors.impactScore}
          />
      </Box>

        <Box mb={2}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} mb={1}>
            <Typography variant="subtitle2">Number of records</Typography>
            <Chip
              label={`Impact: ${impactWithRecords[formik.values.numberOfHealthRecords] || 'N/A'}`}
              color="primary"
            />
          </Box>
          <Dropdown
            options={numberOfRecords}
            name="numberOfHealthRecords"
            formik={formik}
            placeholder="Select an option"
          />
        </Box>
        <Box mb={2}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} mb={1}>
            <Typography variant="subtitle2"> Type of sensitive data</Typography>
            <Chip label={`Impact: ${impactWithSensitiveData[formik.values.sensitiveData] || 'N/A'}`} color="primary" />
          </Box>
          <Dropdown options={sensitiveData} name="sensitiveData" formik={formik} placeholder="Select an option" />
        </Box>
        <Box mb={2}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} mb={1}>
            <Typography variant="subtitle2"> Number of users</Typography>
            <Chip label={`Impact: ${impactWithUsers[formik.values.numberOfUserRecords] || 'N/A'}`} color="primary" />
          </Box>
          <Dropdown options={numberOfUsers} name="numberOfUserRecords" formik={formik} placeholder="Select an option" />
        </Box>
        <Box mb={2}>
          <Typography mb={1} variant="subtitle2">
            System hosting model
          </Typography>
          <Dropdown options={hostingModal} name="hostingModal" formik={formik} placeholder="Select an option" />
        </Box>
        <Box mb={2}>
          <Typography mb={1} variant="subtitle2">
            System priority
          </Typography>
          <Dropdown options={systemPriority} name="systemPriority" formik={formik} placeholder="Select an option" />
        </Box>
      </Grid>
    </Grid>
  );
};

export default GDriveInheritRiskForm;

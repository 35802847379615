import { ALEX, DANIEL, VINIT } from './imageConst';

export const USERS = {
  'vainik.kakadiya+1212@sculptsoft.com': VINIT,
  'vineet+staging@humanagency.com': VINIT,
  'daniel+staging@humanagency.com': DANIEL,
  'akshay.patel@sculptsoft.com': DANIEL,
  'alex+staging@humanagency.com': ALEX,
  'masten+staging@humanagency.com': ALEX,
  'nirali.joshi+07@sculptsoft.com': ALEX,
};

import api from './api';

class UserAPI {

  getUser(uuid,params) {
    let queryParams = '';
    if (params) {
      queryParams =
        Object.keys(params)
          .map(key => {
            return `${key}=${encodeURIComponent(params[key])}`;
          }).join('&');
    }
    return api.get(`/user/${uuid}?${queryParams}`);
  }

  AddUser(data) {
    return api.post('/users', data);
  }

  updateUser(uuid, data) {
    return api.put(`/user/${uuid}`, data);
  }

  resetPassword(uuid, data) {
    return api.post(`/user/${uuid}/reset-password`, data);
  }

  deleteUser(uuid) {
    return api.delete(`/user/${uuid}`);
  }

  assignRoles(data) {
    return api.post('/user/assign-roles', data);
  }


}

export default UserAPI;

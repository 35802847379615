import { ReactSVG } from 'react-svg';

import { Box, Divider, styled, Typography } from '@mui/material';
import { Chip } from 'components/common';
import { IC_CALENDER, USER1 } from 'assets/constants/imageConst';
import Avatar from 'components/common/Avatar/Avatar';

const CustomBox = styled(Box)(({ theme }) => ({
  border: theme.palette.border.linearBorder,
  borderRadius: theme.palette.borderRadius.main,
}));

const TaskBox = ({ title, subtitle, date, status, name }) => {
  return (
    <CustomBox mb={2}>
      <Box p={2}>
        <Chip label={status} color="warning" sx={{ mr: 0.75 }} />
        <Chip label={date} icon={<ReactSVG src={IC_CALENDER} />} />
        <Box mt={2}>
          <Typography variant="subtitle2" mb={0.5}>
            {title}
          </Typography>
          <Typography variant="body2" color="secondary">
            {subtitle}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box p={2} sx={{ display: 'flex', alignItems: 'center', columnGap: '5px' }}>
        <Avatar src={USER1} />
        <Typography variant="body2" color="secondary">
          {name}
        </Typography>
      </Box>
    </CustomBox>
  );
};
export default TaskBox;

import { Box, Typography } from '@mui/material';
import React from 'react';

function Title({ title, subtitle }) {
  return (
    <Box>
      <Typography variant="subtitle1">{title}</Typography>
      {subtitle && (
        <Typography variant="body2" color="secondary" mt={0.5}>
          {subtitle}
        </Typography>
      )}
    </Box>
  );
}

export default Title;

import { Badge, Box, Grid, Typography } from '@mui/material';
import ImageWrapper from 'components/common/Wrapper/ImageWrapper';
import WrapperBox from 'components/common/Wrapper/WrapperBox';
import React from 'react';
import { Link } from 'react-router-dom';

const getColorFromStatus = (status) => {
  if (status === 'success') {
    return 'success';
  } else if (status === 'failure' || status === 'processing') {
    return 'warning';
  }
};

function ConnectCard({ secondaryButton, primaryButton, logo, title, description, byName, status }) {
  return (
    <WrapperBox>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={4}>
          <Box mr={3}>
            <ImageWrapper background="#fff" size="default" padding="12px">
              <img src={logo} width="100%" height="100%" />
            </ImageWrapper>
            <Box mb={3} mt={2}>
              <Typography variant="h6" mb={0.5}>
                {title}{' '}
                {status && (
                  <Badge color={getColorFromStatus(status)} sx={{ left: '4px', top: '-2px' }} variant="dot"></Badge>
                )}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {byName && 'by '}
                <Typography component={Link} color="primary">
                  {byName}
                </Typography>
              </Typography>
            </Box>
            <Box mb={1}>
              <Typography variant="body2" color="text.secondary" mb={1}>
                {description}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <Box mr={2}>{secondaryButton}</Box>
            <Box>{primaryButton}</Box>
          </Box>
        </Grid>
      </Grid>
    </WrapperBox>
  );
}

export default ConnectCard;

import { CircularProgress } from '@mui/material';
import React, { useState, useEffect, memo } from 'react';
import { useDispatch } from 'react-redux';

import { getFiles } from 'store/actions/connectors';
import Avatar from './Avatar/Avatar';
import { Avatar as MUIAvatar } from '@mui/material';

const AsyncImage = ({ fileName, isAvatar = true }) => {
  const dispatch = useDispatch();
  const [imagePath, setImagePath] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (fileName) {
      dispatch(getFiles(fileName)).then((res) => {
        setLoading(false);
        setImagePath(res?.value?.data?.payload?.data);
      });
    } else {
      setLoading(false);
    }
  }, []);

  return loading ? (
    <MUIAvatar sx={{ width: 24, height: 24 }}>
      <CircularProgress size={24} />
    </MUIAvatar>
  ) : isAvatar ? (
    <Avatar size="small" src={imagePath} alt="Profile Pic" />
  ) : (
    <img src={imagePath} className="svg-wrapper" />
  );
};
export default memo(AsyncImage);

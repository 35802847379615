import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { IC_PLUS } from 'assets/constants/imageConst';
// MUI
import { Box } from '@mui/material';
// Internal
import { Button } from 'components/common/FormControls';

import TenantTable from './TenantTable';
import { breadcrumbsData } from 'assets/constants/const';
import { setBreadcrumbs } from 'store/actions/breadcrumbs';
import { SwipeableDrawer } from 'components/common';
import { getTenant, updateTenant } from 'store/actions/tenant';
import AddTenantModal from './AddTenantModal';

const TenantManagment = () => {
  const dispatch = useDispatch();
  const [dataPerPage, setDataPerPage] = useState(10);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [addModalData, setAddModalData] = useState(null);

    useEffect(() => {
        dispatch(setBreadcrumbs(breadcrumbsData.tenantManagment));
        dispatch(getTenant());
    }, []);

    const getTenantList = (params) => {
        dispatch(getTenant(params))
    }

    const handleAddModal = (value, obj) => {
        setOpenAddModal(value);
        setAddModalData(obj);
    }

    const handleDelete = (row) => {
        const is_deleted = row?.is_deleted == true?"false":"true";

        dispatch(updateTenant(row?.uuid,{'data':{
            uuid:row?.uuid,
            is_deleted
        }})).then(() => {
            toast.success('Tenant deleted successfully');
            callTenantList();
        })
    }
    const callTenantList = () => {
        setDataPerPage(10);
        dispatch(getTenant());
    }

    return (
        <>
            <Box sx={{ position: 'relative' }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} mb={3} mt={3}>
                    <Box>
                        <Button
                            startIcon={<ReactSVG src={IC_PLUS} />}
                            variant="filled"
                            onClick={() => { handleAddModal(true, null) }}
                        >
                            Add Tenant
                        </Button>
                    </Box>
                </Box>
                <TenantTable
                    getTenantList={getTenantList}
                    dataPerPage={dataPerPage}
                    setDataPerPage={setDataPerPage}
                    handleDelete={handleDelete}
                    handleAddModal={handleAddModal}
                />
            </Box>
            {openAddModal &&
                <SwipeableDrawer
                    anchor="right"
                    open={openAddModal}
                    onclose={() => handleAddModal(false)}
                    onopen={() => { }}
                    isFooter={true}
                    title={_.isNil(addModalData) ? 'Add tenant' : 'Update tenant'}
                    onSave={() => { }}
                    cancelText="Close"
                >
                    <AddTenantModal
                        tenantDetail={addModalData}
                        openAddModal={openAddModal}
                        callTenantList={callTenantList}
                        handleAddModal={handleAddModal}


                    />
                </SwipeableDrawer>
            }
        </>
    );
};
export default TenantManagment;

import React from 'react';
import Icon from 'components/common/Icon/Icon';
import { IC_ARROW_DOWN, IC_PLUS } from 'assets/constants/imageConst';
import { Box, Button, Divider, FormControl, Input, ListItemText, MenuItem, Select } from '@mui/material';

class ButtonDropdown extends React.Component {
  state = {
    selected: '',
    open: false,
  };

  handleChange = (event) => {
    this.setState({ selected: event.target.value });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  render() {
    return (
      <FormControl>
        <Button
          id="openMenu"
          onClick={this.handleOpen}
          variant="contained"
          size="small"
          startIcon={<Icon src={IC_PLUS} size="small" />}
          endIcon={<Icon src={IC_ARROW_DOWN} size="small" />}
          sx={{
            '& .MuiButton-endIcon': { marginLeft: '5px' },
            svg: {
              path: { stroke: '#fff !important', strokeWidth: '2 !important', fill: '#fff !important', fillOpacity: 1 },
            },
            borderRadius: '8px',
            padding: '4px 8px',
          }}
        >
          Add
          <Divider
            orientation="vertical"
            variant="middle"
            style={{ backgroundColor: '#d9d5d5', marginLeft: '5px', height: '9px' }}
            flexItem
          />
        </Button>
        <Select
          // multiple
          value={this.state.selected}
          onChange={this.handleChange}
          input={<Input id="select-multiple-checkbox" />}
          sx={{ display: 'none' }}
          open={this.state.open}
          onClose={this.handleClose}
          MenuProps={{
            anchorEl: document.getElementById('openMenu'),
            style: { marginTop: 1, marginLeft: '-15px' },
          }}
        >
          {this.props.options.map((item, index) => (
            <MenuItem
              disableRipple
              sx={{
                paddingTop: 0,
                paddingBottom: 0,
                borderBottom: this.props.options.length !== index + 1 ? '0.5px solid #919eab3d' : '',
              }}
              key={item + index}
              value={item}
              onClick={item.click}
            >
              {item.icon && (
                <Box mr={2}>
                  <Icon src={item.icon} size="small" />
                </Box>
              )}
              <ListItemText primary={item.label} sx={{ py: 0.75 }} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

export default ButtonDropdown;

import { get } from 'lodash';
const FULL_ACCESS = 'full-access';
const READ_ONLY = 'read-only';
const HIDDEN = 'hidden';
export const DASHBOARD = 'dashboard';
export const RISK_AI_LIGHTENING = 'risk_ai_lightening';
export const COMPLIANCE = 'compliance';
export const RISK_ANALYSIS = 'risk_analysis';
export const RISK_MANAGMENT = 'risk_managment';
export const TECHNICAL_TESTING = 'technical_testing';
export const THIRD_PARTIES = 'third_parties';
export const USER_MANAGMENT = 'user_managment';
export const TENANT_MANAGMENT = 'tenant_managment';

export const CONTROL_MANAGEMENT = 'control_management';
export const CONTROL_MANAGEMENT_CONTROLS = 'control_management.controls';
export const CONTROL_MANAGEMENT_FRAMEWORKS = 'control_management.frameworks';

export const RISK_AI_LIGHTENING_INTEGRATIONS = 'risk_ai_lightening.integrations';
export const RISK_AI_LIGHTENING_INTEGRATIONS_ADD_CUSTOM_CONNECTOR =
  'risk_ai_lightening.integrations.add_custom_connecter';
export const RISK_AI_LIGHTENING_INTEGRATIONS_CONNECT = 'risk_ai_lightening.integrations.connect';
export const RISK_AI_LIGHTENING_INTEGRATIONS_DELETE = 'risk_ai_lightening.integrations.delete';
export const RISK_AI_LIGHTENING_INTEGRATIONS_EDIT = 'risk_ai_lightening.integrations.edit';

export const RISK_AI_LIGHTENING_WORKSPACE_EDIT = 'risk_ai_lightening.workspace.edit';
export const RISK_AI_LIGHTENING_WORKSPACE_DELETE = 'risk_ai_lightening.workspace.delete';
export const RISK_AI_LIGHTENING_INTEGRATIONS_WORKSPACE_INTEGRATION_RUNS_RUN_INTEGRATION =
  'risk_ai_lightening.workspace.integration_runs.run_integration';

export const COMPLIANCE_HIPPA = 'compliance.hippa';
export const COMPLIANCE_SOC = 'compliance.soc';
export const COMPLIANCE_HITRUST = 'compliance.hitrust';
export const COMPLIANCE_PCI = 'compliance.pci';
export const COMPLIANCE_ISO = 'compliance.iso';

export const RISK_ANALYSIS_INFORMATION_SYSTEMS = 'risk_analysis.information_systems';
export const RISK_ANALYSIS_INFORMATION_SYSTEMS_CONNECTOR_UPDATE_RISK_SCORE =
  'risk_analysis.information_systems.connector.update_risk_score';
export const RISK_ANALYSIS_INFORMATION_SYSTEMS_CONNECTOR_RUN_RISK_ANALYSIS =
  'risk_analysis.information_systems.connector.run_risk_analysis_report';
export const RISK_ANALYSIS_INFORMATION_SYSTEMS_CONNECTOR_RESPONSE =
  'risk_analysis.information_systems.connector.response';
export const RISK_ANALYSIS_INFORMATION_SYSTEMS_CONNECTOR_EDIT_DOCUMENT =
  'risk_analysis.information_systems.connector.edit_document';
export const RISK_ANALYSIS_INFORMATION_SYSTEMS_CONNECTOR_EDIT_NOTES =
  'risk_analysis.information_systems.connector.edit_notes';

export const RISK_ANALYSIS_IT_ASSESTS = 'risk_analysis.it_assets';
export const RISK_ANALYSIS_CONTROL_LIBRARY = 'risk_analysis.control_library';
export const RISK_ANALYSIS_CONTROL_LIBRARY_RESPONSE = 'risk_analysis.control_library.response';

export function hasPermission(allowedPemissions, permission) {
  const access = get(allowedPemissions, permission, 'hidden');
  return new Set([FULL_ACCESS, READ_ONLY]).has(access) ? true : false;
}

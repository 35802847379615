import { Button as MUIButton, styled } from '@mui/material';

const Button = ({ children, ...rest }) => {
  const CustomButton = styled(MUIButton)(({ theme }) => ({
    '&.MuiButtonBase-root': {
      '&.MuiButton-filled': {
        '&.MuiButton-filledPrimary': {
          background: theme.palette.primary.main,
          color: theme.palette.common.white,
          '&:hover': {
            background: theme.palette.primary.dark,
          },
          '&.Mui-disabled': {
            opacity: 0.5,
          },
          '.MuiButton-startIcon': {
            marginLeft: 0,
            marginRight: '4px',
            lineHeight: 0,
            svg: {
              width: '16px',
              height: '16px',
              path: {
                fill: `${theme.palette.common.white} !important`,
                stroke: `${theme.palette.common.white} !important`,
                fillOpacity: 1,
              },
            },
          },
          '.MuiButton-endIcon': {
            marginLeft: '4px',
            marginRight: 0,
            lineHeight: 0,
            svg: {
              width: '16px',
              height: '16px',
              path: {
                fill: theme.palette.common.white,
                fillOpacity: 1,
              },
            },
          },
        },
        '&.MuiButton-filledSecondary': {
          background: theme.palette.background.linear,
          color: theme.palette.primary.main,
          '&:hover': {
            background: theme.palette.background.linear2,
          },
          '&.Mui-disabled': {
            opacity: 0.5,
          },
          '.MuiButton-startIcon': {
            marginLeft: 0,
            marginRight: '4px',
            lineHeight: 0,
            svg: {
              width: '16px',
              height: '16px',
              path: {
                fill: theme.palette.primary.main,
              },
            },
          },
          '.MuiButton-endIcon': {
            marginLeft: '4px',
            marginRight: 0,
            lineHeight: 0,
            svg: {
              width: '16px',
              height: '16px',
              path: {
                fill: theme.palette.primary.main,
              },
            },
          },
        },
      },
      '&.MuiButton-outlined': {
        '&.MuiButton-outlinedSecondary': {
          '.MuiButton-startIcon': {
            marginLeft: 0,
            marginRight: '4px',
            lineHeight: 0,
            svg: {
              width: '16px',
              height: '16px',
              path: {
                fill: `${theme.palette.secondary.main} !important`,
              },
              polyline: {
                fill: `${theme.palette.secondary.main} !important`,
              },
            },
          },
          '.MuiButton-endIcon': {
            marginLeft: '4px',
            marginRight: 0,
            lineHeight: 0,
            svg: {
              width: '16px',
              height: '16px',
              path: {
                fill: `${theme.palette.secondary.main} !important`,
              },
              polyline: {
                fill: `${theme.palette.secondary.main} !important`,
              },
            },
          },
        },
      },
      '&.MuiButton-sizeSmall': {
        padding: '4px 8px',
        borderRadius: '8px',
        fontSize: '12px',
        fontWeight: '500',
        lineHeight: '15px',
      },
      '&.MuiButton-sizeMedium': {
        padding: '8px 14px',
        borderRadius: '10px',
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '17px',
      },

      '&.MuiButton-sizeLarge': {
        padding: '8px 20px',
        borderRadius: '10px',
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '19px',
      },
    },
  }));
  return <CustomButton {...rest}>{children}</CustomButton>;
};

export default Button;

// ----------------------------------------------------------------------

export function remToPx(value) {
  return Math.round(parseFloat(value) * 16);
}

export function pxToRem(value) {
  return `${value / 16}rem`;
}

export function responsiveFontSizes({ sm, md, lg }) {
  return {
    '@media (max-width:600px)': {
      fontSize: pxToRem(sm),
    },
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm),
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md),
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg),
    },
  };
}

// ----------------------------------------------------------------------

const FONT_PRIMARY = 'Cabin'; // Google Font
// const FONT_SECONDARY = 'CircularStd, sans-serif'; // Local Font

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  color: '#4F4A55',
  h1: {
    fontWeight: 700,
    lineHeight: '116.64px',
    fontSize: '96px',
    ...responsiveFontSizes({ sm: 96, md: 96, lg: 96 }),
  },
  h2: {
    fontWeight: 700,
    lineHeight: 64 / 48,
    fontSize: '60px',
    ...responsiveFontSizes({ sm: 60, md: 60, lg: 60 }),
  },
  h3: {
    fontWeight: 700,
    lineHeight: '58.32px',
    fontSize: '48px',
    ...responsiveFontSizes({ sm: 48, md: 48, lg: 48 }),
  },
  h4: {
    fontWeight: 700,
    lineHeight: '41.31px',
    fontSize: '34px',
    ...responsiveFontSizes({ sm: 24, md: 34, lg: 34 }),
  },
  h5: {
    fontWeight: 700,
    lineHeight: '29.16px',
    fontSize: '24px',
    ...responsiveFontSizes({ sm: 24, md: 24, lg: 24 }),
  },
  h6: {
    fontWeight: 700,
    lineHeight: '24.3px',
    fontSize: '20px',
    ...responsiveFontSizes({ sm: 20, md: 20, lg: 20 }),
  },
  subtitle1: {
    fontWeight: 600,
    lineHeight: '19.44px',
    fontSize: '16px',
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: '15.8px',
    fontSize: '13px',
  },
  body1: {
    lineHeight: '19.44px',
    fontSize: '16px',
  },
  body2: {
    fontWeight: 400,
    lineHeight: '15.8px',
    fontSize: '13px',
  },
  caption: {
    lineHeight: '13.3px',
    fontSize: '11px',
  },
  overline: {
    fontWeight: 400,
    lineHeight: '12.15px',
    fontSize: '10px',
    textTransform: 'uppercase',
  },
  progressLabel: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '14.58px',
  },
  button: {
    fontWeight: 700,
    lineHeight: 24 / 14,
    fontSize: '14px',
    textTransform: 'capitalize',
  },
  italic32: {
    fontFamily: 'Crimson Pro',
    fontStyle: 'italic',
    fontWeight: 300,
    fontSize: '32px',
    lineHeight: '36px',
  },
  italic20: {
    fontFamily: 'Crimson Pro',
    fontStyle: 'italic',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '24.3px',
    textTransform: 'capitalize',
  },
};

export default typography;

import React from 'react';
import { Box, Typography } from '@mui/material';
import { ReactSVG } from 'react-svg';
import { Tooltip } from '../FormControls';
import Avatar from '../Avatar/Avatar';
import InfoIconComponent from '../InfoIcon/InfoIconComponent';
import WrapperCard from '../Wrapper/WrapperCard';

function ProgressCard({ icon, title, tooltip, chip, progress, button }) {
  return (
    <WrapperCard sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <Avatar size="small" bg="primary">
          <ReactSVG src={icon} />
        </Avatar>
        <Typography variant="body2" color="secondary" ml={1}>
          {title}
        </Typography>
        {tooltip && (
          <Tooltip title={tooltip} arrow placement="top">
            <InfoIconComponent ml={0.7} />
          </Tooltip>
        )}
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {progress && (
          <Typography variant="subtitle1" mr={1}>
            {progress}
          </Typography>
        )}
        {button && button}
        {chip}
      </Box>
    </WrapperCard>
  );
}

ProgressCard.defaultProps = {
  title: '',
  icon: null,
  tooltip: '',
  progress: '',
  chip: null,
};

export default React.memo(ProgressCard);

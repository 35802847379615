import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { CUSTOM_CONNECTOR, IC_DUSTBIN, IC_EDIT } from 'assets/constants/imageConst';
import { breadcrumbsData, connectorData, connectorsName } from 'assets/constants/const';
import { Tabs } from 'components/common';
import { Button, Tooltip } from 'components/common/FormControls';
import { deleteConnector, getIntegrationDetail } from 'store/actions/connectors';
import ConnectCard from '../Cards/ConnectCard';
import IntegrationDetail from '../IntegrationDetails/IntegrationDetail';
import IntegrationTable from '../IntegrationTable/IntegrationTable';
import CommonLoader from 'components/common/Loader/CommonLoader';
import { setBreadcrumbs } from 'store/actions/breadcrumbs';
import ConfirmationModal from 'components/common/Modal/ConfirmationModal';
import ROUTE from 'assets/constants/route';
import RBAC from 'components/RBAC';
import { RISK_AI_LIGHTENING_WORKSPACE_DELETE, RISK_AI_LIGHTENING_WORKSPACE_EDIT } from 'utils/permissionUtils';

function WorkspaceDetailPage() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const { getWorkspace, isDetailLoading, integrationDetail, deleteIntegration, loading, connectors } = useSelector(
    (state) => state.connectors
  );

  // Custom Connector
  const isCustomConnector = integrationDetail?.data?.type == connectorsName.CUSTOM;

  const customConnectorData = {
    logo: CUSTOM_CONNECTOR,
    title: integrationDetail?.data.tenant_connector_name,
    description: '',
    byName: integrationDetail?.data?.connector_name,
    setting: `These informations will be used to connect with your ${integrationDetail?.connector_name} environment.`,
    pdf: '',
    userConfig: integrationDetail?.data?.connector_name,
    connectingText: integrationDetail?.data?.connector_name,
  };

  const customCOnnectorBreadcrums = [
    { path: ROUTE.LIGHTNING_INTEGRATION, label: 'RiskAI Lightening' },
    { path: '', label: integrationDetail?.data?.tenant_connector_name },
  ];

  const workSpaceName = integrationDetail?.data?.connector_name;

  const isDisable =
    workSpaceName === connectorsName.GOOGLE && connectors?.some((con) => con.type_name === connectorsName.GDRIVE);

  const workSpaceData = isCustomConnector ? customConnectorData : connectorData[workSpaceName];
  const uuid = params?.id;

  const bc = isCustomConnector ? customCOnnectorBreadcrums : breadcrumbsData.lightningConnectors[workSpaceName];

  useEffect(() => {
    if (deleteIntegration) {
      navigate(ROUTE.LIGHTNING_INTEGRATION);
    }
  }, [deleteIntegration]);

  useEffect(() => {
    if (bc) {
      dispatch(setBreadcrumbs(bc));
    }
  }, [workSpaceName]);

  useEffect(() => {
    dispatch(getIntegrationDetail(uuid));
  }, []);

  const handleEdit = () => {
    navigate(`/lightening/edit/${uuid}`);
  };

  const handleDelete = () => {
    setOpen(true);
  };

  const handleSubmitModal = () => {
    dispatch(deleteConnector(uuid));
    setOpen(false);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const tabInfo = [
    {
      id: 0,
      label: <Typography variant="subtitle2"> Integration details</Typography>,
      component: (
        <IntegrationDetail
          formDetails={integrationDetail?.data}
          settingDescription={workSpaceData?.setting}
          pdf={workSpaceData?.pdf}
          workSpaceName={workSpaceName}
          isCustomConnector={isCustomConnector}
        />
      ),
    },
    {
      id: 1,
      label: <Typography variant="subtitle2">Integration runs</Typography>,
      component: (
        <Box mx={3}>
          <IntegrationTable />
        </Box>
      ),
    },
  ];

  let workspaceStatus =
    getWorkspace?.data && getWorkspace.data.length > 0 && getWorkspace?.data.some((value) => value.status !== 'success')
      ? 'processing'
      : 'success';

  if (isDetailLoading || loading || deleteIntegration) {
    return <CommonLoader height="100vh" />;
  }

  if (!workSpaceData) {
    return <></>;
  }

  return (
    <Box>
      <Grid container spacing={0} mb={4}>
        <Grid item xs={12}>
          <ConnectCard
            logo={workSpaceData.logo}
            title={workSpaceData.title}
            byName={workSpaceData.byName}
            status={workspaceStatus}
            description={workSpaceData.description}
            secondaryButton={
              <RBAC
                action={RISK_AI_LIGHTENING_WORKSPACE_DELETE}
                yes={
                  isDisable ? (
                    <Tooltip title="Delete GDrive connection first" width="100px">
                      <div>
                        <IconButton aria-label="delete" color="primary" onClick={handleDelete} disabled={isDisable}>
                          <ReactSVG src={IC_DUSTBIN} />
                        </IconButton>
                      </div>
                    </Tooltip>
                  ) : (
                    <IconButton aria-label="delete" color="primary" onClick={handleDelete} disabled={isDisable}>
                      <ReactSVG src={IC_DUSTBIN} />
                    </IconButton>
                  )
                }
              />
            }
            primaryButton={
              <RBAC
                action={RISK_AI_LIGHTENING_WORKSPACE_EDIT}
                yes={
                  <Button
                    variant="filled"
                    color="secondary"
                    startIcon={<ReactSVG src={IC_EDIT} />}
                    onClick={handleEdit}
                  >
                    Edit
                  </Button>
                }
              />
            }
          />
        </Grid>
      </Grid>
      <Tabs tabInfo={tabInfo} />
      <ConfirmationModal
        title="Are you sure you want to delete this connector?"
        open={isOpen}
        handleClose={handleCloseModal}
        handleSubmit={handleSubmitModal}
      />
    </Box>
  );
}

export default WorkspaceDetailPage;

import { createStore, compose, applyMiddleware } from 'redux';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import promise from 'redux-promise-middleware';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import appReducers from './reducers/index';

function configureStoreProd(initialState) {
  const middlewares = [thunkMiddleware, promise];

  return createStore(appReducers, initialState, compose(applyMiddleware(...middlewares)));
}

function configureStoreDev(initialState) {
  const loggerMiddleware = createLogger({
    level: 'info',
    collapsed: true,
    predicate: () => true,
  });

  const middlewares = [thunkMiddleware, promise, loggerMiddleware, reduxImmutableStateInvariant()];

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(appReducers, initialState, composeEnhancers(applyMiddleware(...middlewares)));

  return store;
}

export const store = process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev;

export default store;

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/system';
import { styled } from '@mui/material';

WrapperBox.propTypes = {
  padding: PropTypes.string,
  background: PropTypes.string,
};

WrapperBox.defaultProps = {
  padding: '24px',
  background: 'whiteShade5',
};

function WrapperBox({ children, padding, background, ...rest }) {
  const CustomBox = styled(Box)(({ theme }) => ({
    padding: padding,
    borderRadius: '8px',
    background: theme.palette.background[background],
  }));
  return <CustomBox {...rest}>{children}</CustomBox>;
}

export default WrapperBox;

import { Area, AreaChart, ResponsiveContainer } from 'recharts';

import { ListItem, ListItemText, ListItemAvatar, Grid, Typography, Box, styled } from '@mui/material';
import { ELLIPSE, IC_CALENDER, IC_UP_TREND } from 'assets/constants/imageConst';
import { ReactSVG } from 'react-svg';
import { Chip } from 'components/common';

const data = [
  {
    uv: 0,
  },
  {
    uv: 0.7,
  },
  {
    uv: 1.8,
  },
  {
    uv: 2.5,
  },
  {
    uv: 2.4,
  },
  {
    uv: 3,
  },
  {
    uv: 3.5,
    pv: 3.5,
  },

  {
    pv: 9,
  },
  {
    pv: 9.8,
  },
  {
    pv: 10,
  },
];

const CustomBox = styled(Box)(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '18px',
    borderRadius: theme.palette.borderRadius.main,
    border: theme.palette.border.solidBorder,
    marginBottom: theme.spacing(3),
  };
});

const listViewData = [
  {
    icon: ELLIPSE,
    primaryText: 'Add multi-factor authentication on 15 laptops',
    secondaryText: '55 Assets',
    date: '11/30',
    points: '5 pts',
  },
  {
    icon: ELLIPSE,
    primaryText: 'Install anti-virus on 13 database servers',
    secondaryText: '55 Assets',
    date: '12/31',
    points: '3 pts',
  },
  {
    icon: ELLIPSE,
    primaryText: 'Change credentials on 27 laptops',
    secondaryText: '55 Assets',
    date: '10/31',
    points: '12 pts',
  },
];

const List = () => {
  return (
    <Box>
      <CustomBox>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box p={2}>
              <Typography variant="body2" color="secondary" mb={2}>
                Predictive posture score
              </Typography>
              <Typography variant="h6" mb={0.5}>
                87%
              </Typography>
              <Typography variant="body2" color="secondary">
                By end of December 2022
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* Area Graph start*/}
            <ResponsiveContainer width="100%" height={'100%'}>
              <AreaChart data={data} margin={{ top: 10, left: 0, bottom: 0, right: 0 }}>
                <defs>
                  <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="100%" stopColor="#36C39533" stopOpacity={0.8} />
                    <stop stopColor="#36C39533" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <Area type="monotone" dataKey="uv" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
                <Area
                  type="monotone"
                  dataKey="pv"
                  stroke="#82ca9d"
                  fillOpacity={1}
                  fill="url(#colorPv)"
                  strokeDasharray="3 3"
                  dot={{ strokeWidth: 1, r: 2, strokeDasharray: '' }}
                />
              </AreaChart>
            </ResponsiveContainer>
            {/* Area Graph end*/}
          </Grid>
        </Grid>
      </CustomBox>
      {listViewData.map((item, index) => (
        <ListItem
          key={index}
          divider={true}
          sx={{ pl: '0', pr: '0' }}
          secondaryAction={<Chip icon={<ReactSVG src={IC_UP_TREND} />} label={item.points} color="success" />}
        >
          <ListItemAvatar style={{ minWidth: '30px' }}>
            <ReactSVG src={item.icon} />
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography variant="body2" mb={1}>
                {item.primaryText}
              </Typography>
            }
            secondary={
              <Typography variant="body2" color="secondary">
                Due date: <Chip label={item.date} icon={<ReactSVG src={IC_CALENDER} />} />
              </Typography>
            }
          />
        </ListItem>
      ))}
    </Box>
  );
};
export default List;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';

import { Box, Typography, Menu, MenuItem, styled, TextField, IconButton } from '@mui/material';

// Internal
import CommonLoader from 'components/common/Loader/CommonLoader';
import AsyncImage from 'components/common/AsyncImage';
import DashedBox from 'components/common/Wrapper/DashedBox';
import UpdatedDate from 'components/common/UpdatedDate/UpdatedDate';
import ConfirmationModal from 'components/common/Modal/ConfirmationModal';

import { deleteNotes, updateNotes } from 'store/actions/connectors';
import { IC_CHAT_ALT3, IC_FILE_CHECK, IC_HORIZONTAL_DOTS } from 'assets/constants/imageConst';
import CommentBox from '../ManageRisk/Comments/CommentBox';
const CONTROL_LIBRARY = 'CONTROL_LIBRARY';

const NotesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
}));

const NoteList = styled(Box)(() => ({
  display: 'flex',
  columnGap: '16px',
}));

const ContentWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '8px',
  width: '100%',
}));

const HeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  columnGap: '8px',
  alignItems: 'center',
}));

const InnerContentWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

const CommentBoxWrapper = styled(Box)(() => ({
  position: 'absolute',
  width: 'calc(100% - 32px)',
  bottom: '12px',
}));

const EditInputWrapper = styled(Box)(() => ({
  display: 'flex',
  columnGap: '5px',
  alignItems: 'flex-end',
}));

const NotesList = ({ AddNotesHandler, refetchData, type, viewOnly = false }) => {
  const dispatch = useDispatch();

  const { userProfile } = useSelector((state) => state.common);
  const { notesList, controlLibraryNotesList, controlLibraryLoading, loading } = useSelector((state) => state.notes);

  const [selectedNotes, setSelectedNotes] = useState({});
  const [isEdit, setIsEdit] = useState(false);

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    refetchData();
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsEdit(false);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateNotesHandler = (data) => {
    if (data) {
      dispatch(updateNotes(selectedNotes?.uuid, { data: { note: data } })).then(() => {
        refetchData();
        setSelectedNotes({});
      });
    }
  };

  const deleteNotesHandler = (notes_uuid) => {
    setIsDeleteOpen(false);
    dispatch(deleteNotes(notes_uuid)).then(() => {
      refetchData();
    });
  };
  const dataList = type == CONTROL_LIBRARY ? controlLibraryNotesList : notesList;

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <NotesWrapper>
          {controlLibraryLoading || loading ? (
            <CommonLoader />
          ) : dataList.length > 0 ? (
            dataList.map((item, index) => (
              <>
                <NoteList key={index} mt={1}>
                  <AsyncImage fileName={item?.created_by?.profile_pic} />
                  <ContentWrapper>
                    <InnerContentWrapper>
                      <HeaderWrapper>
                        <Typography variant="subtitle2" sx={{ maxWidth: '200px' }} component={Box}>
                          {`${item?.created_by?.first_name}  ${item?.created_by?.last_name} `}
                        </Typography>
                        <Typography variant="caption" color="secondary" component={Box}>
                          <UpdatedDate date={item?.modified_at} />
                        </Typography>
                      </HeaderWrapper>
                      {!viewOnly && (
                        <IconButton
                          onClick={(e) => {
                            handleClick(e);
                            setSelectedNotes(item);
                          }}
                        >
                          <ReactSVG src={IC_HORIZONTAL_DOTS} />
                        </IconButton>
                      )}
                    </InnerContentWrapper>

                    {isEdit && item.uuid == selectedNotes?.uuid ? (
                      <EditInputWrapper>
                        <TextField
                          id="filled-multiline-flexible"
                          multiline
                          maxRows={4}
                          value={selectedNotes?.note}
                          variant="filled"
                          onChange={(e) => {
                            setSelectedNotes({ ...selectedNotes, note: e.target.value });
                          }}
                          sx={{
                            '& .MuiInputBase-root': {
                              borderRadius: '8px',
                              padding: '10px',
                              '&:before': {
                                borderBottom: 'none',
                              },
                              '&:hover': {
                                borderBottom: 'none',
                                '&:before': {
                                  borderBottom: 'none',
                                },
                              },
                            },
                          }}
                        />
                        <IconButton
                          onClick={() => {
                            updateNotesHandler(selectedNotes?.note);
                            setIsEdit(false);
                          }}
                        >
                          <ReactSVG src={IC_FILE_CHECK} />
                        </IconButton>
                      </EditInputWrapper>
                    ) : (
                      <Typography variant="body2">{item?.note}</Typography>
                    )}
                  </ContentWrapper>
                </NoteList>
              </>
            ))
          ) : (
            <DashedBox mt={2}>
              <ReactSVG src={IC_CHAT_ALT3} />
              <Box>
                <Typography variant="subtitle2">Nothing to display here!</Typography>
                <Typography variant="caption" color="secondary">
                  There&apos;s no notes here yet, be the first to write one!
                </Typography>
              </Box>
            </DashedBox>
          )}
        </NotesWrapper>
        {!viewOnly && (
          <CommentBoxWrapper>
            <CommentBox
              isAttachmentIcon={false}
              isSignIcon={false}
              handleComments={AddNotesHandler}
              placeholder="Write a note"
              profileImg={userProfile.profile_pic}
              inputRows={3}
            />
          </CommentBoxWrapper>
        )}
      </Box>
      <ConfirmationModal
        title="Are you sure you want to delete this note?"
        open={isDeleteOpen}
        handleClose={() => setIsDeleteOpen(false)}
        handleSubmit={() => deleteNotesHandler(selectedNotes?.uuid)}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            setIsEdit(true);
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            setIsDeleteOpen(true);
            handleClose();
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </>
  );
};
export default NotesList;

import { CircularProgress as MUICircularProgress, Box } from '@mui/material';
import styled from '@emotion/styled';

const ProgressbarWithLabel = styled('div')({
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const CircularProgressbar = ({
  size = 'small',
  color = 'primary',
  thickness = 3.4,
  value = 100,
  variant = 'determinate',
  isLabel = true,
  valueLabel,
  customStyle = {},
  ...rest
}) => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <MUICircularProgress
        size={size}
        color={color}
        thickness={thickness}
        value={value}
        variant={variant}
        style={{ position: 'absolute', zIndex: 99, ...customStyle }}
        {...rest}
      />
      <MUICircularProgress
        value={100}
        size={size}
        thickness={thickness}
        variant={variant}
        style={{ color: 'rgba(236, 234, 244, 1)' }}
      />
      {isLabel && <ProgressbarWithLabel>{valueLabel}</ProgressbarWithLabel>}
    </Box>
  );
};
export default CircularProgressbar;

import { Box, CircularProgress, IconButton, Typography, InputAdornment } from '@mui/material';
import { IC_CROSS } from 'assets/constants/imageConst';
import { dummyOptions, numberOfRecords, sensitiveData } from 'assets/constants/options';
import { Chip } from 'components/common';
import { Button } from 'components/common/FormControls';
import Input from 'components/common/FormField/Input/Input';
import Dropdown from 'components/common/FormField/Select/Dropdown';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { toast } from 'react-toastify';
import { uploadConnectorCertificate } from 'store/actions/connectors';
import { UP_ARROW } from 'assets/constants/imageConst';


function MicrosoftForm({ formik }) {
  const dispatch = useDispatch();
  const [fileValue, setFileValue] = useState();
  const fileName = formik.values?.certificate?.name || formik.values?.certificate || '';
  const { isUploadLoading, uploadedId } = useSelector((state) => state.connectors);

  useEffect(() => {
    if (uploadedId?.data) {
      formik.setFieldValue('certificate', fileValue);
    }
  }, [uploadedId]);

  const handleUpload = (e) => {
    const file = e.target.files[0];
    setFileValue(file);
    const filename = e.target.files[0]?.name;
    const ext = filename.split('.').reverse()[0];
    if (ext === 'crt' || ext === 'cer') {
      const formData = new FormData();
      formData.append('file', file);
      dispatch(uploadConnectorCertificate(formData));
    } else {
      toast.error('please upload valid file');
    }
  };

  const handleRemoveUpload = () => {
    formik.setFieldValue('certificate', '');
  };

  return (
    <>
      <Box mb={2}>
        <Typography variant="subtitle2">Polling Interval</Typography>
        <Typography variant="body2" mb={1} color="text.secondary">
          The interval at which this integration will run
        </Typography>
        <Dropdown
          options={dummyOptions}
          id="id"
          // label="Polling Interval"
          name="pollingInterval"
          formik={formik}
        />
      </Box>

      <Box mb={3}>
        <Input
          id="dns_name"
          className="mb-3"
          fullWidth
          // info="Your Microsoft Active Directory LDAP Server URL."
          placeholder="Enter DNS name"
          label="DNS Name"
          name="dns_name"
          value={formik.values.dns_name}
          onChange={formik.handleChange}
          error={formik.touched.dns_name && Boolean(formik.errors.dns_name)}
          helperText={formik.touched.dns_name && formik.errors.dns_name}
        />
      </Box>
      {/* <Box mb={3}>
        <Input
          id="BaseDN"
          className="mb-3"
          fullWidth
          // info="Your Microsoft Active Directory Base DN."
          placeholder="Enter Public IP Address"
          label="Public IP Address"
          name="public_ip_address"
          value={formik.values.public_ip_address}
          onChange={formik.handleChange}
          error={formik.touched.public_ip_address && Boolean(formik.errors.public_ip_address)}
          helperText={formik.touched.public_ip_address && formik.errors.public_ip_address}
        />
      </Box> */}

      <Box mb={3}>
        <Input
          id="userName"
          className="mb-3"
          fullWidth
          placeholder="Your Active Directory username"
          label="Username"
          name="username"
          value={formik.values.username}
          onChange={formik.handleChange}
          error={formik.touched.username && Boolean(formik.errors.username)}
          helperText={formik.touched.username && formik.errors.username}
        />
      </Box>

      <Box>
        <Input
          id="password"
          fullWidth
          placeholder="Your Active Directory password"
          label="Password"
          name="password"
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
      </Box>

      <Box mt={3} mb={3}>
        <Typography variant="subtitle2" color="text.primary" mb={1} sx={{ display: 'flex' }}>
          Certificate
        </Typography>
        {!fileName && !isUploadLoading && (
          <>
            <input
              color="primary"
              accept=".cer, .crt"
              type="file"
              name="certificate"
              onChange={handleUpload}
              id="icon-button-file"
              style={{ display: 'none' }}
            />
            <label htmlFor="icon-button-file">
              <Button variant="outlined" component="span" color="primary" fullWidth disabled={!!fileName}>
                Upload
              </Button>
            </label>
          </>
        )}
        {isUploadLoading && (
          <Box mt={2}>
            <CircularProgress />
          </Box>
        )}
        {fileName && !isUploadLoading && (
          <Chip
            size="default"
            label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="subtitle1">{fileName}</Typography>
                <IconButton onClick={handleRemoveUpload} ml={3}>
                  <ReactSVG src={IC_CROSS} />
                </IconButton>
              </Box>
            }
          />
        )}
        {formik.touched.certificate && Boolean(formik.errors.certificate) && (
          <Typography variant="caption" color="error">
            {formik.errors.certificate}
          </Typography>
        )}
      </Box>

      <Box mb={3}>
      <Input
            id="impactScore"
            className="mb-3"
            fullWidth
            label="Impact Score"
            name="impactScore"
            placeholder="N/A"
            info="We automatically calculate your application impact scores depending on the application below. You can also override this field by entering any value between 1 and 10."
            value={formik?.values?.impactScore}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <ReactSVG
                    src={UP_ARROW}
                    className="down-arrow"
                    onClick={() => {
                      formik.setFieldValue('impactScore', typeof formik?.values.impactScore == 'number' ? formik?.values.impactScore + 1 : 1);
                    }}
                  />
                  <ReactSVG
                    src={UP_ARROW}
                    onClick={() => {
                      formik.setFieldValue(
                        'impactScore',
                        formik?.values.impactScore && formik?.values.impactScore > 1 ? formik?.values.impactScore - 1 : 1
                      );
                    }}
                  />
                </InputAdornment>
              ),
            }}
            // onChange={formik?.handleChange}
            error={formik.touched.impactScore && Boolean(formik.errors.impactScore)}
            helperText={formik.touched.impactScore && formik.errors.impactScore}
          />
      </Box>

      <Box mb={3}>
        <Typography variant="subtitle2" mb={1}>Number of records</Typography>
        <Dropdown options={numberOfRecords} id="id" name="numberOfHealthRecords" formik={formik} placeholder="Select an option"/>
      </Box>

      <Box mb={3}>
        <Typography variant="subtitle2" mb={1}>Type of sensitive data</Typography>
       
        <Dropdown options={sensitiveData} id="id" name="sensitiveData"
                  placeholder="Select an option"
                  formik={formik} />
      </Box>
    </>
  );
}

export default MicrosoftForm;

import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { Chip } from 'components/common';
import { getWordFromNumber, objectToStringConvertor } from 'utils/general';
import ProgressCard from 'components/common/Card/ProgressCard';
import { IC_FOLDER, IC_TARGET } from 'assets/constants/imageConst';
import { ReactSVG } from 'react-svg';
import { connectorsName } from 'assets/constants/const';
import storage from 'services/storage';

function IntegrationDetail({ formDetails, settingDescription, pdf, workSpaceName, isCustomConnector }) {
  const impactScore = storage.get('gSuite_impact_score');
  return (
    <Box>
      <Box mt={4} mx={3}>
        <Grid container>
          <Grid item xs={12} sm={4}>
            <Box mb={4}>
              <Typography variant="subtitle1" mb={1}>
                Integration Details
              </Typography>
              <Typography variant="body2" color="text.secondary">
                These information will be used for organization purposes only.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={8}>
            <Box ml={3}>
              <Box mb={3}>
                <Typography variant="subtitle2" color="text.secondary" mb={1}>
                  Account Name
                </Typography>

                <Typography variant="body2">{formDetails?.tenant_connector_name}</Typography>
              </Box>
              <Box mb={5}>
                <Typography variant="subtitle2" color="text.secondary" mb={1}>
                  Description
                </Typography>

                <Typography variant="body2">{formDetails?.tenant_connector_description || ''}</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Divider light={false} />
      <Box mt={5} mx={3}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={4}>
            <Box mb={3} mr={3}>
              <Typography variant="subtitle1" mb={1}>
                Settings
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {settingDescription}
              </Typography>
            </Box>
            {!isCustomConnector && (
              <Box>
                <Typography variant="body2" color="text.secondary">
                  Need help?{' '}
                  <Typography component="a" color="primary" variant="body2" target="_blank" href={pdf}>
                    Read documentation
                  </Typography>
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={8}>
            <Box ml={3}>
              {formDetails?.polling_frequency && formDetails?.polling_intrval && (
                <Box mb={3}>
                  <Typography variant="subtitle2" color="text.secondary" mb={1}>
                    Polling Interval
                  </Typography>

                  <Typography variant="body2">
                    {getWordFromNumber(formDetails?.polling_intrval) + ' ' + formDetails.polling_frequency}
                  </Typography>
                </Box>
              )}

              {isCustomConnector && (
                <>
                  {formDetails?.connection_details?.number_of_health_records && (
                    <Box mb={3}>
                      <Typography variant="subtitle2" color="text.secondary" mb={1}>
                        Number of records
                      </Typography>
                      <Typography variant="body2">
                        {objectToStringConvertor(formDetails?.connection_details?.number_of_health_records)}
                      </Typography>
                    </Box>
                  )}
                  {formDetails?.connection_details?.type_of_sensitive_data && (
                    <Box mb={3}>
                      <Typography variant="subtitle2" color="text.secondary" mb={1}>
                        Type of sensitive data
                      </Typography>
                      <Typography variant="body2">{formDetails?.connection_details?.type_of_sensitive_data}</Typography>
                    </Box>
                  )}
                  {formDetails?.connection_details?.number_of_user && (
                    <Box mb={3}>
                      <Typography variant="subtitle2" color="text.secondary" mb={1}>
                        Number of users
                      </Typography>
                      <Typography variant="body2">
                        {objectToStringConvertor(formDetails?.connection_details?.number_of_user)}
                      </Typography>
                    </Box>
                  )}
                  {formDetails?.connection_details?.system_hosting_model && (
                    <Box mb={3}>
                      <Typography variant="subtitle2" color="text.secondary" mb={1}>
                        System hosting model
                      </Typography>
                      <Typography variant="body2">{formDetails?.connection_details?.system_hosting_model}</Typography>
                    </Box>
                  )}
                  {formDetails?.connection_details?.system_priority && (
                    <Box mb={3}>
                      <Typography variant="subtitle2" color="text.secondary" mb={1}>
                        System priority
                      </Typography>

                      <Typography variant="body2">{formDetails?.connection_details?.system_priority}</Typography>
                    </Box>
                  )}
                </>
              )}

              {formDetails?.connection_details?.hippa_folder_path && (
                <Box mb={3}>
                  <Typography variant="subtitle2" color="text.secondary" mb={1}>
                    Root Folder URL containing sensitive data
                  </Typography>
                  <Box display="flex">
                    <ReactSVG src={IC_FOLDER} />
                    <Typography display="inline" variant="body2" ml={1}>
                      {formDetails?.connection_details?.hippa_folder_path}
                    </Typography>
                  </Box>
                </Box>
              )}
              {formDetails?.connection_details?.email && (
                <Box mb={3}>
                  <Typography variant="subtitle2" color="text.secondary" mb={1}>
                    Admin email
                  </Typography>

                  <Typography variant="body2">{formDetails?.connection_details?.email}</Typography>
                </Box>
              )}
              {formDetails?.connection_details?.dns_name && (
                <Box mb={3}>
                  <Typography variant="subtitle2" color="text.secondary" mb={1}>
                    DNS name
                  </Typography>

                  <Typography variant="body2">{formDetails?.connection_details?.dns_name}</Typography>
                </Box>
              )}
              {/* {formDetails?.connection_details?.public_ip_address && (
                <Box mb={3}>
                  <Typography variant="subtitle2" color="text.secondary" mb={1}>
                    Public IP Address
                  </Typography>

                  <Typography variant="body2">{formDetails?.connection_details?.public_ip_address}</Typography>
                </Box>
              )} */}
              {formDetails?.connection_details?.username && (
                <Box mb={3}>
                  <Typography variant="subtitle2" color="text.secondary" mb={1}>
                    Username
                  </Typography>

                  <Typography variant="body2">{formDetails?.connection_details?.username}</Typography>
                </Box>
              )}
              {formDetails?.connection_details?.directory_id && (
                <Box mb={3}>
                  <Typography variant="subtitle2" color="text.secondary" mb={1}>
                    Directory Id
                  </Typography>

                  <Typography variant="body2">{formDetails?.connection_details?.directory_id}</Typography>
                </Box>
              )}
              {formDetails?.connection_details?.application_id && (
                <Box mb={3}>
                  <Typography variant="subtitle2" color="text.secondary" mb={1}>
                    Application Id
                  </Typography>

                  <Typography variant="body2">{formDetails?.connection_details?.application_id}</Typography>
                </Box>
              )}
              {formDetails?.connection_details?.certificate && (
                <Box mb={3}>
                  <Typography variant="subtitle2" color="text.secondary" mb={1}>
                    Certificate
                  </Typography>
                  <Chip
                    size="default"
                    label={
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body2" component="a" download href={formDetails?.file_url}>
                          {formDetails?.connection_details?.certificate}
                        </Typography>
                      </Box>
                    }
                  />
                </Box>
              )}

              {([connectorsName.AZURE, connectorsName.GOOGLE, connectorsName.MICROSOFT].includes(workSpaceName)) && (
                <>
                  {formDetails?.connection_details?.manual_impact_score && (
                    <Box mb={3}>
                      <Typography variant="subtitle2" color="text.secondary" mb={1}>
                        Impact Score
                      </Typography>
                      <Typography variant="body2">
                        {formDetails?.connection_details?.manual_impact_score}
                      </Typography>
                    </Box>
                  )}
                  {formDetails?.connection_details?.number_of_health_records && (
                    <Box mb={3}>
                      <Typography variant="subtitle2" color="text.secondary" mb={1}>
                        Number of records
                      </Typography>
                      <Typography variant="body2">
                        {objectToStringConvertor(formDetails?.connection_details?.number_of_health_records)}
                      </Typography>
                    </Box>
                  )}
                  {formDetails?.connection_details?.type_of_sensitive_data && (
                    <Box mb={3}>
                      <Typography variant="subtitle2" color="text.secondary" mb={1}>
                        Type of sensitive data
                      </Typography>
                      <Typography variant="body2">{formDetails?.connection_details?.type_of_sensitive_data}</Typography>
                    </Box>
                  )}
                </>
              )}

            </Box>
          </Grid>
        </Grid>
      </Box>
      {workSpaceName === connectorsName.GDRIVE && (
        <>
          <Divider light={false} />
          <Box mt={5} mx={3}>
            <Grid container spacing={0}>
              <Grid item xs={12} sm={4}>
                <Box mb={3} mr={3}>
                  <Typography variant="subtitle1" mb={1}>
                    Inherent risk
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    The following data will be used to assess the integration level of raw or untreated risk.
                  </Typography>
                </Box>
                <Box>
                  <ProgressCard icon={IC_TARGET} title="Aggregate impact score" progress={impactScore || 'NA'} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Box ml={3}>
                {formDetails?.connection_details?.manual_impact_score && (
                    <Box mb={3}>
                      <Typography variant="subtitle2" color="text.secondary" mb={1}>
                      Impact Score
                      </Typography>
                      <Typography variant="body2">
                        {formDetails?.connection_details?.manual_impact_score}
                      </Typography>
                    </Box>
                  )}
                  {formDetails?.connection_details?.number_of_health_records && (
                    <Box mb={3}>
                      <Typography variant="subtitle2" color="text.secondary" mb={1}>
                        Number of records
                      </Typography>
                      <Typography variant="body2">
                        {objectToStringConvertor(formDetails?.connection_details?.number_of_health_records)}
                      </Typography>
                    </Box>
                  )}
                  {formDetails?.connection_details?.type_of_sensitive_data && (
                    <Box mb={3}>
                      <Typography variant="subtitle2" color="text.secondary" mb={1}>
                        Type of sensitive data
                      </Typography>
                      <Typography variant="body2">{formDetails?.connection_details?.type_of_sensitive_data}</Typography>
                    </Box>
                  )}
                  {formDetails?.connection_details?.number_of_user && (
                    <Box mb={3}>
                      <Typography variant="subtitle2" color="text.secondary" mb={1}>
                        Number of users
                      </Typography>
                      <Typography variant="body2">
                        {objectToStringConvertor(formDetails?.connection_details?.number_of_user)}
                      </Typography>
                    </Box>
                  )}
                  {formDetails?.connection_details?.system_hosting_model && (
                    <Box mb={3}>
                      <Typography variant="subtitle2" color="text.secondary" mb={1}>
                        System hosting model
                      </Typography>
                      <Typography variant="body2">{formDetails?.connection_details?.system_hosting_model}</Typography>
                    </Box>
                  )}
                  {formDetails?.connection_details?.system_priority && (
                    <Box mb={3}>
                      <Typography variant="subtitle2" color="text.secondary" mb={1}>
                        System priority
                      </Typography>

                      <Typography variant="body2">{formDetails?.connection_details?.system_priority}</Typography>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </Box>
  );
}

export default IntegrationDetail;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { isArray } from 'lodash';

// MUI
import { ListItem, ListItemText, ListItemAvatar, Typography, Divider, Box, Grid, Skeleton } from '@mui/material';
import { ReactSVG } from 'react-svg';

// Internal
import { Avatar, Chip, CircularProgressbar } from 'components/common';
import { Button } from 'components/common/FormControls';
import { IC_KEY, IC_SHIELD, FILE_DARK, IC_DOWNLOAD, CUSTOM_CONNECTOR } from 'assets/constants/imageConst';
import WrapperBox from 'components/common/Wrapper/WrapperBox';
import Title from 'components/common/Wrapper/Title';
import IntegrationRiskCard from 'components/common/Card/IntegrationRiskCard';
import ROUTE from 'assets/constants/route';
import InfoIconComponent from 'components/common/InfoIcon/InfoIconComponent';
import { Slider } from 'components/common';
import connectorData from 'assets/constants/connectors';
import { connectorsName } from 'assets/constants/const';
import { getProgressColor, objectToStringConvertor, shouldBeMasked } from 'utils/general';
import DashedBox from 'components/common/Wrapper/DashedBox';

const listViewData = [
  { icon: IC_KEY, primaryText: 'No  multi-factor authentication on laptops', secondaryText: '55 Assets' },
  { icon: IC_SHIELD, primaryText: 'Lack of anti-virus in database servers', secondaryText: '55 Assets' },
  { icon: IC_KEY, primaryText: 'Weak credentials on laptop', secondaryText: '55 Assets' },
];

const RiskAnalysis = ({ aggregateRiskScore, riskScoreSinceLastWeek, tenant_connectors, loading }) => {
  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate(ROUTE.RISK_ANALYSIS);
  };

  const connectedConnector = tenant_connectors
    ?.map((data) => {
      const customConnectorDetail = {
        icon: data?.logo,
        title: data.tenant_connector_name,
        byName: data.tenant_name,
        path: '/lightening/custom-connector',
        type: data.type_name,
        isDisabled: false,
        accountId: '',
        clientAlias: '',
        isCustomConnector: true,
      };
      const filteredData =
        data?.connector_type == connectorsName.CUSTOM
          ? customConnectorDetail
          : connectorData?.find((d) => data.connector_name === d.type);

      let user = data?.output_json?.user_data?.length || 0;
      let device = data?.output_json?.mobile_devices?.length || 0;
      let progressValue = data?.risk_score?.aggregate_control_response_ratio || 0;

      if (data?.connector_type == connectorsName.CUSTOM) {
        const userForCustomConnector = data?.output_json?.user_data?.number_of_user;
        user = objectToStringConvertor(userForCustomConnector);
      }

      if (data.connector_name === connectorsName.MICROSOFT) {
        let userObject = data.output_json?.data?.find((value) => Object.keys(value).find((obj) => obj === 'user_data'));
        if (isArray(userObject?.user_data?.data)) {
          user = userObject?.user_data?.data.length;
        }
        let computerObject = data.output_json?.data?.find((value) =>
          Object.keys(value).find((obj) => obj === 'computers')
        );
        if (isArray(computerObject?.computers?.data)) {
          device = computerObject?.computers?.data.length;
        }
      } else if (data.connector_name === connectorsName.AZURE) {
        if (isArray(data?.output_json?.devices)) {
          device = data?.output_json?.devices.length;
        }
      }
      return { ...data, ...filteredData, user, device, progressValue };
    })
    .sort((a, b) => {
      const lowercaseA = a.connector_name.toLowerCase();
      const lowercaseB = b.connector_name.toLowerCase();
      if (lowercaseA < lowercaseB) {
        return -1;
      }
      if (lowercaseA > lowercaseB) {
        return 1;
      }
      return 0;
    });

  return (
    <WrapperBox>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} mb={1}>
        <Title title="Risk Analysis" subtitle="HIPAA Security Risk Analysis" />
        {shouldBeMasked() && (
          <Box>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              startIcon={<ReactSVG src={IC_DOWNLOAD} className="download-small-icon" />}
            >
              Auditor report
            </Button>
          </Box>
        )}
      </Box>
      <Box>
        <Box mb={4}>
          <Chip label="45 CFR §164.308(a)(1)(ii)(A)" icon={<ReactSVG src={FILE_DARK} />} />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <WrapperBox sx={{ textAlign: 'center' }} padding="40px 24px">
              <CircularProgressbar
                value={aggregateRiskScore}
                valueLabel={<Typography variant="h6">{aggregateRiskScore}</Typography>}
                size={100}
                thickness={1}
                color={getProgressColor(aggregateRiskScore)}
              />
              <Typography variant="subtitle2" mb={1} mt={1}>
                Overall Risk Score
              </Typography>
              <Box display="flex" mb={2} sx={{ alignItems: 'center', justifyContent: 'center' }}>
                <Typography variant="caption" color="secondary" mr={0.5}>
                  Since last week
                </Typography>
                <Chip
                  icon={<ReactSVG src={riskScoreSinceLastWeek?.icon} />}
                  label={`${riskScoreSinceLastWeek?.label}%`}
                  color={riskScoreSinceLastWeek?.color}
                  size="small"
                />
              </Box>
              <Divider />
              <Box mt={2}>
                <Button variant="outlined" color="secondary" size="small" onClick={handleRedirect}>
                  View Details
                </Button>
              </Box>
            </WrapperBox>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Box mb={1.1} ml={2}>
              <Typography variant="overline" color="secondary">
                Connected systems and integrations at risk
              </Typography>
            </Box>
            {loading && (
              <Box>
                <Skeleton variant="rounded" width={'auto'} height={262} sx={{ mt: 1 }} />
              </Box>
            )}
            {connectedConnector?.length > 1 && !loading && (
              <Slider>
                {connectedConnector?.map((cs, index) => (
                  <IntegrationRiskCard
                    key={index}
                    title={cs.title}
                    padding="30px 24px"
                    logo={cs.icon}
                    progressValue={cs.progressValue}
                    device={cs.device}
                    user={cs.user}
                    isCustomConnector={cs?.isCustomConnector ? true : false}
                  />
                ))}
              </Slider>
            )}
            {connectedConnector?.length === 1 &&
              !loading &&
              connectedConnector?.map((cs, index) => (
                <Box key={index} sx={{ width: '50%' }}>
                  <IntegrationRiskCard
                    title={cs.title}
                    padding="30px 24px"
                    logo={cs.icon}
                    progressValue={cs.progressValue}
                    device={cs.device}
                    user={cs.user}
                  />
                </Box>
              ))}
            {!loading && connectedConnector?.length === 0 && (
              <Box sx={{ height: '100%', p: 10 }}>
                <DashedBox>
                  <Box>
                    <Typography variant="overline" color="secondary">
                      No integration connected
                    </Typography>
                  </Box>
                </DashedBox>
              </Box>
            )}
          </Grid>
        </Grid>
        {shouldBeMasked() && (
          <WrapperBox padding="16px 16px" mt={2}>
            <Typography variant="overline" color="secondary">
              control deficiency quick view
            </Typography>
            {listViewData.map((item, index) => (
              <ListItem key={index} divider={true} sx={{ pl: '0', pr: '0' }}>
                <ListItemAvatar>
                  <Avatar size="small">
                    <ReactSVG src={item.icon} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Box sx={{ display: 'flex' }}>
                      <Typography display="inline" variant="body2">
                        {item.primaryText}
                      </Typography>
                      <InfoIconComponent />
                    </Box>
                  }
                  secondary={
                    <Typography variant="body2" color="secondary">
                      {item.secondaryText}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </WrapperBox>
        )}
      </Box>
    </WrapperBox>
  );
};
export default RiskAnalysis;

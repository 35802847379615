import { Box, Typography } from '@mui/material';
import { IC_CHAT_ALT3 } from 'assets/constants/imageConst';
import DashedBox from 'components/common/Wrapper/DashedBox';
import React, { useState } from 'react';
import { ReactSVG } from 'react-svg';
import CommentBox from './CommentBox';
import CommentResponse from '../../../../components/common/Comment/CommentResponse';

function Comments() {
  const [comments, setComments] = useState([]);
  const handleComments = (comment) => {
    let allComment = [...comments, comment];
    setComments(allComment);
  };

  return (
    <>
      <Box sx={{ maxHeight: '300px', overflow: 'auto' }}>
        {comments.length > 0 ? (
          comments.map((comment, index) => (
            <Box key={comment + index} mb={2}>
              <CommentResponse comment={comment} />
            </Box>
          ))
        ) : (
          <DashedBox mt={2}>
            <ReactSVG src={IC_CHAT_ALT3} />
            <Box>
              <Typography variant="subtitle2">Nothing to display here!</Typography>
              <Typography variant="caption" color="secondary">
                There&apos;s no comment here yet, be the first to write one!
              </Typography>
            </Box>
          </DashedBox>
        )}
      </Box>
      <Box mt={2}>
        <CommentBox handleComments={handleComments} />
      </Box>
    </>
  );
}

export default Comments;

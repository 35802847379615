import React, { useState} from 'react';
import { ReactSVG } from 'react-svg';
import { useDispatch,useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  IconButton
} from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import ConfirmationModal from 'components/common/Modal/ConfirmationModal';

import { IC_DUSTBIN, IC_EDIT } from 'assets/constants/imageConst';
import { Avatar } from 'components/common';
import { NO_RECORD } from 'assets/constants/const';
import CommonLoader from 'components/common/Loader/CommonLoader';
import RestoreIcon from '@mui/icons-material/Restore';
import DomainSharpIcon from '@mui/icons-material/DomainSharp';
import { switchTenant } from 'store/actions/me';
import { toast } from 'react-toastify';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';

const tableHeader = [
  { field: 'tenant_image', title: '',minWidth: 100 },
  { field: 'site_name', title: 'Tenant name',minWidth: 200 },
  { field: 'code', title: 'Code',minWidth: 200 },
  { field: 'action', title: 'Action' },
]

function TenantTable({ getTenantList, dataPerPage, setDataPerPage, handleAddModal, handleDelete }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { tenantData } = useSelector((state) => state.common);
  const { tenantData:tenantDataList, loading } = useSelector((state) => state.tenant);
  const tableData=tenantDataList?.data ?? [];
  const currentPage=tenantDataList?.current_page ?? 1;
  const total= tenantDataList?.total ?? 0
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteTenantData, setDeleteTenantData] = useState(false);
  const [customLoading, setCustomLoading] = useState(false);

  const handleChangePage = (event, newPage) => {
    getTenantList({ page: newPage + 1, per_page: dataPerPage });
  };

  const closeDeleteModal=(flag,value)=>{
    setOpenDeleteModal(flag);
    setDeleteTenantData(value);
  }


  const handleChangeRowsPerPage = (event) => {
    setDataPerPage(event.target.value)
    getTenantList({ page: 0, per_page: parseInt(event.target.value, 10) });
  };

  const handleSwitchTenant = (data) =>{
    setCustomLoading(true)
    setTimeout(() => {
      dispatch(switchTenant(data));
      toast.success('Tenant switched successfully');
      setCustomLoading(false);
      navigate('/dashboard');
    }, 200);
  }

  return (
    <>
      <TableContainer sx={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
        <Table sx={{ minWidth: 650 }} size="medium" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {tableHeader?.map((th) => (
                <TableCell align="left" key={th.field} sx={{ minWidth: th?.minWidth }}>
                  <Typography variant="subtitle2" color="secondary">
                    {th.title}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          {loading || customLoading ?
            <TableBody>
              <TableCell colSpan={6} sx={{ textAlign: 'center', py: 4 }}>
                <CommonLoader height={"100vh"} />
              </TableCell>
            </TableBody>
            :
            <TableBody>
              {tableData?.map((row, index) => {
                return (
                  <TableRow key={index}>
                     <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
                      {row?.icon?
                     <Avatar src={row?.icon} size="large" bg="primary" />:
                     <Avatar   size="large"><DomainSharpIcon /></Avatar>}
                    </TableCell>

                    <TableCell align="left" sx={{ maxWidth: '200px', wordBreak: 'break-all' }}>
                      {row.site_name || '--'}
                    </TableCell>

                    <TableCell align="left" sx={{ maxWidth: '200px', wordBreak: 'break-all' }}>
                      {row.code || '--'}
                    </TableCell>

                    <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>

                      <Box sx={{ display: 'flex', columnGap: '16px' }}>
                        <Avatar variant="rounded" size="small" >
                          <IconButton
                          onClick={() => handleAddModal(true,row)}
                          >
                            <ReactSVG src={IC_EDIT} />
                          </IconButton>
                        </Avatar>

                        <Avatar variant="rounded" size="small" >
                          <IconButton
                            onClick={() => closeDeleteModal(true,row)}
                          >
                            {!row?.is_deleted ?
                            <ReactSVG src={IC_DUSTBIN} />
                            :
                            <RestoreIcon />
              }
                          </IconButton>
                        </Avatar>
                        {/* switch */}
                        {tenantData?.uuid !== row.uuid &&
                        <Avatar variant="rounded" size="small" onClick={()=>{
                          handleSwitchTenant(row);
                        }}>
                          <IconButton
                            aria-label="switch tenant"
                            size='small'
                          >
                            <SwapHorizontalCircleIcon />
                          </IconButton>
                        </Avatar>
              }
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
              {total < 1 && (
                <TableRow>
                  <TableCell colSpan={6} sx={{ textAlign: 'center', py: 4 }}>
                    {NO_RECORD}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          }
        </Table>
        {!loading &&
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={total}
            rowsPerPage={dataPerPage}
            page={(currentPage - 1)}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
        <ConfirmationModal
        title= {`Are you sure you want to ${deleteTenantData?.is_deleted ? 'activate':'delete'} this tenant?`}
        open={openDeleteModal}
        handleClose={() => { closeDeleteModal(false,null) }}
        handleSubmit={() => { 
          handleDelete(deleteTenantData);
          closeDeleteModal(false,null);
        }}
      />
      </TableContainer>
    </>
  );
}

export default TenantTable;

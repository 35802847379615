import React from 'react';

import { Box, Typography, InputAdornment } from '@mui/material';
import { dummyOptions, numberOfRecords, sensitiveData } from 'assets/constants/options';
import Input from 'components/common/FormField/Input/Input';
import Dropdown from 'components/common/FormField/Select/Dropdown';
import { UP_ARROW } from 'assets/constants/imageConst';
import { ReactSVG } from 'react-svg';


const AzureADForm = ({ formik }) => {
  return (
    <>
      <Box mb={2}>
        <Typography variant="subtitle2">Polling Interval</Typography>
        <Typography variant="body2" mb={1} color="text.secondary">
          The interval at which this integration will run
        </Typography>
        <Dropdown options={dummyOptions} id="id" name="pollingInterval" formik={formik} />
      </Box>
      <Box mb={3}>
        <Input
          id="directoryId"
          className="mb-3"
          fullWidth
          placeholder="Directory (tenant) ID"
          label="Directory ID"
          name="directoryId"
          value={formik.values.directoryId}
          onChange={formik.handleChange}
          error={formik.touched.directoryId && Boolean(formik.errors.directoryId)}
          helperText={formik.touched.directoryId && formik.errors.directoryId}
        />
      </Box>
      <Box mb={3}>
        <Input
          id="applicationId"
          className="mb-3"
          fullWidth
          placeholder="Application (client) ID"
          label="Application ID"
          name="applicationId"
          value={formik.values.applicationId}
          onChange={formik.handleChange}
          error={formik.touched.applicationId && Boolean(formik.errors.applicationId)}
          helperText={formik.touched.applicationId && formik.errors.applicationId}
        />
      </Box>
      <Box mb={3}>
        <Input
          id="clientSecret"
          className="mb-3"
          fullWidth
          placeholder="Your client secret"
          label="Client Secret"
          name="clientSecret"
          type="password"
          value={formik.values.clientSecret}
          onChange={formik.handleChange}
          error={formik.touched.clientSecret && Boolean(formik.errors.clientSecret)}
          helperText={formik.touched.clientSecret && formik.errors.clientSecret}
        />
      </Box>
      <Box mb={3}>
      <Input
            id="impactScore"
            className="mb-3"
            fullWidth
            label="Impact Score"
            name="impactScore"
            placeholder="N/A"
            info="We automatically calculate your application impact scores depending on the application below. You can also override this field by entering any value between 1 and 10."
            value={formik?.values?.impactScore}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <ReactSVG
                    src={UP_ARROW}
                    className="down-arrow"
                    onClick={() => {
                      formik.setFieldValue('impactScore', typeof formik?.values.impactScore == 'number' ? formik?.values.impactScore + 1 : 1);
                    }}
                  />
                  <ReactSVG
                    src={UP_ARROW}
                    onClick={() => {
                      formik.setFieldValue(
                        'impactScore',
                        formik?.values.impactScore && formik?.values.impactScore > 1 ? formik?.values.impactScore - 1 : 1
                      );
                    }}
                  />
                </InputAdornment>
              ),
            }}
            // onChange={formik?.handleChange}
            error={formik.touched.impactScore && Boolean(formik.errors.impactScore)}
            helperText={formik.touched.impactScore && formik.errors.impactScore}
          />
      </Box>

      <Box mb={3}>
        <Typography variant="subtitle2" mb={1}>Number of records</Typography>
        <Dropdown options={numberOfRecords} id="id" name="numberOfHealthRecords" formik={formik} placeholder="Select an option"/>
      </Box>

      <Box mb={3}>
        <Typography variant="subtitle2" mb={1}>Type of sensitive data</Typography>
       
        <Dropdown options={sensitiveData} id="id" name="sensitiveData"
                  placeholder="Select an option"
                  formik={formik} />
      </Box>



      </>
  );
};

export default AzureADForm;

import React, { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// MUI
import { styled } from '@mui/system';
import { Box, Typography, IconButton, Divider, Skeleton, Grid, CircularProgress } from '@mui/material';
// Internal
import WrapperBox from 'components/common/Wrapper/WrapperBox';
import { Button } from 'components/common/FormControls';
import FlexBox from 'components/common/Wrapper/FlexBox';
import {
  IC_KEY,
  IC_SHIELD,
  IC_UP_TREND,
  IC_SEARCH,
  IC_FILTER,
  IC_LAPTOP,
  IC_GRID,
  IC_DOWN_TREND,
  IC_DOWNLOAD
} from 'assets/constants/imageConst';
import { setBreadcrumbs } from 'store/actions/breadcrumbs';
import { breadcrumbsData } from 'assets/constants/const';
import ListView from './ListView';
import { getRiskAnalysis, getScore } from 'store/actions/riskAnalysis';
import ListItem from 'components/common/List/ListItem';
import Heading from 'components/common/Wrapper/Heading';
import CommonLoader from 'components/common/Loader/CommonLoader';
import Icon from 'components/common/Icon/Icon';
import { scrollStyle } from 'assets/theme/styles';
import DateWiseGraph from 'components/common/Graph/DateWiseGraph';
import { Chip } from 'components/common';
import moment from 'moment';
import { BACKEND_SHORT_DATE, startOfMonth } from 'utils/date';
import ColorSlider from 'components/common/FormField/Slider/ColorSlider';
import ButtonSlider from 'components/common/ButtonSlider/ButtonSlider';
import { shouldBeMasked } from 'utils/general';
import { getriskAnalysisPDF } from 'utils/riskAnalysisPDF';

const FilteredUIComponent = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  columnGap: '5px',
  alignItems: 'center',
});

const listViewData = [
  { icon: IC_KEY, primaryText: 'No multi-factor authentication on laptops', secondaryText: '55 Assets' },
  { icon: IC_SHIELD, primaryText: 'Lack of anti-virus in database servers', secondaryText: '55 Assets' },
  { icon: IC_KEY, primaryText: 'Weak credentials on laptop', secondaryText: '55 Assets' },
  { icon: IC_KEY, primaryText: 'No device encryption', secondaryText: '55 Assets' },
  { icon: IC_LAPTOP, primaryText: 'Lack of physical inventory', secondaryText: '55 Assets' },
  { icon: IC_GRID, primaryText: 'Lack of data governance policies', secondaryText: '55 Assets' },
  // { icon: IC_KEY, primaryText: 'No multi-factor authentication on laptops', secondaryText: '55 Assets' },
  // { icon: IC_SHIELD, primaryText: 'Lack of anti-virus in database servers', secondaryText: '55 Assets' },
  // { icon: IC_KEY, primaryText: 'Weak credentials on laptop', secondaryText: '55 Assets' },
];

const RiskAnalysis = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { tenantData } = useSelector((state) => state.common);
  const { tenant_connectors, loading, riskAnalysisScoreData, progressScore } = useSelector(
    (state) => state.riskAnalysis
  );

  const [loadingPdf,setLoadingPdf] =useState(false);

  const riskScoreSinceLastWeek = progressScore?.risk_score_diff_since_seven_day?.includes('-')
    ? {
        label: Number(progressScore?.risk_score_diff_since_seven_day) || 0,
        color: 'warning',
        icon: IC_DOWN_TREND,
      }
    : {
        label: `+${Number(progressScore?.risk_score_diff_since_seven_day) || 0}`,
        color: 'success',
        icon: IC_UP_TREND,
      };

  useEffect(() => {
    dispatch(setBreadcrumbs(breadcrumbsData.riskAnalysis));
  }, []);

  useEffect(() => {
    if (tenantData?.uuid) {
      dispatch(getRiskAnalysis(tenantData.uuid));
      dispatch(
        getScore({
          connector_name: '',
          end_date: moment().format(BACKEND_SHORT_DATE),
          start_date: moment(startOfMonth()).format(BACKEND_SHORT_DATE),
          tenant_uuid: tenantData.uuid,
        })
      );
    }
  }, [tenantData]);

  const graphData = riskAnalysisScoreData.map((value) => ({
    ...value,
    date: Number(moment(value.date, BACKEND_SHORT_DATE).format('x')),
    average: value.average === 0 ? undefined : Number(value.average),
  }));

  const riskAnalysisPDFGenration=()=>{
    setLoadingPdf(true);
    getriskAnalysisPDF(tenant_connectors,tenantData,lastGraphValue).then(res=>{
      setLoadingPdf(false);
    });
  }
  const lastGraphValue = graphData?.length > 0 ? graphData[graphData.length - 1].average : '';

  return loading ?<CommonLoader height={'100vh'} />: ( 
    <Box>
      {tenant_connectors && tenant_connectors.length == 0?
      <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="80vh"
    >
      <Box>
        <h2>No integrations have been connected yet.</h2>
      </Box>
      <Button variant="filled" onClick={()=>{navigate('/lightening?tab=integration')}}>
        connect
      </Button>
    </Box>
      :<>
      <Grid container>
        <Grid item xs={12} sm={4}>
        <Heading title="Risk Analysis" subtitle="Manage all your information and integrations in just one place." />
        </Grid>
        <Grid item xs={12} sm={8} sx={{ justifyItems: 'end', display: 'grid' }}>
          
          <FlexBox sx={{ columnGap: '12px' }}>
          {loadingPdf && (
              <FlexBox sx={{ columnGap: '5px' }}>
                <CircularProgress color="secondary" size={13} />{' '}
                <Typography variant="subtitle2" color="secondary">
                Generating PDF
                </Typography>{' '}
              </FlexBox>
            )}
            {!loading &&
                <Button variant="filled"
                startIcon={<ReactSVG src={IC_DOWNLOAD} className="download-small-icon" />}
                onClick={()=>riskAnalysisPDFGenration()}
                disabled={loadingPdf}
                >
                  Download risk report
                </Button>
}
          </FlexBox>

        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', flexDirection: 'row', columnGap: '16px' }}>
        <Box sx={{ width: '470px', display: 'flex', flexDirection: 'column', rowGap: '16px' }}>
          {loading ? (
            <WrapperBox>
              <Box display="flex" sx={{ justifyContent: 'space-between', mb: 2 }}>
                <Box>
                  <Typography variant="subtitle">Overall Risk Score</Typography>
                </Box>
                {shouldBeMasked() &&
                <Box>
                  <ButtonSlider name="This month" />
                </Box>
}
              </Box>
              <Skeleton variant="rounded" width={'auto'} height={230} />
            </WrapperBox>
          ) : (
            <WrapperBox>
              <Box display="flex" sx={{ justifyContent: 'space-between', mb: 1 }}>
                <Box>
                  <Typography variant="subtitle">Overall Risk Score</Typography>
                  <Box display="flex" sx={{ columnGap: '8px', mt: 0.5 }}>
                    <Typography variant="h5">{lastGraphValue}</Typography>
                    {lastGraphValue && (
                      <Chip
                        icon={<ReactSVG src={riskScoreSinceLastWeek?.icon} />}
                        color={riskScoreSinceLastWeek?.color}
                        label={`${riskScoreSinceLastWeek?.label}% since last week`}
                      />
                    )}
                  </Box>
                </Box>
               {shouldBeMasked() && <ButtonSlider name="This month" />}
              </Box>
              {graphData.length > 0 && <DateWiseGraph data={graphData} />}
            </WrapperBox>
          )}
          {shouldBeMasked() &&
          <WrapperBox>
            <Typography variant="body2" mb={1.5}>
              Your risk threshold
            </Typography>
            <Typography variant="body2" color="secondary">
              Adjust your risk threshold to influence how Risk AI will mitigate incoming risks.
            </Typography>
            <Divider variant="fullWidth" sx={{ marginTop: '16px', marginBottom: '16px' }} />
            <ColorSlider />
          </WrapperBox>
}
        </Box>
        {shouldBeMasked() &&
        <WrapperBox sx={{ width: 'calc(100% - 470px)' }} padding="0">
          <Box sx={{ p: 2 }}>
            <Typography variant="subtitle2" color="secondary">
              Control deficiencies snapshot
            </Typography>
          </Box>
          <Divider />
          <Box sx={{ px: 2, overflow: 'auto', height: 'auto', ...scrollStyle }}>
            {listViewData.map((item, index) => (
              <ListItem title={item.primaryText} subtitle={item.secondaryText} icon={item.icon} key={index} />
            ))}
          </Box>
        </WrapperBox>
}
      </Box>
      <Box mt={4} mb={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="subtitle1">Risk Assessment</Typography>
        {shouldBeMasked() &&
        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <FilteredUIComponent>
            <IconButton size="small">
              <Icon src={IC_SEARCH} size="small" />
            </IconButton>
            <Box mr={2} ml={1}>
              <Button variant="outlined" color="secondary" size="small" startIcon={<ReactSVG src={IC_FILTER} />}>
                Filter
              </Button>
            </Box>
            {/* <IconButton
              size="small"
              onClick={() => setIsListView(false)}
              sx={!isListView ? { background: '#4F4A551F', borderRadius: '6px' } : {}}
            >
              <ReactSVG src={IC_GRID} />
            </IconButton>
            <IconButton
              size="small"
              onClick={() => setIsListView(true)}
              sx={isListView ? { background: '#4F4A551F', borderRadius: '6px' } : {}}
            >
              <ReactSVG src={IC_LIST} />
            </IconButton> */}
          </FilteredUIComponent>
        </Box>
}
      </Box>
      <WrapperBox padding="0">
        {loading ? <CommonLoader height={300} /> : <ListView data={tenant_connectors} />}
      </WrapperBox>
      </>}
    </Box>
  );
};

export default RiskAnalysis;

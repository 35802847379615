import * as actionTypes from './actionTypes';
import controlsManagementServices from 'services/controlsManagementServices';

export const getControlLibraryList = (tenant_uuid) => ({
  type: actionTypes.GET_CONTROL_LIBRARY_LIST,
  payload: controlsManagementServices.getControlLibraryList(tenant_uuid),
});

export const getControlLibraryFramework = (tenant_uuid) => ({
  type: actionTypes.GET_CONTROL_LIBRARY_FRAMEWORK,
  payload: controlsManagementServices.getControlLibraryFramework(tenant_uuid),
});

export const saveControlLibraryResponse = (tenant_uuid, data) => ({
  type: actionTypes.SAVE_CONTROL_LIBRARY_RESPONSE,
  payload: controlsManagementServices.saveControlLibraryResponse(tenant_uuid, data),
});

export const addControlsToVulnerability = (tenant_connector_uuid, data) => ({
  type: actionTypes.ADD_CONTROLS_TO_VULNERABILITY,
  payload: controlsManagementServices.addControlsToVulnerability(tenant_connector_uuid, data),
});

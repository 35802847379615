import * as React from 'react';
import { styled } from '@mui/material/styles';
import { ReactSVG } from 'react-svg';
import { Box, Typography, Tab as MuiTab } from '@mui/material';
import Avatar from 'components/common/Avatar/Avatar';

const CustomTab = styled(MuiTab)(({ theme }) => {
  return {
    padding: '16px',
    width: '220px',
    marginRight: '16px',
    borderRadius: '8px',
    border: '1px solid',
    borderColor: theme.palette.divider,
    color: theme.palette.text.primary,
    alignItems: 'stretch',
    '&.Mui-selected': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.whiteShade5,
    },
  };
});

export default function Tab({ tabData, ...props }) {
  const renderLabel = (data) => {
    return (
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {data.img ? (
            <img src={data?.img} width="24px" height="24px" style={{ borderRadius: '50%' }} />
          ) : (
            <Avatar bg="primary" size="small">
              <ReactSVG src={data?.icon} />
            </Avatar>
          )}
          <Typography variant="body2" sx={{ ml: 1, textTransform: 'none' }} color="text.secondary">
            {data?.title}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>{data?.content}</Box>
      </Box>
    );
  };

  return <CustomTab {...props} label={renderLabel(tabData)} />;
}

import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';

const Dropdown = ({ options, id, label, name, formik, helperText, placeholder }) => {
  const error = formik?.touched?.[name] && formik?.errors?.[name];

  return (
    <FormControl fullWidth>
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        labelId={id}
        id={name}
        name={name}
        input={<OutlinedInput />}
        renderValue={(selected) => {
          if (selected) {
            const menuItem = options.find((option) => option.value === selected);
            return menuItem ? menuItem.label : selected;
          } else return placeholder;
        }}
        value={formik?.values[name] || ''}
        onChange={formik.handleChange}
        label={label}
        displayEmpty
        error={Boolean(error)}
      >
        {placeholder && (
          <MenuItem disabled value="">
            {placeholder}
          </MenuItem>
        )}
        {options?.length > 0 &&
          options.map((option) => (
            <MenuItem value={option.value} key={option.value}>
              {option.label}
            </MenuItem>
          ))}
      </Select>
      <FormHelperText error={Boolean(error)}>{helperText || error}</FormHelperText>
    </FormControl>
  );
};

Dropdown.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.string,
  variant: PropTypes.string,
  options: PropTypes.array,
  formik: PropTypes.object,
};

Dropdown.defaultProps = {
  name: '',
  id: '',
  label: '',
  helperText: '',
  variant: 'standard',
  options: [],
  formik: {},
};

export default Dropdown;

import * as actionTypes from '../actions/actionTypes';
import _, { find } from 'lodash';
import { Roles } from 'utils/general';
import sessionStorage from 'services/sessionStorage';

export const defaultState = {
  userProfile: null,
  meLoading: false,
  error: null,
  userTenants:[],
  tenantData:{},
  userPermissions:null,
  userPermissionLoading:false
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case actionTypes.ME_START:
      return {
        ...state,
        meLoading: true,
        error: null,
      };

    case actionTypes.ME_SUCCESS: {
      let userData = action.payload?.data;
      if (_.isEqual(userData, state.userProfile)) {
        return {
          ...state,
          meLoading: false,
        };
      } else {
        return {
          ...state,
          meLoading: false,
          userProfile: userData,
          tenantData: action.payload.tenantData,
          userTenants:action.payload.userTenants,
        };
      }
    }
    case actionTypes.ME_ERROR:
      return {
        ...state,
        meLoading: false,
        error: action.payload,
      };

      case actionTypes.USER_PERMISSION_START:
      return {
        ...state,
        userPermissions:null,
        userPermissionLoading: true,
        error: null,
      };

    case actionTypes.USER_PERMISSION_SUCCESS: {
      const userPermissions = action.payload?.data?.payload?.permissions;
      
        return {
          ...state,
          userPermissionLoading: false,
          userPermissions
        };
    }
    case actionTypes.USER_PERMISSION_ERROR:
      return {
        ...state,
        userPermissionLoading: false,
        error: action.payload,
      };

    case actionTypes.SWITCH_TENANT: {
      sessionStorage.set('SELECTED_TENANT',JSON.stringify(action.payload));
      return {
        ...state,
        tenantData:action.payload
      }
    }

    default:
      return state;
  }
};

import React from 'react';
import { styled } from '@mui/material';
import { Slider as MuiSlider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_THRESHOLD_VALUE } from 'store/actions/actionTypes';

const CustomSlider = styled(MuiSlider)(() => ({
  '&.MuiSlider-root': {
    height: 6,
    '.MuiSlider-rail, .MuiSlider-track': {
      background: 'linear-gradient(90deg, #36C395 -2.18%, #F8E755 32.66%, #FE9800 68.03%, #FF007F 99.17%)',
      opacity: 1,
    },
    '.MuiSlider-thumb': {
      width: 16,
      height: 24,
      borderRadius: 6,
      color: '#fff',
      '&::after': {
        height: 36,
        width: 36,
      },
    },
    '.MuiSlider-mark': {
      width: 0,
      height: 0,
    },
  },
}));

const marks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 100,
    label: '100',
  },
];
function ColorSlider() {
  const dispatch = useDispatch();
  const { threshold_value } = useSelector((state) => state.form);
  return (
    <CustomSlider
      defaultValue={threshold_value}
      aria-label="Default"
      valueLabelDisplay="auto"
      marks={marks}
      track={false}
      onChangeCommitted={(e, v) => dispatch({ type: UPDATE_THRESHOLD_VALUE, payload: v })}
    />
  );
}

export default ColorSlider;

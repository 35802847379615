import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import _ from 'lodash';
import { Avatar, Box, FormControl,OutlinedInput, Select,MenuItem, ListItemText, Checkbox, FormHelperText, Typography } from '@mui/material';
// Internal
import Input from 'components/common/FormField/Input/Input';
import { tenantValidationSchema } from 'utils/validationSchema';
import DashedBox from 'components/common/Wrapper/DashedBox';
import { Button } from 'components/common/FormControls';
import { toast } from 'react-toastify';
import { AddTenant, AddTenantFrameworks, getTenantFrameworks, updateTenant } from 'store/actions/tenant';
import { ICON_DELETE } from 'assets/constants/imageConst';
import { ReactSVG } from 'react-svg';
import style from 'components/Profile/profile.module.scss';
import { uploadProfile } from 'store/actions/me';
import CommonLoader from 'components/common/Loader/CommonLoader';
import { getFrameworks } from 'store/actions/controls';



const AddTenantModal = ({ tenantDetail, callTenantList, handleAddModal }) => {
  const dispatch = useDispatch();
  const { loading: tenantLoading,addTenantFrameworksLoading,tenantFrameworksLoading,tenantFrameworks } = useSelector((state) => state.tenant);
  const { isUploadLoading } = useSelector((state) => state.connectors);
  const { frameworkLoading, frameworkList } = useSelector((state) => state.controls);
  
  const isAdd = _.isNil(tenantDetail);
  const [changeInProfile, setChangeInProfile] = useState(false);

  useEffect(()=>{
   dispatch(getFrameworks({per_page:-1}));
   if(!isAdd){
    dispatch(getTenantFrameworks(tenantDetail?.uuid));
   }
  },[]);

  const handleSubmitForm = (data) => {
    if (data.icon instanceof File && changeInProfile) {
      const formData = new FormData();
      formData.append('file', data.icon);

      dispatch(uploadProfile(formData)).then(res => {
        const image_name = res?.value?.data?.payload.data;
        const requestData = {
          ...data,
          icon: image_name
        }
        handleAction(requestData);
      })
    }
    else if(changeInProfile && _.isEmpty(data.icon)){
      data.icon=null;
      handleAction(data);
    }
    else{
      delete data.icon;
      handleAction(data);
    }

  };

  const handleAction = (data) => {
    if (isAdd) {
      return dispatch(AddTenant({ data: { ...data } })).then(res => {
        dispatch(AddTenantFrameworks(res?.value?.data?.payload?.uuid,
          {
            data: { frameworks: [...data.frameworks] }
          })).then(() => {
            callTenantList();
            handleAddModal(false, null);
            toast.success('Tenant created successfully');
          });
      })
    }
    else {
      
      return dispatch(updateTenant(tenantDetail?.uuid, { data: { ...data, site_name: data.tenant_name } })).then(res => {
        dispatch(AddTenantFrameworks(tenantDetail?.uuid,
          {
            data: { frameworks: [...data.frameworks] }
          })).then(() => {
            callTenantList();
            handleAddModal(false, null);
            toast.success('Tenant updated successfully');
          });
      })
    }
  }




  return tenantLoading || isUploadLoading || frameworkLoading || addTenantFrameworksLoading || tenantFrameworksLoading? <CommonLoader height={'50vh'}/> : (
    <Formik
      initialValues={{
        tenant_name: tenantDetail?.site_name || "",
        code: tenantDetail?.code || "",
        icon: tenantDetail?.icon || '',
        frameworks:!isAdd? tenantFrameworks.map(a=>a.framework_id):[]
      }}
      onSubmit={handleSubmitForm}
      validationSchema={tenantValidationSchema}
    >
      {({ errors, touched, values, handleChange, handleSubmit, setFieldValue,initialValues }) => {
        let profilePreview =
          values?.icon instanceof File ? URL.createObjectURL(values?.icon) : values?.icon;
        return (
          <Form style={{padding:'16px'}}>
            <Box mb={3}>
              <Input
                id="tenant_name"
                className="mb-3"
                fullWidth
                placeholder="Tenant name"
                label="Tenant name *"
                name="tenant_name"
                value={values.tenant_name}
                onChange={handleChange}
                error={touched.tenant_name && Boolean(errors.tenant_name)}
                helperText={touched.tenant_name && errors.tenant_name}
              />
            </Box>
            <Box mb={3}>
              <Input
                id="code"
                className="mb-3"
                fullWidth
                placeholder="Code"
                label="Code *"
                name="code"
                value={values.code}
                onChange={handleChange}
                error={touched.code && Boolean(errors.code)}
                helperText={touched.code && errors.code}
              />
            </Box>
            <FormControl fullWidth>
              <Typography sx={{ marginBottom: '8px' }}>Icon</Typography>
              <input
                color="primary"
                accept=".jpg, .png"
                size="5000000"
                type="file"
                name="files"
                onChange={(e) => {
                  const file = e.target.files[0];
                  setFieldValue('icon', file);
                  setChangeInProfile(true);
                }}
                id="icon-button-file"
                style={{ display: 'none' }}
              />
              <label htmlFor="icon-button-file">
                {profilePreview ? (
                  <div className={style.upload_profile_btn}>
                    <Avatar
                      alt="Avatar"
                      name="icon"
                      src={profilePreview}
                      imgProps
                      sx={{ width: 45, height: 45, }}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
                      <ReactSVG
                        src={ICON_DELETE}
                        onClick={(e) => {
                          e.preventDefault();
                          setChangeInProfile(!changeInProfile);
                          setFieldValue("icon", '');
                        }}
                      />
                    </Box>
                  </div>
                ) : (
                  <DashedBox style={{ cursor: 'pointer' }}>
                    <Typography variant="body1" color="primary">
                      Upload Picture
                    </Typography>
                  </DashedBox>
                )}
              </label>
            </FormControl>

            <Box mb={3} mt={3}>
              <FormControl fullWidth>
                <Typography variant="subtitle2" color="text.primary" mb={1} sx={{ display: 'flex' }}>
                  Frameworks*
                </Typography>
                <Select
                  multiple
                  displayEmpty
                  value={values.frameworks}
                  name="frameworks"
                  onChange={handleChange}
                  input={<OutlinedInput />}
                  renderValue={(selected) => {
                    let arr=[];
                    if (selected.length === 0) {
                      return <Typography color="secondary">Select Frameworks</Typography>;
                    }
                    selected.map(select=>{
                      const menuItem = (frameworkList?.frameworks??[]).find((option) => option.id === select);
                      arr.push(menuItem?menuItem.name:select);
                    })
                    return arr.join(', ');
                  }}
                  label="Frameworks"
                  MenuProps={{
                    PaperProps:{
                      style:{
                        maxHeight:'200px',
                        overflowY:'auto'
                      }
                    }
                  }}
                >
                  {(frameworkList?.frameworks??[]).map((item) => (
                    <MenuItem key={item?.id} value={item?.id} style={{ padding: 0 }}>
                      <Checkbox
                        id="framework-check"
                        checked={values.frameworks.indexOf(item?.id) > -1}
                      />
                      <ListItemText primary={item?.name} />
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={Boolean(errors?.frameworks)}>{touched?.frameworks &&errors.frameworks}</FormHelperText>
              </FormControl>
            </Box>

            <Box mt={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                type="submit"
                variant="contained"
                onClick={handleSubmit}
                disabled ={ _.isEqual(initialValues, values)}
              >
                {isAdd ? 'Add' : 'update'}
              </Button>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};
export default AddTenantModal;

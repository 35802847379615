import lightningService from 'services/connectorServices';
import * as actionTypes from './actionTypes';

export const setWorkspaceData = (data) => ({
  type: actionTypes.SET_WORKSPACE_DATA,
  payload: data,
});

export const deleteWorkspaceData = (data) => ({
  type: actionTypes.DELETE_WORKSPACE_DATA,
  payload: data,
});

export const addConnector = (data) => ({
  type: actionTypes.CONNECTOR,
  payload: lightningService.setConnector(data),
});

export const uploadConnectorCertificate = (data) => ({
  type: actionTypes.UPLOAD_CONNECTOR_CERTIFICATE,
  payload: lightningService.uploadConnectorCertificate(data),
});

export const updateConnector = (data) => ({
  type: actionTypes.CONNECTOR,
  payload: lightningService.updateConnector(data),
});

export const getConnectorRuns = (uuid) => ({
  type: actionTypes.GET_CONNECTOR,
  payload: lightningService.getConnectorRuns(uuid),
});
export const addConnectorRuns = (uuid) => ({
  type: actionTypes.POST_CONNECTOR,
  payload: lightningService.addConnectorRuns(uuid),
});

export const getConnectors = (uuid) => ({
  type: actionTypes.CONNECTORS,
  payload: lightningService.getConnectors(uuid),
});

export const getIntegrationDetail = (uuid) => ({
  type: actionTypes.GET_INTEGRATION_DETAIL,
  payload: lightningService.getIntegrationDetail(uuid),
});

export const deleteConnector = (uuid) => ({
  type: actionTypes.DELETE_INTEGRATION_DETAIL,
  payload: lightningService.deleteConnector(uuid),
});

export const addDocumentDetail = (data) => ({
  type: actionTypes.DOCUMENT_UPDATE,
  payload: lightningService.updateDocument(data),
});

export const addControlLibraryDocument = (tenant_uuid, control_code, data) => ({
  type: actionTypes.DOCUMENT_UPDATE,
  payload: lightningService.addControlLibraryDocument(tenant_uuid, control_code, data),
});

export const getDocumentList = (data) => ({
  type: actionTypes.GET_DOCUMENTS,
  payload: lightningService.getDocumentList(data),
});

export const getControlLibraryDocumentList = ({ tenant_uuid, control_code }) => ({
  type: actionTypes.GET_CONTROL_LIBRARY_DOCUMENTS,
  payload: lightningService.getControlLibraryDocumentList({ tenant_uuid, control_code }),
});

export const deleteDocument = (uuid) => ({
  type: actionTypes.DELETE_DOCUMENT,
  payload: lightningService.deleteDocument(uuid),
});

export const downloadDocument = (uuid, type, isPdf) => ({
  type: actionTypes.DOWNLOAD_DOCUMENT,
  payload: lightningService.downloadDocument(uuid, type, isPdf),
});

export const addNotes = (tenant_connector_uuid, control_code, data) => ({
  type: actionTypes.ADD_NOTES,
  payload: lightningService.addNotes(tenant_connector_uuid, control_code, data),
});

export const getNotesList = (tenant_connector_uuid, control_code) => ({
  type: actionTypes.GET_NOTES,
  payload: lightningService.getNotes(tenant_connector_uuid, control_code),
});

export const addControlLibraryNotes = (tenant_uuid, control_code, data) => ({
  type: actionTypes.ADD_NOTES,
  payload: lightningService.addControlLibraryNotes(tenant_uuid, control_code, data),
});

export const getControlLibraryNotesList = (tenant_uuid, control_code) => ({
  type: actionTypes.CONTROL_LIBRARY_GET_NOTES,
  payload: lightningService.getControlLibraryNotesList(tenant_uuid, control_code),
});

export const updateNotes = (notes_uuid, data) => ({
  type: actionTypes.UPDATE_NOTES,
  payload: lightningService.updateNotes(notes_uuid, data),
});

export const deleteNotes = (notes_uuid) => ({
  type: actionTypes.DELETE_NOTES,
  payload: lightningService.deleteNotes(notes_uuid),
});

export const getFiles = (fileName) => ({
  type: actionTypes.GET_FILES,
  payload: lightningService.getFiles(fileName),
});

import { useState, useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import { useLocation } from 'react-router-dom';

//MUI
import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  Typography,
  TableBody,
  TableRow,
  Box,
  Divider,
  Grid,
  styled,
  IconButton,
  Button as MuiButton,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

// Internal
import {
  FILE_DARK,
  IC_CHAT_ALT5,
  IC_CHART_PIE,
  IMPACT_PRIMARY,
  IC_WARNING_ALT,
  IC_LIGHTNING_BOLD,
  IC_UPDATE,
  R_LOGO,
  IC_PLUS,
} from 'assets/constants/imageConst';
import { Tooltip, Button } from 'components/common/FormControls';
import InfoIconComponent from 'components/common/InfoIcon/InfoIconComponent';
import Avatar from 'components/common/Avatar/Avatar';
import ProgressCard from 'components/common/Card/ProgressCard';
import Drawer from '../../ManageRisk/Drawer';
import ControlResponseButton from './ControlResponseButton';
import * as actionTypes from 'store/actions/actionTypes';
import Icon from 'components/common/Icon/Icon';
import Badge from 'components/common/Badge/Badge';
import { BACKEND_DATE_FORMAT } from 'utils/date';
import GroupChip from 'components/common/GroupChip/GroupChip';
import UpdatedDate from 'components/common/UpdatedDate/UpdatedDate';
import { shouldBeMasked } from 'utils/general';
import RBAC from 'components/RBAC';
import {
  RISK_ANALYSIS_INFORMATION_SYSTEMS_CONNECTOR_EDIT_DOCUMENT,
  RISK_ANALYSIS_INFORMATION_SYSTEMS_CONNECTOR_EDIT_NOTES,
  RISK_ANALYSIS_INFORMATION_SYSTEMS_CONNECTOR_RESPONSE,
} from 'utils/permissionUtils';
import { SwipeableDrawer, Tabs, Chip } from 'components/common';
import DocumentList from '../DocumentList';
import {
  addDocumentDetail,
  addNotes,
  deleteDocument,
  downloadDocument,
  getDocumentList,
  getNotesList,
} from 'store/actions/connectors';
import NotesList from '../NotesList';
import { getConnectorNoteAndDcoument } from 'store/actions/riskAnalysis';
import AsyncProfileImage from 'components/common/AsyncProfileImage';
import { TabsWrapper } from 'assets/theme/styles';
import AddControls from '../AddControls';

const header = [
  { name: '', padding: '0px' },
  { name: 'Control ID', padding: '16px 16px 16px 8px' },
  { name: 'Control' },
  { name: 'Frameworks', width: 200 },
  { name: 'Control response' },
  { name: 'Likelihood score' },
  { name: 'Impact score' },
  { name: 'Risk score' },
  { name: 'Last updated' },
  { name: '' },
];

const LastUpdatedStyled = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  columnGap: '8px',
});

const controlResponseTypes = {
  automation: { tooltipText: 'Responded automatically', icon: IC_LIGHTNING_BOLD },
  manual: { tooltipText: 'Updated manually', icon: IC_UPDATE },
};

const ConnectorTableData = ({ vulnerability, isInProgress, tenant_connector_uuid }) => {
  const [open, setOpen] = useState(false);

  const [currentActiveTab, setCurrentActiveTab] = useState(0);
  const [isDrawerOpen, setIsDraweropen] = useState(false);
  const [isAddControlModalOpen, setIsAddControlModalOpen] = useState(false);

  const [selectedControl, setSelectedControl] = useState({});
  const { state } = useLocation();

  const dispatch = useDispatch();
  const { userProfile } = useSelector((state) => state.common);
  const { selectedControls, tenant_connector_data, newlyAddedControls, connector_notes_documents_count } = useSelector(
    (state) => state.riskAnalysis
  );

  const { tenantFramework, controlLibraryList } = useSelector((state) => state.controlsLibrary);

  const controlUnderVulnerability = newlyAddedControls?.filter((nc) => nc.vulnerability_name == vulnerability.name);

  const combinedData =
    controlUnderVulnerability?.length > 0
      ? [...vulnerability.controls, ...controlUnderVulnerability]
      : vulnerability?.controls;

  const vulnerabilityControls = combinedData?.map((vc) => {
    const controlWithFramework = controlLibraryList?.find((cd) => cd.code === vc.code);
    const TenantRelatedcompliances = tenantFramework?.filter((tf) =>
      controlWithFramework?.frameworks?.includes(tf.framework_id)
    );
    return { ...vc, frameworks: TenantRelatedcompliances?.map((f) => ({ ...f, name: f.framework_name })) };
  });

  const cardData =
    (vulnerability?.aggregate_likelihood_score &&
      vulnerability?.aggregate_impact_score &&
      vulnerability?.aggregate_risk_score && [
        {
          title: 'Aggregate likelihood score',
          icon: IC_CHART_PIE,
          tooltip: '',
          chip: null,
          progress: vulnerability?.aggregate_likelihood_score,
        },
        {
          title: 'Aggregate impact score',
          icon: IMPACT_PRIMARY,
          tooltip: '',
          chip: null,
          progress: vulnerability?.aggregate_impact_score,
        },
        {
          title: 'Aggregate risk score',
          icon: IC_WARNING_ALT,
          tooltip: '',
          chip: null,
          progress: vulnerability?.aggregate_risk_score,
          button: shouldBeMasked() ? (
            <Button variant="outlined" color="secondary" size="small" onClick={() => setOpen(true)}>
              Manage risk
            </Button>
          ) : null,
        },
      ]) ||
    [];

  const handleControlResponse = (control, value) => {
    const updatedControl = {
      ...control,
      response: value,
      modified_at: null,
      created_by: userProfile?.uuid,
    };
    dispatch({
      type: actionTypes.SET_VULNERABILITY_CONTROL,
      payload: { updatedControl },
    });
  };

  // Document Functionality
  const getUpdatedDocumentList = () => {
    dispatch(
      getDocumentList({
        tenant_connector_id: tenant_connector_data?.tenant_connector_id,
        control_id: selectedControl.code,
      })
    );
  };

  const updateDocumentDetail = (data) => {
    const body = {
      data: {
        control_code: selectedControl?.code,
        document_names: [
          {
            description: '',
            document_name: data?.docName,
            file_name: data?.pic,
          },
        ],
        tenant_connector_id: tenant_connector_data?.tenant_connector_id,
      },
    };
    return dispatch(addDocumentDetail(body)).then((res) => {
      getUpdatedDocumentList();
      return res;
    });
  };

  const controlDocumentHandler = (row) => {
    setSelectedControl(row);
    setIsDraweropen(true);
    setCurrentActiveTab(1);
  };

  // Notes Functionality
  const getNotes = () => {
    dispatch(getNotesList(state?.id, selectedControl?.code));
  };

  const getCounts = () => {
    dispatch(getConnectorNoteAndDcoument(tenant_connector_uuid));
  };

  const controlNotesHandler = (row) => {
    setSelectedControl(row);
    setIsDraweropen(true);
    setCurrentActiveTab(0);
  };

  const AddNotesHandler = (data) => {
    if (data) {
      dispatch(addNotes(state?.id, selectedControl?.code, { data: { note: data } })).then(() => {
        getNotes();
      });
    }
  };
  // const handleSubmitComment = (control, comment) => {
  //   const updatedControl = {
  //     ...control,
  //     comments: control.comments ? [...control.comments, comment] : [comment],
  //     modified_at: null,
  //   };
  //   dispatch({
  //     type: actionTypes.SET_VULNERABILITY_CONTROL,
  //     payload: { updatedControl },
  //   });
  // };

  const tabInfo = [
    {
      id: 0,
      label: 'Notes',
      component: (
        <RBAC
          action={RISK_ANALYSIS_INFORMATION_SYSTEMS_CONNECTOR_EDIT_NOTES}
          yes={<NotesList AddNotesHandler={AddNotesHandler} refetchData={getNotes} />}
          no={<NotesList AddNotesHandler={AddNotesHandler} refetchData={getNotes} viewOnly={true} />}
        />
      ),
    },
    {
      id: 1,
      label: 'Attachments',
      component: (
        <RBAC
          action={RISK_ANALYSIS_INFORMATION_SYSTEMS_CONNECTOR_EDIT_DOCUMENT}
          yes={
            <DocumentList
              selectedControl={selectedControl}
              updateDocumentDetail={updateDocumentDetail}
              deleteDocument={deleteDocument}
              downloadDocument={downloadDocument}
              refetchData={getUpdatedDocumentList}
            />
          }
          no={
            <DocumentList
              selectedControl={selectedControl}
              updateDocumentDetail={updateDocumentDetail}
              deleteDocument={deleteDocument}
              downloadDocument={downloadDocument}
              refetchData={getUpdatedDocumentList}
              viewOnly={true}
            />
          }
        />
      ),
    },
  ];

  return (
    <TableContainer sx={{ overflow: 'auto', whiteSpace: 'nowrap' }}>
      <Divider />

      {cardData?.length > 0 && (
        <Grid container spacing={2} sx={{ marginTop: '16px' }}>
          {cardData?.map((cardValue, index) => (
            <Grid item xs={12} sm={4} key={index}>
              <ProgressCard
                title={cardValue.title}
                icon={cardValue.icon}
                chip={cardValue.chip}
                progress={cardValue.progress}
                tooltip={cardValue.tooltip}
                button={cardValue.button || <></>}
              />
            </Grid>
          ))}
        </Grid>
      )}
      <Table sx={{ minWidth: 650, mt: 2 }} size="medium" aria-label="a dense table">
        <TableHead sx={{ backgroundColor: '#FFFFFF80', borderRadius: '8px' }}>
          <TableRow>
            {header.map((h, index) => (
              <TableCell key={index} sx={{ minWidth: h?.width, padding: h?.padding || 2 }} id={h.name}>
                <Typography variant="subtitle2" color="secondary">
                  {h.name}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {vulnerabilityControls?.map((row, index) => {
            const selected = selectedControls.find((value) => value.code === row.code)?.response || row?.response;
            // const comments = selectedControls.find((value) => value.code === row.code)?.comments || row?.comments;
            const count =
              connector_notes_documents_count &&
              connector_notes_documents_count?.find((a) => {
                return a.code === row.code;
              });
            const documentCount = count && count.document != 0 ? count.document : false;
            const noteCount = count && count.note != 0 ? count.note : false;

            return (
              <TableRow
                key={index}
                // sx={{
                //   '&:last-child td, &:last-child th': { border: 0 },
                // }}
              >
                <TableCell sx={{ padding: 0 }}>
                  <Tooltip title={controlResponseTypes[row.control_response_type]?.tooltipText} arrow placement="top">
                    <div>
                      {controlResponseTypes[row.control_response_type]?.icon && (
                        <Icon src={controlResponseTypes[row.control_response_type]?.icon} size="small" />
                      )}
                    </div>
                  </Tooltip>
                </TableCell>
                <TableCell sx={{ pl: 1 }}>
                  <Typography variant="body2">{row.code}</Typography>
                </TableCell>
                <TableCell>
                  <Box display="flex">
                    <Typography variant="body2">{row.name}</Typography>
                    <Tooltip title={row['Device Encryption']} arrow placement="top">
                      <InfoIconComponent />
                    </Tooltip>
                  </Box>
                </TableCell>
                <TableCell>
                  {row.frameworks?.length > 0 ? <GroupChip data={row.frameworks} width={200} /> : '-'}
                </TableCell>
                <TableCell>
                  <RBAC
                    action={RISK_ANALYSIS_INFORMATION_SYSTEMS_CONNECTOR_RESPONSE}
                    yes={
                      <ControlResponseButton
                        handleControlResponse={(value) => handleControlResponse(row, value)}
                        defaultValue={selected}
                        disabled={isInProgress}
                      />
                    }
                    no={
                      <ControlResponseButton
                        handleControlResponse={(value) => handleControlResponse(row, value)}
                        defaultValue={selected}
                        disabled={true}
                      />
                    }
                  />
                </TableCell>
                <TableCell>
                  {row.likelyhood_score || '-'}
                  {/* <Score currentScore={row.likelihood_score} /> */}
                </TableCell>
                <TableCell>
                  {row.impact_score || '-'}
                  {/* <Score currentScore={row.impact_score} /> */}
                </TableCell>
                <TableCell>{row.risk_score || '-'}</TableCell>
                <TableCell>
                  {/* {moment(moment.utc('06/01/2023 10:47:30', 'DD/MM/YYYY HH:mm:ss').toDate()).fromNow()} */}
                  <LastUpdatedStyled>
                    {controlResponseTypes[row.control_response_type] && row.modified_at ? (
                      <>
                        <div>
                          {row?.control_response_type == 'automation' ? (
                            <Avatar size="small" src={R_LOGO}></Avatar>
                          ) : row?.control_response_type == 'manual' && row.created_by ? (
                            <AsyncProfileImage uuid={row.created_by} />
                          ) : (
                            <Avatar size="small"></Avatar>
                          )}
                        </div>
                        <Typography variant="cabin">
                          <UpdatedDate date={row?.modified_at} dateFormat={BACKEND_DATE_FORMAT} />
                        </Typography>
                      </>
                    ) : (
                      '-'
                    )}
                  </LastUpdatedStyled>
                </TableCell>
                <TableCell>
                  <Box sx={{ display: 'flex', columnGap: '8px', alignItems: 'center' }}>
                    <Badge badgeCount={noteCount} color="secondary" invisible={!noteCount}>
                      <IconButton sx={{ padding: 0 }} onClick={() => controlNotesHandler(row)}>
                        <Avatar variant="rounded" size="small">
                          <ReactSVG src={IC_CHAT_ALT5} />
                        </Avatar>
                      </IconButton>
                    </Badge>
                    {/* {anchorCommentEl && (
                      <Popover anchorEl={anchorCommentEl} onClose={() => setAnchorCommentEl(null)}>
                        {comments?.length > 0 &&
                          comments.map((c) => <CommentResponse key={c} size="default" comment={c} sx={{ mb: 1 }} />)}
                        <Comment handleSubmit={(comment) => handleSubmitComment(row, comment)} />
                      </Popover>
                    )} */}
                    {/* ------------Upload file-------------- */}
                    <IconButton onClick={() => controlDocumentHandler(row)}>
                      <Badge badgeCount={documentCount} color="secondary" size="small" invisible={!documentCount}>
                        <Avatar variant="rounded" size="small">
                          <ReactSVG src={FILE_DARK} />
                        </Avatar>
                      </Badge>
                    </IconButton>
                    {/* {fileUploadOpen && (
                      <FileUpload
                        open={fileUploadOpen}
                        files={filesData}
                        setFiles={setFilesData}
                        setOpen={setFileUploadOpen}
                      />
                    )}  */}
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Box sx={{ padding: '12px 0' }}>
        <MuiButton
          startIcon={<Icon src={IC_PLUS} size="small" />}
          variant="standard"
          sx={{
            '&.MuiButtonBase-root': {
              ':hover': {
                backgroundColor: 'transparent',
              },
            },
          }}
          onClick={() => setIsAddControlModalOpen(true)}
        >
          <Typography variant="subtitle2">Add Control</Typography>
        </MuiButton>
      </Box>

      {open && <Drawer open={open} setOpen={setOpen} data={vulnerability} />}

      {isDrawerOpen && (
        <SwipeableDrawer
          anchor="right"
          open={isDrawerOpen}
          onclose={() => {
            getCounts();
            setIsDraweropen(false);
          }}
          onopen={() => {}}
          isFooter={false}
          title={<Chip label={selectedControl?.code} />}
          subTitleComponent={
            <Typography variant="subtitle1" display="inline-flex">
              {selectedControl?.name}
              <InfoIconComponent />
            </Typography>
          }
          onSave={() => {}}
          isHeaderDivider={false}
        >
          <TabsWrapper>
            <Tabs
              tabInfo={tabInfo}
              defaultTab={currentActiveTab}
              onTabChange={({ currentTab }) => setCurrentActiveTab(currentTab)}
            />
          </TabsWrapper>
        </SwipeableDrawer>
      )}
      {isAddControlModalOpen && (
        <AddControls
          open={isAddControlModalOpen}
          onclose={() => setIsAddControlModalOpen(false)}
          vulnerability={vulnerability}
        />
      )}
    </TableContainer>
  );
};
export default ConnectorTableData;

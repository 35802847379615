import React from 'react';
import { styled, Box } from '@mui/material';

const WrapperCard = ({ children, ...rest }) => {
  const CustomBox = styled(Box)(({ theme }) => ({
    padding: '20px 16px',
    borderRadius: theme.palette.borderRadius.main,
    background: theme.palette.background.whiteShade5,
    '&:hover': {
      boxShadow: theme.customShadows.card,
    },
  }));
  return <CustomBox {...rest}>{children}</CustomBox>;
};

export default WrapperCard;

import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import _ from 'lodash';
import {
  Box,
  FormControl,
  OutlinedInput,
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
  Typography,
  FormHelperText,
} from '@mui/material';
// Internal
import Input from 'components/common/FormField/Input/Input';
import { controlValidationSchema } from 'utils/validationSchema';
import { Button } from 'components/common/FormControls';
import { useDispatch, useSelector } from 'react-redux';
import CommonLoader from 'components/common/Loader/CommonLoader';
import { AddUpdateControls, getControls } from 'store/actions/controls';
import { toast } from 'react-toastify';

const ControlDrawer = ({ handleAddModal, controlDetail, setCurrentPage, defaultParams }) => {
  const dispatch = useDispatch();
  const { addControlLoading, frameworkLoading, frameworkList } = useSelector((state) => state.controls);
  const isAdd = _.isNil(controlDetail);

  const controlHandler = (values) => {
    const response = {
      data: {
        ...values,
      },
    };
    if (!isAdd) {
      response['data']['uuid'] = controlDetail?.uuid;
    }
    dispatch(AddUpdateControls(response)).then(() => {
      dispatch(getControls({ ...defaultParams, page: 1 }));
      handleAddModal(false, null);
      toast.success(`Control ${isAdd ? 'created' : 'updated'} successfully`);
    });
    setCurrentPage(1);
  };

  return addControlLoading || frameworkLoading ? (
    <CommonLoader height={'50vh'} />
  ) : (
    <Formik
      initialValues={{
        name: controlDetail?.name ?? '',
        code: controlDetail?.code ?? '',
        description: controlDetail?.description ?? '',
        question: controlDetail?.question ?? '',
        weight: controlDetail?.weight ?? '',
        frameworks: controlDetail?.frameworks ?? [],
      }}
      onSubmit={controlHandler}
      validationSchema={controlValidationSchema}
    >
      {({ errors, touched, values, handleChange, handleSubmit, initialValues }) => {
        return (
          <Form>
            <Box mb={3}>
              <Input
                id="name"
                className="mb-3"
                fullWidth
                placeholder="Control name"
                label="Control name *"
                name="name"
                value={values.name}
                onChange={handleChange}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
              />
            </Box>
            <Box mb={3}>
              <Input
                id="code"
                className="mb-3"
                fullWidth
                placeholder="Control code"
                label="Control code *"
                name="code"
                value={values.code}
                onChange={handleChange}
                error={touched.code && Boolean(errors.code)}
                helperText={touched.code && errors.code}
                disabled={!isAdd}
              />
            </Box>
            <Box mb={3}>
              <Input
                id="weight"
                className="mb-3"
                fullWidth
                type="number"
                placeholder="Weight"
                label="Weight *"
                name="weight"
                value={values.weight}
                onChange={handleChange}
                error={touched.weight && Boolean(errors.weight)}
                helperText={touched.weight && errors.weight}
              />
            </Box>
            <Box mb={3}>
              <Input
                id="question"
                className="mb-3"
                fullWidth
                multiline={true}
                row={2}
                minRows={1}
                placeholder="Question"
                label="Question *"
                name="question"
                value={values.question}
                onChange={handleChange}
                error={touched.question && Boolean(errors.question)}
                helperText={touched.question && errors.question}
              />
            </Box>
            <Box mb={3}>
              <Input
                id="description"
                className="mb-3"
                fullWidth
                multiline={true}
                row={5}
                minRows={3}
                placeholder="Description"
                label="Description *"
                name="description"
                value={values.description}
                onChange={handleChange}
                error={touched.description && Boolean(errors.description)}
                helperText={touched.description && errors.description}
              />
            </Box>
            <Box mb={3}>
              <FormControl fullWidth>
                <Typography variant="subtitle2" color="text.primary" mb={1} sx={{ display: 'flex' }}>
                  Frameworks *
                </Typography>
                <Select
                  multiple
                  displayEmpty
                  value={values.frameworks}
                  name="frameworks"
                  onChange={handleChange}
                  input={<OutlinedInput />}
                  renderValue={(selected) => {
                    let arr = [];
                    if (selected.length === 0) {
                      return <Typography color="secondary">Select Frameworks</Typography>;
                    }
                    selected.map((select) => {
                      const menuItem = (frameworkList?.frameworks ?? []).find((option) => option.id === select);
                      arr.push(menuItem ? menuItem.name : select);
                    });
                    return arr.join(', ');
                  }}
                  label="Frameworks"
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: '200px',
                        overflowY: 'auto',
                      },
                    },
                  }}
                >
                  {(frameworkList?.frameworks ?? []).map((item) => (
                    <MenuItem key={item?.id} value={item?.id} style={{ padding: 0 }}>
                      <Checkbox id="framework-check" checked={values.frameworks.indexOf(item?.id) > -1} />
                      <ListItemText primary={item?.name} />
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={Boolean(errors?.frameworks)}>
                  {touched?.frameworks && errors.frameworks}
                </FormHelperText>
              </FormControl>
            </Box>

            <Box mt={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                type="submit"
                variant="contained"
                onClick={handleSubmit}
                disabled={_.isEqual(initialValues, values)}
              >
                {isAdd ? 'Add' : 'Update'}
              </Button>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};
export default ControlDrawer;

import {
  Typography,
  Paper,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  TableRow,
  TableHead,
  Grid,
} from '@mui/material';

const tableHeader = [
  'Asset Group',
  'Severities',
  'Vulnerable Hosts',
  'Vulnerable Type',
  'Vulnerable Status',
  'Vulnerability Age',
];

const tableSubHeader = [
  ['Title', 'Hosts Scanned'],
  ['Level 5', 'Level 4', 'Level 3'],
  ['Total', 'Goal%'],
  ['Confirmed', 'Potential'],
  ['New', 'Active', 'Fixed', 'Re-Opened'],
  ['> 30 days', '> 60 days', '> 90 days'],
];

const tableRows = [
  {
    Title: 'All Windows Desktop',
    'Hosts Scanned': '4',
    'Level 5': 165,
    'Level 4': 388,
    'Level 3': 179,
    Total: 3,
    'Goal%': '75%',
    Confirmed: 732,
    Potential: 0,
    New: 27,
    Active: 703,
    Fixed: 0,
    'Re-Opened': 2,
    '> 30 days': 27,
    '> 60 days': 0,
    '> 90 days': 705,
  },
  {
    Title: 'All Windows Desktop',
    'Hosts Scanned': '4',
    'Level 5': 165,
    'Level 4': 388,
    'Level 3': 179,
    Total: 3,
    'Goal%': '75%',
    Confirmed: 732,
    Potential: 0,
    New: 27,
    Active: 703,
    Fixed: 0,
    'Re-Opened': 2,
    '> 30 days': 27,
    '> 60 days': 0,
    '> 90 days': 705,
  },
  {
    Title: 'Total',
    'Hosts Scanned': '4',
    'Level 5': 165,
    'Level 4': 388,
    'Level 3': 179,
    Total: 3,
    'Goal%': '75%',
    Confirmed: 732,
    Potential: 0,
    New: 27,
    Active: 703,
    Fixed: 0,
    'Re-Opened': 2,
    '> 30 days': 27,
    '> 60 days': 0,
    '> 90 days': 705,
  },
];

const DetailedResult = () => {
  return (
    <TableContainer>
      <Table>
        <TableHead component={Paper} sx={{ borderRadius: '8px' }}>
          <TableRow>
            {tableHeader.map((tHeader, index) => (
              <TableCell key={index} sx={{ padding: '8px' }}>
                <Typography variant="cabin" sx={{ fontSize: '10px' }}>
                  {tHeader}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {tableSubHeader.map((tSubHeader, index) => (
              <TableCell key={index} sx={{ padding: '8px' }}>
                <Grid container>
                  {tSubHeader.map((tHead, tIndex) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={Math.floor(12 / tSubHeader.length)}
                        key={index + tIndex}
                        justifyContent="start"
                      >
                        <Typography variant="cabin" color="secondary" sx={{ fontSize: '10px' }}>
                          {tHead}
                        </Typography>
                      </Grid>
                    );
                  })}
                </Grid>
              </TableCell>
            ))}
          </TableRow>
          {tableRows.map((tRow, tMainIndex) => (
            <TableRow key={tMainIndex} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              {tableSubHeader.map((tSubHeader, index) => (
                <TableCell key={index} sx={{ padding: '8px' }}>
                  <Grid container>
                    {tSubHeader.map((tHead, tIndex) => {
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={Math.floor(12 / tSubHeader.length)}
                          key={index + tIndex}
                          justifyContent="start"
                        >
                          <Typography variant="cabin" sx={{ fontSize: '10px' }}>
                            {tRow[tHead]}
                          </Typography>
                        </Grid>
                      );
                    })}
                  </Grid>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default DetailedResult;

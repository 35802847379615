import React from 'react';
import { ReactSVG } from 'react-svg';
import { Typography, Grid, Box, Avatar, List, ListItem } from '@mui/material';

import { Chip, SwipeableDrawer, Tabs } from 'components/common';
import { IC_CROSS_CIRCLE, DOWN_ARROW_FILL, IC_CALENDER, USER, USER1 } from 'assets/constants/imageConst';
import { riskResponse } from 'assets/constants/options';
import Comments from './Comments/Comments';
import Description from './Description/Description';
import Tasks from './Task/Tasks';
import InfoIconComponent from 'components/common/InfoIcon/InfoIconComponent';
import Icon from 'components/common/Icon/Icon';
import { TabsWrapper } from 'assets/theme/styles';

// const StyledPlaceholder = styled('div')({
//   color: 'rgba(79, 74, 85, 0.6)',
//   fontWeight: 400,
//   fontSize: '13px',
// });

const userData = [
  { label: 'Erin Carder', value: 'Erin Carder', icon: 'E' },
  { label: 'Gustavo Mango', value: 'Gustavo Mango', icon: 'A' },
  { label: 'Craig Passaquindici Arcand', value: 'Craig Passaquindici Arcand', icon: 'T' },
  { label: 'Rayna Stanton', value: 'Rayna Stanton', icon: 'A' },
];

const Drawer = ({ open, setOpen, data }) => {
  const fieldData = [
    {
      icon: <InfoIconComponent ml={0} />,
      label: 'Relevant controls',
      value: (
        <List sx={{ padding: 0 }}>
          {data?.controls?.map((c, index) => (
            <ListItem key={index} sx={{ padding: '0 0 8px 0' }}>
              <Chip label={c.code + ' ' + c.name} />
            </ListItem>
          ))}
        </List>
      ),
    },
    {
      icon: <ReactSVG src={DOWN_ARROW_FILL} />,
      label: 'Risk response',
      value: (
        <Chip
          icon={<ReactSVG src={riskResponse[0].icon} className={riskResponse[0].class} />}
          color={riskResponse[0].color}
          label={riskResponse[0].label}
        />
        // <Select
        //   sx={{ width: '100%', height: '35px', background: '#F1EFF7', borderRadius: '8px' }}
        //   value={response}
        //   displayEmpty
        //   renderValue={!response ? () => <StyledPlaceholder>Select an option </StyledPlaceholder> : ''}
        //   onChange={(e) => setResponse(e.target.value)}
        // >
        //   {riskResponse.map((r, index) => (
        //     <MenuItem key={index} value={r.value}>
        //       <Chip icon={<ReactSVG src={r.icon} className={r.class} />} color={r.color} label={r.label} />
        //     </MenuItem>
        //   ))}
        // </Select>
      ),
    },
    {
      icon: <Icon src={USER} size="small" />,
      label: 'Risk owner',
      value: (
        <Chip
          label={
            <Box
              sx={{
                display: 'flex',
                columnGap: '2px',
                flexDirection: 'row',
                alignItems: 'self-start',
                justifyContent: 'center',
              }}
            >
              <Typography variant="body2">{userData[0].label}</Typography>
              <Icon size="small" src={IC_CROSS_CIRCLE} />
            </Box>
          }
          icon={<Avatar sx={{ width: 24, height: 24 }} src={USER1} />}
        />

        // <Select
        //   value={owner}
        //   displayEmpty
        //   renderValue={!owner ? () => <StyledPlaceholder>Search for a user </StyledPlaceholder> : ''}
        //   onChange={(e) => setOwner(e.target.value)}
        //   sx={{ width: '100%', height: '35px', background: '#F1EFF7', borderRadius: '8px' }}
        // >
        //   {userData.map((ud, index) => (
        //     <MenuItem key={index} value={ud.value}>
        //       <Box sx={{ display: 'flex', columnGap: '10px' }}>
        //         <Avatar sx={{ width: 24, height: 24 }}>{ud.icon}</Avatar>
        //         <Typography>{ud.label}</Typography>
        //       </Box>
        //     </MenuItem>
        //   ))}
        // </Select>
      ),
    },
    {
      icon: <Icon size="small" src={IC_CALENDER} />,
      label: 'Due date',
      value: (
        <Typography variant="body2" color="secondary">
          12/25/2022
        </Typography>
        // <DatepickerStyled
        //   customInput={<TextField fullWidth variant="outlined" />}
        //   closeOnScroll={true}
        //   selected={date}
        //   placeholderText="Select a date"
        //   sx={{ backgroundColor: '#F1EFF7', borderRadius: '8px' }}
        //   onChange={(date) => setDate(date)}
        // />
      ),
    },
  ];

  const tabInfo = [
    {
      id: 0,
      label: 'Description',
      component: <Description />,
    },
    {
      id: 1,
      label: 'Tasks',
      component: <Tasks vulnerabilityData={data} />,
    },
    {
      id: 2,
      label: 'Comments',
      component: <Comments />,
    },
  ];

  return (
    <SwipeableDrawer
      open={open}
      onclose={() => setOpen(false)}
      onopen={() => {}}
      anchor="right"
      title={<Chip label={data?.event_name} />}
      subTitle={data?.name}
      onSave={() => setOpen(false)}
      cancelText="Cancel"
      saveText="Save"
    >
      <>
        <Box sx={{ padding: '24px', display: 'flex', flexDirection: 'column', rowGap: '15px' }}>
          {fieldData.map((fd, index) => (
            <Grid container key={index}>
              <Grid item xs={12} sm={4}>
                <Box sx={{ display: 'flex', flexDirection: 'row', columnGap: '5px' }}>
                  {fd.icon}
                  <Typography variant="body2" color="secondary">
                    {fd.label}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={8}>
                {fd.value}
              </Grid>
            </Grid>
          ))}
        </Box>
        <TabsWrapper>
          <Tabs tabInfo={tabInfo} />
        </TabsWrapper>
      </>
    </SwipeableDrawer>
  );
};
export default Drawer;

import React, { useState } from 'react';
import { List, Box, ListItemButton, ListItemIcon, Typography, ListItemText, Collapse } from '@mui/material';
import { styled } from '@mui/material/styles';

import _ from 'lodash';

//Internal
import ConnectorTableData from './Google/ConnectorTableData';
import { IC_ARROW_DOWN, IC_ARROW_RIGHT } from 'assets/constants/imageConst';
import { Accordion, Chip } from 'components/common';
import { IconMapping } from 'assets/constants/const';
import Avatar from 'components/common/Avatar/Avatar';
import Icon from 'components/common/Icon/Icon';

export const StyledNavItemIcon = styled(ListItemIcon)({
  height: 20,
  minWidth: 40,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const NestedList = ({ data, tabName, isInProgress, tenant_connector_uuid }) => {
  const [open, setOpen] = useState([]);

  const handleClick = (key) => {
    const index = open.indexOf(key);
    if (index > -1) {
      setOpen((pre) => pre.filter((p) => p !== key));
    } else {
      setOpen((pre) => [...pre, key]);
    }
  };
  // Network Provider Dificiencies

  return (
    <List component="nav">
      {data &&
        _.map(data, (raw, key) => {
          return (
            <Box key={key}>
              <ListItemButton>
                <Icon size="small" variant="default" src={IC_ARROW_DOWN} />
                <StyledNavItemIcon>
                  <Avatar size="small" bg="primary">
                    {IconMapping[key] && <Icon src={IconMapping[key]} size="small" />}
                  </Avatar>
                </StyledNavItemIcon>
                <ListItemText primary={<Typography variant="subtitle1">{key}</Typography>} />
              </ListItemButton>
              <Collapse in={true} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {_.map(raw, (value1, key2) => {
                    return (
                      <Box key={key2}>
                        <ListItemButton onClick={() => handleClick(`${key}-${key2}`)} sx={{ pl: 5 }}>
                          {open?.includes(`${key}-${key2}`) ? (
                            <Icon variant="default" size="small" src={IC_ARROW_DOWN} />
                          ) : (
                            <Icon variant="default" size="small" src={IC_ARROW_RIGHT} />
                          )}
                          <ListItemText primary={<Chip label={key2} />} sx={{ ml: 0.5 }} />
                        </ListItemButton>
                        <Collapse in={open?.includes(`${key}-${key2}`)} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                            <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '8px' }} pl={8}>
                              {_.map(value1, (valn, key3) => {
                                return (
                                  <Accordion
                                    key={key3}
                                    leftSideSummary={<Typography variant="body2">{valn.name}</Typography>}
                                    rightSideSummary={
                                      <Chip label={`Aggregate risk score: ${valn.aggregate_risk_score || 'NA'}`} />
                                    }
                                    expandedPanelId={key3}
                                  >
                                    <ConnectorTableData
                                      vulnerability={{ ...valn, event_name: key2 }}
                                      tabName={tabName}
                                      isInProgress={isInProgress}
                                      tenant_connector_uuid={tenant_connector_uuid}
                                    />
                                  </Accordion>
                                );
                              })}
                            </Box>
                          </List>
                        </Collapse>
                      </Box>
                    );
                  })}
                </List>
              </Collapse>
            </Box>
          );
        })}
    </List>
  );
};
export default NestedList;

import React from 'react';
import { ReactSVG } from 'react-svg';
import { Box, styled, Typography } from '@mui/material';
import { IC_LAPTOP } from 'assets/constants/imageConst';
import Chip from '../Chip/Chip';
import LinearProgressbar from '../Progressbar/LinearProgressbar';
import ImageWrapper from '../Wrapper/ImageWrapper';
import { getProgressColor } from 'utils/general';

const CustomBox = styled(Box)(({ theme }) => ({
  borderRadius: theme.palette.borderRadius.main,
  backgroundColor: theme.palette.background.whiteShade5,
  padding: theme.spacing(1.5, 1),
  display: 'flex',
  columnGap: '16px',
  alignItems: 'center',
}));

function ProgressRiskRow({ title, icon, progressValue, device }) {
  return (
    <CustomBox>
      <Box width="30%">
        <Box sx={{ display: 'flex', alignItems: 'center', lineHeight: 0 }}>
          <ImageWrapper>
            <img src={icon} />
          </ImageWrapper>
          <Typography variant="subtitle2" ml={1.5}>
            {title}
          </Typography>
        </Box>
      </Box>
      <Box width="40%">
        <Box>
          <LinearProgressbar value={progressValue} color={getProgressColor(progressValue, true)} />
        </Box>
      </Box>
      <Box width="10%">
        <Typography variant="progressLabel">{progressValue}%</Typography>
      </Box>
      <Box width="20%">
        <Chip label={device} icon={<ReactSVG src={IC_LAPTOP} />} />
      </Box>
    </CustomBox>
  );
}

ProgressRiskRow.defaultProps = {
  title: '',
  device: '',
  icon: '',
  progressValue: 0,
};

export default ProgressRiskRow;

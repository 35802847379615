// MUI
import { Typography, Grid, Divider } from '@mui/material';

// Internal
import { Avatar, Chip, CircularProgressbar } from 'components/common';
import { Button } from 'components/common/FormControls';
import WrapperBox from 'components/common/Wrapper/WrapperBox';
import { Box } from '@mui/system';
import Title from 'components/common/Wrapper/Title';
import { ReactSVG } from 'react-svg';
import {
  FILE_DARK,
  IC_DOWNLOAD,
  IC_LOCK_CHECK,
  IC_SHIELD,
  IC_CALENDER,
  IC_WARNING_ALT,
} from 'assets/constants/imageConst';

const cardData = [
  {
    icon: IC_LOCK_CHECK,
    title: 'HIPAA Privacy Rule',
    subTitle: '03/15/2022',
    progressbar: (
      <CircularProgressbar
        value={100}
        valueLabel={<Typography variant="subtitle2">100%</Typography>}
        thickness={2}
        size={50}
        color="success"
      />
    ),
    content: '03/15/2022',
  },
  {
    icon: IC_SHIELD,
    title: 'HIPAA Security Rule',
    subTitle: '03/15/2022',
    progressbar: (
      <CircularProgressbar
        value={100}
        valueLabel={<Typography variant="subtitle2">100%</Typography>}
        thickness={2}
        size={50}
        color="success"
      />
    ),
    content: '03/15/2022',
  },
  {
    icon: IC_WARNING_ALT,
    title: 'Breach Notification Rule',
    subTitle: '03/15/2022',
    progressbar: (
      <CircularProgressbar
        value={100}
        valueLabel={<Typography variant="subtitle2">100%</Typography>}
        thickness={2}
        size={50}
        color="success"
      />
    ),
    content: '03/15/2022',
  },
];

const Card = ({ icon, title, subTitle, progressbar }) => (
  <WrapperBox padding="16px">
    <Box mb={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Box>
        <Avatar sx={{ mb: 2 }} color="secondary">
          <ReactSVG src={icon} />
        </Avatar>
        <Typography variant="subtitle2" mb={0.5}>
          {title}
        </Typography>
        <Typography variant="caption" color="secondary">
          Completed :{subTitle}
        </Typography>
      </Box>
      <Box>{progressbar}</Box>
    </Box>

    <Divider />
    <Box mt={2} display={'flex'} sx={{ alignItems: 'center' }}>
      <Typography variant="body2" color="secondary" mr={0.5}>
        Next revision date
      </Typography>
      <Chip label="01/31/2023" icon={<ReactSVG src={IC_CALENDER} />} />
    </Box>
  </WrapperBox>
);

const PolicyProcurement = () => {
  return (
    <WrapperBox>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} mb={1}>
        <Title title="Policies and Procedures" />
        <Box>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            startIcon={<ReactSVG src={IC_DOWNLOAD} className="download-small-icon" />}
          >
            Auditor report
          </Button>
        </Box>
      </Box>
      <Box mb={4}>
        <Chip label="45 CFR §164.308(a)(1)(ii)(A)" icon={<ReactSVG src={FILE_DARK} />} />
      </Box>
      <Grid container spacing={2}>
        {cardData.map((card, index) => (
          <Grid item xs={12} sm={4} key={index}>
            <Card
              icon={card.icon}
              title={card.title}
              subTitle={card.subTitle}
              progressbar={card.progressbar}
              content={card.content}
            />
          </Grid>
        ))}
      </Grid>
    </WrapperBox>
  );
};
export default PolicyProcurement;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

// MUI
import { styled } from '@mui/system';
import { Typography, Box, IconButton, Grid } from '@mui/material';

// Internal
import { IC_GRID, IC_LIST, IC_SEARCH, IC_SORT } from 'assets/constants/imageConst';
import GridView from './GridView';
import ListView from './ListView';
import WrapperBox from 'components/common/Wrapper/WrapperBox';
import { Button } from 'components/common/FormControls';
import { getConnectors } from 'store/actions/connectors';
import connectorsData from '../../assets/constants/connectors';
import * as actionTypes from 'store/actions/actionTypes';
import storage from 'services/storage';
import CommonLoader from 'components/common/Loader/CommonLoader';
import { connectorsName } from 'assets/constants/const';
import Icon from 'components/common/Icon/Icon';
import { shouldBeMasked } from 'utils/general';
import RBAC from 'components/RBAC';
import { RISK_AI_LIGHTENING_INTEGRATIONS_ADD_CUSTOM_CONNECTOR } from 'utils/permissionUtils';
import ButtonDropdown from 'components/common/FormField/Select/ButtonDropdown';
import Stepper from 'components/common/Stepper/Stepper';

const FilteredUIComponent = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  columnGap: '5px',
  alignItems: 'center',
});

const ListViewStyle = styled('div')({
  marginTop: '16px',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '8px',
});

const Integration = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isListView, setIsListView] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(null);
  const [selectedConnectorID, setSelectedConnectorID] = useState(null);

  const { userProfile, tenantData } = useSelector((state) => state.common);
  const { connectors, loading } = useSelector((state) => state.connectors);
  const gSuiteConnector = connectors?.filter((con) => con.type_name === connectorsName.GOOGLE);

  const integrationMenu = [
    { label: 'Application', value: 'Application', click: () => setIsModalOpen(connectorsName?.CUSTOM) },
    { label: 'Vendor ', value: 'Vendor', click: () => setIsModalOpen(connectorsName?.VENDOR) },
  ];

  const getConnectorList = () => dispatch(getConnectors(tenantData?.uuid));

  useEffect(() => {
    dispatch({ type: actionTypes.REMOVE_INTEGRATION_DETAIL });

    if (tenantData?.uuid) {
      getConnectorList();
    }
  }, [userProfile]);

  useEffect(() => {
    if (
      gSuiteConnector &&
      gSuiteConnector.find((con) => con.type_name === connectorsName.GOOGLE)?.connection_details?.email
    ) {
      const gSuiteData = gSuiteConnector.find((con) => con.type_name === connectorsName.GOOGLE);
      storage.set('gsuite_email', gSuiteData?.connection_details?.email);
      storage.set('gSuite_impact_score', gSuiteData?.risk_score?.aggregate_impact_score || 0);
    } else {
      storage.remove('gsuite_email');
      storage.remove('gSuite_impact_score');
    }
  }, [gSuiteConnector]);

  const handleEdit = (id, type) => {
    if (type == connectorsName?.CUSTOM || type == connectorsName?.VENDOR) {
      setSelectedConnectorID(id);
      setIsModalOpen(type);
    } else {
      navigate(`/lightening/detail/${id}`);
    }
  };

  const createWorkSpace = (type) => {
    navigate(`/lightening/${type}`);
  };

  let data = connectorsData.map((v) => {
    let connectorsObj = connectors?.find((con) => con.type_name === v.type);
    let isGoogleConnected = connectors?.some((con) => con.type_name === connectorsName.GOOGLE);
    if (v.type === connectorsName.GDRIVE) {
      if (connectorsObj) {
        return {
          ...v,
          uuid: connectorsObj?.tenant_connector_uuid,
          aggregate_impact_score: connectorsObj?.risk_score?.aggregate_impact_score,
          aliasName: connectorsObj?.tenant_connector_name,
          manual_impact_score:connectorsObj?.connection_details?.manual_impact_score
        };
      } else {
        if (!isGoogleConnected) {
          return {
            ...v,
            isDisabled: true,
          };
        } else {
          return v;
        }
      }
    } else if (connectorsObj) {
      return {
        ...v,
        uuid: connectorsObj?.tenant_connector_uuid,
        aggregate_impact_score: connectorsObj?.risk_score?.aggregate_impact_score,
        aliasName: connectorsObj?.tenant_connector_name,
        manual_impact_score:connectorsObj?.connection_details?.manual_impact_score
      };
    } else return v;
  });

  const customConnectors = connectors
    ?.filter((v) => v.connection_type == connectorsName.CUSTOM)
    .map((cc) => ({
      icon: cc.logo,
      title: cc.tenant_connector_name,
      byName: cc?.connection_details?.account_name || '',
      path: '',
      type: cc.type_name,
      isDisabled: false,
      uuid: cc?.tenant_connector_uuid,
      aggregate_impact_score: cc?.connection_details?.manual_impact_score,
      aliasName: cc?.tenant_connector_name,
    }));

  if (customConnectors?.length) {
    data = [...data, ...customConnectors];
  }

  if (loading || !userProfile) {
    return <CommonLoader height="300px" />;
  }

  return (
    <>
      <WrapperBox>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="subtitle1">Connected systems and integrations</Typography>
          <FilteredUIComponent>
            {shouldBeMasked() && (
              <IconButton size="small">
                <Icon src={IC_SEARCH} size="small" />
              </IconButton>
            )}
            {shouldBeMasked() && (
              <Box mr={1} ml={1}>
                <Button variant="outlined" color="secondary" size="small" startIcon={<ReactSVG src={IC_SORT} />}>
                  Sort
                </Button>
              </Box>
            )}

            <RBAC
              action={RISK_AI_LIGHTENING_INTEGRATIONS_ADD_CUSTOM_CONNECTOR}
              yes={
                <Box mr={2}>
                  <ButtonDropdown options={integrationMenu} />
                </Box>
              }
            />
            <IconButton
              size="small"
              onClick={() => setIsListView(false)}
              sx={!isListView ? { background: '#4F4A551F', borderRadius: '6px' } : {}}
            >
              <ReactSVG src={IC_GRID} />
            </IconButton>
            <IconButton
              size="small"
              onClick={() => setIsListView(true)}
              sx={isListView ? { background: '#4F4A551F', borderRadius: '6px' } : {}}
            >
              <ReactSVG src={IC_LIST} />
            </IconButton>
          </FilteredUIComponent>
        </div>
        {isListView ? (
          <ListViewStyle>
            {data &&
              data.map((d, index) => (
                <ListView key={index} data={d} handleConnect={createWorkSpace} handleEdit={handleEdit} />
              ))}
          </ListViewStyle>
        ) : (
          <Grid container spacing={2} mt={0}>
            {data &&
              data.map((d, index) => (
                <Grid xs={12} sm={6} md={4} key={index} item>
                  <GridView data={d} handleConnect={createWorkSpace} handleEdit={handleEdit} />
                </Grid>
              ))}
          </Grid>
        )}
      </WrapperBox>
      {isModalOpen && (
        <Stepper
          handleClose={() => {
            setIsModalOpen(false);
            setSelectedConnectorID(null);
          }}
          type={isModalOpen}
          // setIsModalOpen={setIsModalOpen}
          refetch={getConnectorList}
          uuid={selectedConnectorID}
        />
      )}
    </>
  );
};
export default Integration;

import controlsManagementServices from 'services/controlsManagementServices';
import * as actionTypes from './actionTypes';

// Controls
export const getControls = (queryParams) => ({
  type: actionTypes.GET_CONTROLS,
  payload: controlsManagementServices.getControls(queryParams),
});

export const AddUpdateControls = (data) => ({
  type: actionTypes.ADD_UPDATE_CONTROLS,
  payload: controlsManagementServices.addUpdateControls(data),
});
// Frameworks
export const getFrameworks = (queryParams) => ({
  type: actionTypes.GET_FRAMEWORKS,
  payload: controlsManagementServices.getFrameworks(queryParams),
});

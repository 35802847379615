import { Box, Divider, styled, Typography } from '@mui/material';
import { IC_FILE_CHECK } from 'assets/constants/imageConst';
import React from 'react';
import { ReactSVG } from 'react-svg';
import Avatar from '../Avatar/Avatar';
import { Button } from '../FormControls';
import CircularProgressbar from '../Progressbar/CicularProgresbar';

const CustomBox = styled(Box)(({ theme }) => ({
  borderRadius: theme.palette.borderRadius.main,
  backgroundColor: theme.palette.background.whiteShade5,
  padding: theme.spacing(2),
}));

function BusinessAssociatesCard({ title, icon, progressValue, progressValueLabel, subtitle }) {
  return (
    <CustomBox>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1.5 }}>
        <Box>
          <Avatar sx={{ mb: 2 }}>
            <ReactSVG src={icon} />
          </Avatar>
          <Typography variant="subtitle2" mb={0.5}>
            {title}
          </Typography>
          {subtitle}
        </Box>
        <Box>
          <CircularProgressbar
            isLabel={true}
            valueLabel={<Typography variant="progressLabel">{progressValueLabel}</Typography>}
            value={progressValue}
            size="48px"
            color="warning"
          />
        </Box>
      </Box>
      <Divider />
      <Box mt={1.5}>
        <Button variant="outlined" color="secondary" size="small">
          View details
        </Button>
      </Box>
    </CustomBox>
  );
}

BusinessAssociatesCard.defaultProps = {
  title: '',
  subtitle: '',
  icon: IC_FILE_CHECK,
  progressValue: 0,
  progressValueLabel: '0%',
};

export default BusinessAssociatesCard;

import { Box, Grid, Typography, styled } from '@mui/material';
import {
  FILE_DARK,
  IC_DOWNLOAD,
  IC_FILE_CHECK,
  IC_EYE,
  G_DRIVE_LOGO,
  GOOGLE_LOGO,
  MICROSOFT_AD_LOGO,
  AWS_LOGO,
  EPIC_LOGO,
} from 'assets/constants/imageConst';
import { Chip } from 'components/common';
import BusinessAssociatesCard from 'components/common/Card/BusinessAssociatesCard';
import { Button } from 'components/common/FormControls';
import ProgressRiskRow from 'components/common/List/ProgressRiskRow';
import Title from 'components/common/Wrapper/Title';
import WrapperBox from 'components/common/Wrapper/WrapperBox';
import React from 'react';
import { ReactSVG } from 'react-svg';

const FooterWrapper = styled(WrapperBox)({
  background: 'rgba(111, 0, 231, 0.04)',
  marginTop: '16px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const businessAssociatesData = [
  {
    title: 'Business Associate Agreements (BAA)',
    subtitle: <Chip label="11/12 signed" />,
    icon: IC_FILE_CHECK,
    progressValue: 65,
    progressValueLabel: '90%',
  },
  {
    title: 'Business Associate Risk Assessments',
    subtitle: <Chip label="8/12 signed" />,
    icon: IC_FILE_CHECK,
    progressValue: 65,
    progressValueLabel: '8/12',
  },
];

const vendorsData = [
  {
    icon: AWS_LOGO,
    title: 'Amazon',
    progressValue: 8,
    device: 45,
  },
  {
    icon: EPIC_LOGO,
    title: 'Epic',
    progressValue: 87,
    device: 72,
  },
  {
    icon: GOOGLE_LOGO,
    title: 'Google',
    progressValue: 12,
    device: 2,
  },
  {
    icon: G_DRIVE_LOGO,
    title: 'Google Drive',
    progressValue: 14,
    device: 1,
  },
  {
    icon: MICROSOFT_AD_LOGO,
    title: 'Microsoft',
    progressValue: 14,
    device: 1,
  },
];

function VendorManagement() {
  return (
    <WrapperBox>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} mb={1}>
        <Title title="Vendor Management" />
        <Box>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            startIcon={<ReactSVG src={IC_EYE} className="download-small-icon" />}
            sx={{ mr: 1 }}
          >
            Resource library
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            startIcon={<ReactSVG src={IC_DOWNLOAD} className="download-small-icon" />}
          >
            Auditor report
          </Button>
        </Box>
      </Box>
      <Box>
        <Box mb={4}>
          <Chip label="45 CFR §164.502(e) & §164.308(b)" icon={<ReactSVG src={FILE_DARK} />} />
        </Box>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <Box mb={1}>
              <Typography variant="overline" color="secondary">
                Business Associates
              </Typography>
            </Box>

            <Box sx={{ rowGap: '8px', display: 'flex', flexDirection: 'column' }}>
              {businessAssociatesData.map((value, index) => (
                <BusinessAssociatesCard
                  key={index}
                  title={value.title}
                  subtitle={value.subtitle}
                  icon={value.icon}
                  progressValue={value.progressValue}
                  progressValueLabel={value.progressValueLabel}
                />
              ))}
            </Box>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Box mb={1} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="overline" color="secondary">
                Top 5 Vendors at Risk
              </Typography>
              <Button variant="outlined" size="small" color="secondary">
                View details
              </Button>
            </Box>

            <Box sx={{ rowGap: '8px', display: 'flex', flexDirection: 'column' }}>
              {vendorsData.map((value, index) => (
                <ProgressRiskRow
                  key={index}
                  title={value.title}
                  device={value.device}
                  icon={value.icon}
                  progressValue={value.progressValue}
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <FooterWrapper>
        <Box>
          <Typography variant="subtitle2">New Business Associate?</Typography>
          <Typography variant="body2" mt={1}>
            Use our questionnaire generator and get simple and quick vendor assessments.{' '}
          </Typography>
        </Box>
        <Button variant="contained" size="small">
          Generate Questionnaire
        </Button>
      </FooterWrapper>
    </WrapperBox>
  );
}

export default VendorManagement;

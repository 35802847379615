import { toast } from 'react-toastify';

export const dataTransformer = (res) => {
  if (res?.data) {
    toast.success(res?.data?.payload?.message);
  }
  return res;
};

export const updateConnectorDataTransformer = (res) => {
  if (res?.data) {
    toast.success(res?.data?.payload?.message);
    res.isIntegrationUpdate = true;
  }
  return res;
};

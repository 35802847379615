import React, { useState } from 'react';
import { ReactSVG } from 'react-svg';
import { Box, Typography } from '@mui/material';
import { Button } from 'components/common/FormControls';
import { IC_PLUS } from 'assets/constants/imageConst';
import DashedBox from 'components/common/Wrapper/DashedBox';
import TaskBox from './TaskBox';
import { tasksList } from 'assets/constants/const';
import { useLocation } from 'react-router-dom';
import CreateTicketModal from './Modal/CreateTicketModal';

const Tasks = ({ vulnerabilityData }) => {
  const [openModal, setModal] = useState(false);
  const { state } = useLocation();
  const vulnerabilityName = vulnerabilityData.name;
  const isInadequateDeviceAuthentication = vulnerabilityName === 'Inadequate Device Authentication';
  const handleNewTaskClick = () => {
    setModal(true);
  };
  return (
    <Box mt={2}>
      {isInadequateDeviceAuthentication ? (
        <>
          {tasksList?.map((tl, index) => (
            <TaskBox
              key={index}
              title={tl.title}
              subtitle={tl.subtitle.replace('{{integrationName}}', state?.name)}
              date={tl.date}
              status={tl.status}
              name={tl.name}
            />
          ))}
          <Button startIcon={<ReactSVG src={IC_PLUS} />} color="secondary" size="small" onClick={handleNewTaskClick}>
            Add Task
          </Button>
        </>
      ) : (
        <DashedBox mt={2}>
          <Box mb={1.5}>
            <Typography variant="subtitle2" mb={0.5}>
              Create your first task!
            </Typography>
            <Typography variant="caption" color="secondary">
              There’s no task linked to this control yet.
            </Typography>
          </Box>
          <Button variant="filled" size="small" startIcon={<ReactSVG src={IC_PLUS} />} onClick={handleNewTaskClick}>
            New task
          </Button>
        </DashedBox>
      )}
      <CreateTicketModal isOpen={openModal} handleCloseModal={() => setModal(false)} />
    </Box>
  );
};

export default Tasks;

import { ReactSVG } from 'react-svg';
import { Table, Typography, TableRow, TableContainer, TableBody, TableHead, TableCell, Box } from '@mui/material';
// internal
import { IC_LAPTOP, USER, IC_HORIZONTAL_DOTS, TARGET_PRIMARY, CUSTOM_CONNECTOR } from 'assets/constants/imageConst';
import { LinearProgressbar, Chip } from 'components/common';
import { useNavigate } from 'react-router-dom';
import { connectorsName, riskAnalysisConnectors } from 'assets/constants/const';
import ImageWrapper from 'components/common/Wrapper/ImageWrapper';
import { isArray } from 'lodash';
import { getProgressColor, objectToStringConvertor, shouldBeMasked } from 'utils/general';

const header = ['Integration Name', 'Risk Score', 'Devices', 'Users'].concat(shouldBeMasked() ? [''] : []);

const ListView = ({ data }) => {
  const navigate = useNavigate();

  const activeRowHandler = (row) => {
    if (row?.tenant_connector_id) {
      navigate(row.path, {
        state: {
          id: row?.tenant_connector_uuid,
          name: row?.integrationName,
          by: row?.by,
          userConfig: row.userConfig,
          icon: row?.icon,
          smallIcon: row?.small_icon,
          aggregate_impact_score: row?.risk_score?.aggregate_impact_score,
          type: row?.type,
        },
      });
    }
  };

  const combinedData = data?.map((cc) => {
    if (cc.connector_type == connectorsName.CUSTOM) {
      return {
        ...cc,
        integrationName: cc?.tenant_connector_name,
        icon: cc?.logo,
        small_icon: cc?.logo,
        path: `/risk-analysis/${connectorsName.CUSTOM}`,
        type: cc.type_name,
        by: cc?.connection_details?.account_name || '',
        userConfig: cc?.output_json?.user_data,
      };
    } else if (cc.connector_name == connectorsName.GDRIVE) {
      const connectedConnector = riskAnalysisConnectors?.find((r) => r.type == cc.connector_name);
      return { ...connectedConnector, ...cc, userConfig: cc?.output_json?.user_data };
    } else {
      const connectedConnector = riskAnalysisConnectors?.find((r) => r.type == cc.connector_name);
      return { ...cc, ...connectedConnector };
    }
  });

  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} size="medium" aria-label="a dense table">
        <TableHead sx={{ borderRadius: '8px' }}>
          <TableRow>
            {header.map((h, index) => (
              <TableCell key={index}>
                <Typography variant="subtitle2" color="secondary">
                  {h}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.length > 0 &&
            combinedData.map((row, index) => {
              let users = row?.output_json?.user_data?.length;
              let laptopDevices = row?.output_json?.mobile_devices?.length;

              if (row?.connector_type == connectorsName.CUSTOM || row?.connector_name == connectorsName?.GDRIVE) {
                const userForCustomConnector = row?.output_json?.user_data?.number_of_user;
                users = objectToStringConvertor(userForCustomConnector);
              }

              if (row.connector_name === connectorsName.MICROSOFT) {
                let userObject = row.output_json?.data?.find((value) =>
                  Object.keys(value).find((obj) => obj === 'user_data')
                );
                if (isArray(userObject?.user_data?.data)) {
                  users = userObject?.user_data?.data.length;
                }
                let computerObject = row.output_json?.data?.find((value) =>
                  Object.keys(value).find((obj) => obj === 'computers')
                );
                if (isArray(computerObject?.computers?.data)) {
                  laptopDevices = computerObject?.computers?.data.length;
                }
              } else if (row.connector_name === connectorsName.AZURE) {
                if (isArray(row?.output_json?.devices)) {
                  laptopDevices = row?.output_json?.devices.length;
                }
              }
              return (
                <TableRow
                  key={index}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    cursor: row?.tenant_connector_id && 'pointer',
                  }}
                  onClick={() => activeRowHandler(row)}
                >
                  <TableCell align="left">
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box mr={1.5}>
                        <ImageWrapper>
                          <img src={row?.icon} />
                        </ImageWrapper>
                      </Box>
                      <Box mr={0.75}>
                        <Typography variant="subtitle2">{row?.integrationName}</Typography>
                      </Box>
                      <Box>
                        {row?.risk_score?.aggregate_impact_score ? (
                          <Chip
                            icon={<ReactSVG src={TARGET_PRIMARY} />}
                            label={`Impact: ${ row?.risk_score?.aggregate_impact_score}`}
                            color="primary"
                          />
                        ) : (
                          ''
                        )}
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell align="left" width="40%">
                    <Box width="70%">
                      {row?.risk_score?.current_risk_score ? (
                        <LinearProgressbar
                          value={row?.risk_score?.current_risk_score || 0}
                          color={getProgressColor(row?.risk_score?.current_risk_score)}
                          label={
                            <Typography variant="progressLabel" ml={1}>
                              {row?.risk_score?.current_risk_score}
                            </Typography>
                          }
                        />
                      ) : (
                        <LinearProgressbar value={0} color="warning" />
                      )}
                    </Box>
                  </TableCell>
                  <TableCell align="left">
                    {laptopDevices ? <Chip icon={<ReactSVG src={IC_LAPTOP} />} label={laptopDevices} /> : '-'}
                  </TableCell>
                  <TableCell align="left">
                    {users ? <Chip icon={<ReactSVG src={USER} />} label={users} /> : '-'}
                  </TableCell>
                  {shouldBeMasked() && (
                    <TableCell align="left">
                      <ReactSVG src={IC_HORIZONTAL_DOTS} />
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default ListView;

import api from './apis/api';

class ControlsServices {
  getControls(params) {
    return api.controls.getControls(params);
  }
  getFrameworks(params) {
    return api.controls.getFrameworks(params);
  }
  getControlLibraryList(uuid) {
    return api.controls.getControlLibraryList(uuid);
  }
  getControlLibraryFramework(uuid) {
    return api.controls.getControlLibraryFramework(uuid);
  }
  saveControlLibraryResponse(uuid, data) {
    return api.controls.saveControlLibraryResponse(uuid, data);
  }
  addUpdateControls(data) {
    return api.controls.addUpdateControls(data);
  }
  addControlsToVulnerability(tenant_connector_uuid, data) {
    return api.controls.addControlsToVulnerability(tenant_connector_uuid, data);
  }
}

export default new ControlsServices();

import * as actionTypes from '../actions/actionTypes';
import _ from 'lodash';
import { controlName } from 'assets/constants/const';

export const defaultState = {
  tenant_connector_data: null,
  error: null,
  loading: true,
  tenant_connectors: null,
  risk_score: {},
  riskAnalysisScoreData: [],
  scoreResponseLoading: false,
  riskAnalysisScoreResponseData: [],
  totalVulnerabilities: [],
  selectedControls: [],
  preSelectedControls: [],
  isInProgress: false,
  isComplete: false,
  IAC21Control: {},
  scoreLoading: false,
  progressScore: {},
  controls_comparison_score: {},
  vulnerabilities: {},
  newlyAddedControls: [],
  connectorNotesDocumentsCountLoading: false,
  connector_notes_documents_count: [],
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_RISK_ANALYSIS_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case actionTypes.GET_RISK_ANALYSIS_SUCCESS:
      return {
        ...state,
        loading: false,
        tenant_connectors: action.payload.data.payload.data,
        progressScore: _.get(action, ['payload', 'data', 'payload', 'scores_difference'], null),
        isInProgress: false,
        selectedControls: [],
        isComplete: false,
      };

    case actionTypes.GET_RISK_ANALYSIS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actionTypes.SET_NEW_CONTROLS_TO_VULNERABILITY:
      return {
        ...state,
        newlyAddedControls: action.payload.data,
      };
    case actionTypes.GET_VULNERABILITIES_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case actionTypes.GET_VULNERABILITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        vulnerabilities: _.get(action, ['payload', 'data', 'payload'], null),
      };

    case actionTypes.GET_VULNERABILITIES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actionTypes.GET_PROGRESS_SCORE_START:
      return {
        ...state,
        error: null,
      };

    case actionTypes.GET_PROGRESS_SCORE_SUCCESS:
      return {
        ...state,
        progressScore: _.get(action, ['payload', 'data', 'payload'], null),
        error: null,
      };

    case actionTypes.GET_PROGRESS_SCORE_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case actionTypes.UPDATE_RISK_SCORE_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.UPDATE_RISK_SCORE_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedControls: [],
      };
    case actionTypes.UPDATE_RISK_SCORE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actionTypes.POST_CONNECTOR_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.POST_CONNECTOR_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_SCORE_START:
      return { ...state, scoreLoading: true, error: null };
    case actionTypes.GET_SCORE_SUCCESS:
      return {
        ...state,
        scoreLoading: false,
        riskAnalysisScoreData: _.get(action, ['payload', 'data', 'payload', 'data'], null),
      };
    case actionTypes.GET_SCORE_ERROR:
      return {
        ...state,
        scoreLoading: false,
        error: action.payload,
      };
    case actionTypes.GET_SCORE_RESPONSE_RATIO_START:
      return { ...state, scoreResponseLoading: true, error: null };
    case actionTypes.GET_SCORE_RESPONSE_RATIO_SUCCESS:
      return {
        ...state,
        scoreResponseLoading: false,
        riskAnalysisScoreResponseData: _.get(action, ['payload', 'data', 'payload', 'data'], null),
      };
    case actionTypes.GET_SCORE_RESPONSE_RATIO_ERROR:
      return {
        ...state,
        scoreResponseLoading: false,
        error: action.payload,
      };

    case actionTypes.GET_CONNECTOR_RUN_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_CONNECTOR_RUN_SUCCESS: {
      const connector_data = _.get(action, ['payload', 'data', 'payload', 'data'], null)[0];
      const controls_comparison_score = _.get(action, ['payload', 'data', 'payload'], null)?.comparison_score;
      const totalVulnerabilities = [];
      let iac21 = {};
      connector_data?.risk_score?.threat_controls?.forEach((element) => {
        element.vulnerabilities?.forEach((element) => {
          totalVulnerabilities.push(element);
        });
      });
      const preSelectedControls = [];
      totalVulnerabilities.map((value) => {
        value.controls.map((control) => {
          if (control.response) {
            preSelectedControls.push(control);
          }
          if (control.code === 'IAC-21') {
            iac21 = control;
          }
        });
      });

      return {
        ...state,
        loading: false,
        tenant_connector_data: connector_data,
        risk_score: connector_data?.risk_score,
        controls_comparison_score,
        totalVulnerabilities,
        isInProgress: connector_data.isProgress,
        preSelectedControls,
        isComplete: false,
        IAC21Control: iac21,
      };
    }
    case actionTypes.GET_CONNECTOR_RUN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_CONNECTOR_RUN_INPROGRESS_START:
      return {
        ...state,
      };
    case actionTypes.GET_CONNECTOR_RUN_INPROGRESS_SUCCESS: {
      const connector_data = _.get(action, ['payload', 'data', 'payload', 'data'], null)[0];
      const isComplete = connector_data?.isProgress || connector_data?.isFailed ? false : true;
      const totalVulnerabilities = [];
      connector_data?.risk_score?.threat_controls?.forEach((element) => {
        element.vulnerabilities?.forEach((element) => {
          totalVulnerabilities.push(element);
        });
      });
      const preSelectedControls = [];
      totalVulnerabilities.map((value) => {
        value.controls.map((control) => {
          if (control.response) {
            preSelectedControls.push(control);
          }
        });
      });
      return {
        ...state,
        isInProgress: connector_data.isProgress,
        isComplete: isComplete,
        tenant_connector_data: connector_data,
        risk_score: connector_data?.risk_score,
        preSelectedControls,
        totalVulnerabilities,
      };
    }
    case actionTypes.GET_CONNECTOR_RUN_INPROGRESS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case actionTypes.SET_VULNERABILITY_CONTROL: {
      const { updatedControl } = action.payload;

      let controls = [...state.selectedControls];

      if (controls.find((value) => value.code === updatedControl.code)) {
        controls = controls.map((value) => {
          if (value.code === updatedControl.code) {
            return updatedControl;
          } else {
            return value;
          }
        });
      } else {
        controls = [...controls, updatedControl];
      }
      //checking if all 'IAC-08', 'IAC-29', 'HRS-11' no then make IAC-21 no
      if (controlName.includes(updatedControl.code) && updatedControl.response === 'no') {
        if (!controls.some((v) => controlName.includes(v.code) && v.response && v.response !== 'no')) {
          if (updatedControl.code === 'IAC-08' && updatedControl.response === 'no') {
            if (controls.some((v) => v.code === 'IAC-29' && v.response === 'no')) {
              if (controls.some((v) => v.code === 'HRS-11' && v.response === 'no')) {
                if (controls.some((c) => c.code === 'IAC-21')) {
                  controls = [...controls.map((v) => (v.code === 'IAC-21' ? { ...v, response: 'no' } : v))];
                } else {
                  controls = [...controls, { ...state.IAC21Control, response: 'no' }];
                }
              } else if (state.preSelectedControls.some((v) => v.code === 'HRS-11' && v.response === 'no')) {
                if (controls.some((c) => c.code === 'IAC-21')) {
                  controls = [...controls.map((v) => (v.code === 'IAC-21' ? { ...v, response: 'no' } : v))];
                } else {
                  controls = [...controls, { ...state.IAC21Control, response: 'no' }];
                }
              }
            } else if (state.preSelectedControls.some((v) => v.code === 'IAC-29' && v.response === 'no')) {
              if (controls.some((v) => v.code === 'HRS-11' && v.response === 'no')) {
                if (controls.some((c) => c.code === 'IAC-21')) {
                  controls = [...controls.map((v) => (v.code === 'IAC-21' ? { ...v, response: 'no' } : v))];
                } else {
                  controls = [...controls, { ...state.IAC21Control, response: 'no' }];
                }
              } else if (state.preSelectedControls.some((v) => v.code === 'HRS-11' && v.response === 'no')) {
                if (controls.some((c) => c.code === 'IAC-21')) {
                  controls = [...controls.map((v) => (v.code === 'IAC-21' ? { ...v, response: 'no' } : v))];
                } else {
                  controls = [...controls, { ...state.IAC21Control, response: 'no' }];
                }
              }
            }
          } else if (updatedControl.code === 'IAC-29' && updatedControl.response === 'no') {
            if (controls.some((v) => v.code === 'IAC-08' && v.response === 'no')) {
              if (controls.some((v) => v.code === 'HRS-11' && v.response === 'no')) {
                if (controls.some((c) => c.code === 'IAC-21')) {
                  controls = [...controls.map((v) => (v.code === 'IAC-21' ? { ...v, response: 'no' } : v))];
                } else {
                  controls = [...controls, { ...state.IAC21Control, response: 'no' }];
                }
              } else if (state.preSelectedControls.some((v) => v.code === 'HRS-11' && v.response === 'no')) {
                if (controls.some((c) => c.code === 'IAC-21')) {
                  controls = [...controls.map((v) => (v.code === 'IAC-21' ? { ...v, response: 'no' } : v))];
                } else {
                  controls = [...controls, { ...state.IAC21Control, response: 'no' }];
                }
              }
            } else if (state.preSelectedControls.some((v) => v.code === 'IAC-08' && v.response === 'no')) {
              if (controls.some((v) => v.code === 'HRS-11' && v.response === 'no')) {
                if (controls.some((c) => c.code === 'IAC-21')) {
                  controls = [...controls.map((v) => (v.code === 'IAC-21' ? { ...v, response: 'no' } : v))];
                } else {
                  controls = [...controls, { ...state.IAC21Control, response: 'no' }];
                }
              } else if (state.preSelectedControls.some((v) => v.code === 'HRS-11' && v.response === 'no')) {
                if (controls.some((c) => c.code === 'IAC-21')) {
                  controls = [...controls.map((v) => (v.code === 'IAC-21' ? { ...v, response: 'no' } : v))];
                } else {
                  controls = [...controls, { ...state.IAC21Control, response: 'no' }];
                }
              }
            }
          } else if (updatedControl.code === 'HRS-11' && updatedControl.response === 'no') {
            if (controls.some((v) => v.code === 'IAC-29' && v.response === 'no')) {
              if (controls.some((v) => v.code === 'IAC-08' && v.response === 'no')) {
                if (controls.some((c) => c.code === 'IAC-21')) {
                  controls = [...controls.map((v) => (v.code === 'IAC-21' ? { ...v, response: 'no' } : v))];
                } else {
                  controls = [...controls, { ...state.IAC21Control, response: 'no' }];
                }
              } else if (state.preSelectedControls.some((v) => v.code === 'IAC-08' && v.response === 'no')) {
                if (controls.some((c) => c.code === 'IAC-21')) {
                  controls = [...controls.map((v) => (v.code === 'IAC-21' ? { ...v, response: 'no' } : v))];
                } else {
                  controls = [...controls, { ...state.IAC21Control, response: 'no' }];
                }
              }
            } else if (state.preSelectedControls.some((v) => v.code === 'IAC-29' && v.response === 'no')) {
              if (controls.some((v) => v.code === 'IAC-08' && v.response === 'no')) {
                if (controls.some((c) => c.code === 'IAC-21')) {
                  controls = [...controls.map((v) => (v.code === 'IAC-21' ? { ...v, response: 'no' } : v))];
                } else {
                  controls = [...controls, { ...state.IAC21Control, response: 'no' }];
                }
              } else if (state.preSelectedControls.some((v) => v.code === 'IAC-08' && v.response === 'no')) {
                if (controls.some((c) => c.code === 'IAC-21')) {
                  controls = [...controls.map((v) => (v.code === 'IAC-21' ? { ...v, response: 'no' } : v))];
                } else {
                  controls = [...controls, { ...state.IAC21Control, response: 'no' }];
                }
              }
            }
          }
        }
      }

      //checking if any 'IAC-08', 'IAC-29', 'HRS-11' yes then make IAC-21 YES
      if (controls.some((control) => controlName.includes(control.code) && control.response === 'yes')) {
        if (controls.some((control) => control.code === 'IAC-21')) {
          controls = controls.map((v) => (v.code === 'IAC-21' ? { ...v, response: 'yes' } : v));
        } else {
          controls = [...controls, { ...state.IAC21Control, response: 'yes' }];
        }
      }

      return {
        ...state,
        selectedControls: controls,
      };
    }
    case actionTypes.GET_CONNECTOR_NOTES_DOCUMENT_COUNT_START:
      return {
        ...state,
        connectorNotesDocumentsCountLoading: true,
        error: null,
      };

    case actionTypes.GET_CONNECTOR_NOTES_DOCUMENT_COUNT_SUCCESS:
      return {
        ...state,
        connectorNotesDocumentsCountLoading: false,
        connector_notes_documents_count: _.get(action, ['payload', 'data', 'payload', 'data'], []),
      };

    case actionTypes.GET_CONNECTOR_NOTES_DOCUMENT_COUNT_ERROR:
      return {
        ...state,
        connectorNotesDocumentsCountLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

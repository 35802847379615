import _ from 'lodash';
import { IC_DOWN_TREND, IC_UP_TREND } from 'assets/constants/imageConst';

export const Roles = {
  SUPER_USER: 'SuperUser',
  CHANNEL_PARTNER: 'ChannelPartner',
  TENANT_ADMIN: 'TenantAdmin',
  TENANT_AUDITIOR: 'TenantAuditor',
  TENANT_MAINTAINER: 'TenantMaintainer',
  TENANT_USER: 'TenantUser',
};

export const TenantUserOption={
  'TenantAdmin':{ label: 'Tenant admin', value: Roles.TENANT_ADMIN },
  'TenantAuditor':{ label: 'Tenant auditor', value: Roles.TENANT_AUDITIOR },
  'TenantMaintainer':{ label: 'Tenant maintainer', value: Roles.TENANT_MAINTAINER },
}

const words = {
  one: 1,
  two: 2,
  three: 3,
  four: 4,
  five: 5,
  six: 6,
  seven: 7,
  eight: 8,
  nine: 9,
  ten: 10,
};
const numbers = {
  1: 'One',
  2: 'Two',
  3: 'Three',
  4: 'Four',
  5: 'Five',
  6: 'Six',
  7: 'Seven',
  8: 'Eight',
  9: 'Nine',
  10: 'Ten',
};
export const getNumberFromWord = (word) => {
  if (word) {
    return words[word.toLowerCase()];
  } else return null;
};

export const getWordFromNumber = (number) => {
  if (number) {
    return numbers[number];
  } else return null;
};

export const getInterval = (interval, frequency) => {
  return getWordFromNumber(interval) + ' ' + frequency;
};

// Risk Category Logic
export const createSeries = (firstNumber, lastNumber, distance) => {
  let riskSeries = [];
  for (let i = firstNumber; i <= lastNumber; i += distance) {
    riskSeries.push(i);
  }
  return riskSeries;
};

export const existCheckHandler = (first, last, number) => {
  if (number >= first && number <= last) {
    return true;
  }
  return false;
};

// String to object
export const stringToObjectConvertor = (val) => {
  if (val.includes('<')) {
    return { from: '0', to: val.split('< ')[1] };
  } else if (val.includes('>')) {
    return { from: val.split('> ')[1], to: null };
  } else if (val.includes('-')) {
    const valArr = val?.split('-');
    if (valArr) {
      return { from: valArr[0], to: valArr[1] };
    }
  } else {
    return undefined;
  }
};

// Object to string
export const objectToStringConvertor = (val) => {
  if (val?.from === 0 || val?.from === '0') {
    return `< ${val?.to}`;
  } else if (val?.to === null || val?.to === 'null') {
    return `> ${val?.from}`;
  } else {
    return `${val?.from}-${val?.to}`;
  }
};

export const getLabelFromRisk = (val) => {
  if (val <= 45) {
    return { color: 'success', label: 'Low risk' };
  } else if (val > 45 && val < 90) {
    return { color: 'warning', label: 'Medium risk' };
  } else {
    return { color: 'error', label: 'High risk' };
  }
};

export const getLabelFromRiskProgress = (val) => {
  if (val <= 45) {
    return { color: 'error', label: 'High risk' };
  } else if (val > 45 && val < 90) {
    return { color: 'warning', label: 'Medium risk' };
  } else {
    return { color: 'success', label: 'Low risk' };
  }
};

export const getProgressColor = (val, isReverse) => {
  if (val) {
    return isReverse ? getLabelFromRiskProgress(Number(val)).color : getLabelFromRisk(Number(val)).color;
  } else {
    return 'primary';
  }
};

export const getFullName = (fName, lName) => {
  if (fName) return `${fName} ${lName}`;
  else return '';
};

export function areEqual(prevProps, nextProps) {
  return _.isEqual(prevProps, nextProps);
}
// Average Risk Score
export const averageRiskScore = (connectors) => {
  const riskScore = connectors
    ?.filter((c) => c?.risk_score?.current_risk_score && c?.risk_score?.current_risk_score !== 0)
    ?.map((risk) => risk?.risk_score?.current_risk_score);

  const aggregateRiskScore = riskScore?.reduce((total, current) => total + current, 0);

  return riskScore?.length ? Math.round(aggregateRiskScore / riskScore?.length) : 0;
};

export function search(nameKey, searchText, myArray) {
  if (searchText) {
    return myArray.map((value) =>
      value[nameKey].toLowerCase().indexOf(searchText.toLowerCase()) > -1
        ? { ...value, display: true }
        : { ...value, display: false }
    );
  } else {
    return myArray.map((value) => ({ ...value, display: true }));
  }
}

const checkIsAvailable = (data, check) => {
  return data.some((value) => check.includes(value));
};

export const getFilteredData = (allData, filter1, filter2) => {
  //filter1 === framworkfilter filter2 === response filter

  const selectedFilter1 = filter1.filter((v) => v.selected).map((v) => v.framework_id);
  const selectedFilter2 = filter2.filter((v) => v.selected).map((v) => v.value);

  if (selectedFilter1.length > 0 && selectedFilter2.length > 0) {
    return allData?.filter(
      (value) => checkIsAvailable(value.frameworks, selectedFilter1) && selectedFilter2.includes(value.response)
    );
  } else if (selectedFilter1.length > 0) {
    return allData?.filter((value) => checkIsAvailable(value.frameworks, selectedFilter1));
  } else if (selectedFilter2.length > 0) {
    return allData?.filter((value) => selectedFilter2.includes(value.response));
  } else {
    return allData;
  }
};

export const getTrendValues = (data, keyName) => {
  return data && data[keyName]?.includes('-')
    ? {
        label: Number((data && data[keyName]) || 0) || 0,
        color: 'warning',
        icon: IC_DOWN_TREND,
      }
    : {
        label: `+${Number((data && data[keyName]) || 0) || 0}`,
        color: 'success',
        icon: IC_UP_TREND,
      };
};

export const ENVIRONMENT_VARIABLES = {
  PRODUCTION: 'production',
  STAGING: 'staging',
  LOCAL: 'local',
};

export function shouldBeMasked(maskedOn = ['production']) {
  return !new Set(maskedOn).has(process.env.REACT_APP_ENV);
}

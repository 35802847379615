import React from 'react';
import { LineChart, ResponsiveContainer } from 'recharts';
// MUI
import { Box, Typography } from '@mui/material';

const LineGraph = ({ title, subTitle, header, height = 350, width = '100%', data, children }) => {
  return (
    <Box p={0} sx={{ position: 'relative' }}>
      <Box>
        {title && (
          <Typography variant="subtitle1" mb={0.5}>
            {title}
          </Typography>
        )}
        {subTitle && (
          <Typography variant="body2" color="secondary">
            {subTitle}
          </Typography>
        )}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', position: 'absolute', right: 0, top: 0 }}>{header}</Box>
      <ResponsiveContainer height={height} width={width}>
        <LineChart data={data}>{children}</LineChart>
      </ResponsiveContainer>
    </Box>
  );
};
export default LineGraph;

import { Box, LinearProgress, styled } from '@mui/material';

const CustomLinearProgress = styled(LinearProgress)(() => ({
  height: '6px',
  borderRadius: '100px',
}));

const LinearProgressbar = ({ label, value, variant, color, ...rest }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box width="90%">
        <CustomLinearProgress variant={variant} color={color} value={value} {...rest} />
      </Box>
      {label}
    </Box>
  );
};

LinearProgressbar.defaultProps = {
  value: 100,
  variant: 'determinate',
  color: 'primary',
};

export default LinearProgressbar;

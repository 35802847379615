import * as yup from 'yup';
import { REGEX } from './regex';
import { validatePhoneNumber } from 'components/RiskLightning/RiskLightningWorkspace/CustomConnector';

export const integrationValidationSchema = {
  'MS AD': yup.object({
    accountName: yup.string('Enter your account name').required('Account name is required'),
    description: yup.string('Enter your description').required('Description is required'),
    pollingInterval: yup.string('Enter your pollingInterval').required('Polling Interval is required'),
    impactScore: yup.mixed().test('impactScore', 'Please enter score from 1 to 10', function (value) {
      return value === null || value === undefined || (typeof value === 'string' && value == '') || (typeof value == 'number' && value <= 10) ? true : false;
    }),
    numberOfHealthRecords: yup.string('Please select number of records').required('Number of records is required'),
    sensitiveData: yup.string('Please select type of sensitive data').required('Type of sensitive data is required'),
    // public_ip_address: yup
    //   .string('Enter your public ip address')
    //   .test('ip address', 'Enter valid ip address', (value) => {
    //     // check your value and return true/false
    //     if (REGEX.IP_ADDRESS_VALIDATION.test(value)) {
    //       return true;
    //     }
    //     return false;
    //   })
    //   .required('LDAP URL is required'),
    dns_name: yup
      .string('Enter your dns name')
      .test('ip address', 'Enter valid DNS name', (value) => {
        // check your value and return true/false
        if (REGEX.DOMAIN_NAME.test(value)) {
          return true;
        }
        return false;
      })
      .required('DNS Name is required'),
    username: yup.string('Enter your Username').required('User name is required'),
    password: yup
      .string('Enter your password')
      .min(8, 'Password must be at least 8 characters')
      .required('Password is required'),
    certificate: yup.string('Upload Certificate').required('Certificate is required'),
  }),
  GSuite: yup.object({
    accountName: yup.string('Enter your account name').required('Account name is required'),
    description: yup.string('Enter your description').required('Description is required'),
    pollingInterval: yup.string('Please select polling interval').required('Polling Interval is required'),
    adminEmail: yup
      .string('Enter your adminEmail')
      .email('Please enter valid email id ')
      .required('Admin email is required'),
      impactScore: yup.mixed().test('impactScore', 'Please enter score from 1 to 10', function (value) {
        return value === null || value === undefined || (typeof value === 'string' && value == '') || (typeof value == 'number' && value <= 10) ? true : false;
      }),
      numberOfHealthRecords: yup.string('Please select number of records').required('Number of records is required'),
      sensitiveData: yup.string('Please select type of sensitive data').required('Type of sensitive data is required'),
  }),
  GDrive: yup.object({
    accountName: yup.string('Enter your account name').required('Account name is required'),
    description: yup.string('Enter your description').required('Description is required'),
    pollingInterval: yup.string('Please select polling interval').required('Polling Interval is required'),
    numberOfHealthRecords: yup.string('Please select number of records').required('Number of records is required'),
    numberOfUserRecords: yup.string('Please select number of users').required('Number of users is required'),
    folderPath: yup.string('Enter root folder URL containing sensitive data').required('Root folder is required'),
    sensitiveData: yup.string('Please select type of sensitive data').required('Type of sensitive data is required'),
    hostingModal: yup.string('Please select system hosting modal').required('System hosting modal is required'),
    systemPriority: yup.string('Please select system priority').required('System priority is required'),
    impactScore: yup.mixed().test('impactScore', 'Please enter score from 1 to 10', function (value) {
      return value === null || value === undefined || (typeof value === 'string' && value == '') || (typeof value == 'number' && value <= 10) ? true : false;
    }),
  }),
  'Azure AD': yup.object({
    accountName: yup.string('Enter your account name').required('Account name is required'),
    description: yup.string('Enter your description').required('Description is required'),
    pollingInterval: yup.string('Please select polling interval').required('Polling Interval is required'),
    directoryId: yup.string('Enter your directory ID').required('Directory ID is required'),
    applicationId: yup.string('Enter your application ID').required('Application ID is required'),
    clientSecret: yup.string('Enter your client secret').required('Client secret is required'),
    impactScore: yup.mixed('Enter').test('impactScore', 'Please enter score from 1 to 10', function (value) {
      return value === null || value === undefined || (typeof value === 'string' && value == '') || (typeof value == 'number' && value <= 10) ? true : false;
    }),
    numberOfHealthRecords: yup.string('Please select number of records').required('Number of records is required'),
    sensitiveData: yup.string('Please select type of sensitive data').required('Type of sensitive data is required'),
  }),
  // CustomConnector: yup.object({
  //   accountName: yup.string('Enter your account name').required('Account name is required'),
  //   description: yup.string('Enter your description').required('Description is required'),
  //   pollingInterval: yup.string('Please select polling interval').required('Polling Interval is required'),
  //   numberOfHealthRecords: yup.string('Please select number of records').required('Number of records is required'),
  //   numberOfUserRecords: yup.string('Please select number of users').required('Number of users is required'),
  //   sensitiveData: yup.string('Please select type of sensitive data').required('Type of sensitive data is required'),
  //   hostingModal: yup.string('Please select system hosting modal').required('System hosting modal is required'),
  //   systemPriority: yup.string('Please select system priority').required('System priority is required'),
  // }),
};

export const integrationValidationSchemaForEdit = {
  ...integrationValidationSchema,
  'Azure AD': yup.object({
    accountName: yup.string('Enter your account name').required('Account name is required'),
    description: yup.string('Enter your description').required('Description is required'),
    pollingInterval: yup.string('Please select polling interval').required('Polling Interval is required'),
    directoryId: yup.string('Enter your directory id').required('Directory id is required'),
    applicationId: yup.string('Enter your application id').required('Application id is required'),
    clientSecret: yup.string('Enter your client secret').required('Client secret is required'), //remove after backend implementation
    impactScore: yup.mixed().test('impactScore', 'Please enter score from 1 to 10', function (value) {
      return value === null || value === undefined || (typeof value === 'string' && value == '') || (typeof value == 'number' && value <= 10) ? true : false;
    }),
    numberOfHealthRecords: yup.string('Please select number of records').required('Number of records is required'),
    sensitiveData: yup.string('Please select type of sensitive data').required('Type of sensitive data is required'),
  }),
};

export const profileValidation = yup.object({
  first_name: yup.string('Enter your first name').required('First Name  is required'),
  last_name: yup.string('Enter your last name').required('Last Name is required'),
});

export const userValidation = yup.object({
  first_name: yup.string('Enter your first name').required('First Name  is required'),
  last_name: yup.string('Enter your last name').required('Last Name is required'),
  user_email: yup.string('Enter your Email').email('Please enter valid email id ').required('Email is required'),
  role: yup.string('Select your role').required('Role is required'),
});

export const frameworkValidationSchema = yup.object({
  name: yup.string('Enter Framework name').required('Framework name is required'),
  code: yup.string('Enter Framework code').required('Framework code is required'),
});

export const tenantValidationSchema = yup.object({
  tenant_name: yup.string('Enter Tenant name').required('Tenant name is required'),
  code: yup.string('Enter Code').required('Code iis required'),
  frameworks: yup.array().required('Select at least one framework').min(1, 'Select at least one framework'),
});

export const controlValidationSchema = yup.object({
  name: yup.string('Enter Control name').required('Control name is required'),
  code: yup.string('Enter Control code').required('Control code is required'),
  weight: yup.string('Enter Control weight').required('Control weight is required'),
  description: yup.string('Enter Description').required('Description is required'),
  question: yup.string('Enter Question').required('Question is required'),
  frameworks: yup.array().required('Select at least one framework').min(1, 'Select at least one framework'),
});

export const uploadDocumentValidation = yup.object({
  name: yup.string('Enter Document name').required('Document name is required'),
  profile_pic: yup
    .object()
    .shape({
      file: yup.mixed().test('required', 'Please upload document', (file) => {
        if (file) return true;
        return false;
      }),
    })
    .nullable(),
});

// Custom Connector validation schema

export const applicationDetailsSchema = yup.object({
  logo: yup.mixed().test('file', 'Please select logo', function (file) {
    if (file) return true;
    return false;
  }),
  accountName: yup.string('Enter your account name').required('Account name is required'),
  systemOwner: yup.string('Enter admin name').required('Admin name is required'),
  name: yup.string('Enter application name').required('Application name is required'),
  description: yup.string('Enter your description').required('Description is required'),
});

export const vendorDetailsSchema = yup.object({
  logo: yup.mixed().test('file', 'Please select logo', function (file) {
    if (file) return true;
    return false;
  }),
  name: yup.string('Enter vendor name').required('Vendor name is required'),
  description: yup.string('Enter your description').required('Description is required'),
  contactName: yup.string('Enter contact name').required('Contact name is required'),
  contactEmail: yup.string('Enter contact email').required('Contact email is required'),
  contactPhoneNumber: yup
    .string()
    .required('Contact phone number is required')
    .test('contactPhoneNumber', 'Please enter valid phone number', function (value) {
      if (validatePhoneNumber(value)) return true;
      return false;
    }),
});

export const impactSchema = yup.object({
  impactScore: yup.mixed().test('impactScore', 'Please enter score from 1 to 10', function (value) {
    return typeof value == 'string' || (typeof value == 'number' && value <= 10) ? true : false;
  }),
  numberOfHealthRecords: yup.string('Please select number of records').required('Number of records is required'),
  numberOfUsers: yup.string('Please select number of users').required('Number of users is required'),
  typeOfSensitiveData: yup
    .string('Please select type of sensitive data')
    .required('Type of sensitive data is required'),
  systemHoistingModel: yup.string('Please select system hosting modal').required('System hosting modal is required'),
  systemPriority: yup.string('Please select system priority').required('System priority is required'),
});

export const settingsSchema = yup.object({
  pollingInterval: yup.string('Please select polling interval').required('Polling Interval is required'),
  customerId: yup.string('Enter cusotmer id').required('Customer Id is required'),
  adminEmail: yup.string('Enter admin email').required('Admin email is required'),
  folderPath: yup.string('Enter root folder path').required('Root folder name is required'),
});

import * as React from 'react';
import { Typography, Box, IconButton } from '@mui/material';
import { ReactSVG } from 'react-svg';
import { IC_EDIT } from 'assets/constants/imageConst';
import { Link } from 'react-router-dom';
import { Button, Tooltip } from 'components/common/FormControls';
import WrapperBox from 'components/common/Wrapper/WrapperBox';
import Switch from 'components/common/FormControls/Switch';
import { TARGET_PRIMARY } from 'assets/constants/imageConst';
import { Chip } from 'components/common';
import ImageWrapper from 'components/common/Wrapper/ImageWrapper';
import {
  RISK_AI_LIGHTENING_INTEGRATIONS_CONNECT,
  RISK_AI_LIGHTENING_INTEGRATIONS_DELETE,
  RISK_AI_LIGHTENING_INTEGRATIONS_EDIT,
} from 'utils/permissionUtils';
import RBAC from 'components/RBAC';

const ListView = ({ data, handleConnect, handleEdit }) => {
  const { icon, title, byName, uuid, isDisabled, aliasName, aggregate_impact_score, type, manual_impact_score } = data;
  return (
    <WrapperBox
      padding="20px 16px"
      sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
    >
      <Box width="35%" sx={{ display: 'flex', alignItems: 'center' }}>
        <ImageWrapper>
          <img src={icon} className="svg-wrapper" />
        </ImageWrapper>
        <Box ml={1}>
          <Box sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', columnGap: '6px' }}>
            <Typography variant="subtitle2">{title}</Typography>
            {aggregate_impact_score||manual_impact_score ? (
              <Chip
                icon={<ReactSVG src={TARGET_PRIMARY} />}
                label={`Impact: ${manual_impact_score?manual_impact_score:aggregate_impact_score}`}
                color="primary"
              />
            ) : (
              ''
            )}
          </Box>
          <Typography variant="body2" color="text.secondary" display="flex">
            {byName && 'By'}
            <Typography variant="body2" color="primary" ml={0.25}>
              {byName}
            </Typography>
          </Typography>
        </Box>
      </Box>
      <Box width="25%" sx={{ textAlign: 'left' }}>
        <Typography variant="caption" color="secondary" sx={{ display: 'block' }}>
          Tenant Connector Name:
        </Typography>
        <Typography variant="caption" color="secondary">
          {aliasName || 'empty'}
        </Typography>
      </Box>

      <Box width="25%" sx={{ textAlign: 'right' }}>
        {uuid ? (
          <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', justifyContent: 'flex-end' }}>
            <RBAC
              action={RISK_AI_LIGHTENING_INTEGRATIONS_EDIT}
              yes={
                <IconButton onClick={() => handleEdit(uuid, type)}>
                  <ReactSVG src={IC_EDIT} />
                </IconButton>
              }
            />
            <RBAC
              action={RISK_AI_LIGHTENING_INTEGRATIONS_DELETE}
              yes={<Switch defaultChecked />}
              no={<Switch defaultChecked disabled />}
            />
          </Box>
        ) : (
          <RBAC
            action={RISK_AI_LIGHTENING_INTEGRATIONS_CONNECT}
            yes={
              isDisabled && data.tooltip ? (
                <Tooltip title={data?.tooltip} width="100px">
                  <div>
                    <Button
                      size="small"
                      color="secondary"
                      disabled={isDisabled}
                      variant="outlined"
                      onClick={() => handleConnect(type)}
                    >
                      Connect
                    </Button>
                  </div>
                </Tooltip>
              ) : (
                <Button
                  size="small"
                  color="secondary"
                  disabled={isDisabled}
                  variant="outlined"
                  onClick={() => handleConnect(type)}
                >
                  Connect
                </Button>
              )
            }
          />
        )}
      </Box>
    </WrapperBox>
  );
};
export default ListView;

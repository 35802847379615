import * as React from 'react';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import { Box, TextField, Tooltip, Typography } from '@mui/material';
import { IC_QUESTION } from 'assets/constants/imageConst';
// import { IC_EYE_OFF, IC_QUESTION, IC_EYE } from 'assets/constants/imageConst';
// import { ReactSVG } from 'react-svg';
import Icon from 'components/common/Icon/Icon';

const CustomInput = styled(TextField)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
    fontSize: '14px',
  },
  '& .MuiInputBase-input': {
    padding: '14px 16px',
  },
  '& .MuiInputAdornment-root': {
    paddingRight: '12px',
  },
}));

export default function Input({ label, info, ...props }) {
  // const [showPassword, setShowPassword] = React.useState(false);

  // const handleClickShowPassword = () => {
  //   setShowPassword(!showPassword);
  // };

  // const handleMouseDownPassword = (event) => {
  //   event.preventDefault();
  // };

  // const inputType = props.type === 'password' ? (showPassword ? 'text' : 'password') : props.type;
  const inputType = props.type;

  const InfoIconComponent = React.forwardRef(function InfoIconComponent(props, ref) {
    //  Spread the props to the underlying DOM element.
    return (
      <Box ml={1} {...props} ref={ref}>
        <Icon src={IC_QUESTION} size="small" />
      </Box>
    );
  });

  // const renderEndAdornment = () => {
  //   return (
  //     <InputAdornment position="end">
  //       <IconButton
  //         aria-label="toggle password visibility"
  //         onClick={handleClickShowPassword}
  //         onMouseDown={handleMouseDownPassword}
  //         edge="end"
  //       >
  //         {showPassword ? <img src={IC_EYE_OFF} /> : <img src={IC_EYE} />}
  //       </IconButton>
  //     </InputAdornment>
  //   );
  // };

  return (
    <FormControl variant="standard" fullWidth>
      {label && (
        <Box
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          mb={props?.subLabel ? '2px' : 1}
        >
          <Typography variant="subtitle2" color="text.primary" sx={{ display: 'flex' }}>
            {label}
            {info && (
              <Tooltip title={info} arrow placement="top">
                <InfoIconComponent />
              </Tooltip>
            )}
          </Typography>
          {props?.rightlabel && props?.rightlabel}
        </Box>
      )}
      {props?.sublabel && (
        <Typography variant="body2" mb={1}>
          {props?.sublabel}
        </Typography>
      )}

      <CustomInput {...props} type={inputType} />
      {/* endAdornment={props.type === 'password' ? renderEndAdornment() : ''} will check letter for show password eye functionality */}
    </FormControl>
  );
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider,useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import './global.css';

import './i18n';
import Auth0ProviderWithHistory from 'Auth';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
// React toast
import 'react-toastify/dist/ReactToastify.css';
// Slick slider
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import api from './services/apis/api';


// Internal
import configureStore from './store/configureStore';

axios.defaults.withCredentials = true;

const store = configureStore();

api.api.interceptors.request.use(async (request) => {
  const { tenantData } = store.getState().common;

	if (tenantData?.id)
		request.headers['tenant-id'] = tenantData?.id;
	return request;
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Auth0ProviderWithHistory>
          <App />
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
        </Auth0ProviderWithHistory>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

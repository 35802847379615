import * as actionTypes from '../actions/actionTypes';
import _ from 'lodash';

export const defaultState = {
  error: null,
  loading: false,
  tenantData: null,
  tenantNotesDocumentsCountLoading: false,
  tenantNotesDocumentsCount: [],
  tenantFrameworksLoading: false,
  tenantFrameworks: [],
  addTenantFrameworksLoading: false,
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {

    case actionTypes.GET_TENANT_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case actionTypes.GET_TENANT_SUCCESS:
      return {
        ...state,
        loading: false,
        tenantData: action.payload?.data?.payload,
      };

    case actionTypes.GET_TENANT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_TENANT_NOTES_DOCUMENT_COUNT_START:
      return {
        ...state,
        tenantNotesDocumentsCountLoading: true,
        error: null,
      };

    case actionTypes.GET_TENANT_NOTES_DOCUMENT_COUNT_SUCCESS:
      return {
        ...state,
        tenantNotesDocumentsCountLoading: false,
        tenantNotesDocumentsCount: _.get(action, ['payload', 'data', 'payload', 'data'], []),
      };

    case actionTypes.GET_TENANT_NOTES_DOCUMENT_COUNT_ERROR:
      return {
        ...state,
        tenantNotesDocumentsCountLoading: false,
        error: action.payload,
      };
    case actionTypes.ADD_TENANT_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case actionTypes.ADD_TENANT_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case actionTypes.ADD_TENANT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.UPDATE_TENANT_START:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case actionTypes.UPDATE_TENANT_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case actionTypes.UPDATE_TENANT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_TENANT_FRAMEWORKS_START:
      return {
        ...state,
        tenantFrameworksLoading: true,
        error: null,
      };

    case actionTypes.GET_TENANT_FRAMEWORKS_SUCCESS:
      return {
        ...state,
        tenantFrameworksLoading: false,
        tenantFrameworks: action.payload?.data?.payload?.tenant_frameworks,
      };

    case actionTypes.GET_TENANT_FRAMEWORKS_ERROR:
      return {
        ...state,
        tenantFrameworksLoading: false,
        error: action.payload,
      };
    case actionTypes.ADD_TENANT_FRAMEWORKS_START:
      return {
        ...state,
        addTenantFrameworksLoading: true,
        error: null,
      };

    case actionTypes.ADD_TENANT_FRAMEWORKS_SUCCESS: {
      return {
        ...state,
        addTenantFrameworksLoading: false,
      };
    }

    case actionTypes.ADD_TENANT_FRAMEWORKS_ERROR:
      return {
        ...state,
        addTenantFrameworksLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

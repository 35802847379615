import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import _ from 'lodash';
import { IC_PLUS } from 'assets/constants/imageConst';
// MUI
import { Box } from '@mui/material';
// Internal
import { Button } from 'components/common/FormControls';
import { getUsers } from 'store/actions/users';

import UserTable from './UserTable';
import AddUserModal from './AddUserModal';
import { breadcrumbsData } from 'assets/constants/const';
import { setBreadcrumbs } from 'store/actions/breadcrumbs';
import { SwipeableDrawer } from 'components/common';
import { deleteUser, resetPassword } from 'store/actions/user';
import { toast } from 'react-toastify';
import TenantRolesMapping from './TenantRolesMapping';

const UserManagment = () => {
    const dispatch = useDispatch();
    const [openAddModal, setOpenAddModal] = useState(false);
    const [openRolesModal, setOpenRolesModal] = useState(false);
    const [addModalData, setAddModalData] = useState(null);
    const [rolesModalData, setRolesModalData] = useState(null);
    const [dataPerPage, setDataPerPage] = useState(10);



    useEffect(() => {
        dispatch(setBreadcrumbs(breadcrumbsData.userManagment));
        dispatch(getUsers())
    }, []);

    const getUserList = (params) => {
        dispatch(getUsers(params))
    }
    const handleAddModal = (value, obj) => {
        setOpenAddModal(value);
        setAddModalData(obj);
    }

    const handleRolesModal = (value, obj) => {
        setOpenRolesModal(value);
        setRolesModalData(obj);
    }

    const handleDelete = (uuid) => {
        dispatch(deleteUser(uuid)).then(() => {
            toast.success('User deleted successfully');
            callUserList();
        })
    }

    const handleReset = (uuid) => {
        dispatch(resetPassword(uuid)).then(() => {
            toast.success('Password reset successfully');
        })
    }

    const callUserList = () => {
        setDataPerPage(10);
        dispatch(getUsers());

    }

    return (
        <>
            <Box sx={{ position: 'relative' }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} mb={3} mt={3}>
                    <Box>
                        <Button
                            startIcon={<ReactSVG src={IC_PLUS} />}
                            variant="filled"
                            onClick={() => { handleAddModal(true, null) }}
                        >
                            Add user
                        </Button>
                    </Box>
                </Box>
                <UserTable
                    getUserList={getUserList}
                    handleAddModal={handleAddModal}
                    handleReset={handleReset}
                    handleDelete={handleDelete}
                    dataPerPage={dataPerPage}
                    setDataPerPage={setDataPerPage}
                    handleRolesModal={handleRolesModal}

                />
            </Box>
            {openAddModal &&
                <SwipeableDrawer
                    anchor="right"
                    open={openAddModal}
                    onclose={() => handleAddModal(false)}
                    onopen={() => { }}
                    isFooter={true}
                    title={_.isNil(addModalData) ? 'Add User' : 'Update User'}
                    onSave={() => { }}
                    cancelText="Close"
                >
                    <AddUserModal
                        userDetail={addModalData}
                        openAddModal={openAddModal}
                        callUserList={callUserList}
                        handleAddModal={handleAddModal}
                    />
                </SwipeableDrawer>
            }

            {openRolesModal &&
                <SwipeableDrawer
                    anchor="right"
                    open={openRolesModal}
                    onclose={() => {
                        handleRolesModal(false);
                        callUserList();
                    }}
                    onopen={() => { }}
                    isFooter={false}
                    title={"Role-Tenant Mapping"}
                    onSave={() => { }}
                    cancelText="Close"
                >
                    <TenantRolesMapping 
                    tenantDetail={rolesModalData}
                    />
                </SwipeableDrawer>
            }
        </>
    );
};
export default UserManagment;

import api from './api';

class TenantAPI {
  getTenant(params) {
    let queryParams = '';
    if (params) {
      queryParams =
        Object.keys(params)
          .map(key => {
            return `${key}=${encodeURIComponent(params[key])}`;
          }).join('&');
    }
    return api.get(`tenant?${queryParams}`);
  }

  getTenantNoteAndDocument(uuid) {
    return api.get(`tenant/${uuid}/counts`);
  }

  AddTenant(data) {
    return api.post('/tenant', data);
  }
  
  updateTenant(uuid, data) {
    return api.put(`/tenant/${uuid}`, data);
  }

  getTenantFrameworks(uuid) {
    return api.get(`tenant/${uuid}/frameworks`);
  }

  AddTenantFrameworks(uuid,data) {
    return api.post(`tenant/${uuid}/frameworks`, data);
  }


}

export default TenantAPI;

import * as actionTypes from '../actions/actionTypes';
import _ from 'lodash';

export const defaultState = {
  error: null,
  addFrameworksLoading: false,
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {

   
    case actionTypes.ADD_UPDATE_FRAMEWORKS_START:
      return {
        ...state,
        addFrameworksLoading: true,
        error: null,
      };

    case actionTypes.ADD_UPDATE_FRAMEWORKS_SUCCESS: {
      return {
        ...state,
        addFrameworksLoading: false,
      };
    }

    case actionTypes.ADD_UPDATE_FRAMEWORKS_ERROR:
      return {
        ...state,
        addFrameworksLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
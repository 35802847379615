import { Chip as MUIChip } from '@mui/material';
import { styled } from '@mui/material/styles';

function Chip({ svgColor, ...rest }) {
  const CustomChip = styled(MUIChip)(({ theme }) => ({
    '&.MuiChip-root': {
      borderRadius: '20px',
      height: 'auto',
      padding: '4px 8px',
      backgroundColor: theme.palette.background.secondary,
      '&.MuiChip-sizeSmall': {
        padding: '2px 4px',
      },
      '&.MuiChip-colorDefault': {
        backgroundColor: theme.palette.background.secondary,
        '&:hover': {
          backgroundColor: theme.palette.secondary.light,
        },
      },
      '&.MuiChip-colorPrimary': {
        backgroundColor: theme.palette.primary.lighter,
        color: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: theme.palette.primary.light,
        },
        svg: {
          path: {
            fill: `${theme.palette.primary.main} !important`,
            fillOpacity: 1,
          },
        },
      },
      '&.MuiChip-colorWarning': {
        backgroundColor: theme.palette.warning.lighter,
        color: theme.palette.warning.main,
        '&:hover': {
          backgroundColor: theme.palette.warning.light,
        },
        svg: {
          path: {
            fill: `${theme.palette.warning.main} !important`,
            fillOpacity: 1,
          },
        },
      },
      '&.MuiChip-colorSuccess': {
        backgroundColor: theme.palette.success.lighter,
        color: theme.palette.success.main,
        '&:hover': {
          backgroundColor: theme.palette.success.light,
        },
        svg: {
          path: {
            fill: `${theme.palette.success.main} !important`,
            fillOpacity: 1,
          },
        },
      },
      '&.MuiChip-colorError': {
        backgroundColor: theme.palette.error.lighter,
        color: theme.palette.error.main,
        '&:hover': {
          backgroundColor: theme.palette.error.light,
        },
        svg: {
          path: {
            fill: `${theme.palette.error.main} !important`,
            fillOpacity: 1,
          },
        },
      },
      '.MuiChip-icon': {
        marginLeft: 0,
        marginRight: '4px',
        lineHeight: 0,
        '&.MuiChip-iconMedium svg': {
          width: '14px',
          height: '14px',
        },
        '&.MuiChip-iconSmall svg': {
          width: '12px',
          height: '12px',
        },
        '&.MuiChip-iconColorPrimary svg': {
          path: {
            fill: theme.palette.primary.main,
          },
        },
        '&.MuiChip-iconColorSuccess svg': {
          path: {
            fill: theme.palette.success.main,
          },
        },
        '&.MuiChip-iconColorError svg': {
          path: {
            fill: theme.palette.error.main,
          },
        },
        '&.MuiChip-iconColorWarning svg': {
          path: {
            fill: theme.palette.warning.main,
          },
        },
        '&.MuiChip-iconColorDefault svg': {
          path: {
            fill: svgColor ? theme.palette.text.primary : '',
          },
        },
      },
      '.MuiChip-label': {
        fontWeight: '400',
        paddingRight: 0,
        paddingLeft: 0,
        '&.MuiChip-labelMedium': {
          fontSize: '12px',
          lineHeight: '15px',
        },
        '&.MuiChip-labelSmall': {
          fontSize: '10px',
          lineHeight: '12px',
        },
      },
    },
  }));

  return <CustomChip {...rest} />;
}
Chip.defaultProps = {
  svgColor: true,
};
export default Chip;

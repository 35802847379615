import api from './api';

class ConnectorAPI {
  getConnectorRuns(uuid) {
    return api.get(`/connectors/${uuid}/runs`);
  }
  addConnectorRuns(uuid) {
    return api.post(`/connectors/${uuid}/runs`);
  }
  getConnectors(uuid) {
    return api.get(`/${uuid}/connectors`);
  }
  setConnector(data) {
    return api.post('/connectors', data);
  }
  uploadConnectorCertificate(data) {
    return api.postUpload('/files', data);
  }
  updateConnector(data) {
    return api.patch('/connectors', data);
  }
  getIntegrationDetail(uuid) {
    return api.get(`/connectors/${uuid}`);
  }
  deleteConnector(uuid) {
    return api.delete(`/connectors/${uuid}`);
  }
  updateDocument(data) {
    return api.post('/upload-document', data);
  }
  addControlLibraryDocument(tenant_uuid, control_code, data) {
    return api.post(`/control/${tenant_uuid}/${control_code}/document`, data);
  }
  getDocumentList(data) {
    return api.get(`/${data?.tenant_connector_id}/${data?.control_id}/documents`);
  }
  getControlLibraryDocumentList({ tenant_uuid, control_code }) {
    return api.get(`control/${tenant_uuid}/${control_code}/document`);
  }

  deleteDocument(uuid) {
    return api.delete(`document/${uuid}`);
  }
  downloadDocument(uuid, type, isPdf) {
    return api.get(`document/${uuid}?type=${type}&isPdf=${isPdf}`, { responseType: 'blob' });
  }
  addNotes(tenant_connector_uuid, control_code, data) {
    return api.post(`tenant_connector/${tenant_connector_uuid}/${control_code}/notes`, data);
  }
  addControlLibraryNotes(tenant_uuid, control_code, data) {
    return api.post(`tenant/${tenant_uuid}/${control_code}/notes`, data);
  }
  getNotes(tenant_connector_uuid, control_code) {
    return api.get(`tenant_connector/${tenant_connector_uuid}/${control_code}/notes`);
  }
  getControlLibraryNotesList(tenant_uuid, control_code) {
    return api.get(`tenant/${tenant_uuid}/${control_code}/notes`);
  }
  deleteNotes(uuid) {
    return api.delete(`notes/${uuid}`);
  }
  updateNotes(uuid, data) {
    return api.put(`notes/${uuid}`, data);
  }
  getFiles(fileName) {
    return api.get(`/files?file_name=${fileName}`);
  }
}

export default ConnectorAPI;

import { Box, TextField } from '@mui/material';
import React from 'react';

function Description() {
  return (
    <Box mt={2}>
      <TextField placeholder="Add your response description" fullWidth multiline rows={2} />
    </Box>
  );
}

export default Description;

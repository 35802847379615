import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { ReactSVG } from 'react-svg';

import { Box, Typography, Divider, styled, InputAdornment, alpha, MenuItem, FormHelperText } from '@mui/material';

import {
  numberOfUsers,
  numberOfRecords,
  sensitiveData,
  hostingModal,
  systemPriority,
  dummyOptions,
  healthImpactScore,
  sensitiveDataImpactScore,
  usersImpactScore,
} from 'assets/constants/options';
import { Avatar, Chip } from 'components/common';
import DashedBox from 'components/common/Wrapper/DashedBox';
import { ICON_DELETE, IC_UPLOAD, UP_ARROW } from 'assets/constants/imageConst';
import Input from 'components/common/FormField/Input/Input';
import { Button } from 'components/common/FormControls';
import Dropdown from 'components/common/FormField/Select/Dropdown';
import { connectorsName, impactWithRecords, impactWithSensitiveData, impactWithUsers } from 'assets/constants/const';

const InputField = styled(Input)(({ theme }) => ({
  '.MuiInputBase-root': {
    backgroundColor: alpha(theme.palette.text.primary, 0.04),
    padding: 0,
    '&.MuiInputBase-input': {
      padding: '14px 16px',
    },
  },
  '& fieldset': { border: 'none' },
}));

const calculateImpactScore = (score1 = 0, score2 = 0, score3 = 0) => Math.max(score1, score2, score3);

const SelectedImageView = ({ fieldname, profilePreview, deleteLogoHandler }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        columnGap: '5px',
        justifyContent: 'space-between',
        border: '0.5px solid #4f4a553b',
        padding: '8px',
        borderRadius: '6px',
        cursor: 'pointer',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '5px' }}>
        <Avatar
          alt="Avatar"
          name={fieldname}
          src={profilePreview}
          imgProps
          sx={{ width: 40, height: 40, borderRadius: '8px' }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Typography variant="subtitle2">me.png</Typography>
        </Box>
      </Box>

      <ReactSVG src={ICON_DELETE} onClick={deleteLogoHandler} />
    </Box>
  );
};

const UploadFile = React.memo(({ setFieldValue, fieldName, file, error }) => {
  const onSelect = (file) => {
    setFieldValue(fieldName, file);
  };

  const deleteLogoHandler = () => setFieldValue(fieldName, null);

  return file ? (
    <SelectedImageView fieldname={fieldName} profilePreview={file} deleteLogoHandler={deleteLogoHandler} />
  ) : (
    <FileUploader
      classes="document-file-upload"
      onSelect={onSelect}
      name={fieldName}
      types={['PNG', 'JPG', 'JPEG']}
      maxSize={5}
      onDrop={onSelect}
    >
      <DashedBox>
        <Box
          sx={{
            padding: '24px, 56px, 24px, 56px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            rowGap: '12px',
            '.document-file-upload': {
              outline: 'none !important',
            },
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '4px' }}>
            <Typography variant="subtitle2">Drop your image here or click the button below.</Typography>
            <Typography sx={{ lineHeight: '13.37px', fontSize: '11px' }}>
              Supports .png and .jpeg files, under 10MB
            </Typography>
          </Box>

          <Button variant="filled" sx={{ borderRadius: '8px', columnGap: '10px', width: 'fit-content' }}>
            <ReactSVG src={IC_UPLOAD} />
            <Typography variant="subtitle2">Upload file</Typography>
          </Button>
        </Box>
      </DashedBox>
      <FormHelperText error={Boolean(error)} sx={{ marginLeft: '14px !important' }}>
        {error}
      </FormHelperText>
    </FileUploader>
  );
});

UploadFile.displayName = 'UploadFile';

const formatPhoneNumber = (input) => {
  // Format the input as +1 (234) 567-8901
  const match = input.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
  if (match) {
    return `${match[1] ? `(${match[1]}` : `${match[1]}`}${match[2] ? `)-${match[2]}` : ''}${
      match[3] ? `-${match[3]}` : ''
    }${match[4] ? `-${match[4]}` : ''}`;
  }
  return input;
};

export const validatePhoneNumber = (input) => {
  const pattern = /^\(\d{3}\)[-]\d{3}[-]\d{4}$/;
  return pattern.test(input);
};

//-----------------------------------CUSTOM CONNECTOR-------------------------

export const CustomConnector = ({ form, type, activeStep, uuid }) => {
  const [isManualSelect, setIsManualSelect] = useState(false);
  const [isInitialEdit, setIsInitialEdit] = useState(false);

  const { values, touched, errors, setFieldValue, handleChange } = form;

  const { numberOfHealthRecords, typeOfSensitiveData, numberOfUsers: users } = values;

  const manualScoreHandler = () => {
    const score =
      calculateImpactScore(
        healthImpactScore[numberOfHealthRecords],
        sensitiveDataImpactScore[typeOfSensitiveData],
        usersImpactScore[users]
      ) || 'N/A';
    setFieldValue('impactScore', score);
  };

  useEffect(() => {
    if (isManualSelect) {
      setIsManualSelect(false);
    }
    if (isInitialEdit) {
      setIsInitialEdit(false);
    }
  }, [activeStep]);

  useEffect(() => {
    if (uuid && !isInitialEdit) {
      setIsInitialEdit(true);
      return;
    }
    if (!isManualSelect) {
      manualScoreHandler();
    }
  }, [numberOfHealthRecords, typeOfSensitiveData, users]);

  const preview = useMemo(
    () => (values?.logo instanceof File ? URL.createObjectURL(values?.logo) : values?.logo),
    [values?.logo]
  );

  return (
    <>
      {type == connectorsName.CUSTOM && activeStep == 0 && (
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '16px' }}>
          <Box>
            <Typography variant="subtitle2" color="text.primary" mb={1} sx={{ display: 'flex' }}>
              Application logo
            </Typography>
            <UploadFile
              setFieldValue={setFieldValue}
              fieldName="logo"
              file={preview}
              error={touched.logo && errors?.logo}
            />
          </Box>
          <InputField
            id="name"
            className="mb-3"
            fullWidth
            placeholder="ex. My Account"
            label="Application Name"
            name="name"
            value={values.name}
            onChange={handleChange}
            error={touched.name && Boolean(errors.name)}
            helperText={touched.name && errors.name}
          />
          <InputField
            id="accountName"
            className="mb-3"
            fullWidth
            placeholder="ex. My Account"
            label="Account Name"
            name="accountName"
            info="The account name or alias that uniquely define this integration."
            value={values.accountName}
            onChange={handleChange}
            error={touched.accountName && Boolean(errors.accountName)}
            helperText={touched.accountName && errors.accountName}
          />
          <InputField
            id="description"
            className="mb-3"
            fullWidth
            placeholder="Write a short description"
            label=" Application description"
            name="description"
            multiline
            rows={4}
            value={values.description}
            onChange={handleChange}
            error={touched.description && Boolean(errors.description)}
            helperText={touched.description && errors.description}
          />
          <InputField
            id="systemOwner"
            className="mb-3"
            fullWidth
            placeholder="ex. John Doe"
            label="System owner or admin"
            name="systemOwner"
            value={values.systemOwner}
            onChange={handleChange}
            error={touched.systemOwner && Boolean(errors.systemOwner)}
            helperText={touched.systemOwner && errors.systemOwner}
          />
        </Box>
      )}
      {type == connectorsName.VENDOR && activeStep == 0 && (
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '16px' }}>
          <Box>
            <Typography variant="subtitle2" color="text.primary" mb={1} sx={{ display: 'flex' }}>
              Vendor logo
            </Typography>
            <UploadFile
              setFieldValue={setFieldValue}
              fieldName="logo"
              file={preview}
              error={touched.logo && errors?.logo}
            />
          </Box>
          <InputField
            id="name"
            className="mb-3"
            fullWidth
            placeholder="ex. My Account"
            label="Vendor Name"
            name="name"
            value={values.name}
            onChange={handleChange}
            error={touched.name && Boolean(errors.name)}
            helperText={touched.name && errors.name}
          />
          <InputField
            id="description"
            className="mb-3"
            fullWidth
            placeholder="Write a short description"
            label=" Vendor description"
            name="description"
            multiline
            rows={4}
            value={values.description}
            onChange={handleChange}
            error={touched.description && Boolean(errors.description)}
            helperText={touched.description && errors.description}
          />
          <InputField
            id="contactName"
            className="mb-3"
            fullWidth
            placeholder="ex. John Doe"
            label="Vendor contact name"
            name="contactName"
            value={values.contactName}
            onChange={handleChange}
            error={touched.contactName && Boolean(errors.contactName)}
            helperText={touched.contactName && errors.contactName}
          />
          <InputField
            id="contactEmail"
            className="mb-3"
            fullWidth
            placeholder="ex. john@mycompany.com"
            label="Vendor contact email"
            name="contactEmail"
            value={values.contactEmail}
            onChange={handleChange}
            error={touched.contactEmail && Boolean(errors.contactEmail)}
            helperText={touched.contactEmail && errors.contactEmail}
          />
          <InputField
            id="contactPhoneNumber"
            className="mb-3"
            fullWidth
            placeholder="ex. (123)-456-7890"
            label="Vendor contact phone number"
            name="contactPhoneNumber"
            value={values.contactPhoneNumber}
            onChange={(e) => {
              let input = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters

              const formattedNumber = formatPhoneNumber(input);
              setFieldValue('contactPhoneNumber', formattedNumber);
            }}
            error={touched.contactPhoneNumber && Boolean(errors.contactPhoneNumber)}
            helperText={touched.contactPhoneNumber && errors.contactPhoneNumber}
          />
        </Box>
      )}
      {type == connectorsName.CUSTOM && activeStep == 1 && (
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '16px' }}>
          {uuid ? (
            values?.pollingInterval && (
              <InputField
                select
                id="pollingInterval"
                className="mb-3"
                fullWidth
                label="Polling Interval"
                sublabel="The interval at which this integration will run"
                name="pollingInterval"
                value={values?.pollingInterval || 'One Week'}
                onChange={handleChange}
                error={touched.pollingInterval && Boolean(errors.pollingInterval)}
                helperText={touched.pollingInterval && errors.pollingInterval}
              >
                {dummyOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </InputField>
            )
          ) : (
            <InputField
              select
              id="pollingInterval"
              className="mb-3"
              fullWidth
              label="Polling Interval"
              sublabel="The interval at which this integration will run"
              name="pollingInterval"
              value={values?.pollingInterval || 'One Week'}
              onChange={handleChange}
              error={touched.pollingInterval && Boolean(errors.pollingInterval)}
              helperText={touched.pollingInterval && errors.pollingInterval}
            >
              {dummyOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </InputField>
          )}
          <InputField
            id="customerId"
            className="mb-3"
            fullWidth
            placeholder="ex. 123abc0b3"
            label="Customer ID"
            name="customerId"
            info=""
            value={values.customerId}
            onChange={handleChange}
            error={touched.customerId && Boolean(errors.customerId)}
            helperText={touched.customerId && errors.customerId}
          />

          <InputField
            id="adminEmail"
            className="mb-3"
            fullWidth
            placeholder="ex. john@mycompany.com"
            label="Admin email"
            name="adminEmail"
            value={values.adminEmail}
            onChange={handleChange}
            error={touched.adminEmail && Boolean(errors.adminEmail)}
            helperText={touched.adminEmail && errors.adminEmail}
          />

          <InputField
            id="folderPath"
            className="mb-3"
            fullWidth
            placeholder="ex. localhost12345/system/folder"
            label="Root folder containing sensitive data"
            name="folderPath"
            value={values.folderPath}
            onChange={handleChange}
            error={touched.folderPath && Boolean(errors.folderPath)}
            helperText={touched.folderPath && errors.folderPath}
          />
        </Box>
      )}
      {((type == connectorsName.CUSTOM && activeStep == 2) || (type == connectorsName.VENDOR && activeStep == 1)) && (
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '16px' }}>
          <InputField
            id="impactScore"
            className="mb-3"
            fullWidth
            label="Impact Score"
            name="impactScore"
            placeholder="N/A"
            info="We automatically calculate your application impact scores depending on the information below. You can also override this field by entering any value between 1 and 10."
            value={values?.impactScore}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <ReactSVG
                    src={UP_ARROW}
                    className="down-arrow"
                    onClick={() => {
                      setFieldValue('impactScore', typeof values.impactScore == 'number' ? values.impactScore + 1 : 1);
                      !isManualSelect && setIsManualSelect(true);
                    }}
                  />
                  <ReactSVG
                    src={UP_ARROW}
                    onClick={() => {
                      setFieldValue(
                        'impactScore',
                        values.impactScore && values.impactScore > 1 ? values.impactScore - 1 : 1
                      );
                      !isManualSelect && setIsManualSelect(true);
                    }}
                  />
                </InputAdornment>
              ),
            }}
            // onChange={handleChange}
            error={touched.impactScore && Boolean(errors.impactScore)}
            helperText={touched.impactScore && errors.impactScore}
          />
          <Divider />
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} mb={1}>
              <Typography variant="subtitle2">Number of records</Typography>
              <Chip label={`Impact: ${impactWithRecords[numberOfHealthRecords] || 'N/A'}`} color="primary" />
            </Box>
            {uuid ? (
              numberOfHealthRecords && (
                <Dropdown
                  options={numberOfRecords}
                  name="numberOfHealthRecords"
                  formik={form}
                  placeholder="Select an option"
                />
              )
            ) : (
              <Dropdown
                options={numberOfRecords}
                name="numberOfHealthRecords"
                formik={form}
                placeholder="Select an option"
              />
            )}
          </Box>
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} mb={1}>
              <Typography variant="subtitle2"> Type of sensitive data</Typography>
              <Chip label={`Impact: ${impactWithSensitiveData[typeOfSensitiveData] || 'N/A'}`} color="primary" />
            </Box>
            {uuid ? (
              typeOfSensitiveData && (
                <Dropdown
                  options={sensitiveData}
                  name="typeOfSensitiveData"
                  formik={form}
                  placeholder="Select an option"
                />
              )
            ) : (
              <Dropdown
                options={sensitiveData}
                name="typeOfSensitiveData"
                formik={form}
                placeholder="Select an option"
              />
            )}
          </Box>
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} mb={1}>
              <Typography variant="subtitle2"> Number of users</Typography>
              <Chip label={`Impact: ${impactWithUsers[users] || 'N/A'}`} color="primary" />
            </Box>
            {uuid ? (
              users && (
                <Dropdown options={numberOfUsers} name="numberOfUsers" formik={form} placeholder="Select an option" />
              )
            ) : (
              <Dropdown options={numberOfUsers} name="numberOfUsers" formik={form} placeholder="Select an option" />
            )}
          </Box>
          <Box>
            <Typography mb={1} variant="subtitle2">
              System hosting model
            </Typography>
            {uuid ? (
              values?.systemHoistingModel && (
                <Dropdown
                  options={hostingModal}
                  name="systemHoistingModel"
                  formik={form}
                  placeholder="Select an option"
                />
              )
            ) : (
              <Dropdown
                options={hostingModal}
                name="systemHoistingModel"
                formik={form}
                placeholder="Select an option"
              />
            )}
          </Box>
          <Box>
            <Typography mb={1} variant="subtitle2">
              System priority
            </Typography>
            {uuid ? (
              values?.systemPriority && (
                <Dropdown options={systemPriority} name="systemPriority" formik={form} placeholder="Select an option" />
              )
            ) : (
              <Dropdown options={systemPriority} name="systemPriority" formik={form} placeholder="Select an option" />
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

import React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import WrapperBox from 'components/common/Wrapper/WrapperBox';
import { Button } from 'components/common/FormControls';
import { ReactSVG } from 'react-svg';
import {
  FILE_DARK,
  IC_CHECK_CIRCLE,
  IC_DOWNLOAD,
  IC_SHIELD,
  IC_LOCK_CHECK,
  IC_WARNING_ALT,
} from 'assets/constants/imageConst';
import Title from 'components/common/Wrapper/Title';
import { Avatar, Chip, CircularProgressbar, LinearProgressbar } from 'components/common';
import { pnpsData, pnpsData2 } from 'assets/constants/const';

const Card = ({ icon, title, progress, listData }) => {
  return (
    <WrapperBox padding="16px">
      <Box mb={2.5}>
        <Avatar sx={{ mb: 1 }}>
          <ReactSVG src={icon} />
        </Avatar>
        <Typography variant="body2" mb={2}>
          {title}
        </Typography>
        <LinearProgressbar
          value={progress}
          color="primary"
          label={
            <Typography variant="body2" ml={1}>
              {progress}%
            </Typography>
          }
        />
      </Box>
      <Divider />
      <Box mt={2}>
        {listData.map((value, index) => (
          <React.Fragment key={index}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 1.5 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box mr={1.25}>
                  {value.status === 'Completed' ? (
                    <ReactSVG src={IC_CHECK_CIRCLE} className="primary-svg" />
                  ) : (
                    <CircularProgressbar value={95} size="20px" color="warning" />
                  )}
                </Box>
                <Box>
                  <Typography variant="body2">{value.title}</Typography>
                  <Typography variant="caption" color="secondary">
                    {value.subtitle}
                  </Typography>
                </Box>
              </Box>
              <Box>...</Box>
            </Box>
            <Divider />
          </React.Fragment>
        ))}
      </Box>
    </WrapperBox>
  );
};

export default function NonTechnicalEvaluation() {
  return (
    <WrapperBox>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} mb={1}>
        <Title title="Non-Technical Evaluation" />
        <Box>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            startIcon={<ReactSVG src={IC_DOWNLOAD} className="download-small-icon" />}
          >
            Auditor report
          </Button>
        </Box>
      </Box>
      <Box mb={5}>
        <Chip label="45 CFR §164.308(a)(1)(ii)(A)" icon={<ReactSVG src={FILE_DARK} />} />
      </Box>
      <Box>
        <Box mb={1}>
          <Typography variant="overline" color="secondary">
            HIPAA Compliance Assessment
          </Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Card
              title="HIPAA Security Rule"
              subtitle="Ensure your teams know about common phishing practices and best security practices. "
              progress={95}
              icon={IC_LOCK_CHECK}
              listData={pnpsData}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card
              title="HIPAA Privacy Rule"
              subtitle="Ensure your teams are aware of your company’s ethical principles."
              progress={100}
              icon={IC_SHIELD}
              listData={pnpsData2}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card
              title="Breach Notification Rule"
              subtitle="Ensure your teams are aware of your company’s ethical principles."
              progress={100}
              icon={IC_WARNING_ALT}
              listData={pnpsData2}
            />
          </Grid>
        </Grid>
      </Box>
    </WrapperBox>
  );
}

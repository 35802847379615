import { useState, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import Nav from './nav';
import Header from './header';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Box } from '@mui/material';
import { Button } from 'components/common/FormControls';
import { Roles } from 'utils/general';

// ----------------------------------------------------------------------

const APP_BAR_DESKTOP = 64;

const NOT_TENANT_DEPENDENT_PATH = [
  '/tenants',
  '/users',
  '/control-management',
  '/framework-management'
]

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  background: '#F1EFF7',
  height: '100vh',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  marginTop: APP_BAR_DESKTOP + 24,
  padding: '0 56px 25px 56px',
  [theme.breakpoints.down('md')]: {
    padding: '0 15px 15px 15px',
  },
}));

const scrollToTop = () => {
  document.getElementById('main').scroll(0, 0);
};

// ----------------------------------------------------------------------

const Layout = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();
  const { breadcrumbs } = useSelector((state) => state.breadcrumbs);
  const { loading, error, tenantData, meLoading, userPermissionLoading, userProfile } = useSelector((state) => state.common);

  useEffect(() => {
    scrollToTop();
  }, [pathname]);

  return (
    <StyledRoot>
      {!loading && error ? (
        <div id="main"></div>
      ) : (
        <>
          <Header onOpenNav={() => setOpen(true)} breadcrumbs={breadcrumbs} />
          <Nav openNav={open} onCloseNav={() => setOpen(false)} />
          <Main id="main">
            {!(meLoading || userPermissionLoading) && _.isEmpty(tenantData) ?
              !NOT_TENANT_DEPENDENT_PATH.includes(pathname) && [Roles.SUPER_USER, Roles.CHANNEL_PARTNER].includes(userProfile?.role) ?
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  height="80vh"
                >
                  <Box>
                    <h2>No Tenant have been Connected yet.</h2>
                  </Box>
                  <Button variant="filled" onClick={() => { navigate('/tenants') }}>
                    connect
                  </Button>
                </Box>
                :
                ![Roles.SUPER_USER, Roles.CHANNEL_PARTNER].includes(userProfile?.role) ?
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    height="80vh"
                  >
                    <Box>
                      <h2>Please contact to your administator to connect to any tenant.
                        .</h2>
                    </Box>
                  </Box>
                  : <Outlet /> :
              <Outlet />
            }


          </Main>
        </>
      )}
    </StyledRoot>
  );
};
export default Layout;

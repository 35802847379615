import PropTypes from 'prop-types';
import { StyledNavItem, StyledNavItemIcon } from './styles';
import { NavLink as RouterLink } from 'react-router-dom';
import { ListItemText } from '@mui/material';

NavItem.propTypes = {
  item: PropTypes.object,
};

export function NavItem({ item }) {
  const { title, path, icon } = item;

  return (
    <StyledNavItem
      component={path ? RouterLink : ''}
      to={path || ''}
      sx={{
        '&.active': {
          color: 'white',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
        },
        '&:hover': {
          color: 'white',
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
      <ListItemText disableTypography primary={title} />
    </StyledNavItem>
  );
}

import React from 'react';
import { styled, alpha } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { InputBase } from '@mui/material';
import { IC_SEARCH } from 'assets/constants/imageConst';
import Icon from '../Icon/Icon';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.text.primary, 0.04),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    // marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    // padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
  },
}));

function SearchInput({ onblur, onChange, searchValue }) {
  return (
    <Search onBlur={onblur}>
      <SearchIconWrapper>
        <Icon size="small" src={IC_SEARCH} />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder="Search…"
        onChange={onChange}
        value={searchValue}
        inputProps={{ 'aria-label': 'search' }}
      />
    </Search>
  );
}

SearchInput.defaultProps = {
  onblur: () => {},
  onChange: () => {},
  searchValue: '',
};

SearchInput.propTypes = {
  onblur: PropTypes.func,
  onChange: PropTypes.func,
  searchValue: PropTypes.string,
};

export default SearchInput;

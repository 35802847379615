import { useState } from 'react';
import { ReactSVG } from 'react-svg';
import { Typography, Box, List, ListItemText, ListItemButton, Divider, Collapse } from '@mui/material';

//Internal
import WrapperBox from 'components/common/Wrapper/WrapperBox';
import { Chip } from 'components/common';
import Title from 'components/common/Wrapper/Title';
import { FILE, IC_ARROW_DOWN } from 'assets/constants/imageConst';
import ReportSettings from './ReportSettings';
import ResultSummary from './ResultSummary';
import DetailedResult from './DetailedResult';

const collapseData = [
  { label: 'Report Settings', component: <ReportSettings /> },
  { label: 'Results Summary', component: <ResultSummary /> },
  { label: 'Detailed Results', component: <DetailedResult /> },
];

const TechnicalEvaluation = () => {
  const [open, setOpen] = useState([]);

  const handleCollapse = (label) => {
    const index = open.indexOf(label);
    if (index > -1) {
      setOpen((pre) => pre.filter((p) => p !== label));
    } else {
      setOpen((pre) => [...pre, label]);
    }
  };

  return (
    <WrapperBox>
      <Title
        title="Technical Evaluation"
        subtitle={<Chip label="45 CFR §164.308(a)(8))" icon={<ReactSVG src={FILE} />} />}
      />
      <Typography mt={4} variant="subtitle2">
        Vulnerability Scans
      </Typography>
      <Typography mt={0.5} variant="body2" color="secondary">
        Qualys Monthly Scans
      </Typography>
      <Box mt={4}>
        <List component="nav">
          {collapseData?.map((cd, index) => (
            <Box key={index}>
              <ListItemButton onClick={() => handleCollapse(cd.label)} sx={{ paddingBottom: '15px', lineHeight: 0 }}>
                {open.includes(cd.label) ? (
                  <ReactSVG src={IC_ARROW_DOWN} className="rotate180" />
                ) : (
                  <ReactSVG src={IC_ARROW_DOWN} />
                )}
                <ListItemText
                  primary={cd.label}
                  primaryTypographyProps={{
                    color: 'secondary',
                    variant: 'body2',
                    textTransform: 'uppercase',
                    fontSize: '10px',
                    marginLeft: '11px',
                  }}
                />
              </ListItemButton>
              <Collapse in={open.includes(cd.label)} timeout="auto" unmountOnExit sx={{ marginBottom: '16px' }}>
                {cd.component}
              </Collapse>
              {collapseData.length !== index + 1 && <Divider />}
            </Box>
          ))}
        </List>
      </Box>
    </WrapperBox>
  );
};
export default TechnicalEvaluation;

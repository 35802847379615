import api from './apis/api';
import { dataTransformer, updateConnectorDataTransformer } from './transformers/connectorTransformer';

class ConnectorServices {
  getConnectorRuns(uuid) {
    return api.connectors.getConnectorRuns(uuid);
  }
  addConnectorRuns(uuid) {
    return api.connectors.addConnectorRuns(uuid);
  }
  getIntegrationDetail(uuid) {
    return api.connectors.getIntegrationDetail(uuid);
  }
  deleteConnector(uuid) {
    return api.connectors.deleteConnector(uuid).then(dataTransformer);
  }
  getConnectors(uuid) {
    return api.connectors.getConnectors(uuid);
  }
  setConnector(data) {
    return api.connectors.setConnector(data).then(dataTransformer);
  }
  uploadConnectorCertificate(data) {
    return api.connectors.uploadConnectorCertificate(data).then(dataTransformer);
  }
  uploadProfilePic(data) {
    return api.connectors.uploadConnectorCertificate(data);
  }
  updateConnector(data) {
    return api.connectors.updateConnector(data).then(updateConnectorDataTransformer);
  }
  updateDocument(data) {
    return api.connectors.updateDocument(data);
  }
  addControlLibraryDocument(tenant_uuid, control_code, data) {
    return api.connectors.addControlLibraryDocument(tenant_uuid, control_code, data);
  }
  getDocumentList(data) {
    return api.connectors.getDocumentList(data);
  }
  getControlLibraryDocumentList({ tenant_uuid, control_code }) {
    return api.connectors.getControlLibraryDocumentList({ tenant_uuid, control_code });
  }

  deleteDocument(uuid) {
    return api.connectors.deleteDocument(uuid);
  }
  downloadDocument(uuid, type, isPdf) {
    return api.connectors.downloadDocument(uuid, type, isPdf);
  }
  addNotes(tenant_connector_uuid, control_code, data) {
    return api.connectors.addNotes(tenant_connector_uuid, control_code, data);
  }
  addControlLibraryNotes(tenant_uuid, control_code, data) {
    return api.connectors.addControlLibraryNotes(tenant_uuid, control_code, data);
  }
  getNotes(tenant_connector_uuid, control_code) {
    return api.connectors.getNotes(tenant_connector_uuid, control_code);
  }
  getControlLibraryNotesList(tenant_uuid, control_code) {
    return api.connectors.getControlLibraryNotesList(tenant_uuid, control_code);
  }
  deleteNotes(uuid) {
    return api.connectors.deleteNotes(uuid);
  }
  updateNotes(uuid, data) {
    return api.connectors.updateNotes(uuid, data);
  }
  getFiles(fileName) {
    return api.connectors.getFiles(fileName);
  }
}

export default new ConnectorServices();
